import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeDureesConges(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/duration-leaves`, {
            params: {
                nom: filterValue,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveDureeConge = async (dureeConge) => {
    return instance.post(`${baseURL}/duration-leaves`, dureeConge).then(HandleResponse).catch(HandleError);
};

const updateDureeConge = async (dureeConge) => {
    return instance.put(`${baseURL}/duration-leaves/${dureeConge.id}`, dureeConge).then(HandleResponse).catch(HandleError);
};

const deleteDureeConge = async (id) => {
    return instance.delete(`${baseURL}/duration-leaves/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeDureesConges,
    saveDureeConge,
    updateDureeConge,
    deleteDureeConge
};

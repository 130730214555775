import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getTraining(params) {
    try {
        const response = await instance.get(
            `${uri}/trainings?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addTraining = async (values) => {
    try {
        const response = await instance.post(`${uri}/trainings`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTraining = async (values) => {
    try {
        const response = await instance.put(`${uri}/trainings/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTraining = async (id) => {
    try {
        const response = await instance.delete(`${uri}/trainings/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const changeStatusTraining = async (values) => {
    try {
        const response = await instance.patch(`${uri}/trainings/${values.training.id}/${values.statusParam}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addParticipants = async (dataParticipants) => {
    try {
        const response = await instance.post(`${uri}/training/register/batch`, dataParticipants);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTraining, addTraining, updateTraining, deleteTraining, changeStatusTraining, addParticipants };

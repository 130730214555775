import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';

const TableDemandeCongeToPrint = ({ data }) => {
    const intl = useIntl();
    console.log('DATA', data);
    const FormatDate = (date) => {
        const intl = useIntl();
        return date
            .toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(/,/, '');
    };

    return (
        <>
            <table className="printTable" style={{ border: 'none' }} width="100%">
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'infos-demandeur' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'matricule' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.matriculeNumber : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'firstName' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.firstName : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>
                                <FormattedMessage id="lastName" />
                            </Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.lastName : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'gender' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.gender : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'nationalite' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.nationality : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'phone' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.mobilePhoneNumber : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'mail' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.email : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'direction' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.direction : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'service' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.service : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'grades' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data?.employee?.personalGrade ? data?.employee?.personalGrade?.code : '-'}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'location' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.employee ? data?.employee?.address2 : '-'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'infos-conge' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'types-conges' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data?.typeConge ? `${data?.typeConge?.designation} - ${data?.typeConge?.sorteConge?.code}` : '-'}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'duration' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {`${intl.formatMessage({ id: 'from' })} ${
                                        data?.startDate ? FormatDate(new Date(data.startDate)) : ''
                                    } ${intl.formatMessage({
                                        id: 'to'
                                    })} ${data?.endDate ? FormatDate(new Date(data.endDate)) : ''}  (${
                                        data?.numberDays ? data?.numberDays : 0
                                    }) ${intl.formatMessage({ id: 'joursDemandes' })}`}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'reason' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.reason ? data?.reason : 'Sans'}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'date-demande' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data?.requestDate ? FormatDate(new Date(data?.requestDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'infos-decision' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'statut' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                {data.state === 'GRANTED' && (
                                    <Typography sx={{ marginRight: 5 }}>{`${intl.formatMessage({ id: 'accepte' })} `}</Typography>
                                )}
                                {data.state === 'REFUSED' && (
                                    <Typography sx={{ marginRight: 5 }}>
                                        {/* {`${intl.formatMessage({ id: 'refus' })} ${data ? data?.reason : 'Sans'} `} */}
                                        {`${intl.formatMessage({ id: 'refus' })}`}
                                    </Typography>
                                )}
                                {data.state === 'IN_TREATMENT' && (
                                    <Typography sx={{ marginRight: 5 }}>{`${intl.formatMessage({ id: 'pending' })} `}</Typography>
                                )}
                            </Stack>
                        </td>
                    </tr>
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'decisionDate' })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionDate ? FormatDate(new Date(data?.decisionDate)) : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'reason' })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionReason ? data?.decisionReason : 'Sans'}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 't-balance' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {`${data?.numberDays ? data?.numberDays : 0} ${intl.formatMessage({ id: 'joursUtilises' })}`}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }} />
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {`${data?.numberDays ? data?.numberDays : 0} ${intl.formatMessage({ id: 'joursManquant' })}`}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'decisionneur' })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee
                                                ? `${data?.decisionEmployee?.firstName} ${data?.decisionEmployee?.lastName}`
                                                : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'phoneDecisionneur' })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee ? `${data?.decisionEmployee?.telephoneNumber}` : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'mailDecisionneur' })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee ? `${data?.decisionEmployee?.email}` : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                        id: 'directionDecisionneur'
                                    })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee ? `${data?.decisionEmployee?.direction}` : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                        id: 'serviceDecisionneur'
                                    })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee ? `${data?.decisionEmployee?.service}` : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                        id: 'grades'
                                    })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee
                                                ? `${data?.decisionEmployee?.personalGrade?.echelon} - ${data?.decisionEmployee?.personalGrade?.code}`
                                                : 'Directeur'}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    {data.state === 'GRANTED' ||
                        (data.state === 'REFUSED' && (
                            <tr style={{ border: 'none' }}>
                                <td style={{ border: 'none' }} width="38%">
                                    <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                        id: 'location'
                                    })} `}</Typography>
                                </td>
                                <td style={{ border: 'none' }} width="60%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <div>: </div>
                                        <Typography sx={{ marginRight: 5 }}>
                                            {data?.decisionEmployee ? `${data?.decisionEmployee?.address2}` : ''}
                                        </Typography>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {/* ========================== TO DELETE HERE ===================== */}
        </>
    );
};
TableDemandeCongeToPrint.propTypes = {
    data: PropTypes.object.isRequired
};
export default TableDemandeCongeToPrint;

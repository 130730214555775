import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { measureApi } from 'api';
import TableSanctionAppealing from './TableSanctionAppealing';
import AddSanctionAppealing from './AddSanctionAppealing';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import SanctionDecisionDialog from './SanctionDecisionDialog';

const initialValues = {
    id: '',
    sanctionedEmployee: null,
    disciplinaryMeasure: null,
    reason: '',
    reasonDecision: '',
    decisionStatus: ''
};

const SanctionAppealing = ({ ...props }) => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataSanctionAppealing, setDataSanctionAppealing] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState({ delete: false, approuve: false });

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'center'
        },
        {
            id: 'employee',
            numeric: true,
            label: 'employee',
            align: 'left'
        },
        {
            id: 'appealingDate',
            numeric: true,
            label: 'appealingDate',
            align: 'right'
        },
        {
            id: 'reason',
            numeric: true,
            label: 'reason',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'center'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const setState = (row) => {
        const appealing = {
            id: row.id,
            appealingDate: new Date(row.appealingDate),
            sanctionAppealingStatus: row.sanctionAppealingStatus,
            reason: row.reason
        };
        let resultAppealings = [];
        if (yourState.id === '') {
            const dataAppealings = yourState.disciplinaryMeasure?.sanctionAppealings;
            dataAppealings.push(appealing);
            resultAppealings = dataAppealings;
        } else {
            resultAppealings = [appealing];
        }
        const result = {
            id: '',
            sanctionedEmployee: null,
            disciplinaryMeasure: {
                ...yourState.disciplinaryMeasure,
                sanctionAppealings: resultAppealings
            },
            reason: '',
            reasonDecision: '',
            decisionStatus: ''
        };
        setYourState(result);
    };

    const refleshTable = async (params) => {
        setLoading(true);
        await measureApi
            .getSanctionAppealing(params)
            .then((res) => {
                if (res.success) {
                    const result = [];
                    console.log(res.data);
                    res.data.data.content.forEach((row) => {
                        result.push({
                            id: row.id,
                            sanctionedEmployee: row.sanctionedEmployee,
                            appealingDate: new Date(row.appealingDate),
                            sanctionAppealingStatus: row.sanctionAppealingStatus,
                            reason: row.reason
                        });
                    });
                    setDataSanctionAppealing({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: res.data.data?.totalPages || 0,
                        totalItems: res.data.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataSanctionAppealing.rows.filter((row) => {
                let matches = true;

                const properties = ['appealingDate', 'reason'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataSanctionAppealing({ ...dataSanctionAppealing, rows: newRows });
        } else {
            setDataSanctionAppealing({ ...dataSanctionAppealing, rows: dataSanctionAppealing.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddSanctionAppealing
                api={measureApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
                setState={setState}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const handleClickOpenApprouveDialog = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, approuve: true });
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        const result = {
            ...row,
            disciplinaryMeasure: {
                id: '',
                employee: row.sanctionedEmployee,
                baseSalary: '',
                sanctionDate: '',
                startDate: '',
                endDate: '',
                durationInMonths: '',
                retainedPercentage: '',
                retainedAmount: '',
                retentionMonths: [],
                disciplinaryMeasureStatus: '',
                reason: '',
                sanctionTypeId: '',
                sanctionType: '',
                sanctionAppealings: [
                    {
                        id: row.id,
                        appealingDate: new Date(row.appealingDate),
                        sanctionAppealingStatus: row.sanctionAppealingStatus,
                        reason: row.reason
                    }
                ]
            }
        };
        setYourState(result);
        setOpen(true);
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, delete: true });
    };

    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete.delete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete({ ...openDelete, delete: false });
                }}
                open={openDelete.delete}
                text="Are you sure you want to delete this sanction applealing ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={measureApi.deleteSanctionAppealing}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const HandleOpenApprouveDialog = () => {
        const approuveDialog = openDelete.approuve ? (
            <SanctionDecisionDialog
                api={measureApi}
                snackAlert={snackAlert}
                params={params}
                open={openDelete}
                setOpen={setOpenDelete}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return approuveDialog;
    };

    return (
        <>
            {HandleOpenApprouveDialog()}
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            <Grid container spacing={gridSpacing}>
                <HeaderComponent header="sanction-appealing-list" subtitle="view-your-sanction-appealing" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataSanctionAppealing({ ...dataSanctionAppealing, rows: dataSanctionAppealing.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableSanctionAppealing
                                actions
                                params={params}
                                dataSanctionAppealing={dataSanctionAppealing.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                approuveFunction={handleClickOpenApprouveDialog}
                                setCurrentSanctionAppealing={props.setCurrentSanctionAppealing}
                                open={props.open}
                                setOpen={props.setOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

SanctionAppealing.propTypes = {
    setCurrentSanctionAppealing: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default SanctionAppealing;

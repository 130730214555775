import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeLettre(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/job-postings`, {
            params: {
                codeDesignation: filterValue || null,
                page: paginationDetails ? paginationDetails.page : null,
                size: paginationDetails ? paginationDetails.size : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveLettre = async (lettre) => {
    return instance.post(`${baseURL}/job-postings`, lettre).then(HandleResponse).catch(HandleError);
};

const updateLettre = async (lettre) => {
    return instance.put(`${baseURL}/job-postings/${lettre.id}`, lettre).then(HandleResponse).catch(HandleError);
};

const deleteLettre = async (id) => {
    return instance.delete(`${baseURL}/job-postings/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeLettre,
    saveLettre,
    updateLettre,
    deleteLettre
};

import { useEffect, useState } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { employeeVisaApi, typeVisaApi } from 'api';
import TableEmployeeVisa from './TableEmployeeVisa';
import AddEmployeeVisa from './AddEmployeeVisa';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const initialValues = {
    id: '',
    emissionDate: new Date(),
    expirationDate: new Date(),
    deliveryCountry: '',
    numeroVisa: '',
    idTypeVisa: '',
    employee: null,
    typeVisa: null
};

const EmployeeVisa = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataEmployeeVisa, setDataEmployeeVisa] = useState({ rows: [], rowsInitial: [] });
    const [dataTypeVisa, setDataTypeVisa] = useState([]);
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const headCells = [
        {
            id: 'code',
            numeric: true,
            label: 'code',
            align: 'left'
        },
        {
            id: 'employee',
            numeric: true,
            label: 'employee',
            align: 'left'
        },
        {
            id: 'numeroVisa',
            numeric: true,
            label: 'numeroVisa',
            align: 'left'
        },
        {
            id: 'typeVisa',
            numeric: true,
            label: 'typeVisa',
            align: 'left'
        },
        {
            id: 'emissionDate',
            numeric: true,
            label: 'emissionDate',
            align: 'right'
        },
        {
            id: 'expirationDate',
            numeric: true,
            label: 'expirationDate',
            align: 'right'
        },
        {
            id: 'deliveryCountry',
            numeric: true,
            label: 'deliveryCountry',
            align: 'left'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const getTypeVisa = async () => {
        setLoading(true);
        await typeVisaApi
            .getTypeVisa()
            .then((res) => {
                if (res.success) {
                    const result =
                        res.data.length > 0
                            ? res.data.map((row) => {
                                  return {
                                      id: row.id,
                                      code: row.code,
                                      designation: row.designation
                                  };
                              })
                            : [];
                    setDataTypeVisa(result);
                } else {
                    setDataTypeVisa([]);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const refleshTable = async (params) => {
        getTypeVisa();
        setLoading(true);
        await employeeVisaApi
            .getEmployeeVisa()
            .then((res) => {
                if (res.success) {
                    const result = [];
                    res.data.forEach((row) => {
                        result.push({
                            id: row.id,
                            emissionDate: new Date(row.emissionDate),
                            expirationDate: new Date(row.expirationDate),
                            deliveryCountry: row.deliveryCountry,
                            numeroVisa: row.numeroVisa,
                            idTypeVisa: row.visaType.id,
                            employee: {
                                id: row.employee.id,
                                code: row.employee.code,
                                firstName: row.employee.firstName,
                                lastName: row.employee.lastName
                            },
                            typeVisa: {
                                id: row.visaType.id,
                                code: row.visaType.code,
                                designation: row.visaType.designation
                            }
                        });
                    });
                    setDataEmployeeVisa({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: Math.ceil(res.data.length / params.rowsPerPage) || 1,
                        totalItems: res.data.length || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        refleshTable(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        setParams({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        setParams({
            ...params,
            length: Math.ceil(dataEmployeeVisa.rowsInitial.length / parseInt(event?.target.value, 10)) || 1,
            page: 0,
            rowsPerPage: parseInt(event?.target.value, 10)
        });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataEmployeeVisa.rows.filter((row) => {
                let matches = true;

                const properties = ['employee', 'numeroVisa'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataEmployeeVisa({ ...dataEmployeeVisa, rows: newRows });
        } else {
            setDataEmployeeVisa({ ...dataEmployeeVisa, rows: dataEmployeeVisa.rowsInitial });
        }
    };

    const handleClickSearch = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddEmployeeVisa
                api={employeeVisaApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                dataTypeVisa={dataTypeVisa}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };
    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete(true);
    };
    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete(false);
                }}
                open={openDelete}
                text="Are you sure you want to delete this employee visa ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={employeeVisaApi.deleteEmployeeVisa}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };
    return (
        <>
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            <Grid container spacing={2}>
                <HeaderComponent header="employee-visa-list" subtitle="view-your-employee-visa" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataEmployeeVisa({ ...dataEmployeeVisa, rows: dataEmployeeVisa.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableEmployeeVisa
                                actions
                                params={params}
                                dataEmployeeVisa={dataEmployeeVisa.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default EmployeeVisa;

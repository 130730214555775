import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { typePositionAPI, sortePositionAPI } from 'api';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import TypePositionTable from './TypePositionTable';
import TypePositionModal from './TypePositionModal';

const TypePosition = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [typePositionListe, setTypePositionListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listeSortesPositions, setListeSortesPositions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [typePositionToDelete, setTypePositionToDelete] = useState(null);
    const [typePositionToEdit, setTypePositionToEdit] = useState({
        code: '',
        designation: '',
        sortPositionId: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const typePositionPromise = typePositionAPI.getTypesPositions(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const sortePositionPromise = sortePositionAPI.getSortesPosition('', 100, 0);
        axios
            .all([typePositionPromise, sortePositionPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const typePositionsData = responses[0]?.content;
                        const sortesPositions = responses[1]?.content?.map((sc) => ({
                            label: `${sc.code} | ${sc.designation}`,
                            value: sc.id
                        }));
                        const typePositionData = [];
                        typePositionsData.forEach((row) => {
                            typePositionData.push({
                                id: row.id,
                                sortPositionId: row.sortPosition,
                                designation: row.designation,
                                code: row.code
                            });
                        });
                        setTypePositionListe((typePositionListe) => ({
                            ...typePositionListe,
                            ...responses[0],
                            content: typePositionData
                        }));
                        setListeSortesPositions(sortesPositions);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setTypePositionToEdit({
                ...row,
                sortPositionId: {
                    label: `${row?.sortPositionId?.code} | ${row?.sortPositionId?.designation}`,
                    value: row?.sortPositionId?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            typePositionAPI
                .getTypesPositions()
                .then((res) => {
                    setTypePositionListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setTypePositionToEdit({
            reason: '',
            sortPositionId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (typePosition) => {
        setTypePositionToDelete(typePosition);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (typePositionToDelete) => {
        if (typePositionToDelete) {
            setTypePositionListe(typePositionListe.filter((typePosition) => typePosition.id !== typePositionToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getTypesPositions = (pageNumber, pageSize) => {
        setLoading(true);
        let typePositionPromise;
        if (!pageNumber && !pageSize) {
            typePositionPromise = typePositionAPI.getTypesPositions(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            typePositionPromise = typePositionAPI.getTypesPositions(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([typePositionPromise])
            .then(
                axios.spread((...responses) => {
                    const typePositionsData = [];
                    responses[0]?.content?.forEach((row) => {
                        typePositionsData.push({
                            id: row.id,
                            code: row.code,
                            designation: row.designation,
                            sortPositionId: row.sortPositionId
                        });
                    });
                    setTypePositionListe((typePositionListe) => ({
                        ...typePositionListe,
                        ...responses[0],
                        content: typePositionsData
                    }));
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        typePositionAPI
            .deleteTypesPositions(typePositionToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getTypesPositions();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getTypesPositions(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getTypesPositions(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (typePositionToEdit.id) {
            setLoading(true);
            typePositionAPI
                .updateTypesPositions({
                    id: typePositionToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getTypesPositions();
                    setTypePositionToEdit({
                        code: '',
                        designation: '',
                        sortPositionId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            typePositionAPI
                .saveTypesPositions({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setTypePositionToEdit({
                        code: '',
                        designation: '',
                        sortPositionId: null
                    });
                    getTypesPositions();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFilterList = (event) => {
        const newString = event?.target?.value;
        setFilterValue(newString);

        if (newString) {
            const newRows = typePositionListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setTypePositionListe({ ...typePositionListe, content: newRows });
        } else {
            getTypesPositions();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'typesPositions' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-type-position' })} />
            {successFetch ? (
                <>
                    <TypePositionTable
                        typePositionListe={{
                            content: typePositionListe.content,
                            totalPages: typePositionListe.totalPages,
                            totalElements: typePositionListe.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        filterValue={filterValue}
                        getTypesPositions={getTypesPositions}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        handleFilterList={handleFilterList}
                    />
                    <TypePositionModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        typePositionToEdit={typePositionToEdit}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        listeSortesPositions={listeSortesPositions}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'types-position' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default TypePosition;

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const FiscalSection = () => {
    const theme = useTheme();
    const intl = useIntl();
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                    ml: 1
                }
            }}
        >
            <Typography sx={{ fontWeight: 'bold' }}>{`${intl.formatMessage({
                id: 'fiscal-year'
            })} : ${JSON.parse(localStorage.getItem('CurrentYear'))?.designation}`}</Typography>
        </Box>
    );
};

export default FiscalSection;

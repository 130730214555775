import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slide,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import SearchTypeFee from 'views/payrall-service/type-fee/SearchTypeFee';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import TypeFeeList from './TypeFeeList';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    reservableAmount: yup.string().required('reservableAmount')
});

const AddSettingTypeFee = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [dataTypeFee, setDataTypeFee] = useState(yourState.dataTypeFee);
    const [openType, setOpenType] = useState(false);
    const [currentTypeFee, setCurrentTypeFee] = useState(null);

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'numero',
            align: 'left'
        },
        {
            id: 'code',
            numeric: true,
            label: 'code',
            align: 'left'
        },
        {
            id: 'designation',
            numeric: true,
            label: 'designation',
            align: 'left'
        },
        {
            id: 'reservableAmount',
            numeric: true,
            label: 'reservableAmount',
            align: 'right'
        },
        {
            id: 'isGrantable',
            numeric: true,
            label: 'isGrantable',
            align: 'center'
        }
    ];

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataTypeFee.filter((row, index) => index !== indexOf);
        setDataTypeFee(arrayCopy);
    };

    const handleClickOpenCreateOrEditDialog = (row, indexOf) => {
        const arrayCopy = dataTypeFee.filter((row, index) => index !== indexOf);
        setDataTypeFee(arrayCopy);
        setYourState({
            ...yourState,
            feeType: row.feeType,
            reservableAmount: row.reservableAmount,
            isGrantable: row.isGrantable
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const selectedIndex = dataTypeFee.findIndex((object) => {
                return object.feeType.code === values.feeType?.code && object.feeType.designation === values.feeType?.designation;
            });
            let newTypeFee = [];
            if (selectedIndex >= 0) {
                snackAlert('elementExist', 'warning');
                return;
            }

            newTypeFee = newTypeFee.concat(dataTypeFee, [
                {
                    feeType: values.feeType,
                    reservableAmount: values.reservableAmount,
                    isGrantable: values.isGrantable
                }
            ]);
            setDataTypeFee(newTypeFee);
        }
    });

    useEffect(() => {
        if (currentTypeFee !== null) {
            setYourState({ ...yourState, ...formik.values, feeType: currentTypeFee });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTypeFee]);

    const getSubmitSettingTypeFee = async () => {
        const valuesSettingTypeFee = [];
        dataTypeFee.forEach((row) => {
            valuesSettingTypeFee.push({
                feeTypeId: row.feeType?.id,
                reservableAmount: row.reservableAmount,
                isGrantable: row.isGrantable
            });
        });
        if (valuesSettingTypeFee.length > 0) {
            setLoading(true);
            if (formik.values.id === '') {
                api.addSettingTypeFee(valuesSettingTypeFee)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                const valuesUpSettingTypeFee = {
                    id: formik.values.id,
                    feeTypeId: formik.values.feeType?.id,
                    reservableAmount: formik.values.reservableAmount,
                    isGrantable: formik.values.isGrantable
                };
                api.updateSettingTypeFee(valuesUpSettingTypeFee)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            snackAlert('addTypeFee', 'warning');
        }
    };

    const reinitForm = () => {
        setYourState(initialValues);
        setDataTypeFee([]);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogTypeFee = () => {
        const openDialog = openType ? <SearchTypeFee open={openType} setOpen={setOpenType} setCurrentTypeFee={setCurrentTypeFee} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogTypeFee()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Setting Type fee</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="feeType"
                                    id="feeType"
                                    name="feeType"
                                    value={`${formik.values.feeType?.code || ''} || ${formik.values.feeType?.designation || ''} `}
                                    handleChange={() => {}}
                                    onDoubleClick={() => formik.values.id === '' && setOpenType(true)}
                                    error={formik.touched.feeType && Boolean(formik.errors.feeType)}
                                    helperText={formik.touched.feeType && formik.errors.feeType}
                                    placeholder="feeType"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="reservableAmount"
                                    id="reservableAmount"
                                    name="reservableAmount"
                                    value={formik.values?.reservableAmount || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reservableAmount && Boolean(formik.errors.reservableAmount)}
                                    helperText={formik.touched.reservableAmount && formik.errors.reservableAmount}
                                    placeholder="reservableAmount"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>isGrantable</InputLabel>
                                <RadioGroup
                                    row
                                    aria-label="period"
                                    id="isGrantable"
                                    name="isGrantable"
                                    value={formik.values?.isGrantable.toString() || ''}
                                    onChange={formik.handleChange}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary" sx={{ color: 'primary.main' }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="secondary" sx={{ color: 'secondary.main' }} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </Grid>
                            {formik.values.id === '' && (
                                <>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <ButtonComponent
                                                variant="outlined"
                                                type="submit"
                                                size="small"
                                                color="secondary"
                                                startIcon={<Add />}
                                                text="Add"
                                                handleClick={() => {}}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TypeFeeList
                                            actions
                                            dataTypeFee={dataTypeFee}
                                            headCells={headCells}
                                            deleteFunction={deleteFunction}
                                            editFunction={handleClickOpenCreateOrEditDialog}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                </form>
                <DialogActions sx={{ mr: 2 }}>
                    {formik.values.id === '' && (
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                    )}
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={getSubmitSettingTypeFee}
                        data-testid="button-component"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

AddSettingTypeFee.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddSettingTypeFee;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-global-service/api/v1';

async function getCoordonnee() {
    try {
        const response = await instance.get(`${uri}/company-details`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

async function getCoordonneeImprLiaison() {
    try {
        const response = await instance.get(`${uri}/coordonnees-liaisonCompta-impression`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addCoordonnee = async (values) => {
    try {
        const response = await instance.post(`${uri}/company-details`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteCoordonnee = async (id) => {
    try {
        const response = await instance.delete(`${uri}/company-details/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateCoordonnee = async (values) => {
    try {
        const response = await instance.put(`${uri}/company-details/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addLiaisonComptable = async (values) => {
    try {
        const response = await instance.post(`${uri}/liaison`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateLiaisonComptable = async (values) => {
    try {
        const response = await instance.put(`${uri}/liaison/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addImpression = async (values) => {
    try {
        const response = await instance.post(`${uri}/impression`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateImpression = async (values) => {
    try {
        const response = await instance.put(`${uri}/impression/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const uploadFile = async (values, formData) => {
    try {
        const response = await instance.post(`${uri}/logos/upload`, formData);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getFile = async (values) => {
    try {
        const response = await instance.get(`${uri}/logos/upload/${values.id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getCoordonnee,
    getCoordonneeImprLiaison,
    addCoordonnee,
    deleteCoordonnee,
    updateCoordonnee,
    addLiaisonComptable,
    updateLiaisonComptable,
    addImpression,
    updateImpression,
    uploadFile,
    getFile
};

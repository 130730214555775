import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme, alpha } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Stack,
    TableSortLabel,
    Pagination,
    Chip,
    Menu,
    MenuItem
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { Check, Delete, Edit, KeyboardArrowDown } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/system';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import SelectComponent from 'ui-component/SelectComponent';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, theme }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    Action
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const Actions = ({ theme, row, deleteFunction, editFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} margin={-1}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        deleteFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="edit" />} arrow placement="right">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        editFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Edit fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    theme: PropTypes?.object,
    row: PropTypes?.object,
    editFunction: PropTypes?.func.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const TableTrainingType = ({
    actions,
    params,
    dataTrainingType,
    headCells,
    loading,
    staticLenght,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteFunction,
    editFunction,
    markFunction,
    setCurrentTrainingType,
    open,
    setOpen,
    ...tableProps
}) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;

    const [anchorEl, setAnchorEl] = useState(null);
    const openEl = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const setCurrent = (event, row) => {
        if (open) {
            setCurrentTrainingType(row);
            setOpen(false);
        }
    };

    return (
        <MainCard content={false}>
            {loading ? (
                <EarningCard />
            ) : (
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            theme={theme}
                        />

                        {dataTrainingType && (
                            <TableBody>
                                {stableSort(dataTrainingType, getComparator(order, orderBy))?.map((row, index) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <StyledTableRow
                                            sx={{ ': hover td': { backgroundColor: '#DDEDF9', color: 'black', cursor: 'pointer' } }}
                                            tabIndex={-1}
                                            key={row.id}
                                            onDoubleClick={(event) => setCurrent(event, row)}
                                            data-testid="rowItems"
                                        >
                                            <StyledTableCell>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    {row.code}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>{row.designation}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.status === 'AVAILABLE' && (
                                                    <Chip
                                                        label="available"
                                                        color="success"
                                                        size="small"
                                                        deleteIcon={<KeyboardArrowDown />}
                                                    />
                                                )}
                                                {row.status === 'UNAVAILABLE' && (
                                                    <div>
                                                        <Chip
                                                            sx={{ cursor: 'pointer' }}
                                                            deleteIcon={<KeyboardArrowDown />}
                                                            label="unavailable"
                                                            color="warning"
                                                            size="small"
                                                            onClick={handleClick}
                                                            onDelete={handleClick}
                                                        />

                                                        <StyledMenu
                                                            id="demo-customized-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'demo-customized-button'
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={openEl}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem onClick={() => markFunction(row)} disableRipple>
                                                                <Check />
                                                                Mark as available
                                                            </MenuItem>
                                                        </StyledMenu>
                                                    </div>
                                                )}
                                            </StyledTableCell>
                                            <Actions theme={theme} row={row} deleteFunction={deleteFunction} editFunction={editFunction} />
                                        </StyledTableRow>
                                    );
                                })}
                                {dataTrainingType.length <= 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="no-data" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            )}
            {dataTrainingType && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: -1,
                            alignItems: 'center'
                        }}
                    >
                        <TypographyListDescription text={` Display`} />
                        <SelectComponent
                            options={[
                                { label: 5, value: 5 },
                                { label: 10, value: 10 },
                                { label: 25, value: 25 }
                            ]}
                            style={{ width: 70 }}
                            defaultValue={params.rowsPerPage}
                            handleChange={handleChangeRowsPerPage}
                            value={params.rowsPerPage}
                        />
                        <TypographyListDescription text={` Recordings`} />
                    </div>
                    <Pagination
                        count={params.length}
                        color="primary"
                        size="small"
                        onChange={handleChangePage}
                        shape="rounded"
                        page={params.page + 1}
                    />
                    <TypographyListDescription text={`${params.totalItems} recordings`} />
                </div>
            )}
        </MainCard>
    );
};
TableTrainingType.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object.isRequired,
    dataTrainingType: PropTypes?.array.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool.isRequired,
    staticLenght: PropTypes?.number,
    handleChangePage: PropTypes?.func.isRequired,
    handleChangeRowsPerPage: PropTypes?.func.isRequired,
    deleteFunction: PropTypes?.func.isRequired,
    editFunction: PropTypes?.func.isRequired,
    markFunction: PropTypes?.func.isRequired,
    setCurrentTrainingType: PropTypes?.func,
    open: PropTypes.bool,
    setOpen: PropTypes?.func
};

export default TableTrainingType;

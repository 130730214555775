import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import SearchEmployee from 'views/employee/SearchEmployee';
import EmployeeOutingList from './EmployeeOutingList';
import TimePickerComponent from 'ui-component/TimePickerComponent';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    reason: yup.string().required('reason'),
    requestDate: yup.date().required('requestDate')
});

const init = {
    employee: null,
    formErrors: { employee: null },
    touched: { employee: false },
    formValid: false
};

const AddOuting = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [initState, setInitState] = useState(init);
    const [dataEmployeeOuting, setDataEmployeeOuting] = useState(yourState.employees);

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'No',
            align: 'center'
        },
        {
            id: 'firstName',
            numeric: true,
            label: 'firstName',
            align: 'left'
        },
        {
            id: 'lastName',
            numeric: true,
            label: 'lastName',
            align: 'left'
        }
    ];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const employees = [];
            dataEmployeeOuting.forEach((row) => employees.push(row.employee.id));
            const departureTime = new Date(values.departureTime);
            const returnTime = new Date(values.returnTime);
            const valuesOutings = {
                id: values.id,
                reason: values.reason,
                requestDate: values.requestDate,
                departureTime: departureTime?.toLocaleTimeString('en-US', { hour12: false }),
                returnTime: returnTime?.toLocaleTimeString('en-US', { hour12: false }),
                employeesIds: employees
            };
            setLoading(true);
            if (values.id === '') {
                api.addOuting(valuesOutings)
                    .then((res) => {
                        if (res.success) {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: 'Operation reussie',
                                variant: 'alert',
                                alertSeverity: 'success',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            formik.resetForm();
                            setDataEmployeeOuting([]);
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                                variant: 'alert',
                                alertSeverity: 'warning',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateOuting(valuesOutings)
                    .then((res) => {
                        if (res.success) {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: 'Operation reussie',
                                variant: 'alert',
                                alertSeverity: 'success',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                                variant: 'alert',
                                alertSeverity: 'warning',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataEmployeeOuting.filter((row, index) => index !== indexOf);
        setDataEmployeeOuting(arrayCopy);
    };

    const reinitForm = () => {
        setYourState(initialValues);
        setDataEmployeeOuting([]);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Outing</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reason"
                                    id="reason"
                                    name="reason"
                                    value={formik.values?.reason || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    placeholder="reason"
                                />
                            </Grid>
                            <Grid item xs={6} lg={4}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="requestDate"
                                    id="requestDate"
                                    name="requestDate"
                                    value={formik.values.requestDate || ''}
                                    handleChange={(value) => formik.setFieldValue('requestDate', value)}
                                    error={formik.touched.requestDate && Boolean(formik.errors.requestDate)}
                                    helperText={formik.touched.requestDate && formik.errors.requestDate}
                                />
                            </Grid>
                            <Grid item xs={6} lg={4}>
                                <TimePickerComponent
                                    size="small"
                                    fullWidth
                                    label="departureTime"
                                    id="departureTime"
                                    name="departureTime"
                                    value={formik.values.departureTime || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('returnTime', value?.getTime());
                                        formik.setFieldValue('departureTime', value?.getTime());
                                    }}
                                    error={formik.touched.departureTime && Boolean(formik.errors.departureTime)}
                                    helperText={formik.touched.departureTime && formik.errors.departureTime}
                                />
                            </Grid>
                            <Grid item xs={6} lg={4}>
                                <TimePickerComponent
                                    size="small"
                                    fullWidth
                                    label="returnTime"
                                    id="returnTime"
                                    name="returnTime"
                                    minTime={formik.values.departureTime || ''}
                                    value={formik.values.returnTime || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('returnTime', value?.getTime());
                                    }}
                                    error={formik.touched.returnTime && Boolean(formik.errors.returnTime)}
                                    helperText={formik.touched.returnTime && formik.errors.returnTime}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmployeeOutingList
                                    actions
                                    dataEmployeeOuting={dataEmployeeOuting}
                                    setDataEmployeeOuting={setDataEmployeeOuting}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                    initState={initState}
                                    setInitState={setInitState}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

AddOuting.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired
};

export default AddOuting;

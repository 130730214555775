import PropTypes from 'prop-types';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Fab, Badge } from '@mui/material';

// assets
import { Error } from '@mui/icons-material';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 75,
        top: -15,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

// ==============================|| CART ITEMS - FLOATING BUTTON ||============================== //

const FloatingCart = ({ dataResponse, setDataResponse }) => {
    const theme = useTheme();

    return (
        <Fab
            size="large"
            sx={{
                width: '80px',
                top: '50%',
                position: 'fixed',
                right: 0,
                zIndex: theme.zIndex.speedDial,
                boxShadow: theme.customShadows.warning,
                bgcolor: 'error.main',
                color: 'error.light',
                borderRadius: '8px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                '&:hover': {
                    bgcolor: 'error.dark',
                    color: 'error.light'
                }
            }}
            color="secondary"
            aria-label="add"
            onClick={() => setDataResponse({ ...dataResponse, open: true })}
        >
            <StyledBadge showZero badgeContent={dataResponse.failures.length} color="error">
                <Error /> Failures
            </StyledBadge>
        </Fab>
    );
};

FloatingCart.propTypes = {
    dataResponse: PropTypes.object.isRequired,
    setDataResponse: PropTypes.func.isRequired
};

export default FloatingCart;

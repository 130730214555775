import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import SearchEmployee from 'views/employee/SearchEmployee';
import MultipleSelectCheckmarks from 'ui-component/MultipleSelectCheckmarks';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';
// import AutocompleteComponent from 'ui-component/AutocompleteComponent';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    }),
    startDate: yup.date().required('startDate'),
    endDate: yup.date().required('endDate'),
    durationInMonths: yup.string().required('durationInMonths'),
    retainedPercentage: yup.string().required('retainedPercentage'),
    retainedAmount: yup.string().required('retainedAmount'),
    baseSalary: yup.string().required('baseSalary')
});

const AddMeasure = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    snackAlert,
    dataRetentionMonths,
    dataTypeSanction
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const retentionMonths = [];
            values.retentionMonths.forEach((row) => retentionMonths.push({ ordinalRetentionMonth: row.id }));
            const valuesMeasure = {
                id: values.id,
                startDate: values.startDate,
                endDate: values.endDate,
                durationInMonths: values.durationInMonths,
                retainedPercentage: values.retainedPercentage,
                retainedAmount: values.retainedAmount,
                retentionMonthsRequest: retentionMonths,
                reason: values.reason,
                employeeId: values.employee.id,
                sanctionTypeId: values.sanctionTypeId
            };
            setLoading(true);
            if (values.id === '') {
                api.addMeasure(valuesMeasure)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateMeasure(valuesMeasure)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentEmployee !== null) {
            setYourState({ ...yourState, employee: currentEmployee, baseSalary: currentEmployee.baseSalary });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const reinitForm = () => {
        setYourState(initialValues);
        setDataResponse({ open: false, success: [], failures: [] });
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        const lastItem = value[value.length - 1];
        const selectedIndex = yourState.retentionMonths.findIndex((p) => p.id === lastItem.id);
        if (selectedIndex > -1) {
            const arrayCopy = value.filter((row) => row.id !== lastItem.id);
            formik.setFieldValue('retentionMonths', arrayCopy);
            setYourState({ ...yourState, retentionMonths: arrayCopy });
        } else {
            formik.setFieldValue('retentionMonths', value);
        }
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Measure</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="employee"
                                    id="employee"
                                    name="employee"
                                    value={formik.values.employee?.firstName || ''}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenEmpl(true)}
                                    error={formik.touched.employee && Boolean(formik.errors.employee)}
                                    helperText={formik.touched.employee && formik.errors.employee}
                                    placeholder="employee"
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="startDate"
                                    id="startDate"
                                    name="startDate"
                                    value={formik.values.startDate || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('endDate', value);
                                        formik.setFieldValue('startDate', value);
                                    }}
                                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                    helperText={formik.touched.startDate && formik.errors.startDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="durationInMonths"
                                    id="durationInMonths"
                                    name="durationInMonths"
                                    value={formik.values?.durationInMonths || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.durationInMonths && Boolean(formik.errors.durationInMonths)}
                                    helperText={formik.touched.durationInMonths && formik.errors.durationInMonths}
                                    placeholder="durationInMonths"
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="endDate"
                                    id="endDate"
                                    name="endDate"
                                    minDate={formik.values.startDate || ''}
                                    value={formik.values.endDate || ''}
                                    handleChange={(value) => formik.setFieldValue('endDate', value)}
                                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                    helperText={formik.touched.endDate && formik.errors.endDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="retainedPercentage"
                                    id="retainedPercentage"
                                    name="retainedPercentage"
                                    value={formik.values?.retainedPercentage || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.retainedPercentage && Boolean(formik.errors.retainedPercentage)}
                                    helperText={formik.touched.retainedPercentage && formik.errors.retainedPercentage}
                                    placeholder="retainedPercentage"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="baseSalary"
                                    id="baseSalary"
                                    name="baseSalary"
                                    value={formik.values.baseSalary || ''}
                                    handleChange={() => {}}
                                    error={formik.touched.baseSalary && Boolean(formik.errors.baseSalary)}
                                    helperText={formik.touched.baseSalary && formik.errors.baseSalary}
                                    placeholder="baseSalary"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="retainedAmount"
                                    id="retainedAmount"
                                    name="retainedAmount"
                                    value={formik.values?.retainedAmount || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.retainedAmount && Boolean(formik.errors.retainedAmount)}
                                    helperText={formik.touched.retainedAmount && formik.errors.retainedAmount}
                                    placeholder="retainedAmount"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultipleSelectCheckmarks
                                    size="small"
                                    fullWidth
                                    label="retentionMonths"
                                    id="retentionMonths"
                                    name="retentionMonths"
                                    value={formik.values?.retentionMonths}
                                    handleChange={handleChange}
                                    dataOption={dataRetentionMonths}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reason"
                                    id="reason"
                                    name="reason"
                                    value={formik.values?.reason || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    placeholder="reason"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <AutocompleteComponent
                                    size="small"
                                    fullWidth
                                    label="sanctionType"
                                    id="sanctionType"
                                    name="sanctionType"
                                    // value={formik.values?.sanctionType || ''}
                                    handleChange={formik.handleChange}
                                    options={[
                                        { label: 'The Godfather', id: 1 },
                                        { label: 'Pulp Fiction', id: 2 }
                                    ]}
                                /> */}
                                <InputField
                                    select
                                    size="small"
                                    fullWidth
                                    label="select sanctionTypeId"
                                    name="sanctionTypeId"
                                    value={formik.values.sanctionTypeId || ''}
                                    handleChange={(e, params) => {
                                        formik.setFieldValue('sanctionTypeId', e.target.value);
                                        formik.setFieldValue('sanctionType', params.props.option);
                                    }}
                                    error={formik.touched.sanctionTypeId && Boolean(formik.errors.sanctionTypeId)}
                                    helperText={formik.touched.sanctionTypeId && formik.errors.sanctionTypeId}
                                >
                                    <MenuItem value="">
                                        <em>none</em>
                                    </MenuItem>
                                    {dataTypeSanction.map((option, index) => (
                                        <MenuItem key={index} value={option.id} option={option}>
                                            {option.code} || {option.designation}
                                        </MenuItem>
                                    ))}
                                </InputField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

AddMeasure.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired,
    dataRetentionMonths: PropTypes.array,
    dataTypeSanction: PropTypes.array
};

export default AddMeasure;

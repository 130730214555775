import { GET_ABSENCES } from './actions';

const initialState = {
    absences: [],
    type: {}
};

const absenceReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ABSENCES:
            return {
                absences: action.payload,
                type: action.operationType
            };
        default:
            return state;
    }
};

export default absenceReducer;

// third-party
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import { Card, Grid, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import ButtonComponent from 'ui-component/ButtonComponent';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';

// API files
import { useTheme } from '@mui/styles';
import { jobsPostingAPI, staticListSpokenLanguagesAPI } from 'api';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import DeleteDialog from 'ui-component/DeleteDialog';
import CreationOffreHeader from './CreationOffreHeader';
import ListeOffresEmploi from './ListeOffresEmploi';
import axios from 'axios';
import { Replay } from '@mui/icons-material';

const CreationOffre = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [savingEntry, setSavingEntry] = useState(false);
    const [errors, setErrors] = useState({
        idJournal: ''
    });
    const [lettreToEdit, setLettreToEdit] = useState({
        position: '',
        // introduction: '',
        introduction: '<p><br></p>',
        description: '<p><br></p>',
        workType: 'ONSITE',
        openingDate: new Date(),
        closingDate: new Date(),
        benefits: [''],
        requiredSkills: [''],
        spokenLanguages: [
            {
                language: '',
                fluency: '',
                mention: ''
            }
        ],
        wantedWorkforce: 0
    });
    const [lettreToDelete, setLettreToDelete] = useState(null);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [entryInputValues, setEntryInputValues] = useState(['']);
    const [entryValues, setEntryValues] = useState([null]);
    const [listeNiveaux, setListeNiveaux] = useState([]);
    const [reload, setReload] = useState(false);
    const [essaiIntroduction, setEssaiIntroduction] = useState('');
    const [descr, setDescr] = useState('');

    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();
    const paginationDetails = {
        page: 1,
        size: 15,
        totalOptions: 1
    };
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
        });
    };
    useEffect(() => {
        let isSubscribed = true;
        const paramLanguesPromise = staticListSpokenLanguagesAPI.getParamsLangues();
        axios
            .all([paramLanguesPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        setListeNiveaux(responses[0]);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(err.message, 'error');
                }
            });
        return () => {
            isSubscribed = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.match(/^([0-9a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáãäåæëìíîïðñòóôõöüûúùéèêç'\s-]+)$/) || value === '') {
            setLettreToEdit({
                ...lettreToEdit,
                [name]: value
            });
        }
        setErrors({
            ...errors,
            position: '',
            workType: ''
        });
    };
    const handleChangeOpeningDate = (date) => {
        setLettreToEdit({
            ...lettreToEdit,
            openingDate: date,
            closingDate: ''
        });
        setErrors({
            openingDate: '',
            closingDate: ''
        });
    };
    const handleChangeIntro = (e) => {
        setLettreToEdit({
            ...lettreToEdit,
            introduction: e
        });
    };
    const handleChangeTest = (e) => {
        setLettreToEdit({
            ...lettreToEdit,
            description: e
        });
    };
    const handleChangeClosingDate = (date) => {
        setLettreToEdit({
            ...lettreToEdit,
            closingDate: date
        });
        setErrors({
            closingDate: ''
        });
    };
    const handleChangeDate = (date) => {
        setLettreToEdit({
            ...lettreToEdit,
            date
        });
        setErrors({
            date: ''
        });
    };
    const handleChangeBeneficts = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            benefits:
                lettreToEdit.benefits.length - 1 === index
                    ? [
                          ...lettreToEdit.benefits.map((entry, entryIndex) => {
                              if (index === entryIndex) {
                                  return value;
                              }
                              return entry;
                          }),
                          ''
                      ]
                    : lettreToEdit.benefits.map((entry, entryIndex) => {
                          if (index === entryIndex) {
                              return value;
                          }
                          return entry;
                      })
        });
    };
    const handleChangeCompetences = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            requiredSkills:
                lettreToEdit.requiredSkills.length - 1 === index
                    ? [
                          ...lettreToEdit.requiredSkills.map((skills, skillsIndex) => {
                              if (index === skillsIndex) {
                                  return value;
                              }
                              return skills;
                          }),
                          ''
                      ]
                    : lettreToEdit.requiredSkills.map((skills, skillsIndex) => {
                          if (index === skillsIndex) {
                              return value;
                          }
                          return skills;
                      })
        });
    };
    const handleChangeLangues = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            spokenLanguages:
                lettreToEdit.spokenLanguages.length - 1 === index
                    ? [
                          ...lettreToEdit.spokenLanguages.map((entry, entryIndex) => {
                              if (index === entryIndex) {
                                  return value;
                              }
                              return entry;
                          }),
                          ''
                      ]
                    : lettreToEdit.spokenLanguages.map((entry, entryIndex) => {
                          if (index === entryIndex) {
                              return value;
                          }
                          return entry;
                      })
        });
    };
    const entryValidation = () => {
        if (!lettreToEdit.position) {
            setErrors({
                ...errors,
                position: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!lettreToEdit.workType) {
            setErrors({
                ...errors,
                workType: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!lettreToEdit.openingDate) {
            setErrors({
                ...errors,
                openingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(lettreToEdit.openingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                openingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        if (!lettreToEdit.closingDate) {
            setErrors({
                ...errors,
                closingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(lettreToEdit.closingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                closingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        return true;
    };
    const resetLettreToEdit = () => {
        setLettreToEdit({
            position: '',
            introduction: '',
            description: '',
            workType: 'ONSITE',
            openingDate: new Date(),
            closingDate: new Date(),
            benefits: [''],
            requiredSkills: [''],
            spokenLanguages: [
                {
                    language: '',
                    fluency: '',
                    mention: ''
                }
            ],
            wantedWorkforce: 0
        });
        setEssaiIntroduction('');
        setDescr('');
        setErrors({});
    };
    const handleSubmitLettreToEdit = () => {
        if (entryValidation()) {
            if (lettreToEdit?.id) {
                setSavingEntry(true);
                jobsPostingAPI
                    .updateLettre({
                        ...lettreToEdit,
                        introduction: essaiIntroduction.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ''),
                        description: descr.description.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ''),
                        openingDate: moment(lettreToEdit?.openingDate).format('YYYY-MM-DD'),
                        closingDate: moment(lettreToEdit?.closingDate).format('YYYY-MM-DD')
                    })
                    .then(() => {
                        snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                        setLettreToEdit({
                            position: '',
                            introduction: '',
                            description: '',
                            workType: 'ONSITE',
                            openingDate: new Date(),
                            closingDate: new Date(),
                            benefits: [''],
                            requiredSkills: [''],
                            spokenLanguages: [
                                {
                                    language: '',
                                    fluency: '',
                                    mention: ''
                                }
                            ],
                            wantedWorkforce: 0
                        });
                        setSavingEntry(false);
                    })
                    .catch((err) => {
                        snackAlert(err.response ? err.response.data.detail : err.message, 'error');
                        setSavingEntry(false);
                    });
            } else {
                setSavingEntry(true);
                jobsPostingAPI
                    .saveLettre({
                        ...lettreToEdit,
                        introduction: essaiIntroduction.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ''),
                        description: descr.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ''),
                        openingDate: moment(lettreToEdit?.openingDate).format('YYYY-MM-DD'),
                        closingDate: moment(lettreToEdit?.closingDate).format('YYYY-MM-DD')
                    })
                    .then(() => {
                        snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                        setLettreToEdit({
                            position: '',
                            introduction: '',
                            description: '',
                            workType: 'ONSITE',
                            openingDate: new Date(),
                            closingDate: new Date(),
                            benefits: [''],
                            requiredSkills: [''],
                            spokenLanguages: [
                                {
                                    language: '',
                                    fluency: '',
                                    mention: ''
                                }
                            ],
                            wantedWorkforce: 0
                        });
                        resetLettreToEdit();
                        setSavingEntry(false);
                    })
                    .catch((err) => {
                        snackAlert(err.response ? err.response.data.detail : err.message, 'error');
                        setSavingEntry(false);
                    });
            }
        }
    };

    const removeBeneficts = useCallback(
        (index) => {
            setLettreToEdit({
                ...lettreToEdit,
                benefits: lettreToEdit.benefits.filter((entry, i) => i !== index)
            });
        },
        [lettreToEdit]
    );
    const removeCompetences = useCallback(
        (index) => {
            setLettreToEdit({
                ...lettreToEdit,
                requiredSkills: lettreToEdit.requiredSkills.filter((entry, i) => i !== index)
            });
        },
        [lettreToEdit]
    );
    const removeLangue = useCallback(
        (index) => {
            setLettreToEdit({
                ...lettreToEdit,
                spokenLanguages: lettreToEdit.spokenLanguages.filter((entry, i) => i !== index)
            });
        },
        [lettreToEdit]
    );
    const setRowToEdit = (row) => {
        console.log('ROW', row);
        setOpenDialog(false);
        setLettreToEdit({
            openingDate: new Date(row.openingDate),
            closingDate: new Date(row.closingDate),
            position: row.position,
            workType: row.workType,
            benefits: row.benefits,
            requiredSkills: row.requiredSkills,
            spokenLanguages: row.spokenLanguages,
            wantedWorkforce: row.wantedWorkforce,
            companyDetails: row.companyDetails?.companyDetails,
            id: row.id
        });
        setEssaiIntroduction(row.introduction);
        setDescr(row.description);
        console.log('ROW e', row.companyDetails.companyDetails);
        console.log('ROW emb', row.companyDetails);
    };
    const handleClickOpenDeleteAlertDialog = (lettre) => {
        setLettreToDelete(lettre);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = () => {
        setOpenDeleteAlertDialog(false);
    };
    const handleDelete = () => {
        jobsPostingAPI
            .deleteLettre(lettreToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                resetLettreToEdit();
                handleClickCloseDeleteAlertDialog();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };
    const handleChangeSpokenLlangue = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            spokenLanguages:
                lettreToEdit.spokenLanguages.length - 1 === index
                    ? [
                          ...lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                              if (index === langueIndex) {
                                  return { ...langue, language: value };
                              }
                              return langue;
                          }),
                          {
                              language: '',
                              fluency: '',
                              mention: ''
                          }
                      ]
                    : lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                          if (index === langueIndex) {
                              return { ...langue, language: value };
                          }
                          return langue;
                      })
        });
        if (entryValues.length - 1 === index) {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                }),
                null
            ]);
        } else {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                })
            ]);
        }

        if (entryInputValues.length - 1 === index) {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;

                    return v;
                }),
                ''
            ]);
        } else {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;
                    return v;
                })
            ]);
        }
    };
    const handleChangeSpokenLfluency = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            spokenLanguages:
                lettreToEdit.spokenLanguages.length - 1 === index
                    ? [
                          ...lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                              if (index === langueIndex) {
                                  return { ...langue, fluency: value };
                              }
                              return langue;
                          }),
                          {
                              language: '',
                              fluency: '',
                              mention: ''
                          }
                      ]
                    : lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                          if (index === langueIndex) {
                              return { ...langue, fluency: value };
                          }
                          return langue;
                      })
        });
        if (entryValues.length - 1 === index) {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                }),
                null
            ]);
        } else {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                })
            ]);
        }

        if (entryInputValues.length - 1 === index) {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;

                    return v;
                }),
                ''
            ]);
        } else {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;
                    return v;
                })
            ]);
        }
    };
    const handleChangeSpokenLmention = (value, index) => {
        setLettreToEdit({
            ...lettreToEdit,
            spokenLanguages:
                lettreToEdit.spokenLanguages.length - 1 === index
                    ? [
                          ...lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                              if (index === langueIndex) {
                                  return { ...langue, mention: value };
                              }
                              return langue;
                          }),
                          {
                              language: '',
                              fluency: '',
                              mention: ''
                          }
                      ]
                    : lettreToEdit.spokenLanguages.map((langue, langueIndex) => {
                          if (index === langueIndex) {
                              return { ...langue, mention: value };
                          }
                          return langue;
                      })
        });
        if (entryValues.length - 1 === index) {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                }),
                null
            ]);
        } else {
            setEntryValues([
                ...entryValues.map((v, i) => {
                    if (i === index) return v;

                    return v;
                })
            ]);
        }

        if (entryInputValues.length - 1 === index) {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;

                    return v;
                }),
                ''
            ]);
        } else {
            setEntryInputValues([
                ...entryInputValues.map((v, i) => {
                    if (i === index) return value;
                    return v;
                })
            ]);
        }
    };
    const handleChangeNombreRecherche = (values) => {
        const { value } = values;
        if (value >= 1) {
            setLettreToEdit({
                ...lettreToEdit,
                wantedWorkforce: parseInt(value, 10)
            });
        }
        setErrors({
            ...errors,
            wantedWorkforce: null
        });
    };
    const handleChangeComment = (e) => {
        setEssaiIntroduction(e);
    };
    const handleChangeDescription = (e) => {
        setDescr(e);
    };
    const toggleReload = () => {
        setReload(!reload);
    };

    return (
        <>
            <div style={{}}>
                <Stack direction="row" justifyContent="space-between">
                    <div>
                        <TypographyListHeader text={intl.formatMessage({ id: 'create-offre-emploi' })} />
                        <TypographyListDescription text={intl.formatMessage({ id: 'cvm-sorte-conge' })} />
                    </div>
                    {/* <div style={{ mr: 2 }}> */}
                    <div style={{ xs: 12, md: 12, lg: 2 }}>
                        {/* <Grid sx={{ mr: 2 }}> */}
                        <ButtonComponent
                            text={intl.formatMessage({ id: 'refresh' })}
                            handleClick={() => toggleReload()}
                            size="small"
                            startIcon={<Replay />}
                            variant="contained"
                            style={{ marginRight: 10 }}
                        />
                        {/* </Grid> */}
                        {/* <Grid item xs={12} md={12} lg={2}> */}
                        <ButtonComponent
                            text={intl.formatMessage({ id: 'create-offre-emploi' })}
                            handleClick={() => {
                                setOpenDialog(true);
                            }}
                            variant="contained"
                            style={{ marginLeft: 10 }}
                        />
                        {/* </Grid> */}
                    </div>
                </Stack>

                <Card style={{ padding: '10px 10px' }}>
                    <CreationOffreHeader
                        lettreToEdit={lettreToEdit}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        handleChangeOpeningDate={handleChangeOpeningDate}
                        handleChangeClosingDate={handleChangeClosingDate}
                        errors={errors}
                        handleChangeBeneficts={handleChangeBeneficts}
                        handleChangeCompetences={handleChangeCompetences}
                        handleChangeLangues={handleChangeLangues}
                        removeBeneficts={removeBeneficts}
                        removeCompetences={removeCompetences}
                        removeLangue={removeLangue}
                        handleChangeIntro={handleChangeIntro}
                        handleChangeTest={handleChangeTest}
                        handleChangeSpokenLlangue={handleChangeSpokenLlangue}
                        handleChangeSpokenLfluency={handleChangeSpokenLfluency}
                        listeNiveaux={listeNiveaux}
                        handleChangeSpokenLmention={handleChangeSpokenLmention}
                        handleChangeNombreRecherche={handleChangeNombreRecherche}
                        essaiIntroduction={essaiIntroduction}
                        handleChangeComment={handleChangeComment}
                        descr={descr}
                        handleChangeDescription={handleChangeDescription}
                    />
                </Card>
                <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ marginRight: 20 }}>
                    <Grid item xs={4} lg={4}>
                        <ButtonComponent
                            text={lettreToEdit.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            variant="contained"
                            style={{
                                width: '100%'
                            }}
                            handleClick={() => {
                                handleSubmitLettreToEdit();
                                resetLettreToEdit();
                            }}
                            disabled={savingEntry}
                            startIcon={lettreToEdit.id ? <EditIcon /> : <SaveIcon />}
                        />
                    </Grid>

                    <Grid item xs={4} lg={4}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: 'reset' })}
                            variant="outlined"
                            style={{
                                width: '100%'
                            }}
                            handleClick={resetLettreToEdit}
                            startIcon={<LockResetIcon />}
                        />
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: 'delete' })}
                            variant="contained"
                            style={{
                                width: '100%'
                            }}
                            handleClick={() => handleClickOpenDeleteAlertDialog(lettreToEdit)}
                            disabled={!lettreToEdit.id}
                            startIcon={<DeleteIcon />}
                            sx={{
                                background: theme.palette.warning.dark,
                                '&:hover': { background: theme.palette.warning.main },
                                color: 'grey.900'
                            }}
                        />
                    </Grid>
                </Grid>

                <Snackbar
                    open={errors?.linesEmpty}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={3000}
                    onClose={() => {
                        setErrors({
                            ...errors,
                            linesEmpty: ''
                        });
                    }}
                    sx={{ width: '100%' }}
                >
                    <Alert
                        onClose={() => setErrors({})}
                        severity="error"
                        sx={{ width: '100%' }}
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            width: '98%',
                            paddingLeft: '10%',
                            paddingRight: '10%'
                        }}
                    >
                        {errors?.linesEmpty}
                    </Alert>
                </Snackbar>
            </div>
            <ListeOffresEmploi
                open={openDialog}
                handleClose={handleCloseDialog}
                setRowToEdit={setRowToEdit}
                paginationDetails={paginationDetails}
            />
            <DeleteDialog
                text={intl.formatMessage({ id: 'offres' })}
                handleClose={handleClickCloseDeleteAlertDialog}
                open={openDeleteAlertDialog}
                snackAlert={snackAlert}
                handleDelete={handleDelete}
            />
            <SpinnerLoader open={savingEntry} />
        </>
    );
};

const mapStateToProps = (state) => {
    return { account: state.account };
};

export default connect(mapStateToProps)(CreationOffre);

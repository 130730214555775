import React from 'react';
import DemandeCongeHeader from './DemandeCongeHeader';
import PropTypes from 'prop-types';

const DemandeCongeToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <DemandeCongeHeader title={props?.title} />
            <div className="contentBody">{props.body}</div>
        </div>
    );
});
DemandeCongeToPrint.propTypes = {
    title: PropTypes?.string.isRequired,
    body: PropTypes?.object
};

export default DemandeCongeToPrint;

import React, { useEffect, useState } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { criteriaApi, assessmentCriteriaApi } from 'api';
import TableCriteria from './TableCriteria';
import AddCriteria from './AddCriteria';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import AddAssessmentCriteriaDialog from './AddAssessmentCriteriaDialog';
import UpdateAssessmentCriteriaDialog from './UpdateAssessmentCriteriaDialog';

const initialValues = {
    id: '',
    criteriaName: '',
    assessment: {
        id: '',
        assessmentName: '',
        maximumMarks: '',
        isApplicable: ''
    }
};

const Criteria = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataCriteria, setDataCriteria] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState({ delete: false, addAss: false });
    const [openAss, setOpenAss] = useState({ delete: false, update: false, add: false });

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'left'
        },
        {
            id: 'criteriaName',
            numeric: true,
            label: 'criteriaName',
            align: 'left'
        },
        {
            id: 'assessment',
            numeric: true,
            label: 'assessment',
            align: 'left'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const refleshTable = React.useCallback(async (params) => {
        setLoading(true);
        await criteriaApi
            .getCriteria()
            .then((res) => {
                if (res.success) {
                    const result = [];
                    console.log(res);
                    res.data.forEach((row) => {
                        result.push({
                            id: row.id,
                            criteriaName: row.criteriaName,
                            assessment:
                                row.assessmentCriteria.length > 0
                                    ? row.assessmentCriteria.map((row) => {
                                          return {
                                              id: row.id,
                                              assessmentName: row.assessmentName,
                                              maximumMarks: row.maximumMarks,
                                              isApplicable: row.isApplicable
                                          };
                                      })
                                    : []
                        });
                    });
                    setDataCriteria({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: Math.ceil(res.data.length / params.rowsPerPage) || 1,
                        totalItems: res.data.length || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refleshTable(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        setParams({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        setParams({
            ...params,
            length: Math.ceil(dataCriteria.rowsInitial.length / parseInt(event?.target.value, 10)) || 1,
            page: 0,
            rowsPerPage: parseInt(event?.target.value, 10)
        });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataCriteria.rows.filter((row) => {
                let matches = true;

                const properties = ['criteriaName'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataCriteria({ ...dataCriteria, rows: newRows });
        } else {
            setDataCriteria({ ...dataCriteria, rows: dataCriteria.rowsInitial });
        }
    };

    const handleClickSearch = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddCriteria
                api={criteriaApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };
    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const handleClickOpenEditAssessmentDialog = (row) => {
        setYourState(row);
        setOpenAss({ ...openAss, update: true });
    };

    const handleClickOpenAddAssessmentDialog = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, addAss: true });
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, delete: true });
    };

    const deleteAssFunction = (row) => {
        setYourState(row);
        setOpenAss({ ...openAss, delete: true });
    };

    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete.delete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete({ ...openDelete, delete: false });
                }}
                open={openDelete.delete}
                text="Are you sure you want to delete this criteria ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={criteriaApi.deleteCriteria}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const HandleOpenDeleteAssessmentDialog = () => {
        const deleteDialog = openAss.delete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenAss({ ...openAss, delete: false });
                }}
                open={openAss.delete}
                text="Are you sure you want to delete this assessment criteria ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState.assessment}
                api={assessmentCriteriaApi.deleteAssessmentCriteria}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const HandleOpenAddAssessmentDialog = () => {
        const addAssDialog = openDelete.addAss ? (
            <AddAssessmentCriteriaDialog
                api={assessmentCriteriaApi}
                snackAlert={snackAlert}
                params={params}
                open={openDelete}
                setOpen={setOpenDelete}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return addAssDialog;
    };

    const HandleOpenUpdateAssessmentDialog = () => {
        const updateAssDialog = openAss.update ? (
            <UpdateAssessmentCriteriaDialog
                api={assessmentCriteriaApi}
                snackAlert={snackAlert}
                params={params}
                open={openAss}
                setOpen={setOpenAss}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return updateAssDialog;
    };

    return (
        <>
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            {HandleOpenAddAssessmentDialog()}
            {HandleOpenUpdateAssessmentDialog()}
            {HandleOpenDeleteAssessmentDialog()}
            <Grid container spacing={2}>
                <HeaderComponent header="criteria-list" subtitle="view-your-criteria" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataCriteria({ ...dataCriteria, rows: dataCriteria.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableCriteria
                                actions
                                params={params}
                                dataCriteria={dataCriteria.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                addAssessment={handleClickOpenAddAssessmentDialog}
                                editAssFunction={handleClickOpenEditAssessmentDialog}
                                deleteAssFunction={deleteAssFunction}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Criteria;

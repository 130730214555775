import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Stack,
    TableSortLabel,
    Pagination,
    Collapse,
    Box,
    Paper,
    Chip
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { visuallyHidden } from '@mui/utils';
import { FormattedMessage } from 'react-intl';
import MainCard from 'ui-component/cards/MainCard';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import SelectComponent from 'ui-component/SelectComponent';
import { Add, Delete, Check, Edit, Face, SentimentVeryDissatisfied, Close } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, theme }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    Action
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const Actions = ({ row, deleteFunction, editFunction, addAssessment, theme }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} margin={-1}>
            <Tooltip title="Add assessment" arrow placement="right">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        addAssessment(row);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Add fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        deleteFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="edit" />} arrow placement="right">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        editFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Edit fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    editFunction: PropTypes?.func.isRequired,
    deleteFunction: PropTypes?.func.isRequired,
    addAssessment: PropTypes.func.isRequired,
    theme: PropTypes?.object
};

const Row = (props) => {
    const { row, deleteFunction, editFunction, addAssessment, deleteAssFunction, editAssFunction, theme } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <StyledTableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    #{row.id}
                </StyledTableCell>
                <StyledTableCell>{row.criteriaName}</StyledTableCell>
                <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" sx={{ padding: '0px' }} onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Chip label={row.assessment.length} color="primary" size="small" variant="outlined" />
                    </IconButton>
                </StyledTableCell>
                <Actions
                    row={row}
                    deleteFunction={deleteFunction}
                    editFunction={editFunction}
                    addAssessment={addAssessment}
                    theme={theme}
                />
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h4" gutterBottom component="div">
                                assessmentList
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>number</TableCell>
                                        <TableCell>assessmentName</TableCell>
                                        <TableCell>maximumMarks</TableCell>
                                        <TableCell align="center">isApplicable</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.assessment.map((ass, index) => (
                                        <TableRow hover key={ass.id}>
                                            <TableCell scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {ass.assessmentName}
                                            </TableCell>
                                            <TableCell>{ass.maximumMarks}</TableCell>
                                            <TableCell align="center">
                                                {ass.isApplicable ? (
                                                    <Chip
                                                        color="success"
                                                        size="small"
                                                        deleteIcon={<Check />}
                                                        onDelete={() => {}}
                                                        icon={<Face />}
                                                    />
                                                ) : (
                                                    <Chip
                                                        color="error"
                                                        size="small"
                                                        deleteIcon={<Close />}
                                                        onDelete={() => {}}
                                                        icon={<SentimentVeryDissatisfied />}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} margin={-1}>
                                                    <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                                                        <IconButton
                                                            component="span"
                                                            style={{
                                                                padding: 4,
                                                                margin: 0
                                                            }}
                                                            size="small"
                                                            onClick={() => {
                                                                deleteAssFunction({
                                                                    id: row.id,
                                                                    criteriaName: row.criteriaName,
                                                                    assessment: ass
                                                                });
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: theme.palette.error.main
                                                                }
                                                            }}
                                                        >
                                                            <Delete fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={<FormattedMessage id="edit" />} arrow placement="right">
                                                        <IconButton
                                                            component="span"
                                                            style={{
                                                                padding: 4,
                                                                margin: 0
                                                            }}
                                                            size="small"
                                                            onClick={() => {
                                                                editAssFunction({
                                                                    id: row.id,
                                                                    criteriaName: row.criteriaName,
                                                                    assessment: ass
                                                                });
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: theme.palette.primary.dark
                                                                }
                                                            }}
                                                        >
                                                            <Edit fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

Row.propTypes = {
    row: PropTypes.object.isRequired,
    addAssessment: PropTypes.func.isRequired,
    deleteFunction: PropTypes.func.isRequired,
    editFunction: PropTypes.func.isRequired,
    theme: PropTypes.object,
    deleteAssFunction: PropTypes.func.isRequired,
    editAssFunction: PropTypes.func.isRequired
};

const TableCriteria = ({
    actions,
    params,
    dataCriteria,
    headCells,
    loading,
    staticLenght,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteFunction,
    editFunction,
    addAssessment,
    deleteAssFunction,
    editAssFunction
}) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('criteriaName');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <MainCard content={false}>
            {loading ? (
                <EarningCard />
            ) : (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="collapsible table">
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            theme={theme}
                        />
                        <TableBody>
                            {stableSort(dataCriteria, getComparator(order, orderBy))
                                ?.slice(params.page * params.rowsPerPage, params.page * params.rowsPerPage + params.rowsPerPage)
                                ?.map((row) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <Row
                                            key={row.id}
                                            row={row}
                                            deleteFunction={deleteFunction}
                                            editFunction={editFunction}
                                            addAssessment={addAssessment}
                                            deleteAssFunction={deleteAssFunction}
                                            editAssFunction={editAssFunction}
                                            theme={theme}
                                        />
                                    );
                                })}
                            {dataCriteria.length <= 0 && (
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                        <Typography textAlign="center" variant="subtitle1" color="inherit">
                                            <FormattedMessage id="no-data" />
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {dataCriteria && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: -1,
                            alignItems: 'center'
                        }}
                    >
                        <TypographyListDescription text={` Display`} />
                        <SelectComponent
                            options={[
                                { label: 5, value: 5 },
                                { label: 10, value: 10 },
                                { label: 25, value: 25 }
                            ]}
                            style={{ width: 70 }}
                            defaultValue={params.rowsPerPage}
                            handleChange={handleChangeRowsPerPage}
                            value={params.rowsPerPage}
                        />
                        <TypographyListDescription text={` Recordings`} />
                    </div>
                    <Pagination
                        count={params.length}
                        color="primary"
                        size="small"
                        onChange={handleChangePage}
                        shape="rounded"
                        page={params.page + 1}
                    />
                    <TypographyListDescription text={`${params.totalItems} recordings`} />
                </div>
            )}
        </MainCard>
    );
};

TableCriteria.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object.isRequired,
    dataCriteria: PropTypes?.array.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool.isRequired,
    staticLenght: PropTypes?.number,
    handleChangePage: PropTypes?.func.isRequired,
    handleChangeRowsPerPage: PropTypes?.func.isRequired,
    deleteFunction: PropTypes?.func.isRequired,
    editFunction: PropTypes?.func.isRequired,
    addAssessment: PropTypes?.func.isRequired,
    deleteAssFunction: PropTypes?.func.isRequired,
    editAssFunction: PropTypes?.func.isRequired
};

export default TableCriteria;

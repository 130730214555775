import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeCandidature(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/job-submissions`, {
            params: {
                codeDesignation: filterValue || null,
                page: paginationDetails ? paginationDetails.page : null,
                size: paginationDetails ? paginationDetails.size : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveCandidature = async (candidature) => {
    return instance.post(`${baseURL}/job-submissions`, candidature).then(HandleResponse).catch(HandleError);
};

const updateCandidature = async (candidature) => {
    return instance.put(`${baseURL}/job-submissions/${candidature.id}`, candidature).then(HandleResponse).catch(HandleError);
};

const deleteCandidature = async (id) => {
    return instance.delete(`${baseURL}/job-submissions/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeCandidature,
    saveCandidature,
    updateCandidature,
    deleteCandidature
};

import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    IconButton,
    Tooltip,
    Stack,
    Pagination,
    Chip
} from '@mui/material';
// project imports
import { FormattedMessage } from 'react-intl';
import { Check, Close, Delete, Edit, Face, SentimentVeryDissatisfied } from '@mui/icons-material';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import SelectComponent from 'ui-component/SelectComponent';
import InputField from 'ui-component/InputField';
import SubCard from 'ui-component/cards/SubCard';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Actions = ({ row, deleteFunction, editFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} margin={-1}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        deleteFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="edit" />} arrow placement="right">
                <IconButton
                    component="span"
                    style={{
                        padding: 4,
                        margin: 0
                    }}
                    size="small"
                    onClick={() => {
                        editFunction(row);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.primary.dark
                        }
                    }}
                >
                    <Edit fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    editFunction: PropTypes?.func.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const TableAssessmentCriteria = ({
    params,
    dataElementEvaluation,
    setDataElementEvaluation,
    snackAlert,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    ...tableProps
}) => {
    const theme = useTheme();

    const onChangeElementValue = (row, rowAss, e) => {
        if (rowAss.maximumMarks < e.target.value) {
            snackAlert('maximumMarks', 'warning');
        } else {
            const updateAss = row.assessmentCriteria.map((entry) => {
                if (entry.id === rowAss.id) {
                    return { ...entry, value: e.target.value };
                }
                return entry;
            });
            setDataElementEvaluation({
                ...dataElementEvaluation,
                evaluationElements: dataElementEvaluation.evaluationElements.map((entry) => {
                    if (entry.id === row.id) {
                        return { ...entry, assessmentCriteria: updateAss };
                    }
                    return entry;
                })
            });
        }
    };

    return (
        <SubCard content={false} title={`Evaluation ${dataElementEvaluation.nextEvaluation}`}>
            {loading ? (
                <EarningCard />
            ) : (
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center" rowSpan="2">
                                    Critères
                                </StyledTableCell>
                                <StyledTableCell align="center" rowSpan="2">
                                    Rubriques
                                </StyledTableCell>
                                <StyledTableCell align="center" colSpan="2">
                                    Appréciation
                                </StyledTableCell>
                                <StyledTableCell align="center" rowSpan="2">
                                    Critères applicables
                                </StyledTableCell>
                            </StyledTableRow>
                            <TableRow>
                                <StyledTableCell align="center">Note obtenu</StyledTableCell>
                                <StyledTableCell align="center">Note de référence</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataElementEvaluation.evaluationElements.length > 0 &&
                                dataElementEvaluation.evaluationElements.map((row) => {
                                    const firstAssessmentCrit = row.assessmentCriteria.length > 0 && row.assessmentCriteria[0];
                                    const otherAssessmentCrit = row.assessmentCriteria.filter((ass) => ass.id !== firstAssessmentCrit.id);
                                    return (
                                        <>
                                            <TableRow hover key={row.id}>
                                                <StyledTableCell rowSpan={row.assessmentCriteria.length}>
                                                    {row.criteriaName}
                                                </StyledTableCell>
                                                <StyledTableCell>{firstAssessmentCrit?.assessmentName}</StyledTableCell>
                                                <StyledTableCell>
                                                    <InputField
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                        id="note"
                                                        name="note"
                                                        value={firstAssessmentCrit.value}
                                                        handleChange={(e) => onChangeElementValue(row, firstAssessmentCrit, e)}
                                                        placeholder="note"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{firstAssessmentCrit.maximumMarks}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {firstAssessmentCrit.isApplicable ? (
                                                        <Chip
                                                            color="success"
                                                            size="small"
                                                            deleteIcon={<Check />}
                                                            onDelete={() => {}}
                                                            icon={<Face />}
                                                        />
                                                    ) : (
                                                        <Chip
                                                            color="error"
                                                            size="small"
                                                            deleteIcon={<Close />}
                                                            onDelete={() => {}}
                                                            icon={<SentimentVeryDissatisfied />}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                            {otherAssessmentCrit.map((ass) => {
                                                return (
                                                    <TableRow hover key={ass.id}>
                                                        <StyledTableCell>{ass.assessmentName}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <InputField
                                                                size="small"
                                                                fullWidth
                                                                type="number"
                                                                id="note"
                                                                name="note"
                                                                value={ass.value}
                                                                handleChange={(e) => onChangeElementValue(row, ass, e)}
                                                                placeholder="note"
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">{ass.maximumMarks}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {ass.isApplicable ? (
                                                                <Chip
                                                                    color="success"
                                                                    size="small"
                                                                    deleteIcon={<Check />}
                                                                    onDelete={() => {}}
                                                                    icon={<Face />}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    color="error"
                                                                    size="small"
                                                                    deleteIcon={<Close />}
                                                                    onDelete={() => {}}
                                                                    icon={<SentimentVeryDissatisfied />}
                                                                />
                                                            )}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {dataElementEvaluation.evaluationElements && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: -1,
                            alignItems: 'center'
                        }}
                    >
                        <TypographyListDescription text={` Display`} />
                        <SelectComponent
                            options={[
                                { label: 5, value: 5 },
                                { label: 10, value: 10 },
                                { label: 25, value: 25 }
                            ]}
                            style={{ width: 70 }}
                            defaultValue={params.rowsPerPage}
                            handleChange={handleChangeRowsPerPage}
                            value={params.rowsPerPage}
                        />
                        <TypographyListDescription text={` Recordings`} />
                    </div>
                    <Pagination
                        count={params.length}
                        color="primary"
                        size="small"
                        onChange={handleChangePage}
                        shape="rounded"
                        page={params.page + 1}
                    />
                    <TypographyListDescription text={`${params.totalItems} recordings`} />
                </div>
            )}
        </SubCard>
    );
};

TableAssessmentCriteria.propTypes = {
    params: PropTypes?.object.isRequired,
    dataElementEvaluation: PropTypes?.object.isRequired,
    setDataElementEvaluation: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired,
    staticLenght: PropTypes?.number,
    handleChangePage: PropTypes?.func.isRequired,
    handleChangeRowsPerPage: PropTypes?.func.isRequired
};

export default TableAssessmentCriteria;

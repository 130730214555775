import { forwardRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import PropTypes from 'prop-types';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomStatusDialog = ({ text, handleClose, open, values, setYourState, api, snackAlert, params, refleshTable }) => {
    const [loading, setLoading] = useState(false);

    const setState = (state) => {
        const result = {
            ...values.training,
            id: state.id,
            subject: state.subject,
            startDateTime: new Date(state.startDateTime),
            endDateTime: new Date(state.endDateTime),
            maxExpectedParticipants: state.maxExpectedParticipants,
            venue: state.venue,
            additionalNotes: state.additionalNotes,
            trainingStatus: state.trainingStatus,
            updatable: state.updatable,
            trainers:
                state.trainers.length > 0
                    ? state.trainers.map((row) => {
                          return {
                              id: row.id,
                              firstName: row.firstName,
                              middleName: row.middleName,
                              lastName: row.lastName,
                              name: `${row.firstName} ${row.middleName} ${row.lastName}`,
                              legitimateForDeleteOperation: row.legitimateForDeleteOperation,
                              trainerStatus: row.trainerStatus,
                              trainingLevelDetailRequests:
                                  row.trainerTrainingLevelDescriptions !== null || row.trainerTrainingLevelDescriptions.length > 0
                                      ? row.trainerTrainingLevelDescriptions.map((row) => {
                                            return {
                                                id: row.id,
                                                trainingLevel: row.trainingLevel,
                                                description: row.trainingLevelDescription
                                            };
                                        })
                                      : []
                          };
                      })
                    : []
        };
        setYourState(result);
    };

    const handleClick = () => {
        setLoading(true);
        api(values)
            .then((res) => {
                if (res.success) {
                    setState(res.data);
                    setLoading(false);
                    handleClose();
                    snackAlert('Operation reussie', 'success');
                    refleshTable(params);
                } else {
                    setLoading(false);
                    handleClose();
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                }
            })
            .catch((error) => {
                setLoading(false);
                snackAlert(`${error.response?.data.message ? error.response.data.message : error}`, 'error');
            });
    };
    return (
        <>
            <SpinnerLoader open={loading} />
            <Dialog keepMounted open={loading ? false : open} maxWidth="md" TransitionComponent={Transition}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {text} ?
                </DialogTitle>
                <DialogContent> </DialogContent>
                <DialogActions>
                    <Button size="small" color="success" variant="contained" onClick={handleClick} disabled={loading}>
                        Valider
                    </Button>
                    <Button variant="contained" size="small" onClick={() => handleClose()}>
                        <FormattedMessage id="cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CustomStatusDialog.propTypes = {
    text: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    values: PropTypes.object,
    setYourState: PropTypes.func,
    api: PropTypes.func,
    snackAlert: PropTypes.func,
    params: PropTypes.object,
    refleshTable: PropTypes.func
};

export default CustomStatusDialog;

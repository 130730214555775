import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { paramEvaluationAPI, evaluationApi, typeAppreciationApi } from 'api';
import MainCard from 'ui-component/cards/MainCard';
import { Refresh } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import EvaluationList from './EvaluationList';
import SearchEmployee from 'views/employee/SearchEmployee';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import AddAppreciationDialog from './AddAppreciationDialog';

function ordinalSuffixOf(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
        return `${i}st`;
    }
    if (j === 2 && k !== 12) {
        return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
        return `${i}rd`;
    }
    return `${i}th`;
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Grid>{children}</Grid>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const ListTab = (props) => {
    const { valueEl, paramEvaluation, handleClick } = props;
    const rows = [];
    for (let index = 0; index < paramEvaluation.maxEvaluationLevels; index += 1) {
        const number = index + 1;
        rows.push({
            label: `${ordinalSuffixOf(number)} Evaluation`,
            value: number
        });
    }
    return (
        <Tabs value={valueEl} onClick={handleClick} aria-label="basic tabs example">
            <Tab label="" {...a11yProps(0)} />
            {rows.map((row, index) => {
                const value = parseInt(row.value, 36);
                return <Tab key={index} label={`${row.label}`} {...a11yProps(value)} />;
            })}
        </Tabs>
    );
};

ListTab.propTypes = {
    valueEl: PropTypes.number.isRequired,
    paramEvaluation: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
};

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    }),
    evaluationLevel: yup.string().required('evaluationLevel ')
});

const initialValues = {
    id: '',
    employee: null,
    evaluationLevel: '',
    evaluatorId: '',
    appreciation: {
        evaluationCode: '',
        appreciationTypeId: '',
        appreciationType: null,
        appreciatorId: 2
    }
};

const EvaluationVisualisation = () => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const [yourState, setYourState] = useState(initialValues);
    const [dataEvaluation, setDataEvaluation] = useState({
        nextEvaluation: 0,
        fullName: '',
        maximumMarks: '',
        obtainedMarks: '',
        percentage: 0,
        evaluationCode: '',
        ratingType: '',
        appreciationType: '',
        evaluationElements: []
    });
    const [paramEvaluation, setParamEvaluation] = useState({
        id: '',
        endDate: '',
        startDate: '',
        maxEvaluationLevels: 0,
        isEvaluationUnderWay: ''
    });
    const [loading, setLoading] = useState({ level: false, data: false });
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [valueEl, setValueEl] = useState(0);
    const [dataTypeAppreciation, setDataTypeAppreciation] = useState([]);
    const [open, setOpen] = useState(false);

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const getTypeAppreciation = async () => {
        await typeAppreciationApi
            .getTypeAppreciation()
            .then((res) => {
                if (res.success) {
                    setDataTypeAppreciation(res.data);
                } else {
                    setDataTypeAppreciation([]);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const refleshTable = React.useCallback(async () => {
        setLoading({ ...loading, level: true });
        getTypeAppreciation();
        await paramEvaluationAPI
            .getListeParamAddEvaluation()
            .then((res) => {
                console.log(res);
                const result = {
                    id: res?.id,
                    endDate: res?.endDate,
                    startDate: res?.startDate,
                    maxEvaluationLevels: res?.maxEvaluationLevels,
                    isEvaluationUnderWay: res?.isEvaluationUnderWay
                };
                setParamEvaluation(result);
                setLoading({ ...loading, level: false });
            })
            .catch((error) => {
                snackAlert(`${error.response?.data.description ? error.response.data.description : error.message}`, 'warning');
                setLoading({ ...loading, level: false });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...yourState, employee: yourState.employee },
        validationSchema,
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    useEffect(() => {
        isMounted.current = true;
        refleshTable();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentEmployee !== null) {
            setYourState({ ...yourState, employee: currentEmployee });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const handleChangePage = (event, newPage) => {
        setParams({ ...params, page: newPage - 1 });
    };

    const handleChangeRowsPerPage = (event) => {
        setParams({
            ...params,
            length: Math.ceil(dataEvaluation.evaluationElements.length / parseInt(event?.target.value, 10)) || 1,
            page: 0,
            rowsPerPage: parseInt(event?.target.value, 10)
        });
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    const handleClick = (event) => {
        const valueLevel = parseInt(event.target.id, 36);
        if (yourState.employee === null || yourState.employee?.id === '' || yourState.employee?.id < 0) {
            snackAlert('choose Employee', 'warning');
            return;
        }
        setValueEl(valueLevel);
        const values = { ...yourState, evaluationLevel: valueLevel, evaluatorId: 2 };
        setLoading({ ...loading, data: true });
        evaluationApi
            .getEvaluation(values)
            .then((res) => {
                console.log(res);
                if (res.success) {
                    const result = {
                        nextEvaluation: valueLevel,
                        fullName: res.data.fullName,
                        maximumMarks: res.data.maximumMarks,
                        obtainedMarks: res.data.obtainedMarks,
                        percentage: res.data.percentage,
                        evaluationCode: res.data.evaluationCode,
                        ratingType: res.data.ratingType,
                        appreciationType: res.data.appreciationType,
                        evaluationElements:
                            res.data.evaluationByCriteriaSummaryResponses.length > 0
                                ? res.data.evaluationByCriteriaSummaryResponses.map((row) => {
                                      return {
                                          id: row.id,
                                          criteriaName: row.criteriaName,
                                          percentage: row.percentage,
                                          totalMaximumMarks: row.totalMaximumMarks,
                                          totalObtainedMarks: row.totalObtainedMarks,
                                          assessmentCriteria:
                                              row.assessments.length > 0
                                                  ? row.assessments.map((row) => {
                                                        return {
                                                            id: row.id,
                                                            assessmentName: row.assessmentName,
                                                            maximumMarks: row.maximumMarks,
                                                            obtainedMarks: row.obtainedMarks,
                                                            percentage: row.percentage
                                                        };
                                                    })
                                                  : []
                                      };
                                  })
                                : []
                    };
                    setDataEvaluation(result);
                    setParams({
                        ...params,
                        length: Math.ceil(result.evaluationElements.length / params.rowsPerPage) || 1,
                        totalItems: result.evaluationElements.length || 0
                    });
                    setLoading({ ...loading, data: false });
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setDataEvaluation({
                        nextEvaluation: valueLevel,
                        fullName: '',
                        maximumMarks: '',
                        obtainedMarks: '',
                        percentage: 0,
                        evaluationCode: '',
                        ratingType: '',
                        evaluationElements: []
                    });
                    setLoading({ ...loading, data: false });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const appreciationFunction = (values) => {
        setYourState({
            ...yourState,
            ...values,
            appreciation: {
                ...yourState.appreciation,
                evaluationCode: values.evaluationCode
            }
        });
        setOpen(true);
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddAppreciationDialog
                api={evaluationApi}
                params={params}
                open={open}
                setOpen={setOpen}
                yourState={yourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
                dataTypeAppreciation={dataTypeAppreciation}
                ordinalSuffixOf={ordinalSuffixOf}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            {HandleOpenDialog()}
            <Grid container spacing={2}>
                <HeaderComponent header="evaluation" subtitle="view-your-evaluation" />
                <Grid item xs={12} lg={12}>
                    <SpinnerLoader open={loading.data} />
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                <ButtonComponent
                                    text={<FormattedMessage id="refresh" />}
                                    size="small"
                                    variant="contained"
                                    startIcon={<Refresh />}
                                    handleClick={() => {
                                        refleshTable();
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <MainCard sx={{ mt: 1, mb: 1 }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="doucleClick employee"
                                        id="employee"
                                        name="employee"
                                        value={`${formik.values.employee?.code || ''} || ${formik.values.employee?.firstName || ''} ${
                                            formik.values.employee?.middleName || ''
                                        } ${formik.values.employee?.lastName || ''}`}
                                        handleChange={() => {}}
                                        onDoubleClick={() => setOpenEmpl(true)}
                                        error={formik.touched.employee && Boolean(formik.errors.employee)}
                                        helperText={formik.touched.employee && formik.errors.employee}
                                        placeholder="employee"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="personnel"
                                        id="personnel"
                                        name="personnel"
                                        value={formik.values?.personnel || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.personnel && Boolean(formik.errors.personnel)}
                                        helperText={formik.touched.personnel && formik.errors.personnel}
                                        placeholder="personnel"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="categorie"
                                        id="categorie"
                                        name="categorie"
                                        value={formik.values?.categorie || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.categorie && Boolean(formik.errors.categorie)}
                                        helperText={formik.touched.categorie && formik.errors.categorie}
                                        placeholder="categorie"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="grade"
                                        id="grade"
                                        name="grade"
                                        value={formik.values?.grade || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.grade && Boolean(formik.errors.grade)}
                                        helperText={formik.touched.grade && formik.errors.grade}
                                        placeholder="grade"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={gridSpacing} sx={{ mt: gridSpacing }}>
                                <Grid item xs={12}>
                                    <Box sx={{ width: '100%', typography: 'body1' }}>
                                        {loading.level ? (
                                            <EarningCard />
                                        ) : (
                                            <>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <ListTab
                                                        valueEl={valueEl}
                                                        paramEvaluation={paramEvaluation}
                                                        handleClick={handleClick}
                                                    />
                                                </Box>
                                                <TabPanel value={valueEl} index={valueEl} sx={{ padding: '0px' }}>
                                                    <EvaluationList
                                                        params={params}
                                                        dataEvaluation={dataEvaluation}
                                                        handleChangePage={handleChangePage}
                                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                        ordinalSuffixOf={ordinalSuffixOf}
                                                        appreciationFunction={appreciationFunction}
                                                    />
                                                </TabPanel>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default EvaluationVisualisation;

import { forwardRef } from 'react';

// material-ui
import { CircularProgress, Dialog, Grid, IconButton, Slide, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Close from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { Edit, RotateLeftRounded, Save } from '@mui/icons-material';
import { gridSpacing } from 'store/constant';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import ButtonComponent from 'ui-component/ButtonComponent';
import SelectComponent from 'ui-component/SelectComponent';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import moment from 'moment/moment';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

// ================= IMPORT AXIOS ET INITIALISATION DE JSON DB =============

const ParamPositionModal = ({ open, handleClose, listeCategories, typePositionListe, typePositionToEdit, handleSubmit, loading }) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            atachmentDate: typePositionToEdit?.atachmentDate,
            detachmentDate: typePositionToEdit?.detachmentDate,
            personalCategoryId: typePositionToEdit.id
                ? typePositionToEdit?.personalCategoryId?.value
                : typePositionToEdit?.personalCategoryId,
            positionTypeId: typePositionToEdit.id ? typePositionToEdit?.positionTypeId?.value : typePositionToEdit?.positionTypeId
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            personalCategoryId: yup.string().required(<FormattedMessage id="category-req" />),
            positionTypeId: yup.string().required(<FormattedMessage id="type-position-req" />),
            atachmentDate: yup
                .date()
                .nullable(true)
                .required(<FormattedMessage id="atachment-date-req" />)
                .typeError(<FormattedMessage id="format-date-invalid" />),
            detachmentDate: yup
                .date()
                .nullable(true)
                .typeError(<FormattedMessage id="format-date-invalid" />)
        }),
        onSubmit: async (values, { resetForm }) => {
            const tcToSave = {
                ...values,
                personalCategoryId: values?.personalCategoryId,
                positionTypeId: values?.positionTypeId,
                detachmentDate: values?.detachmentDate ? moment(values?.detachmentDate).format('YYYY-MM-DD') : '',
                atachmentDate: moment(values?.atachmentDate).format('YYYY-MM-DD')
            };
            handleSubmit(tcToSave, resetForm);
        }
    });
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-adresse1"
                maxWidth="sm"
                fullWidth
            >
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            {typePositionToEdit.id ? (
                                <FormattedMessage id="edit-param-position" />
                            ) : (
                                <FormattedMessage id="add-new-param-position" />
                            )}
                        </DialogTitle>
                        <IconButton
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-categorie' })}
                                            options={listeCategories}
                                            handleChange={formik.handleChange}
                                            name="personalCategoryId"
                                            error={formik.touched.personalCategoryId && formik.errors.personalCategoryId}
                                            helperText={formik.touched.personalCategoryId && formik.errors.personalCategoryId}
                                            value={formik.values.personalCategoryId}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-typePosition' })}
                                            options={typePositionListe}
                                            handleChange={formik.handleChange}
                                            name="positionTypeId"
                                            error={formik.touched.positionTypeId && formik.errors.positionTypeId}
                                            helperText={formik.touched.positionTypeId && formik.errors.positionTypeId}
                                            value={formik.values.positionTypeId}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <DatePickerComponent
                                            label={intl.formatMessage({ id: 'atachmentDate' })}
                                            name="atachmentDate"
                                            minDate={new Date()}
                                            horizontal={false}
                                            handleChange={(e) => {
                                                formik.setFieldValue('atachmentDate', e);
                                                formik.setFieldValue('detachmentDate', '');
                                            }}
                                            value={formik.values.atachmentDate}
                                            error={formik.touched.atachmentDate && Boolean(formik.errors.atachmentDate)}
                                            helperText={formik.touched.atachmentDate && formik.errors.atachmentDate}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <DatePickerComponent
                                            label={intl.formatMessage({ id: 'detachmentDate' })}
                                            name="detachmentDate"
                                            minDate={formik.values.atachmentDate}
                                            horizontal={false}
                                            handleChange={(e) => {
                                                formik.setFieldValue('detachmentDate', e);
                                            }}
                                            value={formik.values.detachmentDate}
                                            error={formik.touched.detachmentDate && Boolean(formik.errors.detachmentDate)}
                                            helperText={formik.touched.detachmentDate && formik.errors.detachmentDate}
                                            disabled={loading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={intl.formatMessage({ id: 'reset' })}
                            disabled={loading || !formik.dirty}
                            handleClick={() => formik.resetForm()}
                        />
                        <ButtonComponent
                            variant="contained"
                            text={
                                typePositionToEdit.id
                                    ? `${intl.formatMessage({ id: 'update' })}`
                                    : `${intl.formatMessage({
                                          id: 'save'
                                      })}`
                            }
                            disabled={loading || !formik.dirty}
                            size="small"
                            type="submit"
                            startIcon={typePositionToEdit.id ? <Edit /> : <Save />}
                            endIcon={loading ? <CircularProgress size="16px" /> : null}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};
ParamPositionModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    typePositionToEdit: PropTypes?.object.isRequired,
    listeCategories: PropTypes?.array.isRequired,
    typePositionListe: PropTypes?.array.isRequired,
    handleSubmit: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired
};
export default ParamPositionModal;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const addDependents = async (values, valuesDependents) => {
    try {
        const response = await instance.post(`${uri}/employees/${values.idEmployee}/dependents`, valuesDependents);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateDependents = async (values, valuesDependents) => {
    try {
        const response = await instance.put(`${uri}/dependents/multiselect-update/${values.idEmployee}`, valuesDependents);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteDependents = async (values) => {
    try {
        const response = await instance.delete(`${uri}/dependents/multiselect-delete`, { data: values });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { addDependents, updateDependents, deleteDependents };

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeAvancementsGrades(filterValue, paginationDetails) {
    if (filterValue) {
        return instance
            .get(`${baseURL}/grade-advancements/designationOrCode/${filterValue}`, {
                params: {
                    pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                    pageSize: paginationDetails ? paginationDetails.pageSize : null
                }
            })
            .then(HandleResponse)
            .catch(HandleError);
    }
    return instance
        .get(`${baseURL}/grade-advancements`, {
            params: {
                page: paginationDetails ? paginationDetails.page : null,
                size: paginationDetails ? paginationDetails.size : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveAvancementGrade = async (avancement) => {
    return instance.post(`${baseURL}/grade-advancements`, avancement).then(HandleResponse).catch(HandleError);
};

const updateAvancementGrade = async (avancement) => {
    return instance.put(`${baseURL}/grade-advancements/${avancement.id}`, avancement).then(HandleResponse).catch(HandleError);
};

const deleteAvancementGrade = async (id) => {
    return instance.delete(`${baseURL}/grade-advancements/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeAvancementsGrades,
    saveAvancementGrade,
    updateAvancementGrade,
    deleteAvancementGrade
};

import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
// assets
import { IconSearch } from '@tabler/icons';
import Close from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const ListSearchComponent = ({ handleChange, handleClear, sx, filterValue, filter, style, ...props }) => {
    const intl = useIntl();
    return (
        <OutlinedInput
            id="input-search-list-style1"
            placeholder={intl.formatMessage({ id: 'search' })}
            style={{ ...style }}
            data-testid="list-search"
            name="list-search"
            value={filterValue}
            sx={{ ...sx }}
            onChange={handleChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    filter();
                }
            }}
            {...props}
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1rem" />
                </InputAdornment>
            }
            endAdornment={
                filterValue ? (
                    <InputAdornment position="end">
                        <IconButton component="span" size="smalll" onClick={() => handleClear()} data-testid="close-list">
                            <Close fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ) : null
            }
            size="small"
            autoComplete="off"
        />
    );
};

ListSearchComponent.propTypes = {
    handleChange: PropTypes.func,
    handleClear: PropTypes.func,
    sx: PropTypes.object,
    filterValue: PropTypes.string,
    filter: PropTypes.func,
    style: PropTypes.object
};

export default ListSearchComponent;

import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DeleteDialog = ({ handleClose, open, handleDelete, deleting, text }) => {
    return (
        <Dialog open={open}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <FormattedMessage id="delete-alert" /> {text} ?
            </DialogTitle>
            <DialogContent> </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={deleting ? <CircularProgress size="16px" /> : null}
                    disabled={deleting}
                    onClick={handleDelete}
                    style={{ backgroundColor: 'red' }}
                >
                    <FormattedMessage id="delete" />
                </Button>
                <Button
                    onClick={() => handleClose()}
                    variant="contained"
                    // style={{ backgroundColor: "blue" }}
                >
                    <FormattedMessage id="cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    handleDelete: PropTypes.func,
    deleting: PropTypes.bool,
    text: PropTypes.string
};

export default DeleteDialog;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const url = '/erp-payroll-hr-service/api/v1';

async function getParamsPositions(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${url}/param-positions`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveParamPosition = async (values) => {
    console.log('OBJET', values);
    try {
        const response = await instance.post(`${url}/param-positions`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateParamPosition = async (values) => {
    console.log('OBJET', values);
    try {
        const response = await instance.put(`${url}/param-positions/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteParamPosition = async (id) => {
    try {
        const response = await instance.delete(`${url}/param-positions/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};
export default {
    getParamsPositions,
    saveParamPosition,
    updateParamPosition,
    deleteParamPosition
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import TrainingLevelList from './TrainingLevelList';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    firstName: yup.string().required('firstName'),
    middleName: yup.string().required('middleName'),
    lastName: yup.string().required('lastName'),
    email: yup.string().email().required('lastName')
});

const init = {
    trainingLevel: null,
    description: '',
    formErrors: { trainingLevel: null, description: '' },
    touched: { trainingLevel: false, description: false },
    formValid: false
};

const AddTrainer = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    dataAllTrainingLevel,
    snackAlert
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [initState, setInitState] = useState(init);
    const [dataTrainingLevel, setDataTrainingLevel] = useState(yourState.trainingLevelDetailRequests);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'numero',
            align: 'left'
        },
        {
            id: 'trainingLevel',
            numeric: true,
            label: 'trainingLevel',
            align: 'left'
        },
        {
            id: 'description',
            numeric: true,
            label: 'description',
            align: 'left'
        }
    ];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesTrainer = {
                id: values.id,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                email: values.email,
                trainingLevelDetailRequests:
                    dataTrainingLevel !== null || dataTrainingLevel.length !== 0
                        ? dataTrainingLevel.map((row) => {
                              return {
                                  trainingLevelId: row.trainingLevel?.id,
                                  description: row.description
                              };
                          })
                        : []
            };
            setLoading(true);
            if (values.id === '') {
                api.addTrainer(valuesTrainer)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setDataTrainingLevel([]);
                            setDataResponse({ open: false, success: [], failures: [] });
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateTrainer(valuesTrainer)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataTrainingLevel.filter((row, index) => index !== indexOf);
        setDataTrainingLevel(arrayCopy);
    };

    const reinitForm = () => {
        setYourState(initialValues);
        setDataTrainingLevel([]);
        setDataResponse({ open: false, success: [], failures: [] });
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Trainer</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="firstName"
                                    id="firstName"
                                    name="firstName"
                                    value={formik.values?.firstName || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    placeholder="firstName"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="middleName"
                                    id="middleName"
                                    name="middleName"
                                    value={formik.values?.middleName || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                    helperText={formik.touched.middleName && formik.errors.middleName}
                                    placeholder="middleName"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="lastName"
                                    fullWidth
                                    label="lastName"
                                    id="lastName"
                                    name="lastName"
                                    value={formik.values?.lastName || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    placeholder="lastName"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="email"
                                    fullWidth
                                    type="email"
                                    label="email"
                                    id="email"
                                    name="email"
                                    value={formik.values?.email || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    placeholder="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TrainingLevelList
                                    actions
                                    dataTrainingLevel={dataTrainingLevel}
                                    setDataTrainingLevel={setDataTrainingLevel}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                    initState={initState}
                                    setInitState={setInitState}
                                    dataAllTrainingLevel={dataAllTrainingLevel}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

AddTrainer.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    dataAllTrainingLevel: PropTypes?.array,
    snackAlert: PropTypes?.func.isRequired
};

export default AddTrainer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { trainerApi, trainingApi } from 'api';
import TableTraining from './TableTraining';
import AddTraining from './AddTraining';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import ViewTraining from './ViewTraining';

const initialValues = {
    id: '',
    subject: '',
    startDateTime: new Date(),
    endDateTime: new Date(),
    maxExpectedParticipants: '',
    venue: '',
    additionalNotes: '',
    trainingStatus: '',
    updatable: '',
    trainers: [],
    trainingType: {
        id: '',
        code: '',
        designation: ''
    }
};

const TrainingList = ({ ...props }) => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataTraining, setDataTraining] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState({ add: false, view: false });
    const [openDelete, setOpenDelete] = useState(false);
    const [dataTrainer, setDataTrainer] = useState([]);

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'left'
        },
        {
            id: 'subject',
            numeric: true,
            label: 'subject',
            align: 'left'
        },
        {
            id: 'startDateTime',
            numeric: true,
            label: 'startDateTime',
            align: 'right'
        },
        {
            id: 'endDateTime',
            numeric: true,
            label: 'endDateTime',
            align: 'right'
        },
        {
            id: 'maxExpectedParticipants',
            numeric: true,
            label: 'maxExpectedParticipants',
            align: 'right'
        },
        {
            id: 'venue',
            numeric: true,
            label: 'venue',
            align: 'left'
        },
        {
            id: 'additionalNotes',
            numeric: true,
            label: 'additionalNotes',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'left'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const getTrainer = async () => {
        await trainerApi
            .getTrainers()
            .then((res) => {
                if (res.success) {
                    const result = [];
                    res.data.data.forEach((row) => {
                        result.push({
                            id: row.id,
                            firstName: row.firstName,
                            middleName: row.middleName,
                            lastName: row.lastName,
                            name: `${row.firstName} ${row.middleName} ${row.lastName}`
                        });
                    });
                    setDataTrainer(result);
                } else {
                    setDataTrainer([]);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const refleshTable = async (params) => {
        setLoading(true);
        getTrainer();
        await trainingApi
            .getTraining(params)
            .then((res) => {
                if (res.success) {
                    const result = [];
                    console.log(res.data);
                    res.data.content.forEach((row) => {
                        result.push({
                            id: row.id,
                            subject: row.subject,
                            startDateTime: new Date(row.startDateTime),
                            endDateTime: new Date(row.endDateTime),
                            maxExpectedParticipants: row.maxExpectedParticipants,
                            venue: row.venue,
                            additionalNotes: row.additionalNotes,
                            trainingStatus: row.trainingStatus,
                            updatable: row.updatable,
                            trainers:
                                row.trainers.length > 0
                                    ? row.trainers.map((row) => {
                                          return {
                                              id: row.id,
                                              firstName: row.firstName,
                                              middleName: row.middleName,
                                              lastName: row.lastName,
                                              name: `${row.firstName} ${row.middleName} ${row.lastName}`,
                                              legitimateForDeleteOperation: row.legitimateForDeleteOperation,
                                              trainerStatus: row.trainerStatus,
                                              trainingLevelDetailRequests:
                                                  row.trainerTrainingLevelDescriptions !== null ||
                                                  row.trainerTrainingLevelDescriptions.length > 0
                                                      ? row.trainerTrainingLevelDescriptions.map((row) => {
                                                            return {
                                                                id: row.id,
                                                                trainingLevel: row.trainingLevel,
                                                                description: row.trainingLevelDescription
                                                            };
                                                        })
                                                      : []
                                          };
                                      })
                                    : [],
                            trainingType: {
                                id: row.trainingType?.id,
                                code: row.trainingType?.code,
                                designation: row.trainingType?.designation
                            }
                        });
                    });
                    setDataTraining({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: res.data?.totalPages || 0,
                        totalItems: res.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataTraining.rows.filter((row) => {
                let matches = true;

                const properties = ['subject', 'startDateTime', 'endDateTime'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataTraining({ ...dataTraining, rows: newRows });
        } else {
            setDataTraining({ ...dataTraining, rows: dataTraining.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open.add ? (
            <AddTraining
                api={trainingApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                dataTrainer={dataTrainer}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen({ ...open, add: true });
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete(true);
    };

    const viewFunction = (row) => {
        setYourState(row);
        setOpen({ ...open, view: true });
    };

    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete(false);
                }}
                open={openDelete}
                text="Are you sure you want to delete this training ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={trainingApi.deleteTraining}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const HandleOpenViewDialog = () => {
        const openDialog = open.view ? (
            <ViewTraining
                api={trainingApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    return (
        <>
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            {HandleOpenViewDialog()}
            <Grid container spacing={gridSpacing}>
                <HeaderComponent header="training-list" subtitle="view-your-training" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataTraining({ ...dataTraining, rows: dataTraining.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => setOpen({ ...open, add: true })}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableTraining
                                actions
                                params={params}
                                dataTraining={dataTraining.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                viewFunction={viewFunction}
                                setCurrentTraining={props.setCurrentTraining}
                                open={props.open}
                                setOpen={props.setOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

TrainingList.propTypes = {
    setCurrentTraining: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default TrainingList;

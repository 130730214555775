import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

const InputPhone = ({ country, label, handleChange, name, style, labelStyles, value, id, ...props }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: 3,
                marginBottom: 3,
                ...style
            }}
        >
            <label
                htmlFor="input"
                style={{
                    marginRight: 5,
                    fontWeight: 'normal',
                    color: 'black',
                    ...labelStyles
                }}
            >
                {label}
            </label>
            <PhoneInput
                {...props}
                country={country}
                value={value}
                name={name}
                inputProps={{
                    name: { name },
                    required: false,
                    autoFocus: true
                }}
                containerStyle={{
                    width: '100%',
                    display: 'flex'
                }}
                inputStyle={{ borderRadius: '0px', flex: 1 }}
                countryCodeEditable="false"
                enableSearch="true"
                onChange={handleChange}
                data-testid={id}
            />
        </div>
    );
};

InputPhone.propTypes = {
    handleChange: PropTypes?.func,
    country: PropTypes?.string,
    label: PropTypes?.string,
    name: PropTypes?.string,
    style: PropTypes?.func,
    labelStyles: PropTypes?.func,
    value: PropTypes?.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    id: PropTypes.string
};

export default InputPhone;

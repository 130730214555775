import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    TableSortLabel,
    Toolbar,
    Chip
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { visuallyHidden } from '@mui/utils';
import { Box, alpha } from '@mui/system';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = (props) => {
    const { headCells, theme, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const SelectEmployeeDependents = ({ actions, dataSelected, loading, headCells, editFunction, staticLenght, ...tableProps }) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const setCurrent = (row, index) => {
        editFunction(row, index, 'update');
    };

    return (
        <MainCard content={false}>
            {loading ? (
                <EarningCard />
            ) : (
                <>
                    <Toolbar sx={{ bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }}>
                        <Typography
                            sx={{
                                flex: '1 1 100%',
                                padding: '10px'
                            }}
                            variant="h4"
                            id="tableTitle"
                            component="div"
                        >
                            Modification table
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                            <EnhancedTableHead
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />

                            {dataSelected.data && (
                                <TableBody>
                                    {stableSort(dataSelected.data, getComparator(order, orderBy))?.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        return (
                                            <StyledTableRow
                                                sx={{ ': hover td': { backgroundColor: '#DDEDF9', color: 'black', cursor: 'pointer' } }}
                                                tabIndex={-1}
                                                key={index}
                                                onDoubleClick={() => setCurrent(row, index)}
                                                data-testid="rowItems"
                                            >
                                                <StyledTableCell scope="row" sx={{ cursor: 'pointer' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        #{index + 1}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.firstName} {row.middleName} {row.lastName}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.birthDate.toString().slice(0, 15)}</StyledTableCell>
                                                <StyledTableCell>{row.birthPlace}</StyledTableCell>
                                                <StyledTableCell>{row.gender}</StyledTableCell>
                                                <StyledTableCell>{row.relation?.designation}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.relation?.id > 0 ? (
                                                        <Chip label="exist" color="success" size="small" />
                                                    ) : (
                                                        <Chip label="new" color="error" size="small" />
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    {dataSelected.data.length <= 0 && (
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                                <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                    <FormattedMessage id="no-data" />
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </>
            )}
        </MainCard>
    );
};

SelectEmployeeDependents.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object,
    dataSelected: PropTypes?.object.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool,
    staticLenght: PropTypes?.number,
    editFunction: PropTypes?.func.isRequired
};

export default SelectEmployeeDependents;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getMeasure(params) {
    try {
        const response = await instance.get(
            `${uri}/disciplinary-measures?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addMeasure = async (values) => {
    try {
        const response = await instance.post(`${uri}/disciplinary-measures`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateMeasure = async (values) => {
    try {
        const response = await instance.put(`${uri}/disciplinary-measures/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteMeasure = async (id) => {
    try {
        const response = await instance.delete(`${uri}/disciplinary-measures/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

async function getSanctionAppealing(params) {
    try {
        const response = await instance.get(
            `${uri}/sanctions-appealing?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addSanctionAppealing = async (values) => {
    try {
        const response = await instance.post(`${uri}/sanctions-appealing`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSanctionAppealing = async (values) => {
    try {
        const response = await instance.put(`${uri}/sanctions-appealing/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSanctionAppealing = async (id) => {
    try {
        const response = await instance.delete(`${uri}/sanctions-appealing/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getSanctionDecision = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/sanction-decisions?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addSanctionDecision = async (values) => {
    try {
        const response = await instance.post(`${uri}/sanction-decisions`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSanctionDecision = async (values) => {
    try {
        const response = await instance.post(`${uri}/sanction-decisions/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSanctionDecision = async (id) => {
    try {
        const response = await instance.delete(`${uri}/sanction-decisions/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getMeasure,
    addMeasure,
    updateMeasure,
    deleteMeasure,
    getSanctionAppealing,
    addSanctionAppealing,
    updateSanctionAppealing,
    deleteSanctionAppealing,
    getSanctionDecision,
    addSanctionDecision,
    updateSanctionDecision,
    deleteSanctionDecision
};

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-global-service/api/v1';

const getBank = async () => {
    try {
        const response = await instance.get(`${uri}/banques`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addBank = async (values) => {
    try {
        const response = await instance.post(`${uri}/banques`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateBank = async (values) => {
    try {
        const response = await instance.put(`${uri}/banques/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteBank = async (id) => {
    try {
        const response = await instance.delete(`${uri}/banques/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getBank, addBank, updateBank, deleteBank };

import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { heureSupplemetaireApi } from 'api';
import MainCard from 'ui-component/cards/MainCard';
import { RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import HeureSupplementaireList from './HeureSupplementaireList';
import DatePickerComponent from 'ui-component/DatePickerComponent';

const validationSchema = yup.object({
    numero: yup.string().required('numero'),
    startDate: yup.string().required('startDate')
});

const initialValues = {
    id: '',
    numero: '',
    startDate: new Date(),
    endDate: new Date()
};

const init = {
    startHour: '',
    endHour: '',
    rate: '',
    formErrors: { startHour: '', endHour: '', rate: '' },
    touched: { startHour: false, endHour: false, rate: false },
    formValid: false
};

const HeureSupplementaire = () => {
    const dispatch = useDispatch();
    const [yourState, setYourState] = useState(initialValues);
    const [initState, setInitState] = useState(init);
    const [dataHeureSup, setDataHeureSup] = useState([]);
    const [loading, setLoading] = useState(false);
    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'No',
            align: 'center'
        },
        {
            id: 'startHour',
            numeric: true,
            label: 'startHour',
            align: 'left'
        },
        {
            id: 'endHour',
            numeric: true,
            label: 'endHour',
            align: 'left'
        },
        {
            id: 'rate',
            numeric: true,
            label: 'Rate(%) Sur S.Base',
            align: 'left'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataHeureSup.filter((row, index) => index !== indexOf);
        setDataHeureSup(arrayCopy);
    };

    const reinitForm = () => {
        setYourState(initialValues);
        setDataHeureSup([]);
        setInitState(init);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesHeureSup = {
                id: values.id,
                numero: values.numero,
                startDate: values.startDate,
                endDate: values.endDate,
                hoursRequest:
                    dataHeureSup !== null || dataHeureSup.length !== 0
                        ? dataHeureSup.map((row) => {
                              return {
                                  id: row.id,
                                  startHour: row.startHour,
                                  endHour: row.endHour,
                                  rate: row.rate
                              };
                          })
                        : []
            };
            setLoading(true);
            if (values.id === '') {
                heureSupplemetaireApi
                    .addHeureSup(valuesHeureSup)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setLoading(false);
                            reinitForm();
                            formik.resetForm();
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                heureSupplemetaireApi
                    .updateHeureSup(valuesHeureSup)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const getSearchHeureSup = async () => {
        setLoading(true);
        await heureSupplemetaireApi
            .getOneHeureSup(formik.values.numero)
            .then((res) => {
                if (res.success) {
                    setYourState({
                        id: res.data.id,
                        numero: res.data.numero,
                        startDate: new Date(res.data?.startDate),
                        endDate: new Date(res.data?.endDate)
                    });
                    const dataHours = [];
                    res.data.hoursResponse.forEach((element) => {
                        dataHours.push({
                            id: element.id,
                            startHour: element.startHour,
                            endHour: element.startHour,
                            rate: element.rate
                        });
                    });
                    setDataHeureSup(dataHours);
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    return (
        <Grid container spacing={2}>
            <HeaderComponent header="heure-sup-list" subtitle="view-your-heure-sup" />
            <Grid item xs={12} lg={12}>
                <SpinnerLoader open={loading} />
                <MainCard sx={{ mt: 1, mb: 1 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="numero"
                                    id="numero"
                                    name="numero"
                                    value={formik.values?.numero || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.numero && Boolean(formik.errors.numero)}
                                    helperText={formik.touched.numero && formik.errors.numero}
                                    placeholder="numero"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="startDate"
                                    id="startDate"
                                    name="startDate"
                                    value={formik.values.startDate || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('startDate', value);
                                        formik.setFieldValue('endDate', value);
                                    }}
                                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                    helperText={formik.touched.startDate && formik.errors.startDate}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="endDate"
                                    id="endDate"
                                    name="endDate"
                                    value={formik.values?.endDate || ''}
                                    minDate={formik.values?.startDate || ''}
                                    handleChange={(value) => formik.setFieldValue('endDate', value)}
                                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                    helperText={formik.touched.endDate && formik.errors.endDate}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between">
                                    <ButtonComponent
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        startIcon={<RotateLeftRounded />}
                                        text="Charger"
                                        handleClick={getSearchHeureSup}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <HeureSupplementaireList
                                    actions
                                    dataHeureSup={dataHeureSup}
                                    setDataHeureSup={setDataHeureSup}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                    init={init}
                                    initState={initState}
                                    setInitState={setInitState}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            startIcon={<RotateLeftRounded />}
                                            text={<FormattedMessage id="reset" />}
                                            handleClick={reinitForm}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                                            handleClick={() => {}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default HeureSupplementaire;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeGrades(paginationDetails) {
    return instance
        .get(`${baseURL}/personal-grades`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}
async function getListeGradesFiltres(designationCode, paginationDetails) {
    return instance
        .get(`${baseURL}/personal-grades/code-designation/${designationCode}`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveGradePersonnel = async (gradeInferieur) => {
    return instance.post(`${baseURL}/personal-grades`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const updateGradePersonnel = async (gradeInferieur) => {
    return instance.put(`${baseURL}/personal-grades/${gradeInferieur.id}`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const deleteGradePersonnel = async (id) => {
    return instance.delete(`${baseURL}/personal-grades/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeGrades,
    getListeGradesFiltres,
    saveGradePersonnel,
    updateGradePersonnel,
    deleteGradePersonnel
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    Paper,
    Slide,
    Stack,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, SaveRounded, Edit, CalendarTodayTwoTone, PhoneAndroidTwoTone, EmailTwoTone, PictureAsPdf } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import { FileUpload, FileUploadView } from 'views/ui-elements/uploadFile/pdf';
import DialogLayoutFile from './DialogLayoutFile';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const ViewAbsences = ({ api, open, setOpen, current, params, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [file, setFile] = useState(null);
    const [viewFile, setViewFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPdf, setOpenPdf] = useState(false);
    const fileUploadType = ['application/pdf'];

    const getViewFile = (file) => {
        if (file && fileUploadType.includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                setFile(file);
                setViewFile(e.target.result);
                setOpenPdf(true);
            };
        } else {
            setViewFile(file);
            setOpenPdf(true);
        }
    };

    const removeFile = () => {
        setViewFile(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (file && fileUploadType.includes(file.type)) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('justifiedOn', new Date().toISOString().split('T')[0]);
            setLoading(true);
            api.uploadFile(current, formData)
                .then((res) => {
                    console.log(res);
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        refleshTable(params);
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        } else {
            snackAlert('fileError', 'warning');
        }
    };

    const openPdfFile = () => {
        const openDialog = openPdf ? <DialogLayoutFile viewFile={viewFile} open={openPdf} handleClose={() => setOpenPdf(false)} /> : '';
        return openDialog;
    };

    return (
        <>
            {openPdfFile()}
            <Dialog fullWidth={fullWidth} maxWidth="lg" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Upload file</DialogTitle>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={8}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <CalendarTodayTwoTone sx={detailsIconSX} /> {current.employee?.firstName}{' '}
                                                {current.employee?.lastName}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <PhoneAndroidTwoTone sx={detailsIconSX} /> {current.employee?.mobilePhoneNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <EmailTwoTone sx={detailsIconSX} /> {current.employee?.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={sxDivider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">Information Absence</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                reason: &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {current?.reason}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                durationInHours: &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {current?.durationInHours} Hours
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    <Typography variant="subtitle1">
                                                                        startDate : &nbsp;
                                                                        <Typography component="span" variant="body2">
                                                                            {current?.startDate?.toString().slice(0, 15)}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography variant="subtitle1">
                                                                        endDate : &nbsp;
                                                                        <Typography component="span" variant="body2">
                                                                            {current?.endDate?.toString().slice(0, 15)}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">Other information</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                absenceStatus : &nbsp;
                                                                {current?.absenceStatus === 'APPROVED' && (
                                                                    <Chip label="approved" color="success" size="small" />
                                                                )}
                                                                {current?.absenceStatus === 'PENDING' && (
                                                                    <Chip label="pending" color="primary" size="small" />
                                                                )}
                                                                {current?.absenceStatus === 'DENIED' && (
                                                                    <Chip label="denied" color="error" size="small" />
                                                                )}
                                                                {current?.absenceStatus === 'JUSTIFICATION_PENDING' && (
                                                                    <Chip label="justificationPending" color="warning" size="small" />
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                justifiedOn : &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {current?.justifiedOn?.toString().slice(0, 15)}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                justified : &nbsp;
                                                                {current?.justified === true ? (
                                                                    <Chip label="justified" size="small" color="success" />
                                                                ) : (
                                                                    <Chip label="no justified" size="small" color="warning" />
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={sxDivider} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={gridSpacing}>
                                {current?.file?.fileAttachmentLink && (
                                    <Grid item xs={12}>
                                        <Paper
                                            sx={{
                                                p: 1,
                                                margin: 'auto',
                                                maxWidth: 500,
                                                flexGrow: 1,
                                                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff')
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <ButtonBase onClick={() => getViewFile(current?.file?.fileAttachmentLink)}>
                                                        <PictureAsPdf />
                                                    </ButtonBase>
                                                </Grid>
                                                <Grid item xs={12} sm container>
                                                    <Grid item xs container direction="column" spacing={2}>
                                                        <Grid item xs>
                                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                                File justification
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <InputLabel>
                                        <Typography variant="subtitle1">Upload file</Typography>
                                    </InputLabel>
                                    <FileUpload setFile={setFile} label="Upload file" />
                                </Grid>
                                {file !== null ? (
                                    <Grid item xs={12}>
                                        <FileUploadView file={file} removeFile={removeFile} getViewFile={getViewFile} />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        text={current.file?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={current.file?.id ? <Edit /> : <SaveRounded />}
                        handleClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

ViewAbsences.propTypes = {
    api: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    current: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired
};

export default ViewAbsences;

import React, { useEffect, useState } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { historiqueApi } from 'api';
import TableHistoriqueList from './TableHistoriqueList';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const HistoriqueList = () => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 10,
        length: 1,
        totalItems: 0
    });
    const [dataHistorique, setDataHistorique] = useState({ rows: [], rowsInitial: [] });

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'left'
        },
        {
            id: 'user',
            numeric: true,
            label: 'user',
            align: 'left'
        },
        {
            id: 'typeOperation',
            numeric: true,
            label: 'typeOperation',
            align: 'left'
        },
        {
            id: 'dateTimeOperation',
            numeric: true,
            label: 'dateTimeOperation',
            align: 'right'
        },
        {
            id: 'niveauDanger',
            numeric: false,
            label: 'niveauDanger',
            align: 'left'
        },
        {
            id: 'clientIpAddress',
            numeric: false,
            label: 'clientIpAddress',
            align: 'right'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const refleshTable = async (params) => {
        setLoading(true);
        await historiqueApi
            .getHistorique(params)
            .then((res) => {
                if (res.success) {
                    setDataHistorique({ rows: res.data.content, rowsInitial: res.data.content });
                    setParams({
                        ...params,
                        length: res.data?.totalPages || 0,
                        totalItems: res.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataHistorique.rows.filter((row) => {
                let matches = true;

                const properties = ['userId', 'typeOperation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataHistorique({ ...dataHistorique, rows: newRows });
        } else {
            setDataHistorique({ ...dataHistorique, rows: dataHistorique.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    return (
        <Grid container spacing={gridSpacing}>
            <HeaderComponent header="transaction-history" subtitle="view-transactions" />
            <Grid item xs={12} lg={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                <ListSearchComponent
                                    handleChange={handleSearchValue}
                                    handleClear={() => {
                                        setSearchVal('');
                                        setDataHistorique({ ...dataHistorique, rows: dataHistorique.rowsInitial });
                                    }}
                                    filterValue={searchVal}
                                    filter={handleSearchValue}
                                />
                                <ButtonComponent
                                    text="Rechercher"
                                    handleClick={handleClickSearch}
                                    variant="outlined"
                                    sx={{
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: theme?.palette.primary.main
                                        }
                                    }}
                                />
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                <ButtonComponent
                                    text={<FormattedMessage id="refresh" />}
                                    size="small"
                                    variant="contained"
                                    startIcon={<Refresh />}
                                    handleClick={() => {
                                        refleshTable({ ...params, page: 0 });
                                    }}
                                />
                                <ButtonComponent
                                    text="Ajouter"
                                    size="small"
                                    variant="contained"
                                    startIcon={<Add />}
                                    handleClick={() => console.log('add')}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TableHistoriqueList
                            actions
                            params={params}
                            dataHistorique={dataHistorique.rows}
                            headCells={headCells}
                            loading={loading}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HistoriqueList;

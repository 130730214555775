import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-service/api/v1';

const getSocialOrganization = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/social-organizations?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addSocialOrganization = async (values) => {
    try {
        const response = await instance.post(`${uri}/social-organizations`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSocialOrganization = async (values) => {
    try {
        const response = await instance.put(`${uri}/social-organizations/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSocialOrganization = async (id) => {
    try {
        const response = await instance.delete(`${uri}/social-organizations/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getSocialOrganization, addSocialOrganization, updateSocialOrganization, deleteSocialOrganization };

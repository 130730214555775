import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getEmployeeVisa() {
    try {
        const response = await instance.get(`${uri}/visa-employees`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addEmployeeVisa = async (values) => {
    try {
        const response = await instance.post(`${uri}/visa-employees`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateEmployeeVisa = async (values) => {
    try {
        const response = await instance.put(`${uri}/visa-employees/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteEmployeeVisa = async (id) => {
    try {
        const response = await instance.delete(`${uri}/visa-employees/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getEmployeeVisa, addEmployeeVisa, updateEmployeeVisa, deleteEmployeeVisa };

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import EmployeeList from '.';
import { useTheme } from '@mui/styles';
import { REINIT_EMPLOYEE } from 'store/actions';
import { useDispatch } from 'react-redux';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SearchEmployee = ({ open, setOpen, setCurrentEmployee }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullWidth = true;
    const handleClose = () => {
        setOpen(false);
        dispatch({ type: REINIT_EMPLOYEE });
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="lg" open={open} TransitionComponent={Transition}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle> </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <DialogContent>
                <EmployeeList setOpen={setOpen} setCurrentEmployee={setCurrentEmployee} />
            </DialogContent>
        </Dialog>
    );
};

SearchEmployee.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    setCurrentEmployee: PropTypes.func
};

export default SearchEmployee;

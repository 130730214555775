import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, SaveRounded } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    value: yup.string().required('value')
});

const AddAdditionalParams = ({ open, setOpen, dataAdditionalParams, setDataAdditionalParams, current, setCurrent }) => {
    const [yourState, setYourState] = useState({ description: current.description, value: current.value });
    const fullWidth = true;
    const handleClose = () => {
        setOpen(false);
        setCurrent(null);
        setYourState({ description: '', value: '' });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setDataAdditionalParams({
                ...dataAdditionalParams,
                AddParams: dataAdditionalParams.AddParams.map((entry) => {
                    if (entry.description === values.description) {
                        return { ...entry, value: values.value };
                    }
                    return entry;
                })
            });
            handleClose();
        }
    });
    return (
        <Dialog fullWidth={fullWidth} maxWidth="xs" open={open} TransitionComponent={Transition}>
            <DialogTitle>Add Params</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12} lg={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="value"
                                id="value"
                                name="value"
                                value={formik.values?.value || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.value && Boolean(formik.errors.value)}
                                helperText={formik.touched.value && formik.errors.value}
                                placeholder="value"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<Close />}
                        text={<FormattedMessage id="close" />}
                        handleClick={handleClose}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="update" /> : <FormattedMessage id="save" />}
                        startIcon={<SaveRounded />}
                        handleClick={() => {}}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddAdditionalParams.propTypes = {
    dataAdditionalParams: PropTypes?.object.isRequired,
    setDataAdditionalParams: PropTypes?.func.isRequired,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    current: PropTypes?.object.isRequired,
    setCurrent: PropTypes?.func.isRequired
};

export default AddAdditionalParams;

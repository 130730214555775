import { forwardRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide } from '@mui/material';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import PropTypes from 'prop-types';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomDeleteDialog = ({ text, actionSave, actionCancel, handleClose, open, toDelete, api, snackAlert, params, refleshTable }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        api(toDelete.id)
            .then((res) => {
                if (res.success) {
                    setLoading(false);
                    handleClose();
                    snackAlert('Operation reussie', 'success');
                    refleshTable(params);
                } else {
                    setLoading(false);
                    handleClose();
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                }
            })
            .catch((error) => {
                setLoading(false);
                snackAlert(`${error.response?.data.message ? error.response.data.message : error}`, 'error');
            });
    };
    return (
        <>
            <SpinnerLoader open={loading} />
            <Dialog keepMounted open={loading ? false : open} maxWidth="md" TransitionComponent={Transition}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {text}
                </DialogTitle>
                <DialogContent> </DialogContent>
                <DialogActions>
                    <Button size="small" color={actionSave?.color} variant="contained" onClick={handleClick} disabled={loading}>
                        {actionSave?.action}
                    </Button>
                    <Button variant="contained" size="small" color={actionCancel?.color} onClick={() => handleClose()}>
                        {actionCancel?.action}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CustomDeleteDialog.propTypes = {
    text: PropTypes.string,
    actionSave: PropTypes.object,
    actionCancel: PropTypes.object,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    toDelete: PropTypes.object,
    api: PropTypes.func,
    snackAlert: PropTypes.func,
    params: PropTypes.object,
    refleshTable: PropTypes.func
};

export default CustomDeleteDialog;

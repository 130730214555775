import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getTypeSanction = async () => {
    try {
        const response = await instance.get(`${uri}/sanction-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeSanction = async (values) => {
    try {
        const response = await instance.post(`${uri}/sanction-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeSanction = async (values) => {
    try {
        const response = await instance.put(`${uri}/sanction-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeSanction = async (id) => {
    try {
        const response = await instance.delete(`${uri}/sanction-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTypeSanction, addTypeSanction, updateTypeSanction, deleteTypeSanction };

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getEmployee(params) {
    try {
        const response = await instance.get(
            `${uri}/employees?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addEmployee = async (values) => {
    try {
        const response = await instance.post(`${uri}/employees`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateEmployee = async (values) => {
    try {
        const response = await instance.put(`${uri}/employees/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteEmployee = async (id) => {
    try {
        const response = await instance.delete(`${uri}/employees/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getContract = async () => {
    try {
        const response = await instance.get(`${uri}/contracts`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const uploadProfile = async (values, formData) => {
    try {
        const response = await instance.post(`${uri}/profile/upload?idEmployee=${values.id}`, formData);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const getProfile = async (values) => {
    try {
        const response = await instance.get(`${uri}/profile/${values.id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getEmployee, addEmployee, updateEmployee, deleteEmployee, getContract, uploadProfile, getProfile };

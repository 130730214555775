import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getRelation() {
    try {
        const response = await instance.get(`${uri}/relations`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addRelation = async (values) => {
    try {
        const response = await instance.post(`${uri}/relations`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateRelation = async (values) => {
    try {
        const response = await instance.put(`${uri}/relations/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteRelation = async (id) => {
    try {
        const response = await instance.delete(`${uri}/relations/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getRelation, addRelation, updateRelation, deleteRelation };

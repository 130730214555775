import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material';
// project imports
import { FormattedMessage } from 'react-intl';
import { Delete, AddCircleOutlined } from '@mui/icons-material';
import SubCard from 'ui-component/cards/SubCard';
import InputField from 'ui-component/InputField';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Actions = ({ row, index, deleteFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const PrimeIndemnites = ({ dataPrimeInd, setDataPrimeInd }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [initState, setInitState] = useState({
        designation: '',
        montant: '',
        taux: '',
        formErrors: { designation: '', montant: '', taux: '' },
        touched: { designation: false, montant: false, taux: false },
        formValid: false
    });

    const validateForm = (touchedValid) => {
        return touchedValid.designation && touchedValid.montant && touchedValid.taux;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;

        switch (fieldName) {
            case 'designation':
                fieldValidationTouched.designation = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.designation = fieldValidationTouched.designation ? '' : ' designation is required';
                break;
            case 'montant':
                fieldValidationTouched.montant = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.montant = fieldValidationTouched.montant ? '' : ' montant is required';
                break;
            case 'taux':
                fieldValidationTouched.taux = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.taux = fieldValidationTouched.taux ? '' : ' taux is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        validateField(name, value);
    };

    const handleSubmitHeureSup = (event) => {
        event.preventDefault();
        const selectedIndex = dataPrimeInd.findIndex((object) => {
            return object.designation === initState.designation && object.montant === initState.montant && object.taux === initState.taux;
        });
        let newPrimeInd = [];
        if (selectedIndex >= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'elementExist',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
            return;
        }

        newPrimeInd = newPrimeInd.concat(dataPrimeInd, [
            {
                designation: initState.designation,
                montant: initState.montant,
                taux: initState.taux
            }
        ]);
        setDataPrimeInd(newPrimeInd);
        setInitState({
            designation: '',
            montant: '',
            taux: '',
            formErrors: { designation: '', montant: '', taux: '' },
            touched: { designation: false, montant: false, taux: false },
            formValid: false
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataPrimeInd.filter((row, index) => index !== indexOf);
        setDataPrimeInd(arrayCopy);
    };
    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sortDirection={false} align="left" padding="normal">
                                    Code
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="center" padding="normal">
                                    Désignation
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Montant Prime
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Taux prime
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {dataPrimeInd && (
                            <TableBody>
                                <StyledTableRow hover tabIndex={-1} data-testid="rowItems">
                                    <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                        >
                                            ###
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            id="designation"
                                            name="designation"
                                            value={initState.designation || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.designation && Boolean(initState.formErrors.designation)}
                                            helperText={initState.formErrors.designation && initState.formErrors.designation}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="montant"
                                            name="montant"
                                            value={initState.montant || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.montant && Boolean(initState.formErrors.montant)}
                                            helperText={initState.formErrors.montant && initState.formErrors.montant}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="taux"
                                            name="taux"
                                            value={initState.taux || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.taux && Boolean(initState.formErrors.taux)}
                                            helperText={initState.formErrors.taux && initState.formErrors.taux}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
                                            <Tooltip title="Ajouter" arrow placement="left">
                                                <IconButton
                                                    component="span"
                                                    size="medium"
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.success.main
                                                        }
                                                    }}
                                                    disabled={!initState.formValid}
                                                    onClick={handleSubmitHeureSup}
                                                >
                                                    <AddCircleOutlined fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {dataPrimeInd?.map((row, index) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <StyledTableRow hover tabIndex={-1} key={index} data-testid="rowItems">
                                            <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.designation} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.montant} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.taux} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <Actions row={row} index={index} deleteFunction={deleteFunction} />
                                        </StyledTableRow>
                                    );
                                })}
                                {dataPrimeInd.length <= 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={5}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="no-data" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </SubCard>
    );
};

PrimeIndemnites.propTypes = {
    dataPrimeInd: PropTypes.array,
    setDataPrimeInd: PropTypes.func
};

export default PrimeIndemnites;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stepper, StepButton, Step } from '@mui/material';
import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/SubCard';
import { SalaryInformation, ModalityPayment } from './modality';

const steps = ['Informations sur les salaires', 'Modalite de paiement'];

function getStepContent(step, dataModalite, setDataModalite, formik) {
    switch (step) {
        case 0:
            return <SalaryInformation formik={formik} />;
        case 1:
            return <ModalityPayment dataModalite={dataModalite} setDataModalite={setDataModalite} />;
        default:
            throw new Error('unknownStep');
    }
}

const ModalityPaymentSalary = ({ formik }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [dataModalite, setDataModalite] = useState([]);

    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid item xs={6}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index}>
                                        <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                        <>{getStepContent(activeStep, dataModalite, setDataModalite, formik)}</>
                    </Grid>
                </Grid>
            </Grid>
        </SubCard>
    );
};

ModalityPaymentSalary.propTypes = {
    formik: PropTypes.object
};

export default ModalityPaymentSalary;

import { forwardRef } from 'react';
import { Dialog, Slide, Stack } from '@mui/material';
import './loading.css';
import PropTypes from 'prop-types';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const SpinnerLoader = ({ open }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }
            }}
        >
            <Stack alignItems="center" justifyContent="center" sx={{ height: '100px' }}>
                <div className="lds-spinner">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </Stack>
        </Dialog>
    );
};

SpinnerLoader.propTypes = {
    open: PropTypes.bool.isRequired
};

export const FacebookLoader = ({ open }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }
            }}
        >
            <Stack alignItems="center" justifyContent="center" sx={{ height: '100px' }}>
                <div className="lds-facebook">
                    <div />
                    <div />
                    <div />
                </div>
            </Stack>
        </Dialog>
    );
};

FacebookLoader.propTypes = {
    open: PropTypes.bool.isRequired
};

export const DefaultLoader = ({ open }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }
            }}
        >
            <Stack alignItems="center" justifyContent="center" sx={{ height: '100px' }}>
                <div className="lds-default">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </Stack>
        </Dialog>
    );
};

DefaultLoader.propTypes = {
    open: PropTypes.bool.isRequired
};

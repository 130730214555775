import { forwardRef } from 'react';
// material-ui
import { CircularProgress, Dialog, Grid, IconButton, Slide, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Close from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { Edit, RotateLeftRounded, Save } from '@mui/icons-material';
import { gridSpacing } from 'store/constant';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import ButtonComponent from 'ui-component/ButtonComponent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SelectComponent from 'ui-component/SelectComponent';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import moment from 'moment/moment';
import TimePickerComponent from 'ui-component/TimePickerComponent';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

// ================= IMPORT AXIOS ET INITIALISATION DE JSON DB =============

const DemandeSortieModal = ({ open, handleClose, demandeToEdit, handleSubmit, loading, listePersonnels }) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            requestDate: demandeToEdit?.requestDate,
            leaveDate: demandeToEdit?.leaveDate,
            employeeId: demandeToEdit?.employeeId,
            reason: demandeToEdit.reason,
            startHour: demandeToEdit.startHour,
            endHour: demandeToEdit.endHour
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            employeeId: yup.string().required(<FormattedMessage id="personnel-req" />),
            startHour: yup.string().required(<FormattedMessage id="typeConge-req" />),
            reason: yup.string().required(<FormattedMessage id="typeConge-req" />)
        }),
        onSubmit: async (values, { resetForm }) => {
            const categorieToSave = {
                ...values,
                reason: values.reason.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ''),

                requestDate: moment(values?.requestDate).format('YYYY-MM-DD'),
                leaveDate: moment(values?.leaveDate).format('YYYY-MM-DD')
            };
            handleSubmit(categorieToSave, resetForm);
        }
    });

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-adresse1"
                maxWidth="sm"
                fullWidth
            >
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            {demandeToEdit.id ? (
                                <FormattedMessage id="edit-demande-conge" />
                            ) : (
                                <FormattedMessage id="add-new-demande-conge" />
                            )}
                        </DialogTitle>
                        <IconButton
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-personnel' })}
                                            options={listePersonnels.filter((p) => p.status === 'ACTIVE')}
                                            handleChange={formik.handleChange}
                                            name="employeeId"
                                            error={formik.touched.employeeId && formik.errors.employeeId}
                                            helperText={formik.touched.employeeId && formik.errors.employeeId}
                                            value={formik.values.employeeId}
                                            disabled={loading}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>
                                        <DatePickerComponent
                                            label={intl.formatMessage({ id: 'requestDate' })}
                                            name="requestDate"
                                            minDate={new Date()}
                                            horizontal={false}
                                            handleChange={(e) => {
                                                formik.setFieldValue('requestDate', e);
                                                formik.setFieldValue('leaveDate', '');
                                            }}
                                            value={formik.values.requestDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <DatePickerComponent
                                            label={intl.formatMessage({ id: 'leaveDate' })}
                                            name="leaveDate"
                                            minDate={formik.values.requestDate}
                                            horizontal={false}
                                            handleChange={(e) => {
                                                formik.setFieldValue('leaveDate', e);
                                            }}
                                            value={formik.values.leaveDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TimePickerComponent
                                            label={intl.formatMessage({ id: 'startHour' })}
                                            id="startHour"
                                            name="startHour"
                                            value={formik.values.startHour || ''}
                                            // handleChange={(event) => formik.setFieldValue('startHour', event)}
                                            handleChange={(e) => {
                                                formik.setFieldValue('startHour', e);
                                                formik.setFieldValue('endHour', '');
                                            }}
                                            error={formik.touched.startHour && Boolean(formik.errors.startHour)}
                                            helperText={formik.touched.startHour && formik.errors.startHour}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TimePickerComponent
                                            label={intl.formatMessage({ id: 'endHour' })}
                                            id="endHour"
                                            name="endHour"
                                            minHour={formik.values.startHour}
                                            value={formik.values.endHour || ''}
                                            handleChange={(event) => formik.setFieldValue('endHour', event)}
                                            error={formik.touched.endHour && Boolean(formik.errors.endHour)}
                                            helperText={formik.touched.endHour && formik.errors.endHour}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                marginTop: 3,
                                                marginBottom: 3
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    marginRight: 10,
                                                    fontWeight: '',
                                                    color: 'black'
                                                }}
                                            >
                                                <FormattedMessage id="reason" />
                                            </Typography>

                                            <ReactQuill
                                                name="reason"
                                                value={formik.values.reason}
                                                onChange={(e) => {
                                                    formik.setFieldValue('reason', e);
                                                }}
                                                disabled={loading}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={intl.formatMessage({ id: 'reset' })}
                            disabled={loading || !formik.dirty}
                            handleClick={() => formik.resetForm()}
                        />
                        <ButtonComponent
                            variant="contained"
                            text={
                                demandeToEdit.id
                                    ? `${intl.formatMessage({ id: 'update' })}`
                                    : `${intl.formatMessage({
                                          id: 'save'
                                      })}`
                            }
                            disabled={loading || !formik.dirty}
                            size="small"
                            type="submit"
                            startIcon={demandeToEdit.id ? <Edit /> : <Save />}
                            endIcon={loading ? <CircularProgress size="16px" /> : null}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};
DemandeSortieModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    demandeToEdit: PropTypes?.object.isRequired,
    listePersonnels: PropTypes?.array.isRequired,
    handleSubmit: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired
};
export default DemandeSortieModal;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const url = '/erp-payroll-hr-service/api/v1';

async function getListeDemandesConges(filterValue, paginationDetails) {
    return instance
        .get(`${url}/request-leaves`, {
            params: {
                nom: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

async function getListeDemandesParEmploye(id) {
    return instance.get(`${url}/request-leaves/${id}`).then(HandleResponse).catch(HandleError);
}

const saveDemandeConge = async (categoriePersonnel) => {
    return instance.post(`${url}/request-leaves`, categoriePersonnel).then(HandleResponse).catch(HandleError);
};

const updateDemandeConge = async (demandeConge) => {
    return instance.put(`${url}/request-leaves/${demandeConge.id}`, demandeConge).then(HandleResponse).catch(HandleError);
};

const deleteDemandeConge = (id) => {
    return instance.delete(`${url}/request-leaves/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeDemandesConges,
    getListeDemandesParEmploye,
    saveDemandeConge,
    updateDemandeConge,
    deleteDemandeConge
};

import React from 'react';
import HeaderOffreToPrint from './HeaderOffreToPrint';
import PropTypes from 'prop-types';

const OffreToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <HeaderOffreToPrint title={props?.title} />
            <div className="contentBody">{props.body}</div>
        </div>
    );
});
OffreToPrint.propTypes = {
    title: PropTypes?.string.isRequired,
    body: PropTypes?.object
};

export default OffreToPrint;

import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import PropTypes from 'prop-types';

const DemandeCongeHeader = ({ title }) => {
    const date = new Date().toISOString();
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Grid container spacing={0} direction="column">
            <Grid className="titleContainer" item xs={12} textAlign="center">
                <img alt="logo" src="http://www.africa2trust.com/wba/Logos/ASR27884374.gif" style={{ width: 50, alignContent: 'center' }} />
            </Grid>
            <Stack direction="row" justifyContent="space-between">
                <div style={{ marginLeft: 20 }}>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginLeft: 20
                        }}
                    >
                        <FormattedMessage id="c-name" /> :
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginLeft: 20
                        }}
                    >
                        <FormattedMessage id="c-corpo" /> :
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginLeft: 20
                        }}
                    >
                        <FormattedMessage id="nif" /> :
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginLeft: 20
                        }}
                    >
                        <FormattedMessage id="nrc" /> :
                    </Typography>
                </div>
                <div style={{ marginRight: 20 }}>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginRight: 20
                        }}
                    >
                        <FormattedMessage id="adrEse" />
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginRight: 20
                        }}
                    >
                        <FormattedMessage id="phone" />
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.common.black,
                            marginRight: 20
                        }}
                    >
                        <FormattedMessage id="email" />
                    </Typography>
                </div>
            </Stack>
            <Divider
                style={{
                    backgroundColor: '#d1d1d1',
                    height: '2px',
                    marginBottom: 10,
                    marginTop: 20
                }}
            />
            <Grid className="titleContainer" item xs={12} textAlign="center">
                <Typography
                    style={{ fontSize: '20px', fontWeight: 'bolder', color: 'black' }}
                    display="inline"
                    className="title"
                    align="center"
                    alignContent="center"
                >
                    {title}
                </Typography>
            </Grid>
            <Divider
                style={{
                    backgroundColor: '#d1d1d1',
                    height: '2px',
                    marginBottom: 10,
                    marginTop: 20
                }}
            />
            <Grid item xs={2} className="date" textAlign="center">
                <div style={{ textAlign: 'center' }}>
                    <TypographyListDescription
                        textAlign="center"
                        // text={`Date tirage : ${Moment(date).format('MMMM Do, YYYY')} à ${Moment(date).format('hh:mm:ss')}`}
                        text={`${intl.formatMessage({ id: 'date-tirage' })} ${Moment(date).format('MMMM Do, YYYY')} à ${Moment(date).format(
                            'hh:mm:ss'
                        )}`}
                    />
                </div>
            </Grid>
            <Divider
                style={{
                    backgroundColor: '#d1d1d1',
                    height: '2px',
                    marginBottom: 20,
                    marginTop: 10
                }}
            />
        </Grid>
    );
};
DemandeCongeHeader.propTypes = {
    title: PropTypes?.string.isRequired
};

export default DemandeCongeHeader;

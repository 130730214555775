import { forwardRef } from 'react';

// material-ui
import {
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    Slide,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel
} from '@mui/material';
// material-ui
import Stack from '@mui/material/Stack';
import InputField from 'ui-component/InputField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Close from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { Edit, RotateLeftRounded, Save } from '@mui/icons-material';
import { gridSpacing } from 'store/constant';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import ButtonComponent from 'ui-component/ButtonComponent';
import SelectComponent from 'ui-component/SelectComponent';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

// ================= IMPORT AXIOS ET INITIALISATION DE JSON DB =============

const DureeCongeModal = ({ open, handleClose, listeTypeConge, listeTypesPersonnels, dureeToEdit, handleSubmit, loading }) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            consideredDays: dureeToEdit?.consideredDays,
            numberDays: dureeToEdit?.numberDays,
            yearAfter: dureeToEdit?.yearAfter,
            durationAdd: dureeToEdit?.durationAdd,
            typeCongeId: dureeToEdit.id ? dureeToEdit?.typeCongeId?.value : dureeToEdit?.typeCongeId,
            personalTypeId: dureeToEdit.id ? dureeToEdit?.personalTypeId?.value : dureeToEdit?.personalTypeId
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            typeCongeId: yup.string().required(<FormattedMessage id="typeConge-req" />),
            personalTypeId: yup.string().required(<FormattedMessage id="sorteConge-req" />)
        }),
        onSubmit: async (values, { resetForm }) => {
            const tcToSave = {
                ...values,
                personalTypeId: values?.personalTypeId,
                typeCongeId: values?.typeCongeId
            };
            handleSubmit(tcToSave, resetForm);
        }
    });
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-adresse1"
                maxWidth="sm"
                fullWidth
            >
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            {dureeToEdit.id ? <FormattedMessage id="edit-duree-conge" /> : <FormattedMessage id="add-new-duree-conge" />}
                        </DialogTitle>
                        <IconButton
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-typePersonnel' })}
                                            options={listeTypesPersonnels}
                                            handleChange={formik.handleChange}
                                            name="personalTypeId"
                                            error={formik.touched.personalTypeId && formik.errors.personalTypeId}
                                            helperText={formik.touched.personalTypeId && formik.errors.personalTypeId}
                                            value={formik.values.personalTypeId}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-typeConge' })}
                                            options={listeTypeConge}
                                            handleChange={formik.handleChange}
                                            name="typeCongeId"
                                            error={formik.touched.typeCongeId && formik.errors.typeCongeId}
                                            helperText={formik.touched.typeCongeId && formik.errors.typeCongeId}
                                            value={formik.values.typeCongeId}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'numberDays' })}
                                            name="numberDays"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    const value = parseInt(e.target.value, 10);
                                                    formik.setFieldValue('numberDays', value);
                                                }
                                            }}
                                            error={formik.touched.numberDays && Boolean(formik.errors.numberDays)}
                                            helperText={formik.touched.numberDays && formik.errors.numberDays}
                                            value={formik.values.numberDays}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'yearAfter' })}
                                            name="yearAfter"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    const value = parseInt(e.target.value, 10);
                                                    formik.setFieldValue('yearAfter', value);
                                                }
                                            }}
                                            error={formik.touched.yearAfter && Boolean(formik.errors.yearAfter)}
                                            helperText={formik.touched.yearAfter && formik.errors.yearAfter}
                                            value={formik.values.yearAfter}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'durationAdd' })}
                                            name="durationAdd"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    const value = parseInt(e.target.value, 10);
                                                    formik.setFieldValue('durationAdd', value);
                                                }
                                            }}
                                            error={formik.touched.durationAdd && Boolean(formik.errors.durationAdd)}
                                            helperText={formik.touched.durationAdd && formik.errors.durationAdd}
                                            value={formik.values.durationAdd}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="consideredDays" checked={formik.values.consideredDays} disabled={loading} />
                                            }
                                            onChange={formik.handleChange}
                                            label={intl.formatMessage({ id: 'days considered' })}
                                            style={{
                                                color: '#666',
                                                marginLeft: 0,
                                                marginTop: 18,
                                                marginRight: 0,
                                                marginBottom: 0
                                            }}
                                            id="consideredDays"
                                            name="consideredDays"
                                            value={formik.values.consideredDays || false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={intl.formatMessage({ id: 'reset' })}
                            disabled={loading || !formik.dirty}
                            handleClick={() => formik.resetForm()}
                        />
                        <ButtonComponent
                            variant="contained"
                            text={
                                dureeToEdit.id
                                    ? `${intl.formatMessage({ id: 'update' })}`
                                    : `${intl.formatMessage({
                                          id: 'save'
                                      })}`
                            }
                            disabled={loading || !formik.dirty}
                            size="small"
                            type="submit"
                            startIcon={dureeToEdit.id ? <Edit /> : <Save />}
                            endIcon={loading ? <CircularProgress size="16px" /> : null}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};
DureeCongeModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    dureeToEdit: PropTypes?.object.isRequired,
    listeTypeConge: PropTypes?.array.isRequired,
    listeTypesPersonnels: PropTypes?.array.isRequired,
    handleSubmit: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired
};
export default DureeCongeModal;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SNACKBAR_OPEN, ADD_IMAGE } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { FileUpload, FileUploadView } from 'views/ui-elements/uploadFile';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UploadPhotoDialog = ({ api, yourState, open, setOpen }) => {
    const dispatch = useDispatch();
    const fullWidth = true;
    const defaultImage = require('assets/images/paella.jpg'); // eslint-disable-line global-require
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [viewFile, setViewFile] = useState(null);

    const state = useSelector((state) => state);

    const getFile = async () => {
        console.log('state', state);
        setLoading(true);
        await api
            .getFile(yourState)
            .then((res) => {
                if (res.success) {
                    console.log(res.data.response);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getFile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeFile = () => {
        setViewFile(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (viewFile !== null) {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            api.uploadFile(yourState, formData)
                .then((res) => {
                    if (res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Operation reussie',
                            variant: 'alert',
                            alertSeverity: 'success',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            close: true
                        });
                        console.log('res data', res.data);
                        dispatch({
                            type: ADD_IMAGE,
                            payload: {
                                url: res.data.logoUrl
                            }
                        });
                        setLoading(false);
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                            variant: 'alert',
                            alertSeverity: 'warning',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            close: true
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <DialogTitle>Upload image</DialogTitle>
            <DialogContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia component="img" height="194" image={defaultImage} alt="Paella dish" />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputLabel>
                                    <Typography variant="subtitle1">Upload image</Typography>
                                </InputLabel>
                                <FileUpload setFile={setFile} setViewFile={setViewFile} label="Upload image" />
                            </Grid>
                            {viewFile !== null ? (
                                <Grid item xs={12}>
                                    <FileUploadView viewFile={viewFile} removeFile={removeFile} />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonComponent
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<Close />}
                    text={<FormattedMessage id="close" />}
                    handleClick={handleClose}
                />
                <ButtonComponent
                    variant="contained"
                    size="small"
                    text={<FormattedMessage id="save" />}
                    // startIcon={saving ? <CircularProgress size="16px" /> : <SaveRounded />}
                    handleClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

UploadPhotoDialog.propTypes = {
    api: PropTypes.object.isRequired,
    yourState: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default UploadPhotoDialog;

// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';
export const GET_MENU = 'GET_MENU';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// action - cart
export const RESET_CART = '@cart/RESET_CART';
export const ADD_IMAGE = 'ADD_IMAGE';
export const GET_IMAGES = 'GET_IMAGES';
export const CURRENT_EMPLOYEE = 'CURRENT_EMPLOYEE';
export const REINIT_EMPLOYEE = 'REINIT_EMPLOYEE';
export const GET_ABSENCES = 'GET_ABSENCES';

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getSortesPositions() {
    try {
        const response = await instance.get(`${baseURL}/sort-positions`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}
async function getSortesPosition(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${baseURL}/sort-positions`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveSortesPositions = async (values) => {
    try {
        const response = await instance.post(`${baseURL}/sort-positions`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSortesPositions = async (values) => {
    try {
        const response = await instance.put(`${baseURL}/sort-positions/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSortesPositions = async (id) => {
    try {
        const response = await instance.delete(`${baseURL}/sort-positions/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};
export default {
    getSortesPosition,
    getSortesPositions,
    saveSortesPositions,
    updateSortesPositions,
    deleteSortesPositions
};

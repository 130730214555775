import Box from '@mui/system/Box';
import { useEffect, useState } from 'react';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { parametrageCongesAPI } from 'api';
import { FormattedMessage, useIntl } from 'react-intl';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import ParametrageCongeTable from './ParametrageCongeTable';

const Index = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // ALERT
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    const [detailedSetting, setDetailedSetting] = useState({
        id: '',
        numberDays: null
    });
    const [successFetch, setSuccessFetch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isUpdated, setisUpdated] = useState(false);
    const [reload, setReload] = useState(false);

    // ===================== LOAD GENERAL TIME OFF PARAMETERS====================

    useEffect(() => {
        setSuccessFetch(true);
        setLoading(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const setting = parametrageCongesAPI.getListeParametrageConge();
        axios
            .all([setting])
            .then(
                axios.spread((...responses) => {
                    setDetailedSetting(() => responses[0]);
                    setLoading(false);
                })
            )
            .catch((err) => {
                setLoading(false);
                setSuccessFetch(false);
                snackAlert(`${err.response?.data ? err.response.data.description : err.message}`, 'error');
            });
    }, [reload, dispatch]);

    // ===================== TIME OFF PARAMETERS METHOD====================

    const handleSubmit = (values) => {
        if (values.id) {
            setLoading(true);
            setisUpdated(true);
            parametrageCongesAPI
                .saveParametrageConge(values)
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    setDetailedSetting(() => ({
                        id: '',
                        numberDays: null
                    }));
                    setReload((prev) => !prev);
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                    setisUpdated(false);
                });
        } else {
            setLoading(true);
            setisUpdated(true);
            parametrageCongesAPI
                .saveParametrageConge(values)
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    setDetailedSetting(() => ({
                        id: '',
                        numberDays: null
                    }));
                    setReload((prev) => !prev);
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                    setisUpdated(false);
                });
        }
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'leave-settings' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-leave-settings' })} />
            {successFetch ? (
                <ParametrageCongeTable
                    isUpdated={isUpdated}
                    setDetailedSetting={setDetailedSetting}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    detailedSetting={detailedSetting}
                />
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </Box>
    );
};

export default Index;

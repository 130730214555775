import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, TableCell, tableCellClasses, TableRow, Toolbar } from '@mui/material';
import { Info, CheckCircle, Error } from '@mui/icons-material';
import axios from 'axios';
import PropTypes from 'prop-types';

export const moneyFormatter = (number, lng) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, lng === 'en' ? ',' : ' ');
};
export const WarningMessage = ({ message, type }) => {
    const allWarnings = {
        success: { color: '#09D121', title: 'Success', icon: <CheckCircle sx={{ color: '#09D121', fontSize: 35 }} /> },
        warning: { color: '#cab129', title: 'Warning', icon: <Error sx={{ color: '#cab129', fontSize: 35 }} /> },
        error: { color: '#DC220B', title: 'Error', icon: <Error sx={{ color: '#DC220B', fontSize: 35 }} /> },
        info: { color: '#4679C8', title: 'Info', icon: <Info sx={{ color: '#4679C8', fontSize: 35 }} /> }
    };
    return (
        <Box
            sx={{
                width: '100%',
                paddingInline: 1,
                paddingBlock: 2,
                borderLeft: `7px solid ${allWarnings[type].color}`,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
            }}
        >
            {allWarnings[type].icon}
            <Stack>
                <Typography variant="h4">{allWarnings[type].title}</Typography>
                <Typography variant="body2">{message}</Typography>
            </Stack>
        </Box>
    );
};

WarningMessage.propTypes = {
    message: PropTypes.string,
    type: PropTypes.number
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.table.header,
        color: theme.palette.common.white,
        borderRight: '0.5px solid #fff',
        // textAlign: 'left',
        fontWeight: 'bolder',
        fontSize: 15
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottom: '0.5px #ccc'
        // textAlign: 'left'
    }
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
        // height: 5
    }
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0
    // }
}));

export function stripquotes(a) {
    if (a.charAt(0) === '"' && a.charAt(a.length - 1) === '"') {
        return a.substr(1, a.length - 2);
    }
    return a;
}

export const EnhancedTableToolbar = ({ title }) => {
    return (
        <Toolbar
            sx={{
                mt: -2
            }}
        >
            <Typography variant="h4" id="tableTitle">
                {title}
            </Typography>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    title: PropTypes.string
};

export const RequestURL = `http://192.168.20.36:8765`;
export const RequestGlobalURL = `http://192.168.20.39:8085/erp-payroll-global-service/api/v1`;
export const RequestPayURL = `http://192.168.20.39:8085/erp-payroll-global-service/api/v1`;

export const instance = axios.create({
    baseURL: RequestURL,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Credentials': true,
        'Accept-Language': 'us',
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJMTEFSc2Y2SDQ2dDc0eUJBRkpWckFaQ0ZxUS1Ja3BvcEhBSW01eFo0TmxJIn0.eyJleHAiOjE2ODg0ODk3ODYsImlhdCI6MTY4ODQ1Mzc4NiwianRpIjoiZThhNjNmMGEtMTkwNy00OTM4LTlkZmEtYzkzMGYwNmMwNzFmIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwL3JlYWxtcy9leHBsb3JlLWtleWNsb2FrIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImEwZjVmNjE2LTIyYzQtNDVlMi04ZDFjLWUyNzhlODBhMjhjMSIsInR5cCI6IkJlYXJlciIsImF6cCI6InNwcmluZ28tY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjVkYTI3OGE0LTMzMWYtNGYxMC1hNDdhLTQ3OTM1N2JmYmI1ZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiLyoiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtZXhwbG9yZS1rZXljbG9hayIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJzcHJpbmdvLWNsaWVudCI6eyJyb2xlcyI6WyJBRE1JTiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwic2lkIjoiNWRhMjc4YTQtMzMxZi00ZjEwLWE0N2EtNDc5MzU3YmZiYjVkIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiQWRtaW4gRmlyc3QgTmFtZSBBZG1pbiBMYXN0TmFtZSIsInByZWZlcnJlZF91c2VybmFtZSI6ImFkbWluIiwiZ2l2ZW5fbmFtZSI6IkFkbWluIEZpcnN0IE5hbWUiLCJmYW1pbHlfbmFtZSI6IkFkbWluIExhc3ROYW1lIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20ifQ.VYwOyw9SzlsSy5NPmjVOn14Ecr2gDBKkjAnSveBSA5P94dtkn-kTqEtNnzoBVjYuY-utS-hYTqXRkfoNAKTBVto-7b3_dlAVa-w8hmjzNlUrr3_uOppk0m3iDfcVrcFmg0_64N9-a6alZMDCPXylzQR1toD1Jc3Ciht7k7znj8o1IUYwhCUwlYiOPBTEr3TNpuj_iuDMO2DbxaW3CxO9PlRQhPkms2ENvmWjPZSbPcWJnjrxpcLoFWuTpLMgW3r9jekuBinMpQOkmSJfJHmM_29L3yCW_Gv2nnumMifrrvI3wwQ3Tc8elz3Xlo3J9JxKF1k6h3YntPZsKjYzuIn51w`
    }
});

export const instanceGlobal = axios.create({
    baseURL: RequestGlobalURL,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Accept-Language': 'us'
    }
});

export const instancePay = axios.create({
    baseURL: RequestPayURL,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Accept-Language': 'us'
    }
});

// tabs panel
export const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};

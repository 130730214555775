import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getOuting = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/outings?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addOuting = async (values) => {
    try {
        const response = await instance.post(`${uri}/outings`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateOuting = async (values) => {
    try {
        const response = await instance.put(`${uri}/outings/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteOuting = async (id) => {
    try {
        const response = await instance.delete(`${uri}/outings/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getOutingTreatment = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/outing-treatments?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addOutingTreatment = async (values) => {
    try {
        const response = await instance.post(`${uri}/outing-treatments`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateOutingTreatment = async (values) => {
    try {
        const response = await instance.put(`${uri}/outing-treatments/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteOutingTreatment = async (id) => {
    try {
        const response = await instance.delete(`${uri}/outing-treatments/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getOuting,
    addOuting,
    updateOuting,
    deleteOuting,
    getOutingTreatment,
    addOutingTreatment,
    updateOutingTreatment,
    deleteOutingTreatment
};

import PropTypes from 'prop-types';

// material-ui
import { Grid, MenuItem } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import InputField from 'ui-component/InputField';
import DatePickerComponent from 'ui-component/DatePickerComponent';

const SocialEmployee = ({ formik }) => {
    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="select idPayFrequency"
                        id="idPayFrequency"
                        name="idPayFrequency"
                        value={formik.values?.idPayFrequency || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.idPayFrequency && Boolean(formik.errors.idPayFrequency)}
                        helperText={formik.touched.idPayFrequency && formik.errors.idPayFrequency}
                        placeholder="idPayFrequency"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="nationality"
                        id="nationality"
                        name="nationality"
                        value={formik.values?.nationality || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                        helperText={formik.touched.nationality && formik.errors.nationality}
                        placeholder="nationality"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="section"
                        id="section"
                        name="section"
                        value={formik.values?.section || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.section && Boolean(formik.errors.section)}
                        helperText={formik.touched.section && formik.errors.section}
                        placeholder="section"
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePickerComponent
                        size="small"
                        fullWidth
                        label="entryDate"
                        id="entryDate"
                        name="entryDate"
                        value={formik.values.entryDate || ''}
                        handleChange={(value) => {
                            formik.setFieldValue('entryDate', value !== 'Invalid Date' ? new Date(value) : '');
                            formik.setFieldValue('leaveDate', value !== 'Invalid Date' ? new Date(value) : '');
                        }}
                        error={formik.touched.entryDate && Boolean(formik.errors.entryDate)}
                        helperText={formik.touched.entryDate && formik.errors.entryDate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePickerComponent
                        size="small"
                        fullWidth
                        label="leaveDate"
                        id="leaveDate"
                        name="leaveDate"
                        value={formik.values.leaveDate || ''}
                        minDate={formik.values.entryDate || ''}
                        handleChange={(value) => formik.setFieldValue('leaveDate', value !== 'Invalid Date' ? new Date(value) : '')}
                        error={formik.touched.leaveDate && Boolean(formik.errors.leaveDate)}
                        helperText={formik.touched.leaveDate && formik.errors.leaveDate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="socialFundNumber"
                        id="socialFundNumber"
                        name="socialFundNumber"
                        value={formik.values?.socialFundNumber || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.socialFundNumber && Boolean(formik.errors.socialFundNumber)}
                        helperText={formik.touched.socialFundNumber && formik.errors.socialFundNumber}
                        placeholder="socialFundNumber"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        type="number"
                        label="camNumber"
                        id="camNumber"
                        name="camNumber"
                        value={formik.values?.camNumber || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.camNumber && Boolean(formik.errors.camNumber)}
                        helperText={formik.touched.camNumber && formik.errors.camNumber}
                        placeholder="camNumber"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        select
                        size="small"
                        fullWidth
                        label="select gender"
                        name="gender"
                        value={formik.values.gender || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                        helperText={formik.touched.gender && formik.errors.gender}
                    >
                        <MenuItem value="MALE">Male</MenuItem>
                        <MenuItem value="FEMALE">Female</MenuItem>
                    </InputField>
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        select
                        size="small"
                        fullWidth
                        label="select maritalStatus"
                        name="maritalStatus"
                        value={formik.values.maritalStatus || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                        helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                    >
                        <MenuItem value="MARRIED">MARRIED</MenuItem>
                        <MenuItem value="SINGLE">SINGLE</MenuItem>
                    </InputField>
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="accountingSuffix"
                        id="accountingSuffix"
                        name="accountingSuffix"
                        value={formik.values?.accountingSuffix || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.accountingSuffix && Boolean(formik.errors.accountingSuffix)}
                        helperText={formik.touched.accountingSuffix && formik.errors.accountingSuffix}
                        placeholder="accountingSuffix"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        type="number"
                        label="complement"
                        id="complement"
                        name="complement"
                        value={formik.values?.complement || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.complement && Boolean(formik.errors.complement)}
                        helperText={formik.touched.complement && formik.errors.complement}
                        placeholder="complement"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        size="small"
                        fullWidth
                        label="select personalGradeId"
                        id="personalGradeId"
                        name="personalGradeId"
                        value={formik.values?.personalGradeId || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.personalGradeId && Boolean(formik.errors.personalGradeId)}
                        helperText={formik.touched.personalGradeId && formik.errors.personalGradeId}
                        placeholder="personalGradeId"
                    />
                </Grid>
            </Grid>
        </SubCard>
    );
};

SocialEmployee.propTypes = {
    formik: PropTypes.object
};

export default SocialEmployee;

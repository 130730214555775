// third-party
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
// import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import { Card, Grid, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ButtonComponent from 'ui-component/ButtonComponent';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
// import MainCard from 'ui-component/cards/MainCard';

// API files
import { useTheme } from '@mui/styles';
import { jobsPostingAPI, jobSubmissionsAPI } from 'api';
import { SNACKBAR_OPEN } from 'store/actions';
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import DeleteDialog from 'ui-component/DeleteDialog';
import CreationCandidatureHeader from './CreationCandidatureHeader';
import ListeCandidatures from './ListeCandidatures';

const CreationCandidature = () => {
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [savingEntry, setSavingEntry] = useState(false);
    const [journals, setJournals] = useState([]);
    const [errors, setErrors] = useState({
        idJournal: ''
    });
    const [reload, setReload] = useState(false);

    const [entryInputValues, setEntryInputValues] = useState(['']);
    const [entryValues, setEntryValues] = useState([null]);
    const [journalEntry, setJournalEntry] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        experienceYears: null,
        skills: [''],
        email: '',
        jobPostingId: null
    });
    const location = useLocation();
    const dispatch = useDispatch();
    const intl = useIntl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
        });
    };

    const theme = useTheme();
    const paginationDetails = {
        page: 1,
        size: 15,
        totalOptions: 1
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };

        // const jobPromise = jobsPostingAPI.getListeLettre('', {
        //     ...paginationDetails,
        //     pageNumber: paginationDetails.pageNumber - 1
        // });
        const jobPromise = jobsPostingAPI.getListeLettre();
        axios
            .all([jobPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const journals = responses[0]?.content?.map((journal) => {
                            return {
                                label: `${journal.position} | ${journal.openingDate} | ${journal.closingDate}`,
                                value: journal.id
                            };
                        });
                        setJournals(journals);
                        console.log('resp', responses[0]?.content);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(err.message, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });

        if (location.state) {
            setJournalEntry(location.state);
        }
        return () => {
            isSubscribed = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, dispatch, location.state]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setJournalEntry({
            ...journalEntry,
            [name]: value
        });
        // }
        setErrors({
            ...errors,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            jobPostingId: null
        });
    };
    const handleChangeAnneExperience = (e) => {
        setJournalEntry({
            ...journalEntry,
            experienceYears: e
        });
        setErrors({
            ...errors,
            experienceYears: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            jobPostingId: null
        });
    };

    // const handleChangeJournal = (id) => {
    //     debugger;
    //     setJournalEntry({
    //         ...journalEntry,
    //         jobPostingId: id
    //     });
    //     setErrors({
    //         ...errors,
    //         firstName: '',
    //         middleName: '',
    //         lastName: '',
    //         email: '',
    //         jobPostingId: null
    //     });
    // };
    const handleChangeJournal = (e) => {
        setJournalEntry({
            ...journalEntry,
            jobPostingId: e.target.value
        });
        setErrors({
            ...errors,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            jobPostingId: null
        });
    };
    const handleChangeCompetences = (value, index) => {
        setJournalEntry({
            ...journalEntry,
            skills:
                journalEntry.skills.length - 1 === index
                    ? [
                          ...journalEntry.skills.map((entry, entryIndex) => {
                              if (index === entryIndex) {
                                  return value;
                              }
                              return entry;
                          }),
                          ''
                      ]
                    : journalEntry.skills.map((entry, entryIndex) => {
                          if (index === entryIndex) {
                              return value;
                          }
                          return entry;
                      })
        });

        // if (entryValues.length - 1 === index) {
        //     setEntryValues([
        //         ...entryValues.map((v, i) => {
        //             if (i === index) return v;

        //             return v;
        //         }),
        //         null
        //     ]);
        // } else {
        //     setEntryValues([
        //         ...entryValues.map((v, i) => {
        //             if (i === index) return v;

        //             return v;
        //         })
        //     ]);
        // }

        // if (entryInputValues.length - 1 === index) {
        //     setEntryInputValues([
        //         ...entryInputValues.map((v, i) => {
        //             if (i === index) return value;

        //             return v;
        //         }),
        //         ''
        //     ]);
        // } else {
        //     setEntryInputValues([
        //         ...entryInputValues.map((v, i) => {
        //             if (i === index) return value;
        //             return v;
        //         })
        //     ]);
        // }
    };

    const entryValidation = () => {
        if (!journalEntry.firstName) {
            setErrors({
                ...errors,
                firstName: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!journalEntry.lastName) {
            setErrors({
                ...errors,
                lastName: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        // if (!journalEntry.jobPostingId) {
        //     setErrors({
        //         ...errors,
        //         jobPostingId: <FormattedMessage id="this-field-can-not-be-empty" />
        //     });
        //     return false;
        // }
        if (!journalEntry.email) {
            setErrors({
                ...errors,
                email: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        return true;
    };
    const resetJournalEntry = () => {
        setJournalEntry({
            firstName: '',
            middleName: '',
            lastName: '',
            experienceYears: 0,
            skills: [''],
            email: '',
            jobPostingId: null
        });
        setErrors({});
        setEntryInputValues(['']);
        setEntryValues([null]);
    };
    const handleSubmitJournalEntry = () => {
        if (entryValidation()) {
            if (journalEntry?.id) {
                setSavingEntry(true);
                jobSubmissionsAPI
                    .updateCandidature({
                        ...journalEntry,
                        experienceYears: parseInt(journalEntry.experienceYears, 10)
                    })
                    .then((res) => {
                        snackAlert(res.message, 'success');
                        setJournalEntry({
                            firstName: '',
                            middleName: '',
                            lastName: '',
                            experienceYears: 0,
                            skills: [''],
                            email: '',
                            jobPostingId: null
                        });
                        setSavingEntry(false);
                        setEntryInputValues(['']);
                        setEntryValues([null]);
                    })
                    .catch((err) => {
                        snackAlert(err.response ? err.response.data.message : err.message, 'error');
                        setSavingEntry(false);
                    });
            } else {
                setSavingEntry(true);
                jobSubmissionsAPI
                    .saveCandidature({
                        ...journalEntry,
                        experienceYears: parseInt(journalEntry.experienceYears, 10)
                    })
                    .then((res) => {
                        snackAlert(res.message, 'success');
                        setJournalEntry({
                            firstName: '',
                            middleName: '',
                            lastName: '',
                            experienceYears: 0,
                            skills: [''],
                            email: '',
                            jobPostingId: null
                        });
                        resetJournalEntry();
                        setSavingEntry(false);
                        setEntryInputValues(['']);
                        setEntryValues([null]);
                    })
                    .catch((err) => {
                        snackAlert(err.response ? err.response.data.description : err.message, 'error');
                        setSavingEntry(false);
                    });
            }
        }
    };
    const removeCompetences = useCallback(
        (index) => {
            setJournalEntry({
                ...journalEntry,
                skills: journalEntry.skills.filter((entry, i) => i !== index)
            });

            setEntryInputValues(entryInputValues.filter((e, i) => i !== index));
            setEntryValues(entryValues.filter((e, i) => i !== index));
        },
        [journalEntry, entryInputValues, entryValues]
    );

    // const getJournalEntries = (row) => {
    //     // journalEntryAPI
    //     //     .getJournalEntryById({
    //     //         operation: row?.operation,
    //     //         brouillard: row?.brouillard.id,
    //     //         // exercice: JSON.parse(localStorage.getItem('CurrentYear')).id
    //     //     })
    //     //     .then((res) => {
    //     //         setJournalEntry({
    //     //             idJournal: {
    //     //                 id: journals.find((j) => j.code === row?.journal.code).value
    //     //             },
    //     //             reference: row?.numeroPiece,
    //     //             brouillard: row?.brouillard.id,
    //     //             typeOperation: row?.typeOperation ? row?.typeOperation.key : '6',
    //     //             operation: row?.operation,
    //     //             description: row?.description,
    //     //             date: row?.dateValeur,
    //     //             // exercice: JSON.parse(localStorage.getItem('CurrentYear')).id,
    //     //             // utilisateurCreation: { id: user ? user?.id : null },

    //     //             details: [
    //     //                 ...res.data.map((d) => {
    //     //                     if (d.analytique?.length > 0)
    //     //                         return {
    //     //                             saisieAnalytique: d.analytique.map((c) => {
    //     //                                 const value = c.debit > 0 ? c.debit : c.credit;
    //     //                                 return {
    //     //                                     code: c.section?.code,
    //     //                                     id: c.section?.id,
    //     //                                     designation: c.section?.designation,
    //     //                                     valeur: value,
    //     //                                     compteComptable: { id: c.compteComptable.id }
    //     //                                 };
    //     //                             }),
    //     //                             compteComptable: {
    //     //                                 id: d.comptecomptable.id
    //     //                             },
    //     //                             autoriserAnalyse: d.comptecomptable?.autoriserAnalyse,
    //     //                             detail: d.detailEcriture ? d.detailEcriture : '',
    //     //                             debit: d.debit.toString(),
    //     //                             credit: d.credit.toString(),
    //     //                             id: d.id
    //     //                         };

    //     //                     return {
    //     //                         compteComptable: {
    //     //                             id: d.comptecomptable.id
    //     //                         },
    //     //                         autoriserAnalyse: Boolean(d.comptecomptable?.autoriserAnalyse),
    //     //                         detail: d.detailEcriture ? d.detailEcriture : '',
    //     //                         debit: d.debit.toString(),
    //     //                         credit: d.credit.toString(),
    //     //                         id: d.id
    //     //                     };
    //     //                 }),
    //     //                 {
    //     //                     compteComptable: { id: '' },
    //     //                     detail: '',
    //     //                     debit: 0,
    //     //                     credit: 0
    //     //                 }
    //     //             ]
    //     //         });

    //     //         setEntryValues([
    //     //             ...res.data.map((d) => {
    //     //                 return {
    //     //                     title: `${d.comptecomptable.numero} - ${d.comptecomptable.designation}`,
    //     //                     value: d.comptecomptable.id
    //     //                 };
    //     //             }),
    //     //             null
    //     //         ]);
    //     //         setEntryInputValues([
    //     //             ...res.data.map((d) => {
    //     //                 return `${d.comptecomptable.numero} - ${d.comptecomptable.designation}`;
    //     //             }),
    //     //             ''
    //     //         ]);
    //     //         setJournalEntryToEdit({
    //     //             numeroPiece: row?.numeroPiece,
    //     //             brouillard: row?.brouillard.id
    //     //         });
    //     //     })
    //     //     .catch((err) => {
    //     //         snackAlert(err.response ? err.response.data.message : err.message, 'error');
    //     //     })
    //     //     .finally(() => {
    //     //         setLoading(false);
    //     //     });
    //     console.log('ROZ', row);
    // };
    const setRowToEdit = (row) => {
        setLoading(true);
        setOpenDialog(false);
        console.log(row);
        // getJournalEntries(row);
    };

    const handleDeleteJournalEntry = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const DeleteJournalEntry = () => {
        jobsPostingAPI
            .deleteLettre({
                operation: journalEntry.operation,
                brouillard: journalEntry.brouillard
            })
            .then((res) => {
                handleCloseDeleteDialog();
                snackAlert(res.message, 'success');
                resetJournalEntry();
            })
            .catch((err) => {
                snackAlert(err.response ? err.response.data.message : err.message, 'error');
            });
    };

    return (
        <>
            <div style={{}}>
                <Stack direction="row" justifyContent="space-between">
                    <div>
                        <TypographyListHeader text={intl.formatMessage({ id: 'jobSubmissions' })} />
                        <TypographyListDescription text={intl.formatMessage({ id: 'cvm-sorte-conge' })} />
                    </div>
                    <div>
                        <ButtonComponent
                            text={intl.formatMessage({ id: 'jobSubmissions' })}
                            handleClick={() => {
                                setOpenDialog(true);
                            }}
                            variant="contained"
                            disabled={loading}
                        />
                    </div>
                </Stack>
                {loading ? (
                    <TotalGrowthBarChart />
                ) : (
                    <>
                        <Card style={{ padding: '10px 10px' }}>
                            <CreationCandidatureHeader
                                journalEntry={journalEntry}
                                handleChange={handleChange}
                                handleChangeJournal={handleChangeJournal}
                                errors={errors}
                                journalEntryCompetences={journalEntry.skills}
                                handleChangeCompetences={handleChangeCompetences}
                                removeCompetences={removeCompetences}
                                journals={journals}
                                handleChangeAnneExperience={handleChangeAnneExperience}
                            />
                        </Card>
                        <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ marginRight: 20 }}>
                            <Grid item xs={4} lg={4}>
                                <ButtonComponent
                                    text={journalEntry.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                                    variant="contained"
                                    style={{
                                        width: '100%'
                                    }}
                                    handleClick={() => {
                                        handleSubmitJournalEntry();
                                        resetJournalEntry();
                                    }}
                                    disabled={savingEntry}
                                    startIcon={journalEntry.id ? <EditIcon /> : <SaveIcon />}
                                />
                            </Grid>

                            <Grid item xs={4} lg={4}>
                                <ButtonComponent
                                    text={intl.formatMessage({ id: 'reset' })}
                                    variant="outlined"
                                    style={{
                                        width: '100%'
                                    }}
                                    handleClick={resetJournalEntry}
                                    startIcon={<LockResetIcon />}
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <ButtonComponent
                                    text={intl.formatMessage({ id: 'delete' })}
                                    variant="contained"
                                    style={{
                                        width: '100%'
                                    }}
                                    handleClick={handleDeleteJournalEntry}
                                    disabled={!journalEntry.id}
                                    startIcon={<DeleteIcon />}
                                    sx={{
                                        background: theme.palette.warning.dark,
                                        '&:hover': { background: theme.palette.warning.main },
                                        color: 'grey.900'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <Snackbar
                    open={errors?.linesEmpty}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={3000}
                    onClose={() => {
                        setErrors({
                            ...errors,
                            linesEmpty: ''
                        });
                    }}
                    sx={{ width: '100%' }}
                >
                    <Alert
                        onClose={() => setErrors({})}
                        severity="error"
                        sx={{ width: '100%' }}
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            width: '98%',
                            paddingLeft: '10%',
                            paddingRight: '10%'
                        }}
                    >
                        {errors?.linesEmpty}
                    </Alert>
                </Snackbar>
            </div>
            <ListeCandidatures
                open={openDialog}
                loading={loading}
                handleClose={handleCloseDialog}
                setRowToEdit={setRowToEdit}
                journals={journals}
                paginationDetails={paginationDetails}
            />

            <DeleteDialog
                handleClose={handleCloseDeleteDialog}
                open={openDeleteDialog}
                handleDelete={DeleteJournalEntry}
                title={intl.formatMessage({ id: 'delete-title' })}
            />
            <SpinnerLoader open={savingEntry} />
        </>
    );
};
export default CreationCandidature;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import InputPhone from 'ui-component/InputPhone';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    code: yup.string().required('code'),
    designation: yup.string().required('designation'),
    societeNumeroCompte: yup.string().required('societeNumeroCompte')
});

const AddBank = ({ api, params, open, setOpen, initialValues, yourState, setYourState, dataBank, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const reinitForm = () => {
        setYourState(initialValues);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            const valuesBank = {
                id: values.id,
                code: values.code,
                designation: values.designation,
                phoneNumber1: values.phoneNumber1,
                phoneNumber2: values.phoneNumber2,
                address1: values.address1,
                address2: values.address1,
                address3: values.address3,
                societeNumeroCompte: values.societeNumeroCompte,
                idBanqueVirement: values.idBanqueVirement
            };
            setLoading(true);
            if (values.id === '') {
                api.addBank(valuesBank)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            reinitForm();
                            refleshTable(params);
                            formik.resetForm();
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateBank(valuesBank)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const handleClose = () => {
        setOpen(false);
        reinitForm();
        formik.resetForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add bank</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme.palette.error.light,
                            color: theme.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputField
                                size="small"
                                fullWidth
                                label="code"
                                id="code"
                                name="code"
                                value={formik.values?.code || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                                placeholder="enterCode"
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputField
                                size="small"
                                fullWidth
                                label="designation"
                                id="designation"
                                name="designation"
                                value={formik.values?.designation || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.designation && Boolean(formik.errors.designation)}
                                helperText={formik.touched.designation && formik.errors.designation}
                                placeholder="designation"
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputField
                                size="small"
                                fullWidth
                                label="societeNumeroCompte"
                                id="societeNumeroCompte"
                                name="societeNumeroCompte"
                                value={formik.values?.societeNumeroCompte || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.societeNumeroCompte && Boolean(formik.errors.societeNumeroCompte)}
                                helperText={formik.touched.societeNumeroCompte && formik.errors.societeNumeroCompte}
                                placeholder="societeNumeroCompte"
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputField
                                select
                                size="small"
                                fullWidth
                                label="select bank virement"
                                id="idBanqueVirement"
                                name="idBanqueVirement"
                                value={formik.values?.idBanqueVirement || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.idBanqueVirement && Boolean(formik.errors.idBanqueVirement)}
                                helperText={formik.touched.idBanqueVirement && formik.errors.idBanqueVirement}
                            >
                                <MenuItem value="">
                                    <em>none</em>
                                </MenuItem>
                                {dataBank?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.code} - {option.designation}
                                    </MenuItem>
                                ))}
                            </InputField>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputPhone
                                country="bi"
                                label="phoneNumber1"
                                id="phoneNumber1"
                                name="phoneNumber1"
                                value={formik.values?.phoneNumber1 || ''}
                                handleChange={(value) => formik.setFieldValue('phoneNumber1', `+${value}`)}
                                error={formik.touched.phoneNumber1 && Boolean(formik.errors.phoneNumber1)}
                                helperText={formik.touched.phoneNumber1 && formik.errors.phoneNumber1}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <InputPhone
                                country="bi"
                                label="phoneNumber2"
                                id="phoneNumber2"
                                name="phoneNumber2"
                                value={formik.values?.phoneNumber2 || ''}
                                handleChange={(value) => formik.setFieldValue('phoneNumber2', `+${value}`)}
                                error={formik.touched.phoneNumber2 && Boolean(formik.errors.phoneNumber2)}
                                helperText={formik.touched.phoneNumber2 && formik.errors.phoneNumber2}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address1"
                                id="address1"
                                name="address1"
                                value={formik.values?.address1 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                helperText={formik.touched.address1 && formik.errors.address1}
                                placeholder="address1"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address2"
                                id="address2"
                                name="address2"
                                value={formik.values?.address2 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                helperText={formik.touched.address2 && formik.errors.address2}
                                placeholder="address2"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address3"
                                id="address3"
                                name="address3"
                                value={formik.values?.address3 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address3 && Boolean(formik.errors.address3)}
                                helperText={formik.touched.address3 && formik.errors.address3}
                                placeholder="address3"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={() => {}}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddBank.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    dataBank: PropTypes?.array.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddBank;

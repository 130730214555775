import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    TableSortLabel,
    Chip
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ headCells, theme }) => {
    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell key={index} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired
};

const TrainingTab = ({ dataTraining }) => {
    const theme = useTheme();

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'center'
        },
        {
            id: 'subject',
            numeric: true,
            label: 'subject',
            align: 'left'
        },
        {
            id: 'startDateTime',
            numeric: true,
            label: 'startDateTime',
            align: 'right'
        },
        {
            id: 'endDateTime',
            numeric: true,
            label: 'endDateTime',
            align: 'right'
        },
        {
            id: 'maxExpectedParticipants',
            numeric: true,
            label: 'maxExpectedParticipants',
            align: 'right'
        },
        {
            id: 'venue',
            numeric: true,
            label: 'venue',
            align: 'left'
        },
        {
            id: 'additionalNotes',
            numeric: true,
            label: 'additionalNotes',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'left'
        }
    ];

    return (
        <MainCard content={false}>
            <TableContainer>
                <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table">
                    <EnhancedTableHead headCells={headCells} theme={theme} />
                    {dataTraining && (
                        <TableBody>
                            {dataTraining.map((row, index) => {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;
                                return (
                                    <StyledTableRow
                                        sx={{ ': hover td': { backgroundColor: '#DDEDF9', color: 'black', cursor: 'pointer' } }}
                                        tabIndex={-1}
                                        key={index}
                                        data-testid="rowItems"
                                    >
                                        <StyledTableCell sx={{ cursor: 'pointer' }}>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                            >
                                                #{index + 1}
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ cursor: 'pointer' }}>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                            >
                                                {row.subject}
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.startDateTime.toString().slice(0, 10)}</StyledTableCell>
                                        <StyledTableCell align="right">{row.endDateTime.toString().slice(0, 10)}</StyledTableCell>
                                        <StyledTableCell align="right">{row.maxExpectedParticipants}</StyledTableCell>
                                        <StyledTableCell>{row.venue}</StyledTableCell>
                                        <StyledTableCell>{row.additionalNotes}</StyledTableCell>
                                        <StyledTableCell>
                                            {row.trainingStatus === 'PENDING' && <Chip label="pending" color="warning" size="small" />}
                                            {row.trainingStatus === 'HAPPENING' && (
                                                <Chip label="happening" color="warning" variant="outlined" size="small" />
                                            )}
                                            {row.trainingStatus === 'CANCELLED' && <Chip label="cancelled" color="error" size="small" />}
                                            {row.trainingStatus === 'ENDED' && <Chip label="ended" color="success" size="small" />}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </MainCard>
    );
};
TrainingTab.propTypes = {
    dataTraining: PropTypes?.array.isRequired
};

export default TrainingTab;

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import TrainingTypeList from '.';
import { useTheme } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SearchTrainingType = ({ open, setOpen, setCurrentTrainingType }) => {
    const theme = useTheme();
    const fullWidth = true;
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="lg" open={open} TransitionComponent={Transition}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle> </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <DialogContent>
                <TrainingTypeList open={open} setOpen={setOpen} setCurrentTrainingType={setCurrentTrainingType} />
            </DialogContent>
        </Dialog>
    );
};

SearchTrainingType.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    setCurrentTrainingType: PropTypes.func
};

export default SearchTrainingType;

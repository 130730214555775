import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { demandeCongeAPI, traitementCongeAPI, listeStatiqueAPI } from 'api';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import TraitementSortieModal from './TraitementSortieModal';
import TraitementSortieTable from './TraitementSortieTable';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [listeTraitements, setListeTraitements] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listeDemandes, setListeDemandes] = useState([]);
    const [listeSortesDecision, setListeSortesDecision] = useState([]);
    const [loading, setLoading] = useState(true);
    const [traitementToDelete, setTraitementToDelete] = useState(null);
    const [traitementToEdit, setTraitementToEdit] = useState({
        reason: '',
        decision: 3,
        decisionDate: new Date(),
        requestLeaveId: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const traitementPromise = traitementCongeAPI.getListeTraitementsConges(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const demandePromise = demandeCongeAPI.getListeDemandesConges('', 100, 0);
        const sorteDecisionPromise = listeStatiqueAPI.getTypesDecision();

        axios
            .all([traitementPromise, demandePromise, sorteDecisionPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const traitementsData = responses[0]?.content;
                        const demandes = responses[1]?.content?.map((dc) => ({
                            label: `${dc?.employee?.firstName} | ${dc?.typeConge?.code} | ${dc?.requestDate}`,
                            value: dc?.id
                        }));
                        const sortes = responses[2]?.map((sd) => ({
                            label: `${sd?.decision}`,
                            value: sd?.id,
                            decision: sd?.decision
                        }));
                        const traitementsCongesData = [];
                        traitementsData.forEach((row) => {
                            traitementsCongesData.push({
                                id: row.id,
                                decisionDate: row.decisionDate,
                                decision: row.decision,
                                reason: row.reason,
                                requestLeaveId: row.requestLeave ? row.requestLeave : null
                            });
                        });
                        setListeTraitements((listeTraitements) => ({
                            ...listeTraitements,
                            ...responses[0],
                            content: traitementsCongesData
                        }));
                        setListeDemandes(demandes);
                        setListeSortesDecision(sortes);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setTraitementToEdit(row);
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            traitementCongeAPI
                .getListeTraitementsConges()
                .then((res) => {
                    setListeTraitements(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setTraitementToEdit({
            reason: '',
            decision: 3,
            decisionDate: new Date(),
            requestLeaveId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (traitement) => {
        setTraitementToDelete(traitement);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (traitementToDelete) => {
        if (traitementToDelete) {
            setListeTraitements(listeTraitements.filter((traitement) => traitement.id !== traitementToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getTraitementsConges = (pageNumber, pageSize) => {
        setLoading(true);
        let traitementPromise;
        if (!pageNumber && !pageSize) {
            traitementPromise = traitementCongeAPI.getListeTraitementsConges(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            traitementPromise = traitementCongeAPI.getListeTraitementsConges(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([traitementPromise])
            .then(
                axios.spread((...responses) => {
                    const traitementsData = responses[0].content;
                    setListeTraitements({
                        ...listeTraitements,
                        ...responses[0],
                        content: traitementsData
                    });
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        demandeCongeAPI
            .deleteDemandeConge(traitementToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getTraitementsConges();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleFilter = (e) => {
        if (e) {
            setFilterValue(e.target.value);
        } else {
            setFilterValue('');
        }
    };

    const handleClearSearch = () => {
        setFilterValue('');
    };
    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getTraitementsConges(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getTraitementsConges(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (traitementToEdit.id) {
            setLoading(true);
            traitementCongeAPI
                .updateTraitementConge({
                    id: traitementToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getTraitementsConges();
                    setTraitementToEdit({
                        reason: '',
                        decision: 3,
                        decisionDate: new Date(),
                        requestLeaveId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            traitementCongeAPI
                .saveTraitementConge({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setTraitementToEdit({
                        reason: '',
                        decision: 3,
                        decisionDate: new Date(),
                        requestLeaveId: null
                    });
                    getTraitementsConges();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'traitementConges' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-traitement-conge' })} />
            {successFetch ? (
                <>
                    <TraitementSortieTable
                        listeTraitements={{
                            content: listeTraitements.content,
                            totalPages: listeTraitements.totalPages,
                            totalElements: listeTraitements.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        handleFilter={handleFilter}
                        filterValue={filterValue}
                        getTraitementsConges={getTraitementsConges}
                        handleClearSearch={handleClearSearch}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                    />
                    <TraitementSortieModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        traitementToEdit={traitementToEdit}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        listeDemandes={listeDemandes}
                        listeSortesDecision={listeSortesDecision}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'demande-conges' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    Typography,
    Divider,
    MenuItem
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, CalendarTodayTwoTone, PhoneAndroidTwoTone, EmailTwoTone } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import SearchOuting from '../SearchOuting';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import EmployeeOutingTreatment from './EmployeeOutingTreatment';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const validationSchema = yup.object({
    outing: yup.object().shape({
        id: yup.string().required('outing')
    }),
    reason: yup.string().required('reason'),
    decisionDate: yup.string().required('decisionDate'),
    decision: yup.string().required('decision')
});

const AddOutingTreatment = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert, setState }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openOuting, setOpenOuting] = useState(false);
    const [currentOuting, setCurrentOuting] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesOutingTreatment = {
                id: values.id,
                outingId: values.outing?.id,
                reason: values.reason,
                decisionDate: values.decisionDate,
                decision: values.decision
            };
            setLoading(true);
            if (values.id === '') {
                api.addOutingTreatment(valuesOutingTreatment)
                    .then((res) => {
                        if (res.success) {
                            setState(res.data);
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                api.updateOutingTreatment(valuesOutingTreatment)
                    .then((res) => {
                        if (res.success) {
                            setState(res.data);
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentOuting !== null) {
            setYourState({ ...yourState, outing: currentOuting });
        } else {
            setYourState(yourState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOuting]);

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openOuting ? <SearchOuting open={openOuting} setOpen={setOpenOuting} setCurrentOuting={setCurrentOuting} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Outing Treatment</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="outing"
                                    id="outing"
                                    name="outing"
                                    value={`${formik.values.outing?.reason || ''}`}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenOuting(true)}
                                    error={formik.touched.outing && Boolean(formik.errors.outing)}
                                    helperText={formik.touched.outing && formik.errors.outing}
                                    placeholder="outing"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <CalendarTodayTwoTone sx={detailsIconSX} />
                                                    {yourState.outing?.employee?.firstName}
                                                    {yourState.outing?.employee?.lastName}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <PhoneAndroidTwoTone sx={detailsIconSX} />
                                                    {yourState.outing?.employee?.mobilePhoneNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <EmailTwoTone sx={detailsIconSX} /> {yourState.outing?.employee?.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12} sm={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Other Traitement</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    reason : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState?.reason}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    decisionDate : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.decisionDate?.toString().slice(0, 10)}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    decision : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.decision === 'GRANTED' && (
                                                                            <Chip label="granted" color="success" size="small" />
                                                                        )}
                                                                        {yourState.decision === 'REFUSED' && (
                                                                            <Chip label="refused" color="error" size="small" />
                                                                        )}
                                                                        {yourState.decision === 'IN_TREATMENT' && (
                                                                            <Chip label="inTreatment" color="primary" size="small" />
                                                                        )}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Information Outing</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    reason: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.outing?.reason}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    requestDate: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.outing?.requestDate?.toString().slice(0, 10)}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            departureTime : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {new Date(
                                                                                    yourState.outing?.departureTime
                                                                                ).toLocaleTimeString()}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            returnTime : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {new Date(
                                                                                    yourState.outing?.returnTime
                                                                                ).toLocaleTimeString()}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="subtitle1">
                                                                            status outing : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {yourState.outing?.status === 'GRANTED' && (
                                                                                    <Chip label="granted" color="success" size="small" />
                                                                                )}
                                                                                {yourState.outing?.status === 'REFUSED' && (
                                                                                    <Chip label="refused" color="error" size="small" />
                                                                                )}
                                                                                {yourState.outing?.status === 'IN_TREATMENT' && (
                                                                                    <Chip
                                                                                        label="inTreatment"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                    />
                                                                                )}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">Employee</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EmployeeOutingTreatment dataEmployeeOuting={yourState.outing?.employees} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="decisionDate"
                                    id="decisionDate"
                                    name="decisionDate"
                                    value={formik.values.decisionDate || ''}
                                    handleChange={(value) => formik.setFieldValue('decisionDate', value)}
                                    error={formik.touched.decisionDate && Boolean(formik.errors.decisionDate)}
                                    helperText={formik.touched.decisionDate && formik.errors.decisionDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    select
                                    size="small"
                                    fullWidth
                                    label="select decision"
                                    name="decision"
                                    value={formik.values.decision || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.decision && Boolean(formik.errors.decision)}
                                    helperText={formik.touched.decision && formik.errors.decision}
                                >
                                    <MenuItem value="REFUSED">REFUSED</MenuItem>
                                    <MenuItem value="GRANTED">GRANTED</MenuItem>
                                </InputField>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reason"
                                    id="reason"
                                    name="reason"
                                    value={formik.values?.reason || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    placeholder="reason"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

AddOutingTreatment.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired
};

export default AddOutingTreatment;

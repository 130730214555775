import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import * as locale from 'date-fns/locale';
import { useTheme } from '@mui/styles';

const DateDisplay = styled(DatePicker)(({ theme }) => ({
    color: theme.palette.table?.header
}));

const DatePickerComponent = ({
    label,
    value,
    handleChange,
    labelStyles,
    style,
    disabled,
    horizontal,
    error,
    helperText,
    readOnly,
    minDate,
    maxDate,
    ...props
}) => {
    const theme = useTheme();
    const intl = useIntl();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: horizontal ? 'row' : 'column',
                justifyContent: 'start',
                marginTop: 3,
                marginBottom: 3,
                width: '100%',
                flex: 1,
                ...style
            }}
        >
            <Typography
                htmlFor="input"
                style={{
                    marginRight: 5,
                    fontWeight: 'normal',
                    color: 'black',
                    ...labelStyles
                }}
            >
                {label}
            </Typography>
            <LocalizationProvider locale={intl.locale === 'fr' ? locale.fr : locale.enUS} dateAdapter={AdapterDateFns}>
                <DateDisplay
                    slotProps={{ textField: { error, helperText, size: 'small' } }}
                    defaultValue={null}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    theme={theme}
                    {...props}
                />
            </LocalizationProvider>
        </div>
    );
};
DatePickerComponent.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelStyles: PropTypes.string,
    style: PropTypes.func,
    disabled: PropTypes.bool,
    horizontal: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    readOnly: PropTypes.func,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default DatePickerComponent;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getTypeAppreciation = async () => {
    try {
        const response = await instance.get(`${uri}/appreciation-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeAppreciation = async (values) => {
    try {
        const response = await instance.post(`${uri}/appreciation-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeAppreciation = async (values) => {
    try {
        const response = await instance.put(`${uri}/appreciation-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeAppreciation = async (id) => {
    try {
        const response = await instance.delete(`${uri}/appreciation-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTypeAppreciation, addTypeAppreciation, updateTypeAppreciation, deleteTypeAppreciation };

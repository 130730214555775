import 'gantt-task-react/dist/index.css';
import PropTypes from 'prop-types';
import { ViewMode } from 'gantt-task-react';

const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked }) => {
    return (
        <div className="ViewContainer">
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.Hour)}>
                Hour
            </button>
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
                Quarter of Day
            </button>
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.HalfDay)}>
                Half of Day
            </button>
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </button>
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.Week)}>
                Week
            </button>
            <button type="button" className="Button" onClick={() => onViewModeChange(ViewMode.Month)}>
                Month
            </button>

            <div className="Switch">
                <label className="Switch_Toggle" htmlFor="first-checkbox">
                    <input type="checkbox" id="first-checkbox" defaultChecked={isChecked} onClick={() => onViewListChange(!isChecked)} />
                    <span className="Slider" />
                </label>
                Show Task List
            </div>
        </div>
    );
};

ViewSwitcher.propTypes = {
    onViewModeChange: PropTypes.func,
    onViewListChange: PropTypes.func,
    isChecked: PropTypes.bool
};

export default ViewSwitcher;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getTypeVisa() {
    try {
        const response = await instance.get(`${uri}/visa-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addTypeVisa = async (values) => {
    try {
        const response = await instance.post(`${uri}/visa-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeVisa = async (values) => {
    try {
        const response = await instance.put(`${uri}/visa-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeVisa = async (id) => {
    try {
        const response = await instance.delete(`${uri}/visa-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTypeVisa, addTypeVisa, updateTypeVisa, deleteTypeVisa };

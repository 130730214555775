import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-global-service/api/v1';

const getHistorique = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/historique-operations?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getHistorique };

import PropTypes from 'prop-types';

// material-ui
import { Grid } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import InputField from 'ui-component/InputField';

const SalaryInformation = ({ formik }) => {
    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={4}>
                    <InputField
                        size="small"
                        fullWidth
                        label="nombreHeureNormal"
                        id="nombreHeureNormal"
                        name="nombreHeureNormal"
                        placeholder="nombreHeureNormal"
                        handleChange={() => {}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputField size="small" fullWidth label="base" id="base" name="base" placeholder="base" handleChange={() => {}} />
                </Grid>
                <Grid item xs={4}>
                    <InputField
                        size="small"
                        fullWidth
                        label="baseSalary"
                        type="number"
                        id="baseSalary"
                        name="baseSalary"
                        value={formik.values?.baseSalary || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.baseSalary && Boolean(formik.errors.baseSalary)}
                        helperText={formik.touched.baseSalary && formik.errors.baseSalary}
                        placeholder="baseSalary"
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputField
                        size="small"
                        fullWidth
                        label="pourcentageLogement"
                        id="pourcentageLogement"
                        name="pourcentageLogement"
                        placeholder="pourcentageLogement"
                        handleChange={() => {}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputField
                        size="small"
                        fullWidth
                        label="forfaitLogement"
                        id="forfaitLogement"
                        name="forfaitLogement"
                        placeholder="forfaitLogement"
                        handleChange={() => {}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputField
                        size="small"
                        fullWidth
                        label="select idTypePersonnel"
                        id="idTypePersonnel"
                        name="idTypePersonnel"
                        value={formik.values?.idTypePersonnel || ''}
                        handleChange={formik.handleChange}
                        error={formik.touched.idTypePersonnel && Boolean(formik.errors.idTypePersonnel)}
                        helperText={formik.touched.idTypePersonnel && formik.errors.idTypePersonnel}
                        placeholder="idTypePersonnel"
                    />
                </Grid>
            </Grid>
        </SubCard>
    );
};

SalaryInformation.propTypes = {
    formik: PropTypes.object
};

export default SalaryInformation;

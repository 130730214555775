import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Radio,
    RadioGroup,
    Slide,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import SearchEmployee from 'views/employee/SearchEmployee';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    }),
    reason: yup.string().required('reason'),
    startDate: yup.date('Invalid Date').required('startDate')
});

const AddAbsences = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [period, setPeriod] = useState('hours');
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    useEffect(() => {
        if (yourState.durationInHours > 0) {
            setPeriod('hours');
            setYourState({ ...yourState, endDate: '' });
        } else {
            setPeriod('severalDays');
            setYourState({ ...yourState, durationInHours: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            if (values.id === '') {
                api.addAbsences(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateAbsences(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentEmployee !== null) {
            formik.setFieldValue('employee', currentEmployee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    const onChangePeriod = (e) => {
        setPeriod(e.target.value);
        formik.setFieldValue('endDate', '');
        formik.setFieldValue('durationInHours', '');
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Absences</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="employee"
                                    id="employee"
                                    name="employee"
                                    value={`${formik.values.employee?.code || ''} || ${formik.values.employee?.firstName || ''} ${
                                        formik.values.employee?.middleName || ''
                                    } ${formik.values.employee?.lastName || ''}`}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenEmpl(true)}
                                    error={formik.touched.employee && Boolean(formik.errors.employee)}
                                    helperText={formik.touched.employee && formik.errors.employee}
                                    placeholder="employee"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reason"
                                    id="reason"
                                    name="reason"
                                    value={formik.values?.reason || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    placeholder="reason"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Duree</InputLabel>
                                <RadioGroup row aria-label="period" value={period} onChange={onChangePeriod} name="row-radio-buttons-group">
                                    <FormControlLabel
                                        defaultChecked
                                        value="hours"
                                        control={<Radio color="primary" sx={{ color: 'primary.main' }} />}
                                        label="Heures"
                                    />
                                    <FormControlLabel
                                        value="severalDays"
                                        control={<Radio color="secondary" sx={{ color: 'secondary.main' }} />}
                                        label="Plusieurs jours"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="startDate"
                                    id="startDate"
                                    name="startDate"
                                    maxDate={new Date()}
                                    value={formik.values.startDate || ''}
                                    handleChange={(value) => formik.setFieldValue('startDate', value)}
                                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                    helperText={formik.touched.startDate && formik.errors.startDate}
                                />
                            </Grid>
                            {period === 'severalDays' && (
                                <Grid item xs={12}>
                                    <DatePickerComponent
                                        size="small"
                                        fullWidth
                                        label="endDate"
                                        id="endDate"
                                        name="endDate"
                                        minDate={formik.values.startDate || ''}
                                        maxDate={new Date()}
                                        value={formik.values.endDate || ''}
                                        handleChange={(value) => formik.setFieldValue('endDate', value)}
                                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                        helperText={formik.touched.endDate && formik.errors.endDate}
                                    />
                                </Grid>
                            )}
                            {period === 'hours' && (
                                <Grid item xs={12}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        type="number"
                                        label="durationInHours"
                                        id="durationInHours"
                                        name="durationInHours"
                                        value={formik.values?.durationInHours || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.durationInHours && Boolean(formik.errors.durationInHours)}
                                        helperText={formik.touched.durationInHours && formik.errors.durationInHours}
                                        placeholder="durationInHours"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

AddAbsences.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddAbsences;

import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material';
// project imports
import { FormattedMessage } from 'react-intl';
import { Delete, AddCircleOutlined } from '@mui/icons-material';
import SubCard from 'ui-component/cards/SubCard';
import InputField from 'ui-component/InputField';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Actions = ({ row, index, deleteFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const Cotisations = ({ dataCotisation, setDataCotisation }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [initState, setInitState] = useState({
        cotisation: '',
        partSal: '',
        partPatr: '',
        formErrors: { cotisation: '', partSal: '', partPatr: '' },
        touched: { cotisation: false, partSal: false, partPatr: false },
        formValid: false
    });

    const validateForm = (touchedValid) => {
        return touchedValid.cotisation && touchedValid.partSal && touchedValid.partPatr;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;

        switch (fieldName) {
            case 'cotisation':
                fieldValidationTouched.cotisation = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.cotisation = fieldValidationTouched.cotisation ? '' : ' cotisation is required';
                break;
            case 'partSal':
                fieldValidationTouched.partSal = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.partSal = fieldValidationTouched.partSal ? '' : ' partSal is required';
                break;
            case 'partPatr':
                fieldValidationTouched.partPatr = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.partPatr = fieldValidationTouched.partPatr ? '' : ' partPatr is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        validateField(name, value);
    };

    const handleSubmitHeureSup = (event) => {
        event.preventDefault();
        const selectedIndex = dataCotisation.findIndex((object) => {
            return (
                object.cotisation === initState.cotisation && object.partSal === initState.partSal && object.partPatr === initState.partPatr
            );
        });
        let newCotisation = [];
        if (selectedIndex >= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'elementExist',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
            return;
        }

        newCotisation = newCotisation.concat(dataCotisation, [
            {
                cotisation: initState.cotisation,
                partSal: initState.partSal,
                partPatr: initState.partPatr
            }
        ]);
        setDataCotisation(newCotisation);
        setInitState({
            cotisation: '',
            partSal: '',
            partPatr: '',
            formErrors: { cotisation: '', partSal: '', partPatr: '' },
            touched: { cotisation: false, partSal: false, partPatr: false },
            formValid: false
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataCotisation.filter((row, index) => index !== indexOf);
        setDataCotisation(arrayCopy);
    };
    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sortDirection={false} align="left" padding="normal">
                                    Code
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="center" padding="normal">
                                    Cotisation sociale
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Part salarial
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Part patronal
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {dataCotisation && (
                            <TableBody>
                                <StyledTableRow hover tabIndex={-1} data-testid="rowItems">
                                    <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                        >
                                            ###
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            id="cotisation"
                                            name="cotisation"
                                            value={initState.cotisation || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.cotisation && Boolean(initState.formErrors.cotisation)}
                                            helperText={initState.formErrors.cotisation && initState.formErrors.cotisation}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="partSal"
                                            name="partSal"
                                            value={initState.partSal || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.partSal && Boolean(initState.formErrors.partSal)}
                                            helperText={initState.formErrors.partSal && initState.formErrors.partSal}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="partPatr"
                                            name="partPatr"
                                            value={initState.partPatr || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.partPatr && Boolean(initState.formErrors.partPatr)}
                                            helperText={initState.formErrors.partPatr && initState.formErrors.partPatr}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
                                            <Tooltip title="Ajouter" arrow placement="left">
                                                <IconButton
                                                    component="span"
                                                    size="medium"
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.success.main
                                                        }
                                                    }}
                                                    disabled={!initState.formValid}
                                                    onClick={handleSubmitHeureSup}
                                                >
                                                    <AddCircleOutlined fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {dataCotisation?.map((row, index) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <StyledTableRow hover tabIndex={-1} key={index} data-testid="rowItems">
                                            <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.cotisation} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.partSal} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.partPatr} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <Actions row={row} index={index} deleteFunction={deleteFunction} />
                                        </StyledTableRow>
                                    );
                                })}
                                {dataCotisation.length <= 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={5}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="no-data" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </SubCard>
    );
};

Cotisations.propTypes = {
    dataCotisation: PropTypes.array,
    setDataCotisation: PropTypes.func
};

export default Cotisations;

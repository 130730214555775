import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, DialogTitle, Stack } from '@mui/material';
import LayoutPdfViewer from 'views/ui-elements/uploadFile/pdf/layout/LayoutPdfViewer';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

const DialogLayoutFile = ({ viewFile, open, handleClose }) => {
    const theme = useTheme();
    const fullWidth = true;
    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={open}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>File</DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <DialogContent>
                <LayoutPdfViewer viewPdf={viewFile} maxHeigth="750px" />{' '}
            </DialogContent>
        </Dialog>
    );
};

DialogLayoutFile.propTypes = {
    viewFile: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default DialogLayoutFile;

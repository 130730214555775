export const HandleResponse = async (response) => {
    if (response.status >= 200 && response.status <= 299) {
        return { data: response.data, success: true };
    }

    if (response.status === 400) {
        const error = await response.text();
        throw new Error(error);
    }
    throw new Error('Request failed with status code 400');
};
export const HandleError = (error) => {
    return error;
};

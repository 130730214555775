import { useEffect, useState, useRef } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { demandeCongeAPI, employeeApi, typeCongeAPI } from 'api';

import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import DemandeCongeTable from './DemandeCongeTable';
import DemandeCongeModal from './DemandeCongeModal';
import { useReactToPrint } from 'react-to-print';
import DemandeCongeToPrint from './impression/DemandeCongeToPrint';
import TableDemandeCongeToPrint from './impression/TableDemandeCongeToPrint';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [demandeListe, setDemandeListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listePersonnels, setListePersonnels] = useState([]);
    const [listeTypesConges, setListeTypesConges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [demandeToDelete, setDemandeToDelete] = useState(null);
    const [demandeToEdit, setDemandeToEdit] = useState({
        reason: '',
        numberDays: 0,
        startDate: new Date(),
        endDate: new Date(),
        employeeId: null,
        typeCongeId: null
    });
    const [retour, setRetour] = useState({});
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const demandePromise = demandeCongeAPI.getListeDemandesConges(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const test = {
            page: 0,
            rowsPerPage: 100,
            sortOption: ''
        };
        const personnelPromise = employeeApi.getEmployee(test);
        const typeCongePromise = typeCongeAPI.getTypesConges('', 100, 0);
        axios
            .all([demandePromise, personnelPromise, typeCongePromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const demandesData = responses[0]?.content;
                        const demandesCongesData = [];
                        demandesData.forEach((row) => {
                            demandesCongesData.push({
                                id: row.id,
                                numberDays: row.numberDays,
                                startDate: row.startDate,
                                endDate: row.endDate,
                                reason: row.reason,
                                employeeId: row.employee ? row.employee : '',
                                statut: row.state,
                                typeCongeId: row.typeConge
                            });
                        });
                        setDemandeListe((demandeListe) => ({
                            ...demandeListe,
                            ...responses[0],
                            content: demandesCongesData
                        }));
                        const typesPersonnels = responses[1]?.data?.content?.map((e) => ({
                            label: `${e?.firstName} | ${e?.lastName}`,
                            value: e?.id,
                            status: e?.status
                        }));
                        const typesConges = responses[2]?.content?.map((tc) => ({
                            label: `${tc.code} | ${tc.designation}`,
                            value: tc.id
                        }));
                        setListePersonnels(typesPersonnels);
                        setListeTypesConges(typesConges);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setDemandeToEdit({
                ...row,
                startDate: new Date(row.startDate),
                endDate: new Date(row.endDate),
                employeeId: {
                    label: `${row?.employeeId?.firstName} | ${row?.employeeId?.lastName}`,
                    value: row?.employeeId?.id,
                    status: row?.employeeId?.status
                },
                typeCongeId: {
                    label: `${row?.typeCongeId?.code} | ${row?.typeCongeId?.designation}`,
                    value: row?.typeCongeId?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            demandeCongeAPI
                .getListeDemandesConges()
                .then((res) => {
                    setDemandeListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setDemandeToEdit({
            reason: '',
            numberDays: 0,
            startDate: new Date(),
            endDate: new Date(),
            employeeId: null,
            typeCongeId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (demande) => {
        setDemandeToDelete(demande);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (demandeToDelete) => {
        if (demandeToDelete) {
            setDemandeListe(demandeListe.filter((demande) => demande.id !== demandeToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getDemandesConges = (pageNumber, pageSize) => {
        setLoading(true);
        let demandePromise;
        if (!pageNumber && !pageSize) {
            demandePromise = demandeCongeAPI.getListeDemandesConges(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            demandePromise = demandeCongeAPI.getListeDemandesConges(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([demandePromise])
            .then(
                axios.spread((...responses) => {
                    const demandesData = responses[0]?.content;
                    const demandesCongesData = [];
                    demandesData.forEach((row) => {
                        demandesCongesData.push({
                            id: row.id,
                            numberDays: row.numberDays,
                            startDate: row.startDate,
                            endDate: row.endDate,
                            reason: row.reason,
                            employeeId: row.employee ? row.employee : '',
                            statut: row.state,
                            typeCongeId: row.typeConge
                        });
                    });
                    setDemandeListe((demandeListe) => ({
                        ...demandeListe,
                        ...responses[0],
                        content: demandesCongesData
                    }));
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        demandeCongeAPI
            .deleteDemandeConge(demandeToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getDemandesConges();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getDemandesConges(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getDemandesConges(value);
    };
    // ==== IMPRESSION DEMANDE DE CONGE APRES INSERTION
    const componentRef = useRef(null);
    const handlePrintPdf = useReactToPrint({
        content: () => {
            const tableStat = componentRef.current;
            const PrintElem = document.createElement('div');
            PrintElem.append(tableStat);
            return PrintElem;
        }
    });
    const handleSubmit = (values, resetForm) => {
        if (demandeToEdit.id) {
            setLoading(true);
            demandeCongeAPI
                .updateDemandeConge({
                    id: demandeToEdit.id,
                    ...values
                })
                .then((res) => {
                    setRetour(res);
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    if (
                        // eslint-disable-next-line no-alert
                        window.confirm(
                            intl.locale === 'fr'
                                ? 'Voulez-vous imprimer cette demande de congé ?'
                                : 'Do you want to print this time off request ?'
                        ) === true
                    ) {
                        handlePrintPdf();
                    }
                    getDemandesConges();
                    setDemandeToEdit({
                        reason: '',
                        numberDays: 0,
                        startDate: new Date(),
                        endDate: new Date(),
                        employeeId: null,
                        typeCongeId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            demandeCongeAPI
                .saveDemandeConge({
                    ...values
                })
                .then((res) => {
                    setRetour(res);
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    // === IMPRESSION DEMANDE DE CONGE
                    if (
                        // eslint-disable-next-line no-alert
                        window.confirm(
                            intl.locale === 'fr'
                                ? 'Voulez-vous imprimer cette demande de congé ?'
                                : 'Do you want to print this time off request ?'
                        ) === true
                    ) {
                        handlePrintPdf();
                    }

                    setDemandeToEdit({
                        reason: '',
                        numberDays: 0,
                        startDate: new Date(),
                        endDate: new Date(),
                        employeeId: null,
                        typeCongeId: null
                    });
                    getDemandesConges();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const dateDiff = (date1, date2) => {
        const diff = {}; // Initialisation du retour
        let tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60; // Extraction du nombre de secondes

        tmp = Math.floor((tmp - diff.sec) / 60);
        diff.min = tmp % 60; // Extraction du nombre de minutes

        tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
        diff.hour = tmp % 24; // Extraction du nombre d'heures

        tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
        diff.day = tmp;

        return diff;
    };
    const handleFilterList = (event) => {
        const newString = event?.target?.value;
        setSearchVal(newString);
        setFilterValue(newString);

        if (newString) {
            const newRows = demandeListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDemandeListe({ ...demandeListe, content: newRows });
        } else {
            getDemandesConges();
        }
    };
    const title = <FormattedMessage id="demandeConges" />;

    const FormatDate = (date) => {
        const intl = useIntl();
        return date
            .toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(/,/, '');
    };

    return (
        <>
            <div style={{ display: 'none' }}>
                <DemandeCongeToPrint ref={componentRef} title={title} body={<TableDemandeCongeToPrint data={retour} />} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TypographyListHeader text={intl.formatMessage({ id: 'demandeConges' })} />
                <TypographyListDescription text={intl.formatMessage({ id: 'cvm-demande-conge' })} />
                {successFetch ? (
                    <>
                        <DemandeCongeTable
                            demandeListe={{
                                content: demandeListe.content,
                                totalPages: demandeListe.totalPages,
                                totalElements: demandeListe.totalElements
                            }}
                            loading={loading}
                            handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                            handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                            getDemandesConges={getDemandesConges}
                            paginate={paginate}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            paginationDetails={paginationDetails}
                            handleFilterList={handleFilterList}
                            searchVal={searchVal}
                            FormatDate={FormatDate}
                        />
                        <DemandeCongeModal
                            open={openDialog}
                            handleClose={handleClickCloseCreateOrEditDialog}
                            demandeToEdit={demandeToEdit}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            listePersonnels={listePersonnels}
                            listeTypesConges={listeTypesConges}
                            dateDiff={dateDiff}
                        />

                        <DeleteDialog
                            text={intl.formatMessage({ id: 'demande-conges' })}
                            handleClose={handleClickCloseDeleteAlertDialog}
                            open={openDeleteAlertDialog}
                            snackAlert={snackAlert}
                            handleDelete={handleDelete}
                        />
                    </>
                ) : (
                    <MainCard content>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                startIcon={<ReplayIcon />}
                                onClick={() => {
                                    toggleReload();
                                }}
                            >
                                <FormattedMessage id="reload" />
                            </Button>
                        </div>
                    </MainCard>
                )}
            </div>
        </>
    );
};

export default Index;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-global-service/api/v1';

const getDocument = async () => {
    try {
        const response = await instance.get(`${uri}/documents`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addDocument = async (values) => {
    try {
        const response = await instance.post(`${uri}/documents`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateDocument = async (values) => {
    try {
        const response = await instance.put(`${uri}/documents/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteDocument = async (id) => {
    try {
        const response = await instance.delete(`${uri}/documents/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getDocument, addDocument, updateDocument, deleteDocument };

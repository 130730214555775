import { useState, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { criteriaApi, evaluationApi } from 'api';
import MainCard from 'ui-component/cards/MainCard';
import { RotateLeftRounded, SaveRounded, Refresh } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import TableElementEvaluation from './TableElementEvaluation';
import SearchEmployee from 'views/employee/SearchEmployee';

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    })
    // validForYear: yup.string().required('validForYear')
});

const initialValues = {
    id: '',
    employee: null,
    validForYear: '',
    isRefreshElement: false
};

const Evaluation = () => {
    const dispatch = useDispatch();
    const [yourState, setYourState] = useState(initialValues);
    const [dataElementEvaluation, setDataElementEvaluation] = useState({ nextEvaluation: '', evaluationElements: [] });
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const getSubmitEvaluation = async (datas, values) => {
        const result = [];
        for (let index = 0; index < datas.evaluationElements.length; index += 1) {
            result.push(...datas.evaluationElements[index].assessmentCriteria);
        }
        if (datas.evaluationElements.length === 0) {
            snackAlert('Add Evaluation Element', 'warning');
        } else {
            const valueEvaluation = {
                evaluationRequest: result.map((row) => {
                    return {
                        validForYear: values.validForYear,
                        appreciationRequestDTO: {
                            obtainedMarks: row.value,
                            assessmentCriteriaId: row.id
                        }
                    };
                })
            };
            setLoading(true);
            await evaluationApi
                .addEvaluation(values.employee, valueEvaluation)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...yourState, employee: yourState.employee },
        validationSchema,
        onSubmit: async (values) => {
            if (values.isRefreshElement === true) {
                setLoading(true);
                criteriaApi
                    .getElementEvaluation(values.employee)
                    .then((res) => {
                        console.log(res);
                        if (res.success) {
                            const result = {
                                nextEvaluation: res.data.nextEvaluation,
                                evaluationElements:
                                    res.data.evaluationElements.length > 0
                                        ? res.data.evaluationElements.map((row) => {
                                              return {
                                                  id: row.id,
                                                  criteriaName: row.criteriaName,
                                                  assessmentCriteria:
                                                      row.assessmentCriteria.length > 0
                                                          ? row.assessmentCriteria.map((row) => {
                                                                return {
                                                                    id: row.id,
                                                                    assessmentName: row.assessmentName,
                                                                    value: '',
                                                                    maximumMarks: row.maximumMarks,
                                                                    isApplicable: row.isApplicable
                                                                };
                                                            })
                                                          : []
                                              };
                                          })
                                        : []
                            };
                            setDataElementEvaluation(result);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    });
            } else {
                getSubmitEvaluation(dataElementEvaluation, values);
            }
        }
    });

    useEffect(() => {
        if (currentEmployee !== null) {
            setYourState({ ...yourState, employee: currentEmployee });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const handleChangePage = (event, newPage) => {
        setParams({ ...params, page: newPage - 1 });
    };

    const handleChangeRowsPerPage = (event) => {
        setParams({
            ...params,
            length: Math.ceil(dataElementEvaluation.evaluationElements.length / parseInt(event?.target.value, 10)) || 1,
            page: 0,
            rowsPerPage: parseInt(event?.target.value, 10)
        });
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    const reinitForm = () => {
        formik.resetForm();
        setYourState(initialValues);
        setDataElementEvaluation({ nextEvaluation: '', evaluationElements: [] });
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            <Grid container spacing={2}>
                <HeaderComponent header="evaluation" subtitle="view-your-evaluation" />
                <Grid item xs={12} lg={12}>
                    <SpinnerLoader open={loading} />
                    <form onSubmit={formik.handleSubmit}>
                        <MainCard sx={{ mt: 1, mb: 1 }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="doucleClick employee"
                                        id="employee"
                                        name="employee"
                                        value={`${formik.values.employee?.code || ''} || ${formik.values.employee?.firstName || ''} ${
                                            formik.values.employee?.middleName || ''
                                        } ${formik.values.employee?.lastName || ''}`}
                                        handleChange={() => {}}
                                        onDoubleClick={() => setOpenEmpl(true)}
                                        error={formik.touched.employee && Boolean(formik.errors.employee)}
                                        helperText={formik.touched.employee && formik.errors.employee}
                                        placeholder="employee"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="personnel"
                                        id="personnel"
                                        name="personnel"
                                        value={formik.values?.personnel || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.personnel && Boolean(formik.errors.personnel)}
                                        helperText={formik.touched.personnel && formik.errors.personnel}
                                        placeholder="personnel"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="categorie"
                                        id="categorie"
                                        name="categorie"
                                        value={formik.values?.categorie || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.categorie && Boolean(formik.errors.categorie)}
                                        helperText={formik.touched.categorie && formik.errors.categorie}
                                        placeholder="categorie"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="grade"
                                        id="grade"
                                        name="grade"
                                        value={formik.values?.grade || ''}
                                        handleChange={() => {}}
                                        error={formik.touched.grade && Boolean(formik.errors.grade)}
                                        helperText={formik.touched.grade && formik.errors.grade}
                                        placeholder="grade"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="validForYear"
                                        id="validForYear"
                                        name="validForYear"
                                        value={formik.values?.validForYear || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.validForYear && Boolean(formik.errors.validForYear)}
                                        helperText={formik.touched.validForYear && formik.errors.validForYear}
                                        placeholder="validForYear"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <ButtonComponent
                                            variant="outlined"
                                            type="submit"
                                            size="small"
                                            color="secondary"
                                            startIcon={<Refresh />}
                                            handleClick={() => formik.setFieldValue('isRefreshElement', true)}
                                            text="Charger"
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container spacing={gridSpacing} sx={{ mt: gridSpacing }}>
                                <Grid item xs={12}>
                                    <TableElementEvaluation
                                        params={params}
                                        dataElementEvaluation={dataElementEvaluation}
                                        setDataElementEvaluation={setDataElementEvaluation}
                                        snackAlert={snackAlert}
                                        loading={loading}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                        <Grid item xs={4}>
                                            <ButtonComponent
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                startIcon={<RotateLeftRounded />}
                                                text={<FormattedMessage id="reset" />}
                                                handleClick={reinitForm}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ButtonComponent
                                                fullWidth
                                                variant="contained"
                                                type="submit"
                                                size="small"
                                                text={<FormattedMessage id="save" />}
                                                startIcon={<SaveRounded />}
                                                handleClick={() => formik.setFieldValue('isRefreshElement', false)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default Evaluation;

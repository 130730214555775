const { ADD_IMAGE, GET_IMAGES } = require('./actions');

const initialState = {
    galeries: [],
    loading: false,
    error: null
};

const galeryReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_IMAGE:
            return {
                ...state,
                galeries: [...state.galeries, action.payload],
                loading: false
            };
        case GET_IMAGES:
            return {
                ...state,
                galeries: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default galeryReducer;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getTypeContrat = async () => {
    try {
        const response = await instance.get(`${uri}/contract-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeContrat = async (values) => {
    try {
        const response = await instance.post(`${uri}/contract-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeContrat = async (values) => {
    try {
        const response = await instance.put(`${uri}/contract-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeContrat = async (id) => {
    try {
        const response = await instance.delete(`${uri}/contract-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTypeContrat, addTypeContrat, updateTypeContrat, deleteTypeContrat };

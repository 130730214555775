import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    Typography,
    Divider
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, CalendarTodayTwoTone, PhoneAndroidTwoTone, EmailTwoTone } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import SearchMeasure from '../disciplinary-measure/SearchMeasure';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import SanctionAppealingList from '../disciplinary-measure/SanctionAppealingList';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const validationSchema = yup.object({
    // disciplinaryMeasure: yup.object().shape({
    //     id: yup.string().required('employee')
    // }),
    reason: yup.string().required('reason')
});

const AddSanctionAppealing = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    snackAlert,
    setState
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openMeasure, setOpenMeasure] = useState(false);
    const [currentMeasure, setCurrentMeasure] = useState(null);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { id: yourState.id, disciplinaryMeasure: yourState.disciplinaryMeasure, reason: yourState.reason },
        validationSchema,
        onSubmit: (values) => {
            const valuesSanctionAppealing = {
                id: values.id,
                disciplinaryMeasureId: values.disciplinaryMeasure?.id,
                reason: values.reason
            };
            setLoading(true);
            if (values.id === '') {
                api.addSanctionAppealing(valuesSanctionAppealing)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setState(res.data);
                            formik.resetForm();
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateSanctionAppealing(valuesSanctionAppealing)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setState(res.data);
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentMeasure !== null) {
            setYourState({ ...yourState, disciplinaryMeasure: currentMeasure });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMeasure]);

    const reinitForm = () => {
        setYourState(initialValues);
        setDataResponse({ open: false, success: [], failures: [] });
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openMeasure ? (
            <SearchMeasure open={openMeasure} setOpen={setOpenMeasure} setCurrentMeasure={setCurrentMeasure} />
        ) : (
            ''
        );
        return openDialog;
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Sanction Appealing</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="employee"
                                    id="disciplinaryMeasure"
                                    name="disciplinaryMeasure"
                                    value={`${formik.values.disciplinaryMeasure?.employee?.code || ''} ${
                                        formik.values.disciplinaryMeasure?.employee?.firstName || ''
                                    } ${formik.values.disciplinaryMeasure?.employee?.middleName || ''} ${
                                        formik.values.disciplinaryMeasure?.employee?.lastName || ''
                                    }`}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenMeasure(true)}
                                    error={formik.touched.disciplinaryMeasure && Boolean(formik.errors.disciplinaryMeasure)}
                                    helperText={formik.touched.disciplinaryMeasure && formik.errors.disciplinaryMeasure}
                                    placeholder="employee"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <CalendarTodayTwoTone sx={detailsIconSX} />
                                                    {yourState.disciplinaryMeasure?.employee?.firstName}
                                                    {yourState.disciplinaryMeasure?.employee?.lastName}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <PhoneAndroidTwoTone sx={detailsIconSX} />
                                                    {yourState.disciplinaryMeasure?.employee?.mobilePhoneNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <EmailTwoTone sx={detailsIconSX} /> {yourState.disciplinaryMeasure?.employee?.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            {yourState.disciplinaryMeasure?.sanctionAppealings.length > 0 && (
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h4">Information Sanction Appealing</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SanctionAppealingList
                                                                dataSanctionApplealing={yourState.disciplinaryMeasure?.sanctionAppealings}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Information Disciplinary Measure</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    reason: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.reason}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    sanctionDate: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.sanctionDate
                                                                            ?.toString()
                                                                            .slice(0, 10)}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            startDate : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {yourState.disciplinaryMeasure?.startDate
                                                                                    ?.toString()
                                                                                    .slice(0, 10)}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            endDate : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {yourState.disciplinaryMeasure?.endDate
                                                                                    ?.toString()
                                                                                    .slice(0, 10)}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Other information</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    baseSalary : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.employee?.baseSalary}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    retainedAmount : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.retainedAmount}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    retainedPercentage : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.retainedPercentage}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    disciplinaryMeasureStatus : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.disciplinaryMeasureStatus ===
                                                                            'APPROVED' && (
                                                                            <Chip
                                                                                label="approved"
                                                                                variant="contained"
                                                                                size="small"
                                                                                color="error"
                                                                            />
                                                                        )}
                                                                        {yourState.disciplinaryMeasure?.disciplinaryMeasureStatus ===
                                                                            'CANCELLED' && (
                                                                            <Chip
                                                                                label="cancelled"
                                                                                variant="contained"
                                                                                size="small"
                                                                                color="success"
                                                                            />
                                                                        )}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    durationInMonths : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.durationInMonths}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel>retentionMonths</InputLabel>
                                                                {yourState.disciplinaryMeasure?.retentionMonths.length > 0 &&
                                                                    yourState.disciplinaryMeasure?.retentionMonths !== null &&
                                                                    yourState.disciplinaryMeasure?.retentionMonths.map((row, index) => {
                                                                        return (
                                                                            <Chip
                                                                                key={index}
                                                                                label={`${row.name}`}
                                                                                color="primary"
                                                                                variant="contained"
                                                                            />
                                                                        );
                                                                    })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    sanctionType : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasure?.sanctionType?.code} ||{' '}
                                                                        {yourState.disciplinaryMeasure?.sanctionType?.designation}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reason"
                                    id="reason"
                                    name="reason"
                                    value={formik.values?.reason || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    placeholder="reason"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

AddSanctionAppealing.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired
};

export default AddSanctionAppealing;

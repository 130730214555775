// material-ui
import { styled } from '@mui/material/styles';

// project imports
import HomePageCard from './HomePageCard';
// assets
import headerBackground from 'assets/images/landing/header-bg.jpg';

const HeaderWrapper = styled('div')(({ theme }) => ({
    backgroundImage: `url(${headerBackground})`,
    backgroundSize: 'cover',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        paddingTop: 0
    }
}));

// ============================|| CONTACT US MAIN ||============================ //

const HomePage = () => (
    <HeaderWrapper>
        <HomePageCard />
    </HeaderWrapper>
);

export default HomePage;

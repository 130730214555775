import PropTypes from 'prop-types';
// material-ui
import { Button, Grid, Stack } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import InputField from 'ui-component/InputField';

const validationSchema = yup.object({
    fichierDeBase: yup.string().required('fichierDeBase')
    // journalPaie: yup.string().required(<FormattedMessage id="journalPaie" />),
    // etatPaie: yup.string().required(<FormattedMessage id="etatPaie" />),
    // ordreVirement: yup.string().required(<FormattedMessage id="ordreVirement" />),
    // listeEmplyes: yup.string().required(<FormattedMessage id="listeEmplyes" />),
    // recapIpr: yup.string().required(<FormattedMessage id="recapIpr" />),
    // periodeDebut: yup.string().required(<FormattedMessage id="periodeDebut" />),
    // periodeFin: yup.string().required(<FormattedMessage id="periodeFin" />),
    // recapRetenuesCotisations: yup.string().required(<FormattedMessage id="recapRetenuesCotisations" />),
    // recapPrimesIndemnitesDeductions: yup.string().required(<FormattedMessage id="recapPrimesIndemnitesDeductions" />),
    // fraisMedicauxDivers: yup.string().required(<FormattedMessage id="fraisMedicauxDivers" />),
    // fichierTravailleur: yup.string().required(<FormattedMessage id="fichierTravailleur" />)
});

const ImpressionAdd = ({ api, yourStateImpr, setSubmitting, snackAlert, handleBack }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourStateImpr,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setSubmitting(true);
            if (values.id === '') {
                api.addImpression(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Enregistrement effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            console.log(res.response);
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateImpression(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Modification effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            console.log(res.response);
                            snackAlert(`${res.response?.data.recapIpr ? res.response.data.recapIpr : res.message}`, 'warning');
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <MainCard sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="fichierDeBase"
                                id="fichierDeBase"
                                name="fichierDeBase"
                                value={formik.values.fichierDeBase || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.fichierDeBase && Boolean(formik.errors.fichierDeBase)}
                                helperText={formik.touched.fichierDeBase && formik.errors.fichierDeBase}
                                // placeholder={`${intl.formatMessage({ id: 'fichierDeBase' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="journalPaie"
                                id="journalPaie"
                                name="journalPaie"
                                value={formik.values.journalPaie || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.journalPaie && Boolean(formik.errors.journalPaie)}
                                helperText={formik.touched.journalPaie && formik.errors.journalPaie}
                                // placeholder={`${intl.formatMessage({ id: 'journalPaie' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="etatPaie"
                                id="etatPaie"
                                name="etatPaie"
                                value={formik.values.etatPaie || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.etatPaie && Boolean(formik.errors.etatPaie)}
                                helperText={formik.touched.etatPaie && formik.errors.etatPaie}
                                // placeholder={`${intl.formatMessage({ id: 'etatPaie' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="ordreVirement"
                                id="ordreVirement"
                                name="ordreVirement"
                                value={formik.values.ordreVirement || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.ordreVirement && Boolean(formik.errors.ordreVirement)}
                                helperText={formik.touched.ordreVirement && formik.errors.ordreVirement}
                                // placeholder={`${intl.formatMessage({ id: 'ordreVirement' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="listeEmplyes"
                                id="listeEmplyes"
                                name="listeEmplyes"
                                value={formik.values.listeEmplyes || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.listeEmplyes && Boolean(formik.errors.listeEmplyes)}
                                helperText={formik.touched.listeEmplyes && formik.errors.listeEmplyes}
                                // placeholder={`${intl.formatMessage({ id: 'listeEmplyes' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="recapIpr"
                                id="recapIpr"
                                name="recapIpr"
                                value={formik.values.recapIpr || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.recapIpr && Boolean(formik.errors.recapIpr)}
                                helperText={formik.touched.recapIpr && formik.errors.recapIpr}
                                // placeholder={`${intl.formatMessage({ id: 'recapIpr' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="recapRetenuesCotisations"
                                id="recapRetenuesCotisations"
                                name="recapRetenuesCotisations"
                                value={formik.values.recapRetenuesCotisations || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.recapRetenuesCotisations && Boolean(formik.errors.recapRetenuesCotisations)}
                                helperText={formik.touched.recapRetenuesCotisations && formik.errors.recapRetenuesCotisations}
                                // placeholder={`${intl.formatMessage({ id: 'recapRetenuesCotisations' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="recapPrimesIndemnitesDeductions"
                                id="recapPrimesIndemnitesDeductions"
                                name="recapPrimesIndemnitesDeductions"
                                value={formik.values.recapPrimesIndemnitesDeductions || ''}
                                handleChange={formik.handleChange}
                                error={
                                    formik.touched.recapPrimesIndemnitesDeductions && Boolean(formik.errors.recapPrimesIndemnitesDeductions)
                                }
                                helperText={formik.touched.recapPrimesIndemnitesDeductions && formik.errors.recapPrimesIndemnitesDeductions}
                                // placeholder={`${intl.formatMessage({ id: 'recapPrimesIndemnitesDeductions' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="fraisMedicauxDivers"
                                id="fraisMedicauxDivers"
                                name="fraisMedicauxDivers"
                                value={formik.values.fraisMedicauxDivers || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.fraisMedicauxDivers && Boolean(formik.errors.fraisMedicauxDivers)}
                                helperText={formik.touched.fraisMedicauxDivers && formik.errors.fraisMedicauxDivers}
                                // placeholder={`${intl.formatMessage({ id: 'fraisMedicauxDivers' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="fichierTravailleur"
                                id="fichierTravailleur"
                                name="fichierTravailleur"
                                value={formik.values.fichierTravailleur || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.fichierTravailleur && Boolean(formik.errors.fichierTravailleur)}
                                helperText={formik.touched.fichierTravailleur && formik.errors.fichierTravailleur}
                                // placeholder={`${intl.formatMessage({ id: 'fichierTravailleur' })}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={6} lg={4}>
                                    <Button
                                        startIcon={yourStateImpr.id === '' ? <SaveIcon /> : <EditIcon />}
                                        type="submit"
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        {yourStateImpr.id === '' ? <FormattedMessage id="save" /> : <FormattedMessage id="edit" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                                <Button sx={{ my: 3, ml: 1 }} onClick={handleBack}>
                                    <FormattedMessage id="previous" />
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </form>
        </div>
    );
};

ImpressionAdd.propTypes = {
    api: PropTypes.object.isRequired,
    yourStateImpr: PropTypes.object,
    setSubmitting: PropTypes.func,
    snackAlert: PropTypes.func,
    handleBack: PropTypes.func
};

export default ImpressionAdd;

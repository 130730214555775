import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getNiveauxFormation() {
    return instance.get(`${baseURL}/training-levels`).then(HandleResponse).catch(HandleError);
}
async function getListeNiveauxFormation() {
    return instance.get(`${baseURL}/training-levels`).then(HandleResponse).catch(HandleError);
}

const saveNiveauFormation = async (niveauFormation) => {
    return instance.post(`${baseURL}/training-levels`, niveauFormation).then(HandleResponse).catch(HandleError);
};

const updateNiveauFormation = async (niveauFormation) => {
    return instance.put(`/training-levels/${niveauFormation.id}`, niveauFormation).then(HandleResponse).catch(HandleError);
};

const deleteNiveauFormation = async (id) => {
    return instance.delete(`${baseURL}/training-levels/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeNiveauxFormation,
    getNiveauxFormation,
    saveNiveauFormation,
    updateNiveauFormation,
    deleteNiveauFormation
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import MultipleSelectCheckmarks from 'ui-component/MultipleSelectCheckmarks';
import SearchTrainingType from '../settings/training-type/SearchTrainingType';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    subject: yup.string().required('subject'),
    startDateTime: yup.date().required('startDateTime'),
    endDateTime: yup.date().required('endDateTime'),
    maxExpectedParticipants: yup.string().required('maxExpectedParticipants'),
    venue: yup.string().required('venue'),
    additionalNotes: yup.string().required('additionalNotes'),
    trainingType: yup.object().shape({
        id: yup.string().required('trainingType')
    })
});

const AddTraining = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, dataTrainer, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [currentTrainingType, setCurrentTrainingType] = useState(null);
    const [openTrainType, setOpenTrainType] = useState(false);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const trainers = [];
            values.trainers.forEach((row) => trainers.push(row.id));
            const valuesTraining = {
                id: values.id,
                subject: values.subject,
                startDateTime: values.startDateTime,
                endDateTime: values.endDateTime,
                maxExpectedParticipants: values.maxExpectedParticipants,
                venue: values.venue,
                additionalNotes: values.additionalNotes,
                trainersIds: trainers,
                trainingTypeId: values.trainingType.id
            };
            setLoading(true);
            if (values.id === '') {
                api.addTraining(valuesTraining)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateTraining(valuesTraining)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setDataResponse({ open: false, success: [], failures: [] });
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setDataResponse({
                                open: false,
                                success: [],
                                failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentTrainingType !== null) {
            setYourState({ ...yourState, ...formik.values, trainingType: currentTrainingType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrainingType]);

    const reinitForm = () => {
        setYourState(initialValues);
        setDataResponse({ open: false, success: [], failures: [] });
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen({ ...open, add: false });
        reinitForm();
    };

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        const lastItem = value[value.length - 1];
        const selectedIndex = yourState.trainers.findIndex((p) => p.id === lastItem.id);
        if (selectedIndex > -1) {
            const arrayCopy = value.filter((row) => row.id !== lastItem.id);
            formik.setFieldValue('trainers', arrayCopy);
            setYourState({ ...yourState, trainers: arrayCopy });
        } else {
            formik.setFieldValue('trainers', value);
        }
    };

    const HandleOpenDialogTrainingType = () => {
        const openDialog = openTrainType ? (
            <SearchTrainingType open={openTrainType} setOpen={setOpenTrainType} setCurrentTrainingType={setCurrentTrainingType} />
        ) : (
            ''
        );
        return openDialog;
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogTrainingType()}
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="sm" open={open.add} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Training</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="subject"
                                    id="subject"
                                    name="subject"
                                    value={formik.values?.subject || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                                    helperText={formik.touched.subject && formik.errors.subject}
                                    placeholder="subject"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="startDateTime"
                                    id="startDateTime"
                                    name="startDateTime"
                                    value={formik.values.startDateTime || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('endDateTime', value);
                                        formik.setFieldValue('startDateTime', value);
                                    }}
                                    error={formik.touched.startDateTime && Boolean(formik.errors.startDateTime)}
                                    helperText={formik.touched.startDateTime && formik.errors.startDateTime}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="endDateTime"
                                    id="endDateTime"
                                    name="endDateTime"
                                    minDate={formik.values.startDateTime || ''}
                                    value={formik.values.endDateTime || ''}
                                    handleChange={(value) => formik.setFieldValue('endDateTime', value)}
                                    error={formik.touched.endDateTime && Boolean(formik.errors.endDateTime)}
                                    helperText={formik.touched.endDateTime && formik.errors.endDateTime}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="maxExpectedParticipants"
                                    id="maxExpectedParticipants"
                                    name="maxExpectedParticipants"
                                    value={formik.values?.maxExpectedParticipants || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.maxExpectedParticipants && Boolean(formik.errors.maxExpectedParticipants)}
                                    helperText={formik.touched.maxExpectedParticipants && formik.errors.maxExpectedParticipants}
                                    placeholder="maxExpectedParticipants"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="venue"
                                    id="venue"
                                    name="venue"
                                    value={formik.values?.venue || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.venue && Boolean(formik.errors.venue)}
                                    helperText={formik.touched.venue && formik.errors.venue}
                                    placeholder="venue"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="additionalNotes"
                                    id="additionalNotes"
                                    name="additionalNotes"
                                    value={formik.values?.additionalNotes || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.additionalNotes && Boolean(formik.errors.additionalNotes)}
                                    helperText={formik.touched.additionalNotes && formik.errors.additionalNotes}
                                    placeholder="additionalNotes"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultipleSelectCheckmarks
                                    size="small"
                                    fullWidth
                                    label="trainers"
                                    id="trainers"
                                    name="trainers"
                                    value={formik.values?.trainers}
                                    handleChange={handleChange}
                                    dataOption={dataTrainer}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="doucleClick trainingType"
                                    id="trainingType"
                                    name="trainingType"
                                    value={`${formik.values.trainingType?.code} || ${formik.values.trainingType?.designation}`}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenTrainType(true)}
                                    error={formik.touched.trainingType && Boolean(formik.errors.trainingType)}
                                    helperText={formik.touched.trainingType && formik.errors.trainingType?.id}
                                    placeholder="trainingType"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

AddTraining.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    dataTrainer: PropTypes.array,
    snackAlert: PropTypes.func.isRequired
};

export default AddTraining;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getAssessmentCriteria() {
    try {
        const response = await instance.get(`${uri}/assessment-criteria`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addAssessmentCriteria = async (values, dataAssessments) => {
    try {
        const response = await instance.post(`${uri}/assessment-criteria/${values.id}`, dataAssessments);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateAssessmentCriteria = async (values) => {
    try {
        const response = await instance.put(`${uri}/assessment-criteria/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteAssessmentCriteria = async (id) => {
    try {
        const response = await instance.delete(`${uri}/assessment-criteria/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getAssessmentCriteria, addAssessmentCriteria, updateAssessmentCriteria, deleteAssessmentCriteria };

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeCategoriesPersonnels(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/personal-categories`, {
            params: {
                nom: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveCategoriePersonnel = async (categoriePersonnel) => {
    return instance.post(`${baseURL}/personal-categories`, categoriePersonnel).then(HandleResponse).catch(HandleError);
};

const updateCategoriePersonnel = async (categoriePersonnel) => {
    return instance
        .put(`${baseURL}/personal-categories/${categoriePersonnel.id}`, categoriePersonnel)
        .then(HandleResponse)
        .catch(HandleError);
};

const deleteCategoriePersonnel = async (id) => {
    return instance.delete(`${baseURL}/personal-categories/${id}`).then(HandleResponse).catch(HandleError);
};

export default {
    getListeCategoriesPersonnels,
    saveCategoriePersonnel,
    updateCategoriePersonnel,
    deleteCategoriePersonnel
};

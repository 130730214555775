import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-service/api/v1';

const getTypeCredit = async () => {
    try {
        const response = await instance.get(`${uri}/credit-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeCredit = async (values) => {
    try {
        const response = await instance.post(`${uri}/credit-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeCredit = async (values) => {
    try {
        const response = await instance.put(`${uri}/credit-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeCredit = async (id) => {
    try {
        const response = await instance.delete(`${uri}/credit-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getSettingTypeCredit = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/system-settings/credit-types?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addSettingTypeCredit = async (values) => {
    try {
        const response = await instance.post(`${uri}/system-settings/credit-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSettingTypeCredit = async (values) => {
    try {
        const response = await instance.put(`${uri}/system-settings/credit-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSettingTypeCredit = async (id) => {
    try {
        const response = await instance.delete(`${uri}/system-settings/credit-types/${id}/remove`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getTypeCredit,
    addTypeCredit,
    updateTypeCredit,
    deleteTypeCredit,
    getSettingTypeCredit,
    addSettingTypeCredit,
    updateSettingTypeCredit,
    deleteSettingTypeCredit
};

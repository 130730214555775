import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListePlans(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/plan-leaves`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const savePlan = async (plan) => {
    return instance.post(`${baseURL}/plan-leaves`, plan).then(HandleResponse).catch(HandleError);
};

const updatePlan = async (plan) => {
    return instance.put(`${baseURL}/plan-leaves/${plan.id}`, plan).then(HandleResponse).catch(HandleError);
};

const deletePlan = async (id) => {
    return instance.delete(`${baseURL}/plan-leaves/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListePlans,
    savePlan,
    updatePlan,
    deletePlan
};

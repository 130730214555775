import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const MultipleSelectCheckmarks = ({ value, handleChange, size, label, style, labelStyles, dataOption, ...props }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: 3,
                marginBottom: 3,
                ...style
            }}
        >
            <Typography
                htmlFor={label}
                style={{
                    marginRight: 5,
                    color: 'black',
                    ...labelStyles
                }}
            >
                {label}
            </Typography>
            <FormControl>
                <Select
                    id="demo-multiple-checkbox"
                    {...props}
                    fullWidth
                    size={size}
                    multiple
                    value={value}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        const result = [];
                        selected.forEach((row) => result.push(`${row.name}`));
                        return result.join(', ');
                    }}
                    MenuProps={MenuProps}
                >
                    {dataOption.map((row, index) => {
                        return (
                            <MenuItem key={index} value={row}>
                                <Checkbox checked={value.findIndex((p) => p.id === row.id) > -1} />
                                <ListItemText primary={`${row.name}`} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

MultipleSelectCheckmarks.propTypes = {
    size: PropTypes?.string,
    handleChange: PropTypes?.func,
    label: PropTypes?.string,
    style: PropTypes?.func,
    labelStyles: PropTypes?.func,
    value: PropTypes?.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    dataOption: PropTypes.array.isRequired
};

export default MultipleSelectCheckmarks;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slide,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import InputLabel from 'ui-component/extended/Form/InputLabel';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    assessmentName: yup.string().required('assessmentName'),
    maximumMarks: yup.string().required('maximumMarks'),
    isApplicable: yup.string().required('isApplicable')
});

const UpdateAssessmentCriteriaDialog = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    snackAlert
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState.assessment,
        validationSchema,
        onSubmit: (values) => {
            const valuesAss = {
                id: values.id,
                assessmentName: values.assessmentName,
                maximumMarks: values.maximumMarks,
                isApplicable: values.isApplicable
            };
            setLoading(true);
            if (values.id) {
                api.updateAssessmentCriteria(valuesAss)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen({ ...open, update: false });
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="sm" open={open.update} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Update AssessmentCriteria {yourState.criteriaName}</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="assessmentName"
                                id="assessmentName"
                                name="assessmentName"
                                value={formik.values?.assessmentName || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.assessmentName && Boolean(formik.errors.assessmentName)}
                                helperText={formik.touched.assessmentName && formik.errors.assessmentName}
                                placeholder="assessmentName"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="maximumMarks"
                                id="maximumMarks"
                                name="maximumMarks"
                                value={formik.values?.maximumMarks || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.maximumMarks && Boolean(formik.errors.maximumMarks)}
                                helperText={formik.touched.maximumMarks && formik.errors.maximumMarks}
                                placeholder="maximumMarks"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>isApplicable</InputLabel>
                            <RadioGroup
                                row
                                aria-label="isApplicable"
                                name="isApplicable"
                                id="maximumMarks"
                                value={`${formik.values?.isApplicable}` || ''}
                                onChange={(e) => formik.setFieldValue('isApplicable', e.target.value)}
                            >
                                <FormControlLabel
                                    defaultChecked
                                    value="true"
                                    control={<Radio color="primary" sx={{ color: 'primary.main' }} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="warning" sx={{ color: 'warning.main' }} />}
                                    label="No"
                                />
                            </RadioGroup>
                            <FormControl>
                                {formik.touched.isApplicable && formik.errors.isApplicable && (
                                    <FormHelperText error>{formik.errors.isApplicable}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={<FormattedMessage id="edit" />}
                        startIcon={<Edit />}
                        handleClick={() => {}}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

UpdateAssessmentCriteriaDialog.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.object.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default UpdateAssessmentCriteriaDialog;

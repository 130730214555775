import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { typePersonnelAPI, categorieAPI } from 'api';

import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { useTheme } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonComponent from 'ui-component/ButtonComponent';
import CategorieModal from './CategorieModal';
import CategorieTable from './CategorieTable';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [categorieListe, setCategorieListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listePersonnels, setListePersonnels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categorieToDelete, setCategorieToDelete] = useState(null);
    const [categorieToEdit, setCategorieToEdit] = useState({
        code: '',
        designation: '',
        personalTypeId: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const categoriePromise = categorieAPI.getListeCategoriesPersonnels(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const personnelPromise = typePersonnelAPI.getListeTypesPersonnels('', 100, 0);
        axios
            .all([categoriePromise, personnelPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const categorieData = responses[0]?.content;
                        const typesPersonnels = responses[1]?.content?.map((tp) => ({
                            label: `${tp.code} | ${tp.designation}`,
                            value: tp.id
                        }));
                        const categoriePersonnelData = [];
                        categorieData.forEach((row) => {
                            categoriePersonnelData.push({
                                id: row.id,
                                code: row.code,
                                designation: row.designation,
                                personalTypeId: row.personalType ? row.personalType : ''
                            });
                        });
                        setCategorieListe((categorieListe) => ({
                            ...categorieListe,
                            ...responses[0],
                            content: categoriePersonnelData
                        }));
                        setListePersonnels(typesPersonnels);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setCategorieToEdit({
                ...row,
                personalTypeId: {
                    label: `${row?.personalTypeId?.code} | ${row?.personalTypeId?.designation}`,
                    value: row?.personalTypeId?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            categorieAPI
                .getListeCategoriesPersonnels()
                .then((res) => {
                    setCategorieListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        setCategorieToEdit({
            code: '',
            designation: '',
            personalTypeId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (categorie) => {
        setCategorieToDelete(categorie);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (categorieToDelete) => {
        if (categorieToDelete) {
            setCategorieListe(categorieListe.filter((categorie) => categorie.id !== categorieToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getCategoriePersonnel = (pageNumber, pageSize) => {
        setLoading(true);
        let categoriePromise;
        if (!pageNumber && !pageSize) {
            categoriePromise = categorieAPI.getListeCategoriesPersonnels(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });

            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            categoriePromise = categorieAPI.getListeCategoriesPersonnels(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([categoriePromise])
            .then(
                axios.spread((...responses) => {
                    const categorieData = responses[0]?.content;
                    const typesPersonnels = responses[1]?.content?.map((tp) => ({
                        label: `${tp.code} | ${tp.designation}`,
                        value: tp.id
                    }));
                    const categoriePersonnelData = [];
                    categorieData.forEach((row) => {
                        categoriePersonnelData.push({
                            id: row.id,
                            code: row.code,
                            designation: row.designation,
                            personalTypeId: row.personalType ? row.personalType : ''
                        });
                    });
                    setCategorieListe((categorieListe) => ({
                        ...categorieListe,
                        ...responses[0],
                        content: categoriePersonnelData
                    }));
                    setListePersonnels(typesPersonnels);
                    setSuccessFetch(true);
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        categorieAPI
            .deleteCategoriePersonnel(categorieToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getCategoriePersonnel();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getCategoriePersonnel(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getCategoriePersonnel(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (categorieToEdit.id) {
            setLoading(true);
            categorieAPI
                .updateCategoriePersonnel({
                    id: categorieToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getCategoriePersonnel();
                    setCategorieToEdit({
                        code: '',
                        designation: '',
                        personalTypeId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            categorieAPI
                .saveCategoriePersonnel({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setCategorieToEdit({
                        code: '',
                        designation: '',
                        personalTypeId: null
                    });
                    getCategoriePersonnel();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFilterList = (e) => {
        if (e) {
            setFilterValue(e.target.value);
            const newRows = categorieListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(filterValue?.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setCategorieListe({ ...categorieListe, content: newRows });
        } else {
            setFilterValue('');
            getCategoriePersonnel();
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'categoriePersonnel' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-categorie-personnel' })} />
            {successFetch ? (
                <>
                    <CategorieTable
                        categorieListe={{
                            content: categorieListe.content,
                            totalPages: categorieListe.totalPages,
                            totalElements: categorieListe.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        filterValue={filterValue}
                        getCategoriePersonnel={getCategoriePersonnel}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        handleFilterList={handleFilterList}
                    />
                    <CategorieModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        categorieToEdit={categorieToEdit}
                        snackAlert={snackAlert}
                        handleSubmit={handleSubmit}
                        theme={theme}
                        loading={loading}
                        ButtonComponent={ButtonComponent}
                        listePersonnels={listePersonnels}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'categorie-personnel' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

export async function getHolidays() {
    try {
        const response = await instance.get(`${uri}/public-holidays`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export const addHolidays = async (values) => {
    try {
        const response = await instance.post(`${uri}/public-holidays`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const updateHolidays = async (values) => {
    try {
        const response = await instance.put(`${uri}/public-holidays/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const deleteHolidays = async (id) => {
    try {
        const response = await instance.delete(`${uri}/public-holidays/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getHolidays, addHolidays, updateHolidays, deleteHolidays };

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import TableSignataireList from './TableSignataireList';
import SearchEmployee from 'views/employee/SearchEmployee';
import { useTheme } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    code: yup.string().required('code'),
    designation: yup.string().required('designation')
});

const init = {
    id: 0,
    code: '',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    formErrors: { code: '', title: '', firstName: '' },
    touched: { code: false, title: false, firstName: false },
    formValid: false
};

const AddDocument = ({ api, open, setOpen, initialValues, yourState, setYourState, params, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [initState, setInitState] = useState(init);
    const [dataSignataire, setDataSignataire] = useState(yourState.signataires);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [paramsSign, setParamssign] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });

    const headCells = [
        {
            id: 'code',
            numeric: true,
            label: 'code',
            align: 'left'
        },
        {
            id: 'nom-prenom',
            numeric: true,
            label: 'nom et prenom',
            align: 'left'
        },
        {
            id: 'fonction',
            numeric: true,
            label: 'fonction',
            align: 'left'
        }
    ];

    const reinitForm = () => {
        setYourState(initialValues);
        setDataSignataire([]);
        setInitState(init);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesDoc = {
                id: values.id,
                code: values.code,
                designation: values.designation,
                signatairesRequest:
                    dataSignataire !== null || dataSignataire.length !== 0
                        ? dataSignataire.map((row) => {
                              return {
                                  title: row.title,
                                  firstName: row.firstName,
                                  lastName: row.lastName,
                                  middleName: row.middleName
                              };
                          })
                        : []
            };
            setLoading(true);
            if (values.id === '') {
                api.addDocument(valuesDoc)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setLoading(false);
                            reinitForm();
                            formik.resetForm();
                            refleshTable(params);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateDocument(valuesDoc)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setLoading(false);
                            refleshTable(params);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const handleClose = () => {
        setOpen(false);
        reinitForm();
        formik.resetForm();
    };

    const validateForm = (touchedValid) => {
        return touchedValid.code && touchedValid.firstName && touchedValid.title;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;

        switch (fieldName) {
            case 'code':
                fieldValidationTouched.code = value.length >= 1 || value !== '';
                fieldValidationErrors.code = fieldValidationTouched.code ? '' : ' code is required';
                break;
            case 'firstName':
                fieldValidationTouched.firstName = value.length >= 1 || value !== '';
                fieldValidationErrors.firstName = fieldValidationTouched.firstName ? '' : ' firstName is required';
                break;
            case 'title':
                fieldValidationTouched.title = value.length >= 1 || value !== '';
                fieldValidationErrors.title = fieldValidationTouched.title ? '' : ' title is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target.name;
        const value = e?.target.value;
        validateField(name, value);
    };

    const handleSubmitSignataire = (event) => {
        const selectedIndex = dataSignataire.findIndex((object) => {
            return object.code === initState.code;
        });
        let newSignataire = [];
        if (selectedIndex >= 0) {
            snackAlert('elementExist', 'warning');
            return;
        }

        newSignataire = newSignataire.concat(dataSignataire, [
            {
                code: initState.code,
                title: initState.title,
                firstName: initState.firstName,
                middleName: initState.middleName,
                lastName: initState.lastName
            }
        ]);
        setDataSignataire(newSignataire);
        setParamssign({
            ...params,
            length: Math.ceil(newSignataire.length / params.rowsPerPage) || 1,
            totalItems: newSignataire.length || 0
        });
        setInitState({
            id: 0,
            code: '',
            title: '',
            firstName: '',
            middleName: '',
            lastName: '',
            formErrors: { code: '', title: '', firstName: '' },
            touched: { code: false, title: false, firstName: false },
            formValid: false
        });
        event.preventDefault();
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataSignataire.filter((row, index) => index !== indexOf);
        setDataSignataire(arrayCopy);
    };

    useEffect(() => {
        if (currentEmployee !== null) {
            console.log(currentEmployee);
            setInitState({
                id: 0,
                code: currentEmployee?.code,
                title: '',
                firstName: currentEmployee?.firstName,
                middleName: currentEmployee?.middleName,
                lastName: currentEmployee?.lastName,
                formErrors: { code: '', title: '', firstName: '' },
                touched: { code: true, title: false, firstName: true },
                formValid: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    return (
        <>
            <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} />
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Document</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.error.light,
                                color: theme.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={6} md={6} lg={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="code"
                                    id="code"
                                    name="code"
                                    value={formik.values?.code || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.code && Boolean(formik.errors.code)}
                                    helperText={formik.touched.code && formik.errors.code}
                                    placeholder="enterCode"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="designation"
                                    id="designation"
                                    name="designation"
                                    value={formik.values?.designation || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.designation && Boolean(formik.errors.designation)}
                                    helperText={formik.touched.designation && formik.errors.designation}
                                    placeholder="designation"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogContent>
                        <Typography variant="h4">Signataire</Typography>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="code"
                                    id="code"
                                    name="code"
                                    value={initState.code || ''}
                                    handleChange={(event) => handleUserInput(event)}
                                    error={!initState.touched.code && Boolean(initState.formErrors.code)}
                                    helperText={initState.formErrors.code && initState.formErrors.code}
                                    placeholder="code"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="firstName"
                                    id="firstName"
                                    name="firstName"
                                    value={initState.firstName || ''}
                                    handleChange={(event) => handleUserInput(event)}
                                    error={!initState.touched.firstName && Boolean(initState.formErrors.firstName)}
                                    helperText={initState.formErrors.firstName && initState.formErrors.firstName}
                                    placeholder="firstName"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="middleName"
                                    id="middleName"
                                    name="middleName"
                                    value={initState.middleName || ''}
                                    handleChange={(event) => handleUserInput(event)}
                                    error={!initState.touched.middleName && Boolean(initState.formErrors.middleName)}
                                    helperText={initState.formErrors.middleName && initState.formErrors.middleName}
                                    placeholder="middleName"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="lastName"
                                    id="lastName"
                                    name="lastName"
                                    value={initState.lastName || ''}
                                    handleChange={(event) => handleUserInput(event)}
                                    error={!initState.touched.lastName && Boolean(initState.formErrors.lastName)}
                                    helperText={initState.formErrors.lastName && initState.formErrors.lastName}
                                    placeholder="lastName"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="title"
                                    id="title"
                                    name="title"
                                    value={initState.title || ''}
                                    handleChange={(event) => handleUserInput(event)}
                                    error={!initState.touched.title && Boolean(initState.formErrors.title)}
                                    helperText={initState.formErrors.title && initState.formErrors.title}
                                    placeholder="title"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between">
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        disabled={!initState.formValid}
                                        handleClick={handleSubmitSignataire}
                                    />
                                    <ButtonComponent
                                        text="Search"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpenEmpl(true);
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TableSignataireList
                                    actions
                                    params={paramsSign}
                                    setParams={setParamssign}
                                    initState={initState}
                                    setInitState={setInitState}
                                    dataSignataire={dataSignataire}
                                    setDataSignataire={setDataSignataire}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

AddDocument.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddDocument;

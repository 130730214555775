import PropTypes from 'prop-types';
// material-ui
import { Button, Grid, Stack } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import InputField from 'ui-component/InputField';
import AnimateButton from 'ui-component/extended/AnimateButton';

const validationSchema = yup.object({
    nomServeur: yup.string().required('nomServeur')
    // nomDataBase: yup.string().required(<FormattedMessage id="nomDataBase" />),
    // password: yup.string().required(<FormattedMessage id="password" />),
    // nomUtilisateur: yup.string().required(<FormattedMessage id="nomUtilisateur" />),
    // numeroDossierComptable: yup.string().required(<FormattedMessage id="numeroDossierComptable" />),
    // prefixComptaCongeMaladie: yup.string().required(<FormattedMessage id="prefixComptaCongeMaladie" />),
    // periodeDebut: yup.string().required(<FormattedMessage id="periodeDebut" />),
    // periodeFin: yup.string().required(<FormattedMessage id="periodeFin" />),
    // prefixAllocationFamiliale: yup.string().required(<FormattedMessage id="prefixAllocationFamiliale" />),
    // prefixComptaEmployes: yup.string().required(<FormattedMessage id="prefixComptaEmployes" />),
    // prefixComptaHeureSuppl: yup.string().required(<FormattedMessage id="prefixComptaHeureSuppl" />),
    // prefixComptaSalaireBase: yup.string().required(<FormattedMessage id="prefixComptaSalaireBase" />),
    // prefixComptaCongeMaternite: yup.string().required(<FormattedMessage id="prefixComptaCongeMaternite" />),
    // prefixComptaIndemniteLogement: yup.string().required(<FormattedMessage id="prefixComptaIndemniteLogementObligatoire" />),
    // idExercice: yup.string().required(<FormattedMessage id="idExercice" />)
});

const LiasonComptaAdd = ({ api, yourStateLiaison, setSubmitting, snackAlert, handleBack, handleNext }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourStateLiaison,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setSubmitting(true);
            if (values.id === '') {
                api.addLiaisonComptable(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Enregistrement effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            console.log(res.response);
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateLiaisonComptable(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Modification effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            console.log(res.response);
                            snackAlert(
                                `${res.response?.data.prefixComptaCongeMaladie ? res.response.data.prefixComptaCongeMaladie : res.message}`,
                                'warning'
                            );
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <MainCard sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="nomServeur"
                                id="nomServeur"
                                name="nomServeur"
                                value={formik.values.nomServeur || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.nomServeur && Boolean(formik.errors.nomServeur)}
                                helperText={formik.touched.nomServeur && formik.errors.nomServeur}
                                // placeholder={`${intl.formatMessage({ id: 'nomServeur' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="nomDataBase"
                                id="nomDataBase"
                                name="nomDataBase"
                                value={formik.values.nomDataBase || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.nomDataBase && Boolean(formik.errors.nomDataBase)}
                                helperText={formik.touched.nomDataBase && formik.errors.nomDataBase}
                                // placeholder={`${intl.formatMessage({ id: 'nomDataBase' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="password"
                                id="password"
                                name="password"
                                value={formik.values.password || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                // placeholder={`${intl.formatMessage({ id: 'password' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="nomUtilisateur"
                                id="nomUtilisateur"
                                name="nomUtilisateur"
                                value={formik.values.nomUtilisateur || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.nomUtilisateur && Boolean(formik.errors.nomUtilisateur)}
                                helperText={formik.touched.nomUtilisateur && formik.errors.nomUtilisateur}
                                // placeholder={`${intl.formatMessage({ id: 'nomUtilisateur' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="numeroDossierComptable"
                                id="numeroDossierComptable"
                                name="numeroDossierComptable"
                                value={formik.values.numeroDossierComptable || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.numeroDossierComptable && Boolean(formik.errors.numeroDossierComptable)}
                                helperText={formik.touched.numeroDossierComptable && formik.errors.numeroDossierComptable}
                                // placeholder={`${intl.formatMessage({ id: 'numeroDossierComptable' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaCongeMaladie"
                                id="prefixComptaCongeMaladie"
                                name="prefixComptaCongeMaladie"
                                value={formik.values.prefixComptaCongeMaladie || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaCongeMaladie && Boolean(formik.errors.prefixComptaCongeMaladie)}
                                helperText={formik.touched.prefixComptaCongeMaladie && formik.errors.prefixComptaCongeMaladie}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaCongeMaladie' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixAllocationFamiliale"
                                id="prefixAllocationFamiliale"
                                name="prefixAllocationFamiliale"
                                value={formik.values.prefixAllocationFamiliale || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixAllocationFamiliale && Boolean(formik.errors.prefixAllocationFamiliale)}
                                helperText={formik.touched.prefixAllocationFamiliale && formik.errors.prefixAllocationFamiliale}
                                // placeholder={`${intl.formatMessage({ id: 'prefixAllocationFamiliale' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaEmployes"
                                id="prefixComptaEmployes"
                                name="prefixComptaEmployes"
                                value={formik.values.prefixComptaEmployes || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaEmployes && Boolean(formik.errors.prefixComptaEmployes)}
                                helperText={formik.touched.prefixComptaEmployes && formik.errors.prefixComptaEmployes}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaEmployes' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaHeureSuppl"
                                id="prefixComptaHeureSuppl"
                                name="prefixComptaHeureSuppl"
                                value={formik.values.prefixComptaHeureSuppl || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaHeureSuppl && Boolean(formik.errors.prefixComptaHeureSuppl)}
                                helperText={formik.touched.prefixComptaHeureSuppl && formik.errors.prefixComptaHeureSuppl}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaHeureSuppl' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaSalaireBase"
                                id="prefixComptaSalaireBase"
                                name="prefixComptaSalaireBase"
                                value={formik.values.prefixComptaSalaireBase || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaSalaireBase && Boolean(formik.errors.prefixComptaSalaireBase)}
                                helperText={formik.touched.prefixComptaSalaireBase && formik.errors.prefixComptaSalaireBase}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaSalaireBase' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaCongeMaternite"
                                id="prefixComptaCongeMaternite"
                                name="prefixComptaCongeMaternite"
                                value={formik.values.prefixComptaCongeMaternite || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaCongeMaternite && Boolean(formik.errors.prefixComptaCongeMaternite)}
                                helperText={formik.touched.prefixComptaCongeMaternite && formik.errors.prefixComptaCongeMaternite}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaCongeMaternite' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="prefixComptaIndemniteLogement"
                                id="prefixComptaIndemniteLogement"
                                name="prefixComptaIndemniteLogement"
                                value={formik.values.prefixComptaIndemniteLogement || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.prefixComptaIndemniteLogement && Boolean(formik.errors.prefixComptaIndemniteLogement)}
                                helperText={formik.touched.prefixComptaIndemniteLogement && formik.errors.prefixComptaIndemniteLogement}
                                // placeholder={`${intl.formatMessage({ id: 'prefixComptaIndemniteLogement' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="idExercice"
                                id="idExercice"
                                name="idExercice"
                                value={formik.values.idExercice || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.idExercice && Boolean(formik.errors.idExercice)}
                                helperText={formik.touched.idExercice && formik.errors.idExercice}
                                // placeholder={`${intl.formatMessage({ id: 'idExercice' })}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={6} lg={4}>
                                    <Button
                                        startIcon={yourStateLiaison.id === '' ? <SaveIcon /> : <EditIcon />}
                                        type="submit"
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        {yourStateLiaison.id === '' ? <FormattedMessage id="save" /> : <FormattedMessage id="edit" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                                <Button sx={{ my: 3, ml: 1 }} onClick={handleBack}>
                                    <FormattedMessage id="previous" />
                                </Button>
                                <AnimateButton>
                                    <Button variant="contained" sx={{ my: 3, ml: 1 }} onClick={handleNext}>
                                        <FormattedMessage id="next" />
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </form>
        </div>
    );
};

LiasonComptaAdd.propTypes = {
    api: PropTypes.object.isRequired,
    yourStateLiaison: PropTypes.object,
    setSubmitting: PropTypes.func,
    snackAlert: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func
};

export default LiasonComptaAdd;

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TypographyListDescription from './TypographyListDescription';
import TypographyListHeader from './TypographyListHeader';

const HeaderComponent = ({ header, subtitle }) => {
    const intl = useIntl();
    return (
        <Grid item xs={12}>
            <TypographyListHeader text={header ? intl.formatMessage({ id: header }) : ''} />
            <TypographyListDescription text={subtitle ? intl.formatMessage({ id: subtitle }) : ''} />
        </Grid>
    );
};

HeaderComponent.propTypes = {
    header: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
};

export default HeaderComponent;

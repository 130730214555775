import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-global-service/api/v1';

export async function getAdditionalParams() {
    try {
        const response = await instance.get(`${uri}/params`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export const addAdditionalParams = async (values) => {
    try {
        const response = await instance.post(`${uri}/params`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getAdditionalParams, addAdditionalParams };

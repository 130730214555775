import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { dependentsApi, relationApi } from 'api';
import MainCard from 'ui-component/cards/MainCard';
import { RotateLeftRounded, SaveRounded, Add, Refresh, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import EmployeeDependentsList from './EmployeeDependentsList';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import SearchEmployee from 'views/employee/SearchEmployee';
import SelectEmployeeDependents from './SelectEmployeeDependents';
import CustomMultiDeleteDialog from './CustomMultiDeleteDialog';

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    }),
    firstName: yup.string().required('firstName'),
    middleName: yup.string().required('middleName'),
    birthDate: yup.string().required('birthDate'),
    birthPlace: yup.string().required('birthPlace'),
    relationId: yup.string().required('relationId'),
    gender: yup.string().required('gender')
});

const initialValues = {
    id: '',
    employee: null,
    dependent: {
        id: '',
        firstName: '',
        middleName: '',
        lastName: '',
        birthDate: new Date(),
        birthPlace: '',
        gender: '',
        relation: '',
        relationId: ''
    }
};

const EmployeeDependents = () => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const [yourState, setYourState] = useState(initialValues);
    const [dataDependents, setDataDependents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataRelation, setDataRelation] = useState([]);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [actionRow, setActionRow] = useState('');
    const [selected, setSelected] = useState([]);
    const [dataSelected, setDataSelected] = useState({ key: '', data: [] });
    const [openDelete, setOpenDelete] = useState(false);

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'No',
            align: 'center'
        },
        {
            id: 'firstName',
            numeric: true,
            label: 'firstName',
            align: 'left'
        },
        {
            id: 'birthDate',
            numeric: true,
            label: 'birthDate',
            align: 'right'
        },
        {
            id: 'birthPlace',
            numeric: true,
            label: 'birthPlace',
            align: 'left'
        },
        {
            id: 'gender',
            numeric: true,
            label: 'gender',
            align: 'left'
        },
        {
            id: 'relation',
            numeric: true,
            label: 'relation',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'center'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataDependents.filter((row, index) => index !== indexOf);
        setDataDependents(arrayCopy);
    };

    const getRelation = async () => {
        await relationApi
            .getRelation()
            .then((res) => {
                if (res.success) {
                    const result =
                        res.data.length > 0
                            ? res.data.map((row) => {
                                  return {
                                      id: row.id,
                                      code: row.code,
                                      designation: row.designation
                                  };
                              })
                            : [];
                    setDataRelation(result);
                    setLoading(false);
                } else {
                    setDataRelation([]);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false);
            });
    };

    const refleshTable = () => {
        getRelation();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { id: '', employee: yourState.employee, ...yourState.dependent },
        validationSchema,
        onSubmit: (values) => {
            if (actionRow === 'update') {
                const selectedIndex = dataSelected.data.findIndex((object) => {
                    return object.firstName === values.firstName && object.middleName === values.middleName;
                });
                let newDependents = [];
                if (selectedIndex >= 0) {
                    snackAlert('elementExist', 'warning');
                    return;
                }

                newDependents = newDependents.concat(dataSelected.data, [
                    {
                        id: values.id,
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName,
                        birthDate: values.birthDate,
                        birthPlace: values.birthPlace,
                        gender: values.gender,
                        relation: values.relation,
                        delete: false
                    }
                ]);
                setDataSelected({ ...dataSelected, data: newDependents });
            } else {
                const selectedIndex = dataDependents.findIndex((object) => {
                    return object.firstName === values.firstName && object.middleName === values.middleName;
                });
                let newDependents = [];
                if (selectedIndex >= 0) {
                    snackAlert('elementExist', 'warning');
                    return;
                }

                newDependents = newDependents.concat(dataDependents, [
                    {
                        id: values.id,
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName,
                        birthDate: values.birthDate,
                        birthPlace: values.birthPlace,
                        gender: values.gender,
                        relation: values.relation,
                        delete: false
                    }
                ]);
                setDataDependents(newDependents);
            }
        }
    });

    const handleClickOpenCreateOrEditDialog = (row, indexOf, action) => {
        setActionRow(action);
        if (action === 'add') {
            const arrayCopy = dataDependents.filter((row, index) => index !== indexOf);
            setDataDependents(arrayCopy);
        } else {
            const arrayCopy = dataSelected.data.filter((row, index) => index !== indexOf);
            setDataSelected({ ...dataSelected, data: arrayCopy });
        }
        const rowDepend = {
            id: row.id,
            firstName: row.firstName,
            middleName: row.middleName,
            lastName: row.lastName,
            birthDate: new Date(row.birthDate),
            birthPlace: row.birthPlace,
            gender: row.gender,
            relation: row.relation,
            relationId: row.relation?.id
        };
        setYourState({ ...yourState, dependent: rowDepend });
    };

    const dataAllDependents = (data) => {
        const result = [];
        data.forEach((row) => {
            result.push({
                id: row.id,
                firstName: row.firstName,
                middleName: row.middleName,
                lastName: row.lastName,
                birthDate: row.birthDate,
                birthPlace: row.birthPlace,
                gender: row.gender,
                dependentStatus: row.dependentStatus,
                relation: {
                    id: row.relation?.id,
                    code: row.relation?.code,
                    designation: row.relation?.designation
                },
                delete: false
            });
        });
        setDataDependents(result);
    };

    useEffect(() => {
        isMounted.current = true;
        if (currentEmployee !== null) {
            setYourState({ ...yourState, employee: currentEmployee });
            dataAllDependents(currentEmployee.dependents);
            isMounted.current = false;
        }
        if (isMounted.current) {
            setLoading(true);
            refleshTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const getSubmitDependents = async (action) => {
        const values = {
            idEmployee: formik.values.employee?.id
        };
        if (action === 'add') {
            const valuesDependents =
                selected.length > 0
                    ? selected.map((row) => {
                          return {
                              firstName: row.firstName,
                              middleName: row.middleName,
                              lastName: row.lastName,
                              birthDate: row.birthDate,
                              birthPlace: row.birthPlace,
                              gender: row.gender,
                              relationId: row.relation?.id
                          };
                      })
                    : [];
            if (yourState.employee !== null && valuesDependents.length > 0) {
                setLoading(true);
                await dependentsApi
                    .addDependents(values, valuesDependents)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            dataAllDependents(res.data);
                            setSelected([]);
                            setDataSelected({ key: '', data: [] });
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                snackAlert('Check item', 'warning');
            }
        } else {
            const valuesDependents =
                dataSelected.data.length > 0
                    ? dataSelected.data.map((row) => {
                          return {
                              dependentId: row.id,
                              dependentRequestDTO: {
                                  firstName: row.firstName,
                                  middleName: row.middleName,
                                  lastName: row.lastName,
                                  birthDate: row.birthDate,
                                  birthPlace: row.birthPlace,
                                  gender: row.gender,
                                  relationId: row.relation?.id
                              }
                          };
                      })
                    : [];
            setLoading(true);
            await dependentsApi
                .updateDependents(values, valuesDependents)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        dataAllDependents(res.data);
                        setSelected([]);
                        setDataSelected({ key: '', data: [] });
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    const reinitForm = () => {
        formik.resetForm();
        setYourState(initialValues);
        setDataDependents([]);
        setSelected([]);
        setDataSelected({ key: '', data: [] });
        setActionRow('');
    };

    const cleanMemory = () => {
        setSelected([]);
        setDataSelected({ key: '', data: [] });
    };

    const HandleOpenDeleteDialog = () => {
        const dependents = [];
        selected.forEach((row) => dependents.push(row.id));
        const valuesDelete = {
            dependentsIds: dependents
        };
        const deleteDialog = openDelete ? (
            <CustomMultiDeleteDialog
                handleClose={() => setOpenDelete(false)}
                open={openDelete}
                text="Dependents"
                values={valuesDelete}
                api={dependentsApi.deleteDependents}
                snackAlert={snackAlert}
                refleshTable={refleshTable}
                selected={selected}
                dataDependents={dataDependents}
                setDataDependents={setDataDependents}
                cleanMemory={cleanMemory}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            {HandleOpenDeleteDialog()}
            <Grid container spacing={2}>
                <HeaderComponent header="employee-dependents" subtitle="view-your-employee-dependents" />
                <Grid item xs={12} lg={12}>
                    <SpinnerLoader open={loading} />
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                <ButtonComponent
                                    text={<FormattedMessage id="refresh" />}
                                    size="small"
                                    variant="contained"
                                    startIcon={<Refresh />}
                                    handleClick={() => {
                                        refleshTable();
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <MainCard sx={{ mt: 1, mb: 1 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="doucleClick employee"
                                        id="employee"
                                        name="employee"
                                        value={`${formik.values.employee?.code || ''} || ${formik.values.employee?.firstName || ''} ${
                                            formik.values.employee?.middleName || ''
                                        } ${formik.values.employee?.lastName || ''}`}
                                        handleChange={() => {}}
                                        onDoubleClick={() => setOpenEmpl(true)}
                                        error={formik.touched.employee && Boolean(formik.errors.employee)}
                                        helperText={formik.touched.employee && formik.errors.employee}
                                        placeholder="employee"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="firstName"
                                        id="firstName"
                                        name="firstName"
                                        value={formik.values?.firstName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        placeholder="firstName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="middleName"
                                        id="middleName"
                                        name="middleName"
                                        value={formik.values?.middleName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                        helperText={formik.touched.middleName && formik.errors.middleName}
                                        placeholder="middleName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="lastName"
                                        id="lastName"
                                        name="lastName"
                                        value={formik.values?.lastName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        placeholder="lastName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DatePickerComponent
                                        size="small"
                                        fullWidth
                                        label="birthDate"
                                        id="birthDate"
                                        name="birthDate"
                                        value={formik.values.birthDate || ''}
                                        handleChange={(value) =>
                                            formik.setFieldValue('birthDate', value !== 'Invalid Date' ? new Date(value) : '')
                                        }
                                        error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                                        helperText={formik.touched.birthDate && formik.errors.birthDate}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="birthPlace"
                                        id="birthPlace"
                                        name="birthPlace"
                                        value={formik.values?.birthPlace || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.birthPlace && Boolean(formik.errors.birthPlace)}
                                        helperText={formik.touched.birthPlace && formik.errors.birthPlace}
                                        placeholder="birthPlace"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        select
                                        size="small"
                                        fullWidth
                                        label="select gender"
                                        name="gender"
                                        value={formik.values.gender || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.gender && formik.errors.gender}
                                    >
                                        <MenuItem value="MALE">Male</MenuItem>
                                        <MenuItem value="FEMALE">Female</MenuItem>
                                    </InputField>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        select
                                        size="small"
                                        fullWidth
                                        label="select relation"
                                        id="relationId"
                                        name="relationId"
                                        value={formik.values?.relationId || ''}
                                        handleChange={(e, param) => {
                                            formik.setFieldValue('relationId', e.target.value);
                                            formik.setFieldValue('relation', param.props.option);
                                        }}
                                        error={formik.touched.relation && Boolean(formik.errors.relation)}
                                        helperText={formik.touched.relation && formik.errors.relation}
                                    >
                                        <MenuItem value="">
                                            <em>none</em>
                                        </MenuItem>
                                        {dataRelation?.map((option, key) => (
                                            <MenuItem key={key} value={option.id} option={option}>
                                                {option.code} || {option.designation}
                                            </MenuItem>
                                        ))}
                                    </InputField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <ButtonComponent
                                            variant="outlined"
                                            type="submit"
                                            size="small"
                                            color="secondary"
                                            startIcon={<Add />}
                                            text="Add"
                                            handleClick={() => {}}
                                        />
                                        <Typography variant="h4">
                                            Only select dependent with <span style={{ color: 'red' }}>&quot;new&quot;</span> as status
                                            before <span style={{ color: 'blue' }}>&quot;save&quot;</span> action
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container spacing={gridSpacing} sx={{ mt: gridSpacing }}>
                            {dataSelected.key === 'check' && (
                                <Grid item xs={12}>
                                    <SelectEmployeeDependents
                                        actions
                                        dataSelected={dataSelected}
                                        headCells={headCells}
                                        editFunction={handleClickOpenCreateOrEditDialog}
                                    />
                                </Grid>
                            )}
                            {dataSelected.data.length > 0 && (
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                        <Grid item xs={4}>
                                            <ButtonComponent
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                startIcon={<RotateLeftRounded />}
                                                text={<FormattedMessage id="reset" />}
                                                handleClick={() => {
                                                    setSelected([]);
                                                    setDataSelected({ key: '', data: [] });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ButtonComponent
                                                fullWidth
                                                variant="contained"
                                                type="submit"
                                                size="small"
                                                text={<FormattedMessage id="edit" />}
                                                startIcon={<Edit />}
                                                handleClick={() => getSubmitDependents('edit')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <EmployeeDependentsList
                                    actions
                                    dataDependents={dataDependents}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                    editFunction={handleClickOpenCreateOrEditDialog}
                                    selected={selected}
                                    setSelected={setSelected}
                                    setDataSelected={setDataSelected}
                                    setOpenDelete={setOpenDelete}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            startIcon={<RotateLeftRounded />}
                                            text={<FormattedMessage id="reset" />}
                                            handleClick={reinitForm}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            text={<FormattedMessage id="save" />}
                                            startIcon={<SaveRounded />}
                                            handleClick={() => getSubmitDependents('add')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default EmployeeDependents;

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TypographyListHeader = ({ text, ...props }) => {
    return (
        <Typography variant="h3" {...props}>
            {text}
        </Typography>
    );
};

TypographyListHeader.propTypes = {
    text: PropTypes.string,
    props: PropTypes.object
};

export default TypographyListHeader;

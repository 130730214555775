import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-service/api/v1';

const getTypeFee = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/fee-types?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeFee = async (values) => {
    try {
        const response = await instance.post(`${uri}/fee-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeFee = async (values) => {
    try {
        const response = await instance.put(`${uri}/fee-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeFee = async (id) => {
    try {
        const response = await instance.delete(`${uri}/fee-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getSettingTypeFee = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/system-settings/fee-types?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addSettingTypeFee = async (values) => {
    try {
        const response = await instance.post(`${uri}/system-settings/fee-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSettingTypeFee = async (values) => {
    try {
        const response = await instance.put(`${uri}/system-settings/fee-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSettingTypeFee = async (id) => {
    try {
        const response = await instance.delete(`${uri}/system-settings/fee-types/${id}/remove`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getTypeFee,
    addTypeFee,
    updateTypeFee,
    deleteTypeFee,
    getSettingTypeFee,
    addSettingTypeFee,
    updateSettingTypeFee,
    deleteSettingTypeFee
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import SearchEmployee from 'views/employee/SearchEmployee';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    }),
    emissionDate: yup.date().required('emissionDate'),
    expirationDate: yup.date().required('expirationDate'),
    deliveryCountry: yup.string().required('deliveryCountry'),
    numeroVisa: yup.string().required('numeroVisa'),
    idTypeVisa: yup.string().required('typeVisa')
});

const AddEmployeeVisa = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    dataTypeVisa,
    snackAlert
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesVisa = {
                id: values.id,
                emissionDate: values.emissionDate,
                expirationDate: values.expirationDate,
                deliveryCountry: values.deliveryCountry,
                numeroVisa: values.numeroVisa,
                idTypeVisa: values.idTypeVisa,
                idEmployee: values.employee.id
            };
            setLoading(true);
            if (values.id === '') {
                api.addEmployeeVisa(valuesVisa)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            setYourState(initialValues);
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateEmployeeVisa(valuesVisa)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    useEffect(() => {
        if (currentEmployee !== null) setYourState({ ...yourState, employee: currentEmployee });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Employee visa</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="employee"
                                    id="employee"
                                    name="employee"
                                    value={`${`${formik.values.employee?.code || ''} `} || ${formik.values.employee?.firstName || ''} ${
                                        formik.values.employee?.middleName || ''
                                    } ${formik.values.employee?.lastName || ''}`}
                                    handleChange={() => {}}
                                    onDoubleClick={() => setOpenEmpl(true)}
                                    error={formik.touched.employee && Boolean(formik.errors.employee)}
                                    helperText={formik.touched.employee && formik.errors.employee}
                                    placeholder="employee"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    select
                                    size="small"
                                    fullWidth
                                    label="select idTypeVisa"
                                    name="idTypeVisa"
                                    value={formik.values.idTypeVisa || ''}
                                    handleChange={(e, params) => {
                                        formik.setFieldValue('idTypeVisa', e.target.value);
                                        formik.setFieldValue('typeVisa', params.props.option);
                                    }}
                                    error={formik.touched.idTypeVisa && Boolean(formik.errors.idTypeVisa)}
                                    helperText={formik.touched.idTypeVisa && formik.errors.idTypeVisa}
                                >
                                    <MenuItem value="">
                                        <em>none</em>
                                    </MenuItem>
                                    {dataTypeVisa?.map((option, index) => (
                                        <MenuItem key={index} value={option.id} option={option}>
                                            {option.code} || {option.designation}
                                        </MenuItem>
                                    ))}
                                </InputField>
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="numeroVisa"
                                    id="numeroVisa"
                                    name="numeroVisa"
                                    value={formik.values?.numeroVisa || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.numeroVisa && Boolean(formik.errors.numeroVisa)}
                                    helperText={formik.touched.numeroVisa && formik.errors.numeroVisa}
                                    placeholder="numeroVisa"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="emissionDate"
                                    id="emissionDate"
                                    name="emissionDate"
                                    value={formik.values.emissionDate || ''}
                                    handleChange={(value) => {
                                        formik.setFieldValue('expirationDate', '');
                                        formik.setFieldValue('emissionDate', value);
                                    }}
                                    error={formik.touched.emissionDate && Boolean(formik.errors.emissionDate)}
                                    helperText={formik.touched.emissionDate && formik.errors.emissionDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerComponent
                                    size="small"
                                    fullWidth
                                    label="expirationDate"
                                    id="expirationDate"
                                    name="expirationDate"
                                    minDate={formik.values.emissionDate || ''}
                                    value={formik.values.expirationDate || ''}
                                    handleChange={(value) => formik.setFieldValue('expirationDate', value)}
                                    error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                                    helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="deliveryCountry"
                                    id="deliveryCountry"
                                    name="deliveryCountry"
                                    value={formik.values?.deliveryCountry || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.deliveryCountry && Boolean(formik.errors.deliveryCountry)}
                                    helperText={formik.touched.deliveryCountry && formik.errors.deliveryCountry}
                                    placeholder="deliveryCountry"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                            startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

AddEmployeeVisa.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    dataTypeVisa: PropTypes?.array,
    snackAlert: PropTypes?.func.isRequired
};

export default AddEmployeeVisa;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { measureApi, typeSanctionApi } from 'api';
import TableMeasure from './TableMeasure';
import AddMeasure from './AddMeasure';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import MeasureApprouveDialog from './MeasureApprouveDialog';

const initialValues = {
    id: '',
    employee: null,
    startDate: new Date(),
    endDate: new Date(),
    durationInMonths: '',
    retainedPercentage: '',
    baseSalary: '',
    retainedAmount: '',
    retentionMonths: [],
    reason: '',
    reasonSanctionAppealing: '',
    sanctionTypeId: '',
    sanctionType: null,
    sanctionAppealings: []
};

const dataRetentionMonths = [
    { id: 1, name: 'Janvier' },
    { id: 2, name: 'FEBRUARY' }
];

const DisciplinaryMeasure = ({ ...props }) => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataMeasure, setDataMeasure] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState({ delete: false, approuve: false });
    const [dataTypeSanction, setDataTypeSanction] = useState([]);

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'left'
        },
        {
            id: 'employee',
            numeric: true,
            label: 'employee',
            align: 'left'
        },
        {
            id: 'sanctionDate',
            numeric: true,
            label: 'sanctionDate',
            align: 'right'
        },
        {
            id: 'startDate',
            numeric: true,
            label: 'startDate',
            align: 'right'
        },
        {
            id: 'endDate',
            numeric: true,
            label: 'endDate',
            align: 'right'
        },
        {
            id: 'retainedPercentage',
            numeric: true,
            label: 'retainedPercentage',
            align: 'right'
        },
        {
            id: 'retainedAmount',
            numeric: true,
            label: 'retainedAmount',
            align: 'right'
        },
        {
            id: 'reason',
            numeric: true,
            label: 'reason',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'center'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const setState = (row) => {
        const result = {
            id: row.id,
            appealingDate: new Date(row.appealingDate),
            sanctionAppealingStatus: row.sanctionAppealingStatus,
            reason: row.reason
        };
        const dataAppealings = yourState.sanctionAppealings;
        dataAppealings.push(result);
        setYourState({ ...yourState, sanctionAppealings: dataAppealings });
    };

    const getTypeSanction = async () => {
        setLoading(true);
        await typeSanctionApi
            .getTypeSanction()
            .then((res) => {
                if (res.success) {
                    const result =
                        res.data.length > 0
                            ? res.data.map((row) => {
                                  return {
                                      id: row.id,
                                      code: row.code,
                                      designation: row.designation
                                  };
                              })
                            : [];
                    setDataTypeSanction(result);
                } else {
                    setDataTypeSanction([]);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const refleshTable = async (params) => {
        setLoading(true);
        getTypeSanction();
        await measureApi
            .getMeasure(params)
            .then((res) => {
                if (res.success) {
                    const result = [];
                    console.log(res.data);
                    res.data.data.content.forEach((row) => {
                        result.push({
                            id: row.id,
                            employee: row.employee,
                            baseSalary: row.employee?.baseSalary,
                            sanctionDate: new Date(row.sanctionDate),
                            startDate: new Date(row.startDate),
                            endDate: new Date(row.endDate),
                            durationInMonths: row.durationInMonths,
                            retainedPercentage: row.retainedPercentage,
                            retainedAmount: row.retainedAmount,
                            retentionMonths: row.retentionMonths.map((el) => {
                                return {
                                    id: el.ordinalMonth,
                                    name: el.actualMonth
                                };
                            }),
                            disciplinaryMeasureStatus: row.disciplinaryMeasureStatus,
                            reason: row.reason,
                            sanctionTypeId: row.sanctionType.id,
                            sanctionType: row.sanctionType,
                            sanctionAppealings: row.sanctionAppealings
                                ? row.sanctionAppealings.map((el) => {
                                      return {
                                          id: el.id,
                                          appealingDate: new Date(el.appealingDate),
                                          reason: el.reason,
                                          sanctionAppealingStatus: el.sanctionAppealingStatus
                                      };
                                  })
                                : []
                        });
                    });
                    setDataMeasure({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: res.data.data?.totalPages || 0,
                        totalItems: res.data.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataMeasure.rows.filter((row) => {
                let matches = true;

                const properties = ['employee', 'reason'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataMeasure({ ...dataMeasure, rows: newRows });
        } else {
            setDataMeasure({ ...dataMeasure, rows: dataMeasure.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddMeasure
                api={measureApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
                dataRetentionMonths={dataRetentionMonths}
                dataTypeSanction={dataTypeSanction}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const handleClickOpenApprouveDialog = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, approuve: true });
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete({ ...openDelete, delete: true });
    };

    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete.delete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete({ ...openDelete, delete: false });
                }}
                open={openDelete.delete}
                text="Are you sure you want to delete this measure ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={measureApi.deleteMeasure}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const HandleOpenApprouveDialog = () => {
        const approuveDialog = openDelete.approuve ? (
            <MeasureApprouveDialog
                api={measureApi}
                snackAlert={snackAlert}
                params={params}
                open={openDelete}
                setOpen={setOpenDelete}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                setState={setState}
            />
        ) : (
            ''
        );
        return approuveDialog;
    };

    return (
        <>
            {HandleOpenApprouveDialog()}
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            <Grid container spacing={gridSpacing}>
                <HeaderComponent header="disciplinary-measure-list" subtitle="view-your-disciplinary-measure" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataMeasure({ ...dataMeasure, rows: dataMeasure.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableMeasure
                                actions
                                params={params}
                                dataMeasure={dataMeasure.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                approuveFunction={handleClickOpenApprouveDialog}
                                setCurrentMeasure={props.setCurrentMeasure}
                                open={props.open}
                                setOpen={props.setOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

DisciplinaryMeasure.propTypes = {
    setCurrentMeasure: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default DisciplinaryMeasure;

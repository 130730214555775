import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeTypesPersonnels(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/personal-types`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}
async function getListeTypesPersonnelsFiltres(searchVal, paginationDetails) {
    return instance
        .get(`${baseURL}/personal-types/designationOrCode/${searchVal}`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveTypePersonnel = async (typePersonnel) => {
    return instance.post(`${baseURL}/personal-types`, typePersonnel).then(HandleResponse).catch(HandleError);
};

const updateTypePersonnel = async (typePersonnel) => {
    return instance.put(`${baseURL}/personal-types/${typePersonnel.id}`, typePersonnel).then(HandleResponse).catch(HandleError);
};

const deleteTypePersonnel = async (id) => {
    return instance.delete(`${baseURL}/personal-types/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeTypesPersonnels,
    getListeTypesPersonnelsFiltres,
    saveTypePersonnel,
    updateTypePersonnel,
    deleteTypePersonnel
};

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeEchelons(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/echelons`, {
            params: {
                codeDesignation: filterValue || null,
                number: paginationDetails ? paginationDetails.page : null,
                size: paginationDetails ? paginationDetails.size : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}
async function getListeEchelonsFiltres(searchVal, paginationDetails) {
    return instance
        .get(`${baseURL}/echelons/code-designation/${searchVal}`, {
            params: {
                number: paginationDetails ? paginationDetails.number : null,
                size: paginationDetails ? paginationDetails.size : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveEchelon = async (gradeInferieur) => {
    return instance.post(`${baseURL}/echelons`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const updateEchelon = async (gradeInferieur) => {
    return instance.put(`${baseURL}/echelons/${gradeInferieur.id}`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const deleteEchelon = async (id) => {
    return instance.delete(`${baseURL}/echelons/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeEchelons,
    saveEchelon,
    updateEchelon,
    deleteEchelon,
    getListeEchelonsFiltres
};

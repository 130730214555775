import { useEffect, useState } from 'react';
import { Grid, MenuItem, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { trainingApi } from 'api';
import MainCard from 'ui-component/cards/MainCard';
import { RotateLeftRounded, SaveRounded, Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import TrainingParticipantList from './TrainingParticipantList';
import SearchTraining from '../training/SearchTraining';
import TrainingTab from './TrainingTab';
import ResponseTrainingParticipant from './ResponseTrainingParticipant';
import FloatingCart from 'ui-component/extended/FloatingCart';

const validationSchema = yup.object({
    firstName: yup.string().required('firstName'),
    middleName: yup.string().required('middleName'),
    lastName: yup.string().required('lastName'),
    email: yup.string().email().required('email'),
    residencePlace: yup.string().required('residencePlace'),
    gender: yup.string().required('gender')
});

const initialValues = {
    training: {
        id: '',
        subject: '',
        maxExpectedParticipants: '',
        venue: '',
        additionalNotes: ''
    },
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    residencePlace: '',
    gender: ''
};

const TrainingParticipant = () => {
    const dispatch = useDispatch();
    const [yourState, setYourState] = useState(initialValues);
    const [dataParticipants, setDataParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openTrain, setOpenTrain] = useState(false);
    const [currentTraining, setCurrentTraining] = useState(null);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'numero',
            align: 'left'
        },
        {
            id: 'firstName',
            numeric: true,
            label: 'firstName',
            align: 'left'
        },
        {
            id: 'email',
            numeric: true,
            label: 'email',
            align: 'left'
        },
        {
            id: 'residencePlace',
            numeric: true,
            label: 'residencePlace',
            align: 'left'
        },
        {
            id: 'gender',
            numeric: true,
            label: 'gender',
            align: 'left'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataParticipants.filter((row, index) => index !== indexOf);
        setDataParticipants(arrayCopy);
    };

    const handleClickOpenCreateOrEditDialog = (row, indexOf) => {
        const arrayCopy = dataParticipants.filter((row, index) => index !== indexOf);
        setDataParticipants(arrayCopy);
        setYourState({
            ...yourState,
            firstName: row.firstName,
            middleName: row.middleName,
            lastName: row.lastName,
            email: row.email,
            residencePlace: row.residencePlace,
            gender: row.gender
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const selectedIndex = dataParticipants.findIndex((object) => {
                return object.firstName === values.firstName && object.middleName === values.middleName;
            });
            let newParticipants = [];
            if (selectedIndex >= 0) {
                snackAlert('elementExist', 'warning');
                return;
            }

            newParticipants = newParticipants.concat(dataParticipants, [
                {
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    email: values.email,
                    residencePlace: values.residencePlace,
                    gender: values.gender
                }
            ]);
            setDataParticipants(newParticipants);
        }
    });

    useEffect(() => {
        if (currentTraining !== null) {
            setYourState({ ...yourState, ...formik.values, training: currentTraining });
            setDataParticipants([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTraining]);

    const getSubmitParticipants = async () => {
        const values = {
            trainingId: formik.values.training.id
        };
        const valuesParticipants = [];
        dataParticipants.forEach((row) => {
            valuesParticipants.push({
                ...row,
                trainingId: formik.values.training.id
            });
        });
        if (values.trainingId !== '' && valuesParticipants.length > 0) {
            setLoading(true);
            await trainingApi
                .addParticipants(valuesParticipants)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        setDataResponse({
                            open: false,
                            success: res.data?.success.data ? res.data?.success.data : [],
                            failures: res.data?.failures.data ? res.data?.failures.data : []
                        });
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setDataResponse({
                            open: false,
                            success: res.data?.success.data ? res.data?.success.data : [],
                            failures: res.data?.failures.data ? res.data?.failures.data : []
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        } else {
            snackAlert('addParticipants', 'warning');
        }
    };

    const HandleOpenDialogTraining = () => {
        const openDialog = openTrain ? (
            <SearchTraining open={openTrain} setOpen={setOpenTrain} setCurrentTraining={setCurrentTraining} />
        ) : (
            ''
        );
        return openDialog;
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? (
            <ResponseTrainingParticipant dataResponse={dataResponse} setDataResponse={setDataResponse} />
        ) : (
            ''
        );
        return openDialog;
    };

    const reinitForm = () => {
        formik.resetForm();
        setYourState(initialValues);
        setDataParticipants([]);
        setCurrentTraining(null);
        setDataResponse({ open: false, success: [], failures: [] });
    };

    return (
        <>
            {HandleOpenDialogTraining()}
            {HandleOpenDialogResponse()}
            <Grid container spacing={2}>
                <HeaderComponent header="training-participant" subtitle="view-your-training-participant" />
                <Grid item xs={12} lg={12}>
                    <SpinnerLoader open={loading} />
                    <MainCard sx={{ mt: 1, mb: 1 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="doucleClick training"
                                        id="training"
                                        name="training"
                                        value={formik.values.training.subject || ''}
                                        handleChange={() => {}}
                                        onDoubleClick={() => setOpenTrain(true)}
                                        error={formik.touched.training && Boolean(formik.errors.training)}
                                        helperText={formik.touched.training && formik.errors.training}
                                        placeholder="training"
                                    />
                                </Grid>
                                {formik.values.training.id > 0 && (
                                    <Grid item xs={12}>
                                        <TrainingTab dataTraining={[formik.values.training]} />
                                    </Grid>
                                )}
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="firstName"
                                        id="firstName"
                                        name="firstName"
                                        value={formik.values?.firstName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        placeholder="firstName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="middleName"
                                        id="middleName"
                                        name="middleName"
                                        value={formik.values?.middleName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                        helperText={formik.touched.middleName && formik.errors.middleName}
                                        placeholder="middleName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="lastName"
                                        id="lastName"
                                        name="lastName"
                                        value={formik.values?.lastName || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        placeholder="lastName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        type="email"
                                        label="email"
                                        id="email"
                                        name="email"
                                        value={formik.values?.email || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        placeholder="email"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        size="small"
                                        fullWidth
                                        label="residencePlace"
                                        id="residencePlace"
                                        name="residencePlace"
                                        value={formik.values?.residencePlace || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.residencePlace && Boolean(formik.errors.residencePlace)}
                                        helperText={formik.touched.residencePlace && formik.errors.residencePlace}
                                        placeholder="residencePlace"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputField
                                        select
                                        size="small"
                                        fullWidth
                                        label="select gender"
                                        name="gender"
                                        value={formik.values.gender || ''}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.gender && formik.errors.gender}
                                    >
                                        <MenuItem value="MALE">Male</MenuItem>
                                        <MenuItem value="FEMALE">Female</MenuItem>
                                    </InputField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <ButtonComponent
                                            variant="outlined"
                                            type="submit"
                                            size="small"
                                            color="secondary"
                                            startIcon={<Add />}
                                            text="Add"
                                            handleClick={() => {}}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container spacing={gridSpacing} sx={{ mt: gridSpacing }}>
                            <Grid item xs={12}>
                                <TrainingParticipantList
                                    actions
                                    dataParticipants={dataParticipants}
                                    headCells={headCells}
                                    deleteFunction={deleteFunction}
                                    editFunction={handleClickOpenCreateOrEditDialog}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            startIcon={<RotateLeftRounded />}
                                            text={<FormattedMessage id="reset" />}
                                            handleClick={reinitForm}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonComponent
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            text={<FormattedMessage id="save" />}
                                            startIcon={<SaveRounded />}
                                            handleClick={getSubmitParticipants}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Grid>
        </>
    );
};

export default TrainingParticipant;

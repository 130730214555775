import { memo } from 'react';

// material-ui
import {
    Grid,
    IconButton,
    Table,
    TableContainer,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    TextField,
    TableBody,
    Typography
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import InputField from 'ui-component/InputField';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';
import { Delete } from '@mui/icons-material';
import MainCard from 'ui-component/cards/MainCard';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SelectComponent from 'ui-component/SelectComponent';
import FormattageNombre from 'ui-component/FormattageNombre';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const CreationOffreHeader = ({
    handleChange,
    lettreToEdit,
    handleChangeOpeningDate,
    handleChangeClosingDate,
    errors,
    handleChangeBeneficts,
    removeBeneficts,
    handleChangeCompetences,
    removeCompetences,
    removeLangue,
    handleChangeSpokenLlangue,
    handleChangeSpokenLfluency,
    listeNiveaux,
    handleChangeSpokenLmention,
    handleChangeNombreRecherche,
    essaiIntroduction,
    handleChangeComment,
    descr,
    handleChangeDescription
}) => {
    const intl = useIntl();
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={2} lg={2}>
                        <InputField
                            label={intl.formatMessage({ id: 'position' })}
                            name="position"
                            handleChange={handleChange}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            value={lettreToEdit.position}
                            error={errors.position}
                            helperText={errors.position}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <SelectComponent
                            options={[
                                {
                                    label: intl.formatMessage({ id: 'surSite' }),
                                    value: 'ONSITE'
                                },
                                {
                                    label: intl.formatMessage({ id: 'enLigne' }),
                                    value: 'REMOTE'
                                }
                            ]}
                            handleChange={handleChange}
                            label={intl.formatMessage({ id: 'workType' })}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            name="workType"
                            value={lettreToEdit.workType}
                            error={errors.workType}
                            helperText={errors.workType}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <FormattageNombre
                            label={intl.formatMessage({ id: 'obl-wantedWorkforce' })}
                            name="wantedWorkforce"
                            allowNegative={false}
                            value={lettreToEdit.wantedWorkforce}
                            handleChange={handleChangeNombreRecherche}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 2
                            }}
                            error={errors.wantedWorkforce}
                            helperText={errors.wantedWorkforce}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <DatePickerComponent
                            label={intl.formatMessage({ id: 'openingDate' })}
                            name="openingDate"
                            minDate={new Date()}
                            horizontal={false}
                            handleChange={handleChangeOpeningDate}
                            error={errors.openingDate}
                            helperText={errors.openingDate}
                            value={lettreToEdit.openingDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <DatePickerComponent
                            label={intl.formatMessage({ id: 'closingDate' })}
                            name="closingDate"
                            minDate={lettreToEdit.openingDate}
                            horizontal={false}
                            handleChange={handleChangeClosingDate}
                            error={errors.closingDate}
                            helperText={errors.closingDate}
                            value={lettreToEdit.closingDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                marginTop: 3,
                                marginBottom: 3
                            }}
                        >
                            <Typography
                                style={{
                                    marginRight: 10,
                                    fontWeight: '',
                                    color: 'black'
                                }}
                            >
                                <FormattedMessage id="introduction" />
                            </Typography>

                            <ReactQuill name="essaiIntroduction" value={essaiIntroduction} onChange={handleChangeComment} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                marginTop: 3,
                                marginBottom: 3
                            }}
                        >
                            <Typography
                                style={{
                                    marginRight: 10,
                                    fontWeight: '',
                                    color: 'black'
                                }}
                            >
                                <FormattedMessage id="description" />
                            </Typography>

                            <ReactQuill name="descr" value={descr} onChange={handleChangeDescription} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard content={false}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <FormattedMessage id="benefices" />
                                            </StyledTableCell>
                                            <StyledTableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lettreToEdit.benefits?.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell sx={{ width: '98%' }}>
                                                        <TextField
                                                            name="benefits"
                                                            size="small"
                                                            fullWidth
                                                            onChange={(e) => {
                                                                handleChangeBeneficts(e.target.value, index);
                                                            }}
                                                            autoComplete="off"
                                                            value={row}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                if (lettreToEdit.benefits.length > 1) {
                                                                    removeBeneficts(index);
                                                                }
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: 'red'
                                                                }
                                                            }}
                                                        >
                                                            {index === lettreToEdit.benefits.length - 1 && <Delete fontSize="small" />}
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard content={false}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <FormattedMessage id="skills" />
                                            </StyledTableCell>
                                            <StyledTableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lettreToEdit.requiredSkills?.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell sx={{ width: '98%' }}>
                                                        <TextField
                                                            name="requiredSkills"
                                                            size="small"
                                                            fullWidth
                                                            onChange={(e) => {
                                                                handleChangeCompetences(e.target.value, index);
                                                            }}
                                                            autoComplete="off"
                                                            value={row}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                if (lettreToEdit.requiredSkills.length > 1) {
                                                                    removeCompetences(index);
                                                                }
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: 'red'
                                                                }
                                                            }}
                                                        >
                                                            {index === lettreToEdit.requiredSkills.length - 1 && (
                                                                <Delete fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard content={false}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <FormattedMessage id="langues" />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <FormattedMessage id="niveau" />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <FormattedMessage id="statut" />
                                            </StyledTableCell>
                                            <StyledTableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lettreToEdit?.spokenLanguages.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell sx={{ width: '32%' }}>
                                                    <TextField
                                                        name="language"
                                                        size="small"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            handleChangeSpokenLlangue(e.target.value, index);
                                                        }}
                                                        autoComplete="off"
                                                        value={row.language}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ width: '33%' }}>
                                                    <SelectComponent
                                                        options={listeNiveaux?.fluency?.map((ln) => ({
                                                            label: `${ln.key} | ${ln.value}`,
                                                            value: ln.value
                                                        }))}
                                                        handleChange={(e) => {
                                                            handleChangeSpokenLfluency(e.target.value, index);
                                                        }}
                                                        name="fluency"
                                                        size="small"
                                                        fullWidth
                                                        value={row.fluency}
                                                        autoComplete="off"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ width: '33%' }}>
                                                    <SelectComponent
                                                        options={listeNiveaux?.mentions?.map((ln) => ({
                                                            label: `${ln.key} | ${ln.value}`,
                                                            value: ln.value
                                                        }))}
                                                        handleChange={(e) => {
                                                            handleChangeSpokenLmention(e.target.value, index);
                                                        }}
                                                        name="mention"
                                                        size="small"
                                                        fullWidth
                                                        value={row.mention}
                                                        autoComplete="off"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            if (lettreToEdit.spokenLanguages.length > 1) {
                                                                removeLangue(index);
                                                            }
                                                        }}
                                                        sx={{
                                                            ':hover': {
                                                                color: 'red'
                                                            }
                                                        }}
                                                    >
                                                        {index === lettreToEdit.spokenLanguages.length - 1 && <Delete fontSize="small" />}
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
CreationOffreHeader.propTypes = {
    handleChange: PropTypes.func.isRequired,
    lettreToEdit: PropTypes.object.isRequired,
    handleChangeOpeningDate: PropTypes.func.isRequired,
    handleChangeClosingDate: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    handleChangeBeneficts: PropTypes.func.isRequired,
    handleChangeCompetences: PropTypes.func.isRequired,
    removeBeneficts: PropTypes.func.isRequired,
    removeCompetences: PropTypes.func.isRequired,
    removeLangue: PropTypes.func.isRequired,
    handleChangeSpokenLlangue: PropTypes.func.isRequired,
    handleChangeSpokenLfluency: PropTypes.func.isRequired,
    handleChangeSpokenLmention: PropTypes.func.isRequired,
    handleChangeNombreRecherche: PropTypes.func.isRequired,
    listeNiveaux: PropTypes.object.isRequired,
    essaiIntroduction: PropTypes.string.isRequired,
    handleChangeComment: PropTypes.func.isRequired,
    descr: PropTypes.string.isRequired,
    handleChangeDescription: PropTypes.func.isRequired
};

export default memo(CreationOffreHeader);

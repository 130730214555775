import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getCriteria() {
    try {
        const response = await instance.get(`${uri}/criteria`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addCriteria = async (values) => {
    try {
        const response = await instance.post(`${uri}/criteria`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateCriteria = async (values) => {
    try {
        const response = await instance.put(`${uri}/criteria/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteCriteria = async (id) => {
    try {
        const response = await instance.delete(`${uri}/criteria/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getElementEvaluation = async (employee) => {
    try {
        const response = await instance.get(`${uri}/criteria/evaluation-elements/${employee.id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getCriteria, addCriteria, updateCriteria, deleteCriteria, getElementEvaluation };

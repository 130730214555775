import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Stack,
    TableSortLabel
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { Delete, AddCircleOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/system';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import InputField from 'ui-component/InputField';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';
import TimePickerComponent from 'ui-component/TimePickerComponent';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, theme }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    Action
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const Actions = ({ row, index, deleteFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const HeureSupplementaireList = ({
    actions,
    dataHeureSup,
    setDataHeureSup,
    loading,
    headCells,
    deleteFunction,
    initState,
    setInitState,
    staticLenght,
    ...tableProps
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;

    const validateForm = (touchedValid) => {
        return touchedValid.startHour && touchedValid.endHour && touchedValid.rate;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;

        switch (fieldName) {
            case 'startHour':
                fieldValidationTouched.startHour = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.startHour = fieldValidationTouched.startHour ? '' : ' startHour is required';
                break;
            case 'endHour':
                fieldValidationTouched.endHour = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.endHour = fieldValidationTouched.endHour ? '' : ' endHour is required';
                break;
            case 'rate':
                fieldValidationTouched.rate = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.rate = fieldValidationTouched.rate ? '' : ' rate is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target.name;
        const value = e?.target.value;
        validateField(name, value);
    };

    const getFormattedTime = (date) => {
        const hour = `0${date.getHours()}`.slice(-2);
        const min = `0${date.getMinutes()}`.slice(-2);
        const seg = `0${date.getSeconds()}`.slice(-2);
        return `${hour}:${min}:${seg}`;
    };

    const handleSubmitHeureSup = (event) => {
        event.preventDefault();
        const selectedIndex = dataHeureSup.findIndex((object) => {
            return object.startHour === initState.startHour && object.endHour === initState.endHour;
        });
        let newHeureSup = [];
        if (selectedIndex >= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'elementExist',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
            return;
        }
        newHeureSup = newHeureSup.concat(dataHeureSup, [
            {
                startHour: getFormattedTime(initState.startHour),
                endHour: getFormattedTime(initState.endHour),
                rate: initState.rate
            }
        ]);
        setDataHeureSup(newHeureSup);
        setInitState({
            startHour: '',
            endHour: '',
            rate: '',
            formErrors: { startHour: '', endHour: '', rate: '' },
            touched: { startHour: false, endHour: false, rate: false },
            formValid: false
        });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <MainCard content={false}>
            {loading ? (
                <EarningCard />
            ) : (
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            theme={theme}
                        />

                        {dataHeureSup && (
                            <TableBody>
                                <StyledTableRow hover tabIndex={-1} data-testid="rowItems">
                                    <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                        >
                                            ###
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TimePickerComponent
                                            size="small"
                                            fullWidth
                                            label=""
                                            id="startHour"
                                            name="startHour"
                                            value={initState.startHour || ''}
                                            handleChange={(event) => validateField('startHour', event)}
                                            error={!initState.touched.startHour && Boolean(initState.formErrors.startHour)}
                                            helperText={initState.formErrors.startHour && initState.formErrors.startHour}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TimePickerComponent
                                            size="small"
                                            fullWidth
                                            label=""
                                            id="endHour"
                                            name="endHour"
                                            value={initState.endHour || ''}
                                            handleChange={(event) => validateField('endHour', event)}
                                            error={!initState.touched.endHour && Boolean(initState.formErrors.endHour)}
                                            helperText={initState.formErrors.endHour && initState.formErrors.endHour}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="rate"
                                            name="rate"
                                            value={initState.rate || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.rate && Boolean(initState.formErrors.rate)}
                                            helperText={initState.formErrors.rate && initState.formErrors.rate}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
                                            <Tooltip title="Ajouter" arrow placement="left">
                                                <IconButton
                                                    component="span"
                                                    size="medium"
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.success.main
                                                        }
                                                    }}
                                                    disabled={!initState.formValid}
                                                    onClick={handleSubmitHeureSup}
                                                >
                                                    <AddCircleOutlined fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {stableSort(dataHeureSup, getComparator(order, orderBy))?.map((row, index) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <StyledTableRow hover tabIndex={-1} key={index} data-testid="rowItems">
                                            <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.startHour} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.endHour} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.rate} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <Actions row={row} index={index} deleteFunction={deleteFunction} />
                                        </StyledTableRow>
                                    );
                                })}
                                {dataHeureSup.length <= 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="no-data" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            )}
        </MainCard>
    );
};

HeureSupplementaireList.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object,
    dataHeureSup: PropTypes?.array.isRequired,
    setDataHeureSup: PropTypes?.func.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool,
    staticLenght: PropTypes?.number,
    deleteFunction: PropTypes?.func.isRequired,
    initState: PropTypes?.object.isRequired,
    setInitState: PropTypes?.func.isRequired
};

export default HeureSupplementaireList;

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import TrainingTypeList from 'views/hr/training-management/settings/training-type';
import TrainingList from 'views/hr/training-management/training';
import TrainingParticipant from 'views/hr/training-management/training-participant';
import OutingList from 'views/hr/leave-management/employee-outing';
import OutingTreatmentList from 'views/hr/leave-management/employee-outing/treatment';
import Trainer from 'views/hr/training-management/trainer';
import TypeVisa from 'views/settings/hr-service/type-visa';
import HeureSupplementaire from 'views/settings/heure-supplementaire';
import TypeSanction from 'views/settings/hr-service/type-sanction';
import EmployeeVisa from 'views/file-employee/employee-visa';
import EmployeeDependents from 'views/file-employee/employee-dependents';
import Holidays from 'views/settings/hr-service/holidays';
import CompanyList from 'views/company';
import Absences from 'views/hr/absence';
import DocumentList from 'views/settings/document';
import EmployeeList from 'views/employee';
import Relation from 'views/settings/hr-service/relation';
import HomePage from 'views/home-page';
import HistoriqueList from 'views/historique';
import BankList from 'views/settings/bank';
import AdditionalParams from 'views/settings/additional-params';
import Direction from 'views/settings/hr-service/hierarchy/direction';
import DirectionGenerale from 'views/settings/hr-service/hierarchy/direction-generale';
import Service from 'views/settings/hr-service/hierarchy/service';
import TypeContrat from 'views/settings/hr-service/type-contract';
import LeavePlanning from 'views/leave/leave-planning';
import NiveauFormation from 'views/hr/training-management/niveauFormation/Index';
import TypePersonnel from 'views/settings/hr-service/typePersonnel/Index';
import CategoriePersonnel from 'views/settings/hr-service/categoriePersonnel/Index';
import GradeInferieur from 'views/pages/fichiers-de-base/gradeInferieur/Index';
import GradePersonnel from 'views/pages/fichiers-de-base/grade/Index';
import TypeConge from 'views/hr/leave-management/typeConge/Index';
import SorteConge from 'views/hr/leave-management/sorteConge/Index';
import BaremeONPR from 'views/settings/payrall-service/baremeOnpr/Index';
import BaremeIPR from 'views/settings/payrall-service/baremeIPR/Index';
import DureeConge from 'views/hr/leave-management/dureeConge/Index';
import AttendanceList from 'views/attendance';
import EmployeeContract from 'views/file-employee/employee-contract';
import DisciplinaryMeasure from 'views/hr/sanction-management/disciplinary-measure';
import SanctionAppealing from 'views/hr/sanction-management/sanction-appealing';
import SanctionDecision from 'views/hr/sanction-management/sanction-decision';
import PaysOperationnel from 'views/pages/fichiers-de-base/pays/Index';
import DemandeConge from 'views/hr/leave-management/demandeConge/Index';
import TypeRating from 'views/settings/hr-service/type-rating';
import TypeAppreciation from 'views/settings/hr-service/type-appreciation';
import Evaluation from 'views/hr/evaluation/evaluation';
import Criteria from 'views/hr/evaluation/criteria';
import EvaluationVisualisation from 'views/hr/evaluation/visualisation';
import LeavePlan from 'views/hr/leave-management/planLeaves/index';
import Echelon from 'views/hr/rating-and-advancement-management/rungs/index';
import ParamAddEvaluation from 'views/settings/evaluationSystemSettings/index';
import ParametrageConge from 'views/hr/leave-management/parametrage-conge/index';
import TraitementConge from 'views/hr/leave-management/traitementConge/Index';
import DemandeSortie from 'views/hr/gestionSortie/demandeSortie/index';
import TraitementSortie from 'views/hr/gestionSortie/traitementSortie/index';
import SortePosition from 'views/hr/position-management/sortePosition';
import TypePosition from 'views/hr/position-management/typePosition';
import ParamPosition from 'views/hr/position-management/paramPosition';
import CreationOffre from 'views/hr/recruitement-management/jobs-posting/CreationOffre';
import TypeCredit from 'views/payrall-service/type-credit';
import CreationCandidature from 'views/hr/recruitement-management/jobSubmissions';
import GradeAdvancement from 'views/hr/rating-and-advancement-management/gradeAdvancements';
import SocialOrganization from 'views/payrall-service/social-organization';
import SettingTypeFee from 'views/payrall-service/settings/setting-type-fee';
import TypeFee from 'views/payrall-service/type-fee';
import SettingTypeCredit from 'views/payrall-service/settings/setting-type-credit';
import Error from 'views/pages/maintenance/Error';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '*',
            element: <Error />
        },
        {
            path: '/employee-list',
            element: <EmployeeList />
        },
        {
            path: '/employee-visa',
            element: <EmployeeVisa />
        },
        {
            path: '/employee-dependents',
            element: <EmployeeDependents />
        },
        {
            path: '/employee-contract',
            element: <EmployeeContract />
        },
        {
            path: '/coordonnees-entreprise',
            element: <CompanyList />
        },
        {
            path: '/historique',
            element: <HistoriqueList />
        },
        {
            path: '/attendance',
            element: <AttendanceList />
        },
        {
            path: '/countries-list',
            element: <PaysOperationnel />
        },
        {
            path: '/settings/bank',
            element: <BankList />
        },
        {
            path: '/settings/additional-params',
            element: <AdditionalParams />
        },
        {
            path: '/settings/document',
            element: <DocumentList />
        },
        {
            path: '/settings/heure-sup',
            element: <HeureSupplementaire />
        },
        {
            path: '/training-level',
            element: <NiveauFormation />
        },
        {
            path: '/type-training',
            element: <TrainingTypeList />
        },
        {
            path: '/trainer',
            element: <Trainer />
        },
        {
            path: '/training',
            element: <TrainingList />
        },
        {
            path: '/training-participant',
            element: <TrainingParticipant />
        },
        {
            path: '/disciplinary-measure',
            element: <DisciplinaryMeasure />
        },
        {
            path: '/sanction-appealing',
            element: <SanctionAppealing />
        },
        {
            path: '/sanction-decision',
            element: <SanctionDecision />
        },
        {
            path: '/employee-outing',
            element: <OutingList />
        },
        {
            path: '/employee-outing-treatment',
            element: <OutingTreatmentList />
        },
        {
            path: '/settings/type-visa',
            element: <TypeVisa />
        },
        {
            path: '/settings/type-rating',
            element: <TypeRating />
        },
        {
            path: '/settings/type-appreciation',
            element: <TypeAppreciation />
        },
        {
            path: '/settings/type-sanction',
            element: <TypeSanction />
        },
        {
            path: '/settings/holidays',
            element: <Holidays />
        },
        {
            path: '/absences',
            element: <Absences />
        },
        {
            path: '/settings/relation',
            element: <Relation />
        },
        {
            path: '/settings/type-contract',
            element: <TypeContrat />
        },
        {
            path: '/evaluation',
            element: <Evaluation />
        },
        {
            path: '/evaluation/criteria',
            element: <Criteria />
        },
        {
            path: '/evaluation/visualisation',
            element: <EvaluationVisualisation />
        },
        {
            path: '/hierarchy/direction',
            element: <Direction />
        },
        {
            path: '/hierarchy/direction-gen',
            element: <DirectionGenerale />
        },
        {
            path: '/hierarchy/service',
            element: <Service />
        },
        {
            path: '/employee-types-list',
            element: <TypePersonnel />
        },
        {
            path: '/settings/categories-list',
            element: <CategoriePersonnel />
        },
        {
            path: '/lower-rank-list',
            element: <GradeInferieur />
        },
        {
            path: '/leave/leave-planning',
            element: <LeavePlanning />
        },
        {
            path: '/ranks-list',
            element: <GradePersonnel />
        },
        {
            path: '/leave-management/leave-types',
            element: <TypeConge />
        },
        {
            path: '/leave-management/leaves-kind',
            element: <SorteConge />
        },
        {
            path: '/settings/bareme-onpr',
            element: <BaremeONPR />
        },
        {
            path: '/settings/bareme-ipr',
            element: <BaremeIPR />
        },
        {
            path: '/settings/type-credit',
            element: <SettingTypeCredit />
        },
        {
            path: '/type-credit',
            element: <TypeCredit />
        },
        {
            path: '/settings/type-fee',
            element: <SettingTypeFee />
        },
        {
            path: '/type-fee',
            element: <TypeFee />
        },
        {
            path: '/settings/social-organization',
            element: <SocialOrganization />
        },
        {
            path: '/leave-management/leave-duration',
            element: <DureeConge />
        },
        {
            path: '/leave-management/leave-ask',
            element: <DemandeConge />
        },
        {
            path: '/leave-management/leave-plan',
            element: <LeavePlan />
        },
        {
            path: '/rating-advancement-management/rungs',
            element: <Echelon />
        },
        {
            path: '/settings/evaluation-system-settings',
            element: <ParamAddEvaluation />
        },
        {
            path: '/leave-management/leave-params',
            element: <ParametrageConge />
        },
        {
            path: '/leave-management/leave-traetments',
            element: <TraitementConge />
        },
        {
            path: '/gestion-sortie/leaves-ask',
            element: <DemandeSortie />
        },
        {
            path: '/gestion-sortie/leaves-traetment',
            element: <TraitementSortie />
        },
        {
            path: '/position-management/kind-position',
            element: <SortePosition />
        },
        {
            path: '/position-management/type-position',
            element: <TypePosition />
        },
        {
            path: '/position-management/param-position',
            element: <ParamPosition />
        },
        {
            path: '/recruitment-management/job-posting',
            element: <CreationOffre />
        },
        {
            path: '/recruitment-management/job-submissions',
            element: <CreationCandidature />
        },
        {
            path: '/rating-advancement-management/grade-advancements',
            element: <GradeAdvancement />
        }
    ]
};

export default MainRoutes;

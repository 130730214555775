import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';

const OffreToPrintTable = ({ data }) => {
    const intl = useIntl();
    const FormatDate = (date) => {
        const intl = useIntl();
        return date
            .toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(/,/, '');
    };

    return (
        <>
            <table className="printTable" style={{ border: 'none' }} width="100%">
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'general' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'recrutor-comp' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'titre-offre' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'pays-offre' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'type-offre' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'nombre-offre' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'openingDate' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data.openingDate ? FormatDate(new Date(data.openingDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'closingDate' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data.closingDate ? FormatDate(new Date(data.closingDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'date-debut-travail'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data.closingDate ? FormatDate(new Date(data.closingDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'date-publication-offre'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data.closingDate ? FormatDate(new Date(data.closingDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'duree-offre' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>
                                    {data.closingDate ? FormatDate(new Date(data.closingDate)) : ''}
                                </Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'details' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'missions-entreprise'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.position ? data?.position : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'context-recrutement'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.introduction ? data?.introduction : ''}</Typography>
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'description-taches'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                <Typography sx={{ marginRight: 5 }}>{data?.description ? data?.description : ''}</Typography>
                            </Stack>
                        </td>
                        {/* {data.map((niv, index) => (
                                            
                                        ))} */}
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {`${intl.formatMessage({ id: 'details-obligatoires' })} `}
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                id: 'exigence-skills'
                            })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                {data?.requiredSkills?.map((niv) => (
                                    <Typography sx={{ marginRight: 5 }}>{`${niv} |`}</Typography>
                                ))}
                            </Stack>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                        <td style={{ border: 'none' }} width="38%">
                            <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'langues' })} `}</Typography>
                        </td>
                        <td style={{ border: 'none' }} width="60%">
                            <Stack direction="row" justifyContent="space-between">
                                <div>: </div>
                                {/* <Typography sx={{ marginRight: 5 }}>{data?.description ? data?.description : ''}</Typography> */}
                                {data?.spokenLanguages?.map((niv) => (
                                    <Typography sx={{ marginRight: 5 }}>{`${niv} |`}</Typography>
                                ))}
                            </Stack>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* ========================== TO DELETE HERE ===================== */}
        </>
    );
};
OffreToPrintTable.propTypes = {
    data: PropTypes.object.isRequired
};
export default OffreToPrintTable;

import { forwardRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import PropTypes from 'prop-types';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomMultiDeleteDialog = ({
    text,
    handleClose,
    open,
    values,
    api,
    snackAlert,
    params,
    refleshTable,
    selected,
    dataDependents,
    setDataDependents,
    cleanMemory
}) => {
    const [loading, setLoading] = useState(false);

    const dataAllDependents = () => {
        const resultSelected = [];
        selected.forEach((row) => {
            resultSelected.push({
                ...row,
                delete: true
            });
        });
        const result = dataDependents.map((obj) => resultSelected.find((o) => o.id === obj.id) || obj);
        const arrayCopy = result.filter((row) => row.delete !== true);
        setDataDependents(arrayCopy);
    };

    const handleClick = () => {
        setLoading(true);
        api(values)
            .then((res) => {
                if (res.success) {
                    setLoading(false);
                    snackAlert('Operation reussie', 'success');
                    dataAllDependents();
                    handleClose();
                    cleanMemory();
                    refleshTable(params);
                } else {
                    setLoading(false);
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                }
            })
            .catch((error) => {
                setLoading(false);
                snackAlert(`${error.response?.data.message ? error.response.data.message : error}`, 'error');
            });
    };
    return (
        <>
            <SpinnerLoader open={loading} />
            <Dialog keepMounted open={loading ? false : open} maxWidth="md" TransitionComponent={Transition}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <FormattedMessage id="delete-alert" /> {text} ?
                </DialogTitle>
                <DialogContent> </DialogContent>
                <DialogActions>
                    <Button size="small" color="error" variant="contained" onClick={handleClick} disabled={loading}>
                        <FormattedMessage id="delete" />
                    </Button>
                    <Button variant="contained" size="small" onClick={() => handleClose()}>
                        <FormattedMessage id="cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CustomMultiDeleteDialog.propTypes = {
    text: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    values: PropTypes.object,
    api: PropTypes.func,
    snackAlert: PropTypes.func,
    params: PropTypes.object,
    refleshTable: PropTypes.func,
    selected: PropTypes.array,
    dataDependents: PropTypes.array,
    setDataDependents: PropTypes.func,
    cleanMemory: PropTypes.func
};

export default CustomMultiDeleteDialog;

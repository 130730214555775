// material-ui
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Stack,
    Tooltip,
    Pagination,
    Typography,
    Grid
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
// project imports
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import { useTheme } from '@mui/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box } from '@mui/system';
import { gridSpacing } from 'store/constant';
// styles
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const GradeTable = ({
    loading,
    handleClickOpenCreateOrEditDialog,
    gradeListe,
    handleClickOpenDeleteAlertDialog,
    getGradePersonnelsFiltres,
    paginate,
    handleChangeRowsPerPage,
    paginationDetails,
    handleFilterList,
    toggleReload,
    searchVal,
    handleClearSearch
}) => {
    const theme = useTheme();
    const intl = useIntl();
    if (loading) return <TotalGrowthBarChart />;

    return (
        <Grid container spacing={gridSpacing}>
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', sm: 'auto' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: '0.4rem'
                    }}
                >
                    <ListSearchComponent
                        style={{ height: '100%' }}
                        handleChange={handleFilterList}
                        handleClear={handleClearSearch}
                        filterValue={searchVal}
                        filter={handleFilterList}
                    />
                    <ButtonComponent
                        text={intl.formatMessage({ id: 'search' })}
                        handleClick={getGradePersonnelsFiltres}
                        variant="outlined"
                        sx={{
                            '&:hover': {
                                color: 'white',
                                backgroundColor: theme.palette.primary.main
                            },
                            height: '100%',
                            width: { xs: '100%', sm: 'auto' }
                        }}
                        style={{ marginTop: 2 }}
                    />
                </Box>
                <Stack
                    gap="10px"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    width={{ xs: '100%', sm: 'auto' }}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <ButtonComponent
                        text={intl.formatMessage({ id: 'refresh' })}
                        handleClick={() => toggleReload()}
                        size="small"
                        startIcon={<ReplayIcon />}
                        variant="contained"
                    />
                    <ButtonComponent
                        startIcon={<Add />}
                        text={intl.formatMessage({ id: 'new-grade' })}
                        handleClick={() => handleClickOpenCreateOrEditDialog()}
                        variant="contained"
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <TotalGrowthBarChart />
                ) : (
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <FormattedMessage id="categorie" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="code" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="designation" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormattedMessage id="salaire" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormattedMessage id="actions" />
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gradeListe?.content?.length !== 0 &&
                                    gradeListe?.content?.map((row, index) => (
                                        <StyledTableRow
                                            key={index}
                                            sx={{
                                                ':hover td': {
                                                    cursor: 'pointer',
                                                    backgroundColor: theme.palette.table.hover,
                                                    color: 'black'
                                                }
                                            }}
                                        >
                                            <StyledTableCell>
                                                {row.personalCategoryId ? row.personalCategoryId?.designation : ''}
                                            </StyledTableCell>
                                            <StyledTableCell>{row.code ? row.code : ''}</StyledTableCell>
                                            <StyledTableCell>{row.designation ? row.designation : ''}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.salary ? row.salary.toLocaleString(intl.locale) : ''}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Stack direction="row" justifyContent="end">
                                                    <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                                                        <IconButton
                                                            component="span"
                                                            style={{
                                                                padding: 4,
                                                                margin: 0
                                                            }}
                                                            size="smalll"
                                                            onClick={() => handleClickOpenDeleteAlertDialog(row)}
                                                            sx={{
                                                                ':hover': {
                                                                    color: theme.palette.error.main
                                                                }
                                                            }}
                                                        >
                                                            <Delete fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={<FormattedMessage id="edit" />} arrow placement="right">
                                                        <IconButton
                                                            component="span"
                                                            style={{
                                                                padding: 4,
                                                                margin: 0
                                                            }}
                                                            size="smalll"
                                                            onClick={() => {
                                                                handleClickOpenCreateOrEditDialog(row);
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: theme.palette.primary.dark
                                                                }
                                                            }}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                {gradeListe?.content?.length === 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={5}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="noData" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        gap: { xs: '0.04rem', sm: 0 },
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center',
                        padding: '10px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <TypographyListDescription text={intl.formatMessage({ id: 'display' })} />{' '}
                        <select onChange={handleChangeRowsPerPage} value={paginationDetails?.pageSize}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                        </select>
                        <TypographyListDescription text={intl.formatMessage({ id: 'record' })} />
                    </div>
                    <Pagination
                        count={gradeListe?.totalPages}
                        color="primary"
                        size="small"
                        onChange={paginate}
                        shape="rounded"
                        page={paginationDetails?.pageNumber}
                    />
                    <TypographyListDescription
                        text={`${gradeListe?.totalElements} ${intl.formatMessage({
                            id: 'record'
                        })}`}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
GradeTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    handleClickOpenCreateOrEditDialog: PropTypes.func.isRequired,
    gradeListe: PropTypes.object.isRequired,
    handleClickOpenDeleteAlertDialog: PropTypes.func.isRequired,
    getGradePersonnelsFiltres: PropTypes.func.isRequired,
    searchVal: PropTypes.string.isRequired,
    paginate: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    paginationDetails: PropTypes.object.isRequired,
    toggleReload: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
    handleFilterList: PropTypes.func.isRequired
};
export default GradeTable;

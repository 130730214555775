import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Pagination,
    Box,
    LinearProgress,
    Typography,
    Button
} from '@mui/material';
// project imports
import TypographyListDescription from 'ui-component/TypographyListDescription';
import SelectComponent from 'ui-component/SelectComponent';
import SubCard from 'ui-component/cards/SubCard';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const LinearProgressWithLabel = (props) => {
    const { value } = props;
    let color;
    if (value <= 25) {
        color = 'inherit';
    } else if (value <= 50 && value > 25) {
        color = 'warning';
    } else if (value <= 75 && value > 50) {
        color = 'secondary';
    } else {
        color = 'success';
    }
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={3}>
                <LinearProgress variant="determinate" color={color} {...props} sx={{ height: '7px', color: 'red' }} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
};

LinearProgressWithLabel.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const EvaluationList = ({
    params,
    dataEvaluation,
    handleChangePage,
    handleChangeRowsPerPage,
    ordinalSuffixOf,
    appreciationFunction,
    ...tableProps
}) => {
    const theme = useTheme();

    return (
        <SubCard
            content={false}
            title={`${ordinalSuffixOf(dataEvaluation.nextEvaluation)} Evaluation`}
            secondary={<Button onClick={() => appreciationFunction(dataEvaluation)}>Appreciation</Button>}
        >
            <>
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">fullName</StyledTableCell>
                                <StyledTableCell align="center">maximumMarks</StyledTableCell>
                                <StyledTableCell align="center">obtainedMarks</StyledTableCell>
                                <StyledTableCell align="center">percentage</StyledTableCell>
                                <StyledTableCell align="center">ratingType</StyledTableCell>
                                <StyledTableCell align="center">appreciationType</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell align="center">{dataEvaluation.fullName}</StyledTableCell>
                                <StyledTableCell align="center">{dataEvaluation.maximumMarks}</StyledTableCell>
                                <StyledTableCell align="center">{dataEvaluation.obtainedMarks}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <LinearProgressWithLabel value={dataEvaluation.percentage} />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant="h4">{dataEvaluation.ratingType}</Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant="h4">{dataEvaluation.appreciationType}</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" rowSpan="2">
                                    Critères
                                </StyledTableCell>
                                <StyledTableCell align="center" rowSpan="2">
                                    Rubriques
                                </StyledTableCell>
                                <StyledTableCell align="center" colSpan="2">
                                    Appréciation
                                </StyledTableCell>
                                <StyledTableCell align="center" rowSpan="2">
                                    Pourcentage
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="center">Note obtenu</StyledTableCell>
                                <StyledTableCell align="center">Note de référence</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataEvaluation.evaluationElements.length > 0 &&
                                dataEvaluation.evaluationElements.map((row, index) => {
                                    const firstAssessmentCrit = row.assessmentCriteria.length > 0 && row.assessmentCriteria[0];
                                    const otherAssessmentCrit = row.assessmentCriteria.filter((ass) => ass.id !== firstAssessmentCrit.id);
                                    return (
                                        <>
                                            <TableRow key={index}>
                                                <StyledTableCell rowSpan={row.assessmentCriteria.length}>
                                                    {row.criteriaName}
                                                </StyledTableCell>
                                                <StyledTableCell>{firstAssessmentCrit?.assessmentName}</StyledTableCell>
                                                <StyledTableCell>
                                                    {firstAssessmentCrit.obtainedMarks}/{firstAssessmentCrit.maximumMarks}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{firstAssessmentCrit.maximumMarks}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <LinearProgressWithLabel value={firstAssessmentCrit.percentage} />
                                                </StyledTableCell>
                                            </TableRow>
                                            {otherAssessmentCrit.map((ass, indexOf) => {
                                                return (
                                                    <TableRow key={indexOf}>
                                                        <StyledTableCell>{ass.assessmentName}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {ass.obtainedMarks}/{ass.maximumMarks}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">{ass.maximumMarks}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <LinearProgressWithLabel value={ass.percentage} />
                                                        </StyledTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            {dataEvaluation.evaluationElements && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: -1,
                            alignItems: 'center'
                        }}
                    >
                        <TypographyListDescription text={` Display`} />
                        <SelectComponent
                            options={[
                                { label: 5, value: 5 },
                                { label: 10, value: 10 },
                                { label: 25, value: 25 }
                            ]}
                            style={{ width: 70 }}
                            defaultValue={params.rowsPerPage}
                            handleChange={handleChangeRowsPerPage}
                            value={params.rowsPerPage}
                        />
                        <TypographyListDescription text={` Recordings`} />
                    </div>
                    <Pagination
                        count={params.length}
                        color="primary"
                        size="small"
                        onChange={handleChangePage}
                        shape="rounded"
                        page={params.page + 1}
                    />
                    <TypographyListDescription text={`${params.totalItems} recordings`} />
                </div>
            )}
        </SubCard>
    );
};

EvaluationList.propTypes = {
    params: PropTypes?.object.isRequired,
    dataEvaluation: PropTypes?.object.isRequired,
    handleChangePage: PropTypes?.func.isRequired,
    handleChangeRowsPerPage: PropTypes?.func.isRequired,
    ordinalSuffixOf: PropTypes?.func.isRequired,
    appreciationFunction: PropTypes?.func.isRequired
};

export default EvaluationList;

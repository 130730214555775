import PropTypes from 'prop-types';
import { ButtonBase, Grid, IconButton, Paper, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PictureAsPdf } from '@mui/icons-material';

const FileUploadList = ({ file, removeFile, getViewFile }) => {
    return (
        <Paper
            sx={{
                p: 1,
                margin: 'auto',
                maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff')
            }}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase onClick={() => getViewFile(file)}>
                        <PictureAsPdf />
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {file?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" title="Remove" onClick={() => removeFile()}>
                            <DeleteForeverIcon color="error" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
FileUploadList.propTypes = {
    file: PropTypes.object.isRequired,
    removeFile: PropTypes.func,
    getViewFile: PropTypes.func
};

export default FileUploadList;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getTrainingType = async (params) => {
    try {
        const response = await instance.get(
            `${uri}/training-types?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTrainingType = async (values) => {
    try {
        const response = await instance.post(`${uri}/training-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTrainingType = async (values) => {
    try {
        const response = await instance.put(`${uri}/training-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTrainingType = async (id) => {
    try {
        const response = await instance.delete(`${uri}/training-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const markTrainingType = async (id) => {
    try {
        const response = await instance.patch(`${uri}/training-types/${id}/available`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTrainingType, addTrainingType, updateTrainingType, deleteTrainingType, markTrainingType };

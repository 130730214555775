import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    reasonDecision: yup.string().required('reasonDecision'),
    decisionStatus: yup.string().required('decisionStatus')
});

const SanctionDecisionDialog = ({ api, snackAlert, params, open, setOpen, yourState, refleshTable }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { id: yourState.id, reasonDecision: yourState.reasonDecision, decisionStatus: yourState.decisionStatus },
        validationSchema,
        onSubmit: (values) => {
            const valuesSanctionDecision = {
                sanctionAppealingId: values.id,
                reason: values.reasonDecision,
                decisionMakerId: 1,
                decisionStatus: values.decisionStatus
            };
            setLoading(true);
            api.addSanctionDecision(valuesSanctionDecision)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        formik.resetForm();
                        setDataResponse({ open: false, success: [], failures: [] });
                        refleshTable(params);
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setDataResponse({
                            open: false,
                            success: [],
                            failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    });

    const reinitForm = () => {
        setDataResponse({ open: false, success: [], failures: [] });
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen({ ...open, approuve: false });
        reinitForm();
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="sm" open={open.approuve} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Sanction Decision</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reasonDecision"
                                    id="reasonDecision"
                                    name="reasonDecision"
                                    value={formik.values?.reasonDecision || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reasonDecision && Boolean(formik.errors.reasonDecision)}
                                    helperText={formik.touched.reasonDecision && formik.errors.reasonDecision}
                                    placeholder="reasonDecision"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    select
                                    size="small"
                                    fullWidth
                                    label="decisionStatus"
                                    id="decisionStatus"
                                    name="decisionStatus"
                                    value={formik.values?.decisionStatus || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.decisionStatus && Boolean(formik.errors.decisionStatus)}
                                    helperText={formik.touched.decisionStatus && formik.errors.decisionStatus}
                                >
                                    <MenuItem value="APPROVED">APPROVED</MenuItem>
                                    <MenuItem value="DENIED">DENIED</MenuItem>
                                </InputField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={<FormattedMessage id="save" />}
                            startIcon={<SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

SanctionDecisionDialog.propTypes = {
    api: PropTypes.object.isRequired,
    snackAlert: PropTypes.func.isRequired,
    params: PropTypes.object,
    open: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    yourState: PropTypes.object.isRequired,
    refleshTable: PropTypes.func.isRequired
};

export default SanctionDecisionDialog;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    Stepper,
    StepButton,
    Step,
    Avatar,
    Badge,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, Cancel, CloudUpload } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';

import SubCard from 'ui-component/cards/SubCard';
import SocialEmployee from './SocialEmployee';
import ModalityPaymentSalary from './ModalityPaymentSalary';
import PaieElements from './PaieElements';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import { FileUpload } from 'views/ui-elements/uploadFile';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    email: yup.string().email('emailValid').required('email')
});

const steps = ['Informations sociales de employee', 'Modalite de paiement salaire', 'Prime indemite et cotisation'];

function getStepContent(step, formik) {
    switch (step) {
        case 0:
            return <SocialEmployee formik={formik} />;
        case 1:
            return <ModalityPaymentSalary formik={formik} />;
        case 2:
            return <PaieElements />;
        default:
            throw new Error('unknownStep');
    }
}

const AddEmployee = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const dense = true;
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [openFile, setOpenFile] = useState(false);
    const [file, setFile] = useState(null);
    const [viewFile, setViewFile] = useState(yourState.profileImage);

    const handleStep = (step) => async () => {
        setActiveStep(step);
    };

    const snackAlerFieldsErrors = (messageData) => {
        snackAlert(
            <List dense={dense}>
                {messageData.map((row, index) => {
                    return (
                        <ListItem key={index}>
                            <ListItemText primary={`${row.field}, ${row.message} , ${row.rejectedValue}`} />
                        </ListItem>
                    );
                })}
            </List>,
            'warning'
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            const valuesEmployee = {
                id: values.id,
                code: values.code,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                address1: values.address1,
                address2: values.address2,
                address3: values.address3,
                numIdentity: values.numIdentity,
                deliveryDate: values.deliveryDate,
                email: values.email,
                mobilePhoneNumber: values.mobilePhoneNumber,
                landline: values.landline,
                officePhoneNumber: values.officePhoneNumber,
                idTypePersonnel: values.idTypePersonnel,
                matriculeNumber: values.matriculeNumber,
                section: values.section,
                entryDate: values.entryDate,
                leaveDate: values.leaveDate,
                birthDate: values.birthDate,
                birthPlace: values.birthPlace,
                nationality: values.nationality,
                socialFundNumber: values.socialFundNumber,
                accountingSuffix: values.accountingSuffix,
                maritalStatus: values.maritalStatus,
                gender: values.gender,
                idPayFrequency: values.idPayFrequency,
                baseSalary: values.baseSalary,
                paymentMode: values.paymentMode,
                complement: values.complement,
                camNumber: values.camNumber,
                status: values.status,
                conjoint: values.conjoint,
                personalGradeId: values.personalGradeId
            };
            console.log('valuesEmployee', valuesEmployee);
            setLoading(true);
            if (values.id === '') {
                api.addEmployee(valuesEmployee)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            console.log(res.response.data.fieldsErrors);
                            const messageData = res.response.data.fieldsErrors.details.map((row) => {
                                return {
                                    field: row.field,
                                    message: row.message,
                                    rejectedValue: row.rejectedValue
                                };
                            });
                            snackAlerFieldsErrors(messageData);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                        setLoading(false);
                    });
            } else {
                api.updateEmployee(valuesEmployee)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            }
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const onCancelFile = () => {
        setOpenFile(false);
        setViewFile(yourState.profileImage);
    };

    const handleSubmit = () => {
        if (yourState.id > 0) {
            if (viewFile !== null) {
                const formData = new FormData();
                formData.append('file', file);
                setLoading(true);
                api.uploadProfile(yourState, formData)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            console.log('res data', res.data);
                            setYourState({ ...yourState, profileImage: viewFile });
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            }
        } else {
            snackAlert('selectEmployee', 'warning');
        }
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="xl" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add Employee</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} lg={4}>
                            <Grid container direction="column" alignItems="stretch" sx={{ height: '100%' }}>
                                <Grid item>
                                    <SubCard>
                                        <Avatar
                                            alt={yourState.firstName}
                                            size="lg"
                                            src={`${viewFile}`}
                                            sx={{ bgcolor: 'grey', cursor: 'pointer', width: 300, height: 300 }}
                                        />
                                    </SubCard>
                                </Grid>
                                <Grid item>
                                    <SubCard>
                                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                            {openFile === false && (
                                                <Grid item xs={6}>
                                                    <ButtonComponent
                                                        fullWidth
                                                        variant="contained"
                                                        size="small"
                                                        text="Upload"
                                                        startIcon={<CloudUpload />}
                                                        handleClick={() => setOpenFile(true)}
                                                    />
                                                </Grid>
                                            )}
                                            {openFile === true && (
                                                <>
                                                    <Grid item xs={8} lg={8}>
                                                        <FileUpload setFile={setFile} setViewFile={setViewFile} label="Upload image" />
                                                    </Grid>
                                                    <Grid item xs={4} lg={4}>
                                                        <Badge
                                                            badgeContent={<Cancel onClick={onCancelFile} sx={{ cursor: 'pointer' }} />}
                                                            color="error"
                                                        >
                                                            <ButtonComponent
                                                                fullWidth
                                                                variant="contained"
                                                                size="small"
                                                                text={<FormattedMessage id="save" />}
                                                                startIcon={<SaveRounded />}
                                                                handleClick={handleSubmit}
                                                            />
                                                        </Badge>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </SubCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <SubCard>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={6}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="code"
                                            id="code"
                                            name="code"
                                            value={formik.values?.code || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                            helperText={formik.touched.code && formik.errors.code}
                                            placeholder="code"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="matriculeNumber"
                                            id="matriculeNumber"
                                            name="matriculeNumber"
                                            value={formik.values?.matriculeNumber || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.matriculeNumber && Boolean(formik.errors.matriculeNumber)}
                                            helperText={formik.touched.matriculeNumber && formik.errors.matriculeNumber}
                                            placeholder="matriculeNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="firstName"
                                            id="firstName"
                                            name="firstName"
                                            value={formik.values?.firstName || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                            placeholder="firstName"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="middleName"
                                            id="middleName"
                                            name="middleName"
                                            value={formik.values?.middleName || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                            helperText={formik.touched.middleName && formik.errors.middleName}
                                            placeholder="middleName"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="lastName"
                                            id="lastName"
                                            name="lastName"
                                            value={formik.values?.lastName || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                            placeholder="lastName"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="numIdentity"
                                            id="numIdentity"
                                            name="numIdentity"
                                            value={formik.values?.numIdentity || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.numIdentity && Boolean(formik.errors.numIdentity)}
                                            helperText={formik.touched.numIdentity && formik.errors.numIdentity}
                                            placeholder="numIdentity"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="email"
                                            id="email"
                                            name="email"
                                            value={formik.values?.email || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            placeholder="email"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DatePickerComponent
                                            size="small"
                                            fullWidth
                                            label="deliveryDate"
                                            id="deliveryDate"
                                            name="deliveryDate"
                                            value={formik.values.deliveryDate || ''}
                                            handleChange={(value) =>
                                                formik.setFieldValue('deliveryDate', value !== 'Invalid Date' ? new Date(value) : '')
                                            }
                                            error={formik.touched.deliveryDate && Boolean(formik.errors.deliveryDate)}
                                            helperText={formik.touched.deliveryDate && formik.errors.deliveryDate}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DatePickerComponent
                                            size="small"
                                            fullWidth
                                            label="birthDate"
                                            id="birthDate"
                                            name="birthDate"
                                            value={formik.values.birthDate || ''}
                                            handleChange={(value) =>
                                                formik.setFieldValue('birthDate', value !== 'Invalid Date' ? new Date(value) : '')
                                            }
                                            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                                            helperText={formik.touched.birthDate && formik.errors.birthDate}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="birthPlace"
                                            id="birthPlace"
                                            name="birthPlace"
                                            value={formik.values?.birthPlace || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.birthPlace && Boolean(formik.errors.birthPlace)}
                                            helperText={formik.touched.birthPlace && formik.errors.birthPlace}
                                            placeholder="birthPlace"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="address1"
                                            id="address1"
                                            name="address1"
                                            value={formik.values?.address1 || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                            helperText={formik.touched.address1 && formik.errors.address1}
                                            placeholder="address1"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="address2"
                                            id="address2"
                                            name="address2"
                                            value={formik.values?.address2 || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                            helperText={formik.touched.address2 && formik.errors.address2}
                                            placeholder="address2"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="address3"
                                            id="address3"
                                            name="address3"
                                            value={formik.values?.address3 || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.address3 && Boolean(formik.errors.address3)}
                                            helperText={formik.touched.address3 && formik.errors.address3}
                                            placeholder="address3"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="mobilePhoneNumber"
                                            id="mobilePhoneNumber"
                                            name="mobilePhoneNumber"
                                            value={formik.values?.mobilePhoneNumber || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.mobilePhoneNumber && Boolean(formik.errors.mobilePhoneNumber)}
                                            helperText={formik.touched.mobilePhoneNumber && formik.errors.mobilePhoneNumber}
                                            placeholder="mobilePhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="landline"
                                            id="landline"
                                            name="landline"
                                            value={formik.values?.landline || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.landline && Boolean(formik.errors.landline)}
                                            helperText={formik.touched.landline && formik.errors.landline}
                                            placeholder="landline"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label="officePhoneNumber"
                                            id="officePhoneNumber"
                                            name="officePhoneNumber"
                                            value={formik.values?.officePhoneNumber || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.officePhoneNumber && Boolean(formik.errors.officePhoneNumber)}
                                            helperText={formik.touched.officePhoneNumber && formik.errors.officePhoneNumber}
                                            placeholder="officePhoneNumber"
                                        />
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard>
                                <Stepper nonLinear activeStep={activeStep} sx={{ pt: 3 }}>
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={index}>
                                                <StepButton color="inherit" onClick={handleStep(index)}>
                                                    {label}
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                <Grid item xs={12}>
                                    <>{getStepContent(activeStep, formik)}</>
                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={() => {}}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddEmployee.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddEmployee;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getTrainer(params) {
    try {
        const response = await instance.get(
            `${uri}/trainers?paginated=true&page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

async function getTrainers() {
    try {
        const response = await instance.get(`${uri}/trainers?paginated=false&fetchAll=true`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

async function getTrainingLevel() {
    try {
        const response = await instance.get(`${uri}/training-levels`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addTrainer = async (values) => {
    try {
        const response = await instance.post(`${uri}/trainers`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTrainer = async (values) => {
    try {
        const response = await instance.put(`${uri}/trainers/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTrainer = async (id) => {
    try {
        const response = await instance.delete(`${uri}/trainers/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTrainer, getTrainers, addTrainer, updateTrainer, deleteTrainer, getTrainingLevel };

import { forwardRef, useRef } from 'react';
// material-ui
import { Dialog, IconButton, Slide, DialogContent, DialogTitle, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Close from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import { Print } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import MainCard from 'ui-component/cards/MainCard';
import { useReactToPrint } from 'react-to-print';
import OffreToPrint from './impression/OffreToPrint';
import OffreToPrintTable from './impression/OffreToPrintTable';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

const OffreEmploiModal = ({ open, handleClose, demandeToEdit, loading }) => {
    const theme = useTheme();
    const intl = useIntl();
    const FormatDate = (date) => {
        return date
            .toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(/,/, '');
    };
    const componentRef = useRef(null);
    const handlePrintPdf = useReactToPrint({
        content: () => {
            const tableStat = componentRef.current;
            const PrintElem = document.createElement('div');
            PrintElem.append(tableStat);
            return PrintElem;
        }
    });
    console.log('Objet', demandeToEdit);
    const title = `${intl.formatMessage({ id: 'description-offre' })}`;
    return (
        <>
            <div style={{ display: 'none' }}>
                <OffreToPrint ref={componentRef} title={title} body={<OffreToPrintTable data={demandeToEdit} />} />
            </div>

            <MainCard content>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-adresse1"
                    maxWidth="sm"
                    fullWidth
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            <TypographyListHeader text={intl.formatMessage({ id: 'description-offre' })} />
                        </DialogTitle>
                        <div>
                            <IconButton
                                disabled={loading}
                                onClick={() => {
                                    handlePrintPdf();
                                }}
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.primary.light,
                                        color: theme.palette.table.header
                                    }
                                }}
                            >
                                <Print />
                            </IconButton>
                            <IconButton
                                disabled={loading}
                                onClick={() => {
                                    handleClose();
                                }}
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.error.light,
                                        color: theme.palette.primary.light
                                    }
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    </Stack>
                    <DialogContent>
                        <table className="printTable" style={{ border: 'none' }} width="100%">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                                        <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {`${intl.formatMessage({ id: 'general' })} `}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'recrutor-comp' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.position ? demandeToEdit?.position : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'titre-offre' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.position ? demandeToEdit?.position : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'pays-offre' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.position ? demandeToEdit?.position : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'type-offre' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.workType ? demandeToEdit?.workType : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'nombre-offre' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.wantedWorkforce
                                                    ? demandeToEdit?.wantedWorkforce?.toLocaleString(intl.locale)
                                                    : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'openingDate' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit.openingDate ? FormatDate(new Date(demandeToEdit.openingDate)) : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'closingDate' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit.closingDate ? FormatDate(new Date(demandeToEdit.closingDate)) : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'date-debut-travail'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit.closingDate ? FormatDate(new Date(demandeToEdit.closingDate)) : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'date-publication-offre'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit.closingDate ? FormatDate(new Date(demandeToEdit.closingDate)) : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'duree-offre' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit.closingDate ? FormatDate(new Date(demandeToEdit.closingDate)) : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                                        <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {`${intl.formatMessage({ id: 'details' })} `}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'missions-entreprise'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.position ? demandeToEdit?.position : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'context-recrutement'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.introduction ? demandeToEdit?.introduction : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'description-taches'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                            <Typography sx={{ marginRight: 5 }}>
                                                {demandeToEdit?.description ? demandeToEdit?.description : ''}
                                            </Typography>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }} width="98%" colSpan={2}>
                                        <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {`${intl.formatMessage({ id: 'details-obligatoires' })} `}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({
                                            id: 'exigence-skills'
                                        })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%" />
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            {demandeToEdit?.requiredSkills?.map((niv) => (
                                                <Typography sx={{ marginRight: 5 }}>{`${niv} |`}</Typography>
                                            ))}
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%">
                                        <Typography sx={{ marginLeft: 5 }}>{`${intl.formatMessage({ id: 'langues' })} `}</Typography>
                                    </td>
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>: </div>
                                        </Stack>
                                    </td>
                                </tr>
                                <tr style={{ border: 'none' }}>
                                    <td style={{ border: 'none' }} width="38%" />
                                    <td style={{ border: 'none' }} width="60%">
                                        <Stack direction="row" justifyContent="space-between">
                                            {demandeToEdit?.spokenLanguages?.map((niv) => (
                                                <>
                                                    <Typography sx={{ marginRight: 5 }}>{`${niv?.language} |`}</Typography>
                                                    <Typography sx={{ marginRight: 5 }}>{`${niv?.fluency} |`}</Typography>
                                                    <Typography sx={{ marginRight: 5 }}>{`${niv?.mention} |`}</Typography>
                                                    <br />
                                                </>
                                            ))}
                                        </Stack>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                </Dialog>
            </MainCard>
        </>
    );
};
OffreEmploiModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    demandeToEdit: PropTypes?.object.isRequired,
    loading: PropTypes?.bool.isRequired
};
export default OffreEmploiModal;

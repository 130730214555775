import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { demandeCongeAPI, typeCongeAPI, planCongeAPI } from 'api';

import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import PlanLeaveTable from './PlanLeaveTable';
import PlanLeaveModal from './PlanLeaveModal';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [planListe, setPlanListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listeDirections, setListeDirections] = useState([]);
    const [listeServices, setListeServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [planToDelete, setPlanToDelete] = useState(null);
    const [planToEdit, setPlanToEdit] = useState({
        employeesLimited: 0,
        directionId: null,
        serviceId: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const planPromise = planCongeAPI.getListePlans(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const directionPromise = demandeCongeAPI.getListeDemandesConges('', 100, 0);
        const servicePromise = typeCongeAPI.getTypesConges('', 100, 0);
        axios
            .all([planPromise, directionPromise, servicePromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const plansData = responses[0]?.content;
                        const plansCongesData = [];
                        plansData.forEach((row) => {
                            plansCongesData.push({
                                id: row.id,
                                employeesLimited: row.employeesLimited,
                                directionId: row.direction
                            });
                        });
                        setPlanListe((planListe) => ({
                            ...planListe,
                            ...responses[0],
                            content: plansCongesData
                        }));
                        const typesDirections = responses[1]?.data?.content?.map((e) => ({
                            label: `${e?.startDate} | ${e?.endDate}`,
                            value: e?.id
                        }));
                        const Services = responses[2]?.content?.map((tc) => ({
                            label: `${tc.code} | ${tc.designation}`,
                            value: tc.id
                        }));
                        setListeDirections(typesDirections);
                        setListeServices(Services);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setPlanToEdit({
                ...row,
                directionId: {
                    label: `${row?.direction?.firstName} | ${row?.direction?.lastName}`,
                    value: row?.direction?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            planCongeAPI
                .getListePlans()
                .then((res) => {
                    setPlanListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setPlanToEdit({
            employeesLimited: 0,
            directionId: null,
            serviceId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (plan) => {
        setPlanToDelete(plan);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (planToDelete) => {
        if (planToDelete) {
            setPlanListe(planListe.filter((categorie) => categorie.id !== planToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getParamsPlansConges = (pageNumber, pageSize) => {
        setLoading(true);
        let demandePromise;
        if (!pageNumber && !pageSize) {
            demandePromise = demandeCongeAPI.getListeDemandesConges(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            demandePromise = demandeCongeAPI.getListeDemandesConges(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([demandePromise])
            .then(
                axios.spread((...responses) => {
                    const demandesData = responses[0].content;
                    setPlanListe({
                        ...planListe,
                        ...responses[0],
                        content: demandesData
                    });
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        planCongeAPI
            .deletePlan(planToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getParamsPlansConges();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    // const handleFilter = (e) => {
    //     if (e) {
    //         setFilterValue(e.target.value);
    //     } else {
    //         setFilterValue('');
    //     }
    // };

    // const handleClearSearch = () => {
    //     setFilterValue('');
    // };
    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getParamsPlansConges(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getParamsPlansConges(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (planToEdit.id) {
            setLoading(true);
            planCongeAPI
                .updatePlan({
                    id: planToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getParamsPlansConges();
                    setPlanToEdit({
                        employeesLimited: 0,
                        directionId: null,
                        serviceId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            planCongeAPI
                .savePlan({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setPlanToEdit({
                        employeesLimited: 0,
                        directionId: null,
                        serviceId: null
                    });
                    getParamsPlansConges();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFilterList = (e) => {
        const newString = e?.target?.value;
        setSearchVal(newString);
        setFilterValue(e?.target?.value);

        if (newString) {
            const newRows = planListe?.content?.filter((row) => {
                let matches = true;

                // const properties = ['code', 'designation'];
                const properties = ['employeesLimited'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setPlanListe({ ...planListe, content: newRows });
        } else {
            getParamsPlansConges();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'paramPlanConges' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-plan-conge' })} />
            {successFetch ? (
                <>
                    <PlanLeaveTable
                        planListe={{
                            content: planListe.content,
                            totalPages: planListe.totalPages,
                            totalElements: planListe.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        getParamsPlansConges={getParamsPlansConges}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        searchVal={searchVal}
                        handleFilterList={handleFilterList}
                    />
                    <PlanLeaveModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        planToEdit={planToEdit}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        listeDirections={listeDirections}
                        listeServices={listeServices}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'plan-conges' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;

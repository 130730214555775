import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    code: yup.string().required('code'),
    designation: yup.string().required('designation'),
    maxPercentage: yup.number().required('maxPercentage'),
    minPercentage: yup.number().required('minPercentage'),
    rate: yup.number().required('rate')
});

const AddTypeRating = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesRating = {
                id: values.id,
                code: values.code,
                designation: values.designation,
                maxPercentage: values.maxPercentage,
                minPercentage: values.minPercentage,
                rate: values.rate
            };
            setLoading(true);
            if (values.id === '') {
                api.addTypeRating(valuesRating)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateTypeRating(valuesRating)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add Type rating</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="code"
                                id="code"
                                name="code"
                                value={formik.values?.code || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                                placeholder="code"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="designation"
                                id="designation"
                                name="designation"
                                value={formik.values?.designation || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.designation && Boolean(formik.errors.designation)}
                                helperText={formik.touched.designation && formik.errors.designation}
                                placeholder="designation"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="minPercentage"
                                id="minPercentage"
                                name="minPercentage"
                                value={formik.values?.minPercentage || 0}
                                handleChange={formik.handleChange}
                                error={formik.touched.minPercentage && Boolean(formik.errors.minPercentage)}
                                helperText={formik.touched.minPercentage && formik.errors.minPercentage}
                                placeholder="minPercentage"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="maxPercentage"
                                id="maxPercentage"
                                name="maxPercentage"
                                value={formik.values?.maxPercentage || 0}
                                handleChange={formik.handleChange}
                                error={formik.touched.maxPercentage && Boolean(formik.errors.maxPercentage)}
                                helperText={formik.touched.maxPercentage && formik.errors.maxPercentage}
                                placeholder="maxPercentage"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="rate"
                                id="rate"
                                name="rate"
                                value={formik.values?.rate || 0}
                                handleChange={formik.handleChange}
                                error={formik.touched.rate && Boolean(formik.errors.rate)}
                                helperText={formik.touched.rate && formik.errors.rate}
                                placeholder="rate"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={() => {}}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddTypeRating.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddTypeRating;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const addEvaluation = async (values, valueEvaluation) => {
    try {
        const response = await instance.post(`${uri}/evaluations/${values.id}`, valueEvaluation);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const getEvaluation = async (values) => {
    try {
        const response = await instance.get(
            `${uri}/evaluations/${values.employee.id}?evaluationLevel=${values.evaluationLevel}&evaluatorId=${values.evaluatorId}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addAppreciation = async (values) => {
    try {
        const response = await instance.post(
            `${uri}/evaluation-appreciations?evaluationCode=${values.evaluationCode}&appreciationTypeId=${values.appreciationTypeId}&appreciatorId=${values.appreciatorId}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { addEvaluation, getEvaluation, addAppreciation };

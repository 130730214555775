import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getParamsLangues() {
    try {
        const response = await instance.get(`${baseURL}/spoken-languages-details`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}
async function getNiveauxLangues() {
    try {
        const response = await instance.get(`${baseURL}/spoken-languages-details/fluency`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}
async function getMentionLangues() {
    try {
        const response = await instance.get(`${baseURL}/spoken-languages-details/mentions`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export default {
    getParamsLangues,
    getNiveauxLangues,
    getMentionLangues
};

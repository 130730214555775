import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeParamAddEvaluation() {
    return instance.get(`${baseURL}/system-settings/evaluations`).then(HandleResponse).catch(HandleError);
}

const saveParamEvaluation = async (ParamEvaluation) => {
    return instance.post(`${baseURL}/system-settings/evaluations`, ParamEvaluation).then(HandleResponse).catch(HandleError);
};

const updateParamEvaluation = async (ParamEvaluation) => {
    return instance
        .put(`${baseURL}/system-settings/evaluations/${ParamEvaluation.id}`, ParamEvaluation)
        .then(HandleResponse)
        .catch(HandleError);
};

const deleteParamEvaluation = async (id) => {
    return instance.delete(`${baseURL}/system-settings/evaluations/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeParamAddEvaluation,
    saveParamEvaluation,
    updateParamEvaluation,
    deleteParamEvaluation
};

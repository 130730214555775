import PropTypes from 'prop-types';
// material-ui
import { Button, Grid, Stack } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import InputField from 'ui-component/InputField';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import AnimateButton from 'ui-component/extended/AnimateButton';
import UploadPhotoDialog from './UploadPhotoDialog';
import ButtonComponent from 'ui-component/ButtonComponent';

const validationSchema = yup.object({
    employer: yup.string().required(<FormattedMessage id="employer" />),
    representativeName: yup.string().required(<FormattedMessage id="representativeName" />),
    companyName: yup.string().required(<FormattedMessage id="companyName" />),
    fiscalIdentificationNumber: yup.string().required(<FormattedMessage id="fiscalIdentificationNumber" />),
    sector: yup.string().required(<FormattedMessage id="sector" />),
    description: yup.string().required(<FormattedMessage id="description" />),
    startPeriod: yup.date().required(<FormattedMessage id="startPeriod" />),
    endPeriod: yup.date().required(<FormattedMessage id="endPeriod" />),
    employerInssNumber: yup.string().required(<FormattedMessage id="employerInssNumber" />),
    operatingCountry: yup.string().required(<FormattedMessage id="operatingCountry" />),
    phoneNumber1: yup.string().required(<FormattedMessage id="phoneNumber1" />),
    phoneNumber2: yup.string().required(<FormattedMessage id="phoneNumber2" />),
    landline: yup.string().required(<FormattedMessage id="landline" />),
    email: yup
        .string()
        .email(<FormattedMessage id="EmailValid" />)
        .required(<FormattedMessage id="EmailObligatoire" />),
    legalForm: yup.string().required(<FormattedMessage id="legalForm" />),
    postOfficeBox: yup.string().required(<FormattedMessage id="postOfficeBox" />),
    address1: yup.string().required(<FormattedMessage id="address1" />),
    address2: yup.string().required(<FormattedMessage id="address2" />),
    address3: yup.string().required(<FormattedMessage id="address3" />),
    street: yup.string().required(<FormattedMessage id="street" />),
    fiscalYearExerciseId: yup.string().required(<FormattedMessage id="fiscalYearExerciseId" />)
});

const CompanyAdd = ({
    api,
    yourState,
    setSubmitting,
    snackAlert,
    handleNext,
    handleBack,
    open,
    setOpen,
    loadingError,
    setLoadingError
}) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setSubmitting(true);
            if (values.id === '') {
                api.addCoordonnee(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Enregistrement effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateCoordonnee(values)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Modification effectuer avec succes', 'success');
                            setSubmitting(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <UploadPhotoDialog api={api} open={open} setOpen={setOpen} loadingError={loadingError} setLoadingError={setLoadingError} />
        ) : (
            ''
        );
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialog()}
            <form onSubmit={formik.handleSubmit}>
                <MainCard sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="employer"
                                id="employer"
                                name="employer"
                                value={formik.values.employer || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.employer && Boolean(formik.errors.employer)}
                                helperText={formik.touched.employer && formik.errors.employer}
                                // placeholder={`${intl.formatMessage({ id: 'employer' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="representativeName"
                                id="representativeName"
                                name="representativeName"
                                value={formik.values.representativeName || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.representativeName && Boolean(formik.errors.representativeName)}
                                helperText={formik.touched.representativeName && formik.errors.representativeName}
                                // placeholder={`${intl.formatMessage({ id: 'representativeName' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="companyName"
                                id="companyName"
                                name="companyName"
                                value={formik.values.companyName || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                helperText={formik.touched.companyName && formik.errors.companyName}
                                // placeholder={`${intl.formatMessage({ id: 'companyName' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="fiscalIdentificationNumber"
                                id="fiscalIdentificationNumber"
                                name="fiscalIdentificationNumber"
                                type="number"
                                value={formik.values.fiscalIdentificationNumber || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.fiscalIdentificationNumber && Boolean(formik.errors.fiscalIdentificationNumber)}
                                helperText={formik.touched.fiscalIdentificationNumber && formik.errors.fiscalIdentificationNumber}
                                // placeholder={`${intl.formatMessage({ id: 'fiscalIdentificationNumber' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="sector"
                                id="sector"
                                name="sector"
                                value={formik.values.sector || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.sector && Boolean(formik.errors.sector)}
                                helperText={formik.touched.sector && formik.errors.sector}
                                // placeholder={`${intl.formatMessage({ id: 'sector' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <InputField
                                size="small"
                                fullWidth
                                label="description"
                                id="description"
                                name="description"
                                value={formik.values.description || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                // placeholder={`${intl.formatMessage({ id: 'description' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <DatePickerComponent
                                size="small"
                                fullWidth
                                label="startPeriod"
                                id="startPeriod"
                                name="startPeriod"
                                value={formik.values.startPeriod || ''}
                                handleChange={(value) => {
                                    formik.setFieldValue('endPeriod', value);
                                    formik.setFieldValue('startPeriod', value);
                                }}
                                error={formik.touched.startPeriod && Boolean(formik.errors.startPeriod)}
                                helperText={formik.touched.startPeriod && formik.errors.startPeriod}
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <DatePickerComponent
                                size="small"
                                fullWidth
                                label="endPeriod"
                                id="endPeriod"
                                name="endPeriod"
                                minDate={formik.values.startPeriod || ''}
                                value={formik.values.endPeriod || ''}
                                handleChange={(value) => formik.setFieldValue('endPeriod', value)}
                                error={formik.touched.endPeriod && Boolean(formik.errors.endPeriod)}
                                helperText={formik.touched.endPeriod && formik.errors.endPeriod}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="employerInssNumber"
                                id="employerInssNumber"
                                name="employerInssNumber"
                                value={formik.values.employerInssNumber || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.employerInssNumber && Boolean(formik.errors.employerInssNumber)}
                                helperText={formik.touched.employerInssNumber && formik.errors.employerInssNumber}
                                // placeholder={`${intl.formatMessage({ id: 'employerInssNumber' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="operatingCountry"
                                id="operatingCountry"
                                name="operatingCountry"
                                value={formik.values.operatingCountry || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.operatingCountry && Boolean(formik.errors.operatingCountry)}
                                helperText={formik.touched.operatingCountry && formik.errors.operatingCountry}
                                // placeholder={`${intl.formatMessage({ id: 'operatingCountry' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="phoneNumber1"
                                id="phoneNumber1"
                                name="phoneNumber1"
                                value={formik.values.phoneNumber1 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.phoneNumber1 && Boolean(formik.errors.phoneNumber1)}
                                helperText={formik.touched.phoneNumber1 && formik.errors.phoneNumber1}
                                // placeholder={`${intl.formatMessage({ id: 'phoneNumber1' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="phoneNumber2"
                                id="phoneNumber2"
                                name="phoneNumber2"
                                value={formik.values.phoneNumber2 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.phoneNumber2 && Boolean(formik.errors.phoneNumber2)}
                                helperText={formik.touched.phoneNumber2 && formik.errors.phoneNumber2}
                                // placeholder={`${intl.formatMessage({ id: 'phoneNumber2' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="landline"
                                id="landline"
                                name="landline"
                                value={formik.values.landline || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.landline && Boolean(formik.errors.landline)}
                                helperText={formik.touched.landline && formik.errors.landline}
                                // placeholder={`${intl.formatMessage({ id: 'landline' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="email"
                                id="email"
                                name="email"
                                value={formik.values.email || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                // placeholder={`${intl.formatMessage({ id: 'email' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="legalForm"
                                id="legalForm"
                                name="legalForm"
                                value={formik.values.legalForm || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.legalForm && Boolean(formik.errors.legalForm)}
                                helperText={formik.touched.legalForm && formik.errors.legalForm}
                                // placeholder={`${intl.formatMessage({ id: 'legalForm' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="postOfficeBox"
                                id="postOfficeBox"
                                name="postOfficeBox"
                                value={formik.values.postOfficeBox || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.postOfficeBox && Boolean(formik.errors.postOfficeBox)}
                                helperText={formik.touched.postOfficeBox && formik.errors.postOfficeBox}
                                // placeholder={`${intl.formatMessage({ id: 'postOfficeBox' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address1"
                                id="address1"
                                name="address1"
                                value={formik.values.address1 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                helperText={formik.touched.address1 && formik.errors.address1}
                                // placeholder={`${intl.formatMessage({ id: 'address1' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address2"
                                id="address2"
                                name="address2"
                                value={formik.values.address2 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                helperText={formik.touched.address2 && formik.errors.address2}
                                // placeholder={`${intl.formatMessage({ id: 'address2' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="address3"
                                id="address3"
                                name="address3"
                                value={formik.values.address3 || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.address3 && Boolean(formik.errors.address3)}
                                helperText={formik.touched.address3 && formik.errors.address3}
                                // placeholder={`${intl.formatMessage({ id: 'address3' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="street"
                                id="street"
                                name="street"
                                value={formik.values.street}
                                handleChange={formik.handleChange || ''}
                                error={formik.touched.street && Boolean(formik.errors.street)}
                                helperText={formik.touched.street && formik.errors.street}
                                // placeholder={`${intl.formatMessage({ id: 'street' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <InputField
                                size="small"
                                fullWidth
                                label="fiscalYearExerciseId"
                                id="fiscalYearExerciseId"
                                name="fiscalYearExerciseId"
                                value={formik.values.fiscalYearExerciseId || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.fiscalYearExerciseId && Boolean(formik.errors.fiscalYearExerciseId)}
                                helperText={formik.touched.fiscalYearExerciseId && formik.errors.fiscalYearExerciseId}
                                // placeholder={`${intl.formatMessage({ id: 'fiscalYearExerciseId' })}`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <ButtonComponent
                                text="Upload"
                                size="small"
                                variant="contained"
                                // startIcon={<Refresh />}
                                handleClick={() => {
                                    setOpen(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={6} lg={4}>
                                    <Button
                                        startIcon={yourState.id === '' ? <SaveIcon /> : <EditIcon />}
                                        type="submit"
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        {yourState.id === '' ? <FormattedMessage id="save" /> : <FormattedMessage id="edit" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                                <Button sx={{ my: 3, ml: 1 }} onClick={handleBack}>
                                    <FormattedMessage id="previous" />
                                </Button>
                                <AnimateButton>
                                    <Button variant="contained" sx={{ my: 3, ml: 1 }} onClick={handleNext}>
                                        <FormattedMessage id="next" />
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </form>
        </>
    );
};

CompanyAdd.propTypes = {
    api: PropTypes.object.isRequired,
    yourState: PropTypes.object,
    setSubmitting: PropTypes.func,
    snackAlert: PropTypes.func,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    loadingError: PropTypes.bool,
    setLoadingError: PropTypes.func
};

export default CompanyAdd;

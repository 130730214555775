// material-ui
import { Select, MenuItem, FormHelperText, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SelectComponent = ({
    label,
    options = [],
    handleChange,
    name,
    error,
    value,
    style,
    handleOpen,
    handleClose,
    placeholder,
    labelStyles,
    helperText,
    none,
    ...props
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 3,
                minWidth: 150,
                marginBottom: 3,
                // maxWidth: 300,
                ...style
            }}
        >
            <Typography
                htmlFor={label}
                style={{
                    marginRight: 5,
                    color: 'black',
                    ...labelStyles
                }}
            >
                {label}
            </Typography>
            <Select
                id={label}
                name={name}
                size="small"
                onChange={handleChange}
                value={value}
                fullWidth
                onOpen={handleOpen}
                onClose={handleClose}
                displayEmpty
                {...props}
            >
                {none && (
                    <MenuItem value="" style={{ color: 'red' }}>
                        <em style={{ color: 'grey' }}>{none}</em>
                    </MenuItem>
                )}
                {placeholder && (
                    <MenuItem value="" disabled style={{ color: 'red' }}>
                        <em style={{ color: 'grey' }}>{placeholder}</em>
                    </MenuItem>
                )}
                {options?.map((option, index) => {
                    return (
                        <MenuItem value={option.value} key={index}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
            {(error || helperText) && (
                <FormHelperText error={error} id="standard-weight-helper-text-email-login">
                    {error || helperText}
                </FormHelperText>
            )}
        </div>
    );
};

SelectComponent.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    error: PropTypes.bool,
    value: PropTypes?.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    style: PropTypes.object,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func,
    placeholder: PropTypes.string,
    labelStyles: PropTypes.object,
    helperText: PropTypes.string,
    none: PropTypes.bool
};

export default SelectComponent;

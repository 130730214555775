import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

export async function getService() {
    try {
        const response = await instance.get(`/directions`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export const addService = async (values) => {
    try {
        const response = await instance.post(`/directions`, values);
        return HandleResponse(response);
    } catch (error) {
        console.log('error', error.response);
        return HandleError(error);
    }
};

export const updateService = async (values) => {
    try {
        const response = await instance.put(`/directions/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        console.log('error', error);
        return HandleError(error);
    }
};

export const deleteService = async (id) => {
    try {
        const response = await instance.delete(`/directions/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

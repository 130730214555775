import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const url = '/erp-payroll-hr-service/api/v1';

async function getListeParametrageConge() {
    return instance.get(`${url}/annual-days-leaves`).then(HandleResponse).catch(HandleError);
}

const saveParametrageConge = async (parametrageConge) => {
    return instance.post(`${url}/annual-days-leaves`, parametrageConge).then(HandleResponse).catch(HandleError);
};

const updateParametrageConge = async (parametrageConge) => {
    return instance.put(`${url}/annual-days-leaves/${parametrageConge.id}`, parametrageConge).then(HandleResponse).catch(HandleError);
};

const deleteParametrageConge = async (id) => {
    return instance.delete(`${url}/annual-days-leaves/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeParametrageConge,
    saveParametrageConge,
    updateParametrageConge,
    deleteParametrageConge
};

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, MenuItem } from '@mui/material';
import InputField from 'ui-component/InputField';
import { GET_MENU } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import payroll from 'menu-items/payroll';
import career from 'menu-items/career';

const ChangeMenuSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const stateMenus = useSelector((state) => state.menu.menus);

    const handleChange = (e) => {
        if (e.target.value === 'PAYROLL') {
            dispatch({
                type: GET_MENU,
                payload: { value: e.target.value, items: [payroll] }
            });
        }
        if (e.target.value === 'CAREER') {
            dispatch({
                type: GET_MENU,
                payload: { value: e.target.value, items: [career] }
            });
        }
    };
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                    ml: 1
                },
                width: '100%'
            }}
        >
            <Grid item xs={12}>
                <InputField select size="small" fullWidth name="gender" value={stateMenus.value} handleChange={handleChange}>
                    <MenuItem value="CAREER">CAREER</MenuItem>
                    <MenuItem value="PAYROLL">PAYROLL</MenuItem>
                </InputField>
            </Grid>
        </Box>
    );
};

export default ChangeMenuSection;

import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonComponent from 'ui-component/ButtonComponent';
import MainCard from 'ui-component/cards/MainCard';
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import FormattageNombre from 'ui-component/FormattageNombre';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'black'
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const ParametrageCongeTable = ({ loading, detailedSetting, handleSubmit, isUpdated }) => {
    const validationSchema = Yup.object().shape({
        numberDays: Yup.number().test('Is positive?', <FormattedMessage id="greater-zero" />, (value) => value > 0)
    });
    const intl = useIntl();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: detailedSetting?.id,
            numberDays: detailedSetting?.numberDays
        },
        validationSchema,
        onSubmit: async (values) => {
            handleSubmit(values);
        }
    });

    return (
        <MainCard>
            {loading ? (
                <TotalGrowthBarChart />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: '50%' }}>
                                        <FormattedMessage id="fonction" />
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ width: '50%' }}>
                                        <FormattedMessage id="settings" />
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <FormattedMessage id="numberDays" />
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row" sx={{ width: '30%' }}>
                                        <FormattageNombre
                                            name="numberDays"
                                            allowNegative={false}
                                            value={formik.values.numberDays}
                                            handleChange={(values) => {
                                                const { value } = values;
                                                formik.setFieldValue('numberDays', parseFloat(value));
                                            }}
                                            style={{
                                                marginTop: 0,
                                                marginRight: 0,
                                                marginBottom: 2
                                            }}
                                            error={formik.touched.numberDays && Boolean(formik.errors.numberDays)}
                                            helperText={formik.touched.numberDays && formik.errors.numberDays}
                                            {...formik.getFieldProps('numberDays')}
                                            disabled={loading}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Divider light />
                    <Box display="flex" marginTop="20px" gap="10px">
                        <LoadingButton
                            fullWidth
                            style={{
                                cursor: 'pointer',
                                paddingLeft: 30,
                                paddingRight: 30,
                                textTransform: 'none'
                            }}
                            loading={isUpdated}
                            type="submit"
                            loadingPosition="start"
                            variant="contained"
                        >
                            {intl.formatMessage({ id: 'edit' })}
                        </LoadingButton>

                        <ButtonComponent
                            disabled={isUpdated}
                            text={intl.formatMessage({ id: 'reset' })}
                            handleClick={() => {
                                formik.resetForm();
                            }}
                            variant="outlined"
                            style={{ width: '100%' }}
                        />
                    </Box>
                </form>
            )}
        </MainCard>
    );
};

ParametrageCongeTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    detailedSetting: PropTypes?.object.isRequired
};
export default ParametrageCongeTable;

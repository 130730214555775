import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

export async function getDirection() {
    try {
        const response = await instance.get(`/directions`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export const addDirection = async (values) => {
    try {
        const response = await instance.post(`/directions`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const updateDirection = async (values) => {
    try {
        const response = await instance.put(`/directions/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export const deleteDirection = async (id) => {
    try {
        const response = await instance.delete(`/directions/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

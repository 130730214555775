import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slide,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import InputLabel from 'ui-component/extended/Form/InputLabel';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    code: yup.string().required('code'),
    designation: yup.string().required('designation')
});

const AddRelation = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesSanction = {
                id: values.id,
                code: values.code,
                designation: values.designation,
                ageLimit: values.ageLimit,
                maxRelations: values.maxRelations,
                isForSpouses: values.isForSpouses
            };
            setLoading(true);
            if (values.id === '') {
                api.addRelation(valuesSanction)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateRelation(valuesSanction)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add Relation</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="code"
                                id="code"
                                name="code"
                                value={formik.values?.code || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                                placeholder="enterCode"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="designation"
                                id="designation"
                                name="designation"
                                value={formik.values?.designation || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.designation && Boolean(formik.errors.designation)}
                                helperText={formik.touched.designation && formik.errors.designation}
                                placeholder="designation"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="ageLimit"
                                id="ageLimit"
                                name="ageLimit"
                                type="number"
                                value={formik.values?.ageLimit || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.ageLimit && Boolean(formik.errors.ageLimit)}
                                helperText={formik.touched.ageLimit && formik.errors.ageLimit}
                                placeholder="ageLimit"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="maxRelations"
                                id="maxRelations"
                                name="maxRelations"
                                type="number"
                                value={formik.values?.maxRelations || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.maxRelations && Boolean(formik.errors.maxRelations)}
                                helperText={formik.touched.maxRelations && formik.errors.maxRelations}
                                placeholder="maxRelations"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>isForSpouses</InputLabel>
                            <RadioGroup
                                row
                                aria-label="period"
                                id="isForSpouses"
                                name="isForSpouses"
                                value={formik.values?.isForSpouses.toString() || ''}
                                onChange={formik.handleChange}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio color="primary" sx={{ color: 'primary.main' }} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="secondary" sx={{ color: 'secondary.main' }} />}
                                    label="No"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={() => {}}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddRelation.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddRelation;

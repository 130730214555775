import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Checkbox
} from '@mui/material';
// project imports
import { FormattedMessage } from 'react-intl';
import { Delete, AddCircleOutlined } from '@mui/icons-material';
import SubCard from 'ui-component/cards/SubCard';
import InputField from 'ui-component/InputField';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Actions = ({ row, index, deleteFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const ModalityPayment = ({ dataModalite, setDataModalite }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [initState, setInitState] = useState({
        banque: '',
        numeroCompte: '',
        percentNetSalary: '',
        formErrors: { banque: '', numeroCompte: '', percentNetSalary: '' },
        touched: { banque: false, numeroCompte: false, percentNetSalary: false },
        formValid: false
    });

    const validateForm = (touchedValid) => {
        return touchedValid.banque && touchedValid.numeroCompte && touchedValid.percentNetSalary;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;

        switch (fieldName) {
            case 'banque':
                fieldValidationTouched.banque = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.banque = fieldValidationTouched.banque ? '' : ' banque is required';
                break;
            case 'numeroCompte':
                fieldValidationTouched.numeroCompte = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.numeroCompte = fieldValidationTouched.numeroCompte ? '' : ' numeroCompte is required';
                break;
            case 'percentNetSalary':
                fieldValidationTouched.percentNetSalary = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.percentNetSalary = fieldValidationTouched.percentNetSalary ? '' : ' percentNetSalary is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        validateField(name, value);
    };

    const handleSubmitHeureSup = (event) => {
        event.preventDefault();
        const selectedIndex = dataModalite.findIndex((object) => {
            return (
                object.banque === initState.banque &&
                object.numeroCompte === initState.numeroCompte &&
                object.percentNetSalary === initState.percentNetSalary
            );
        });
        let newModalite = [];
        if (selectedIndex >= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'elementExist',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
            return;
        }

        newModalite = newModalite.concat(dataModalite, [
            {
                banque: initState.banque,
                numeroCompte: initState.numeroCompte,
                percentNetSalary: initState.percentNetSalary
            }
        ]);
        setDataModalite(newModalite);
        setInitState({
            banque: '',
            numeroCompte: '',
            percentNetSalary: '',
            formErrors: { banque: '', numeroCompte: '', percentNetSalary: '' },
            touched: { banque: false, numeroCompte: false, percentNetSalary: false },
            formValid: false
        });
    };

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataModalite.filter((row, index) => index !== indexOf);
        setDataModalite(arrayCopy);
    };

    const handleClick = (event) => {
        console.log(event);
    };
    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <TableContainer>
                    <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sortDirection={false} align="left" padding="normal">
                                    code
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="left" padding="normal">
                                    Banque
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Numéro Compte
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Pourcentage salaire net
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="right" padding="normal">
                                    Banque principale
                                </StyledTableCell>
                                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {dataModalite && (
                            <TableBody>
                                <StyledTableRow hover tabIndex={-1} data-testid="rowItems">
                                    <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                        >
                                            ###
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            id="banque"
                                            name="banque"
                                            value={initState.banque || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.banque && Boolean(initState.formErrors.banque)}
                                            helperText={initState.formErrors.banque && initState.formErrors.banque}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            id="numeroCompte"
                                            name="numeroCompte"
                                            value={initState.numeroCompte || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.numeroCompte && Boolean(initState.formErrors.numeroCompte)}
                                            helperText={initState.formErrors.numeroCompte && initState.formErrors.numeroCompte}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <InputField
                                            size="small"
                                            type="number"
                                            fullWidth
                                            id="percentNetSalary"
                                            name="percentNetSalary"
                                            value={initState.percentNetSalary || ''}
                                            handleChange={(event) => handleUserInput(event)}
                                            error={!initState.touched.percentNetSalary && Boolean(initState.formErrors.percentNetSalary)}
                                            helperText={initState.formErrors.percentNetSalary && initState.formErrors.percentNetSalary}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center" onClick={(event) => handleClick(event)}>
                                        <Checkbox edge="start" tabIndex={-1} disableRipple />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
                                            <Tooltip title="Ajouter" arrow placement="left">
                                                <IconButton
                                                    component="span"
                                                    size="medium"
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.success.main
                                                        }
                                                    }}
                                                    disabled={!initState.formValid}
                                                    onClick={handleSubmitHeureSup}
                                                >
                                                    <AddCircleOutlined fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {dataModalite?.map((row, index) => {
                                    /** Make sure no display bugs if row isn't an OrderData object */
                                    if (typeof row === 'number') return null;
                                    return (
                                        <StyledTableRow hover tabIndex={-1} key={index} data-testid="rowItems">
                                            <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.banque} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.numeroCompte} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputField size="small" fullWidth value={row.percentNetSalary} handleChange={() => {}} />
                                            </StyledTableCell>
                                            <Actions row={row} index={index} deleteFunction={deleteFunction} />
                                        </StyledTableRow>
                                    );
                                })}
                                {dataModalite.length <= 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" colSpan={7}>
                                            <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                <FormattedMessage id="no-data" />
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </SubCard>
    );
};

ModalityPayment.propTypes = {
    dataModalite: PropTypes.array,
    setDataModalite: PropTypes.func
};

export default ModalityPayment;

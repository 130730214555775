import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    Typography,
    Divider
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, CalendarTodayTwoTone, PhoneAndroidTwoTone, EmailTwoTone } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import SanctionAppealingList from './SanctionAppealingList';
import FloatingCart from 'ui-component/extended/FloatingCart';
import ResponseFailures from 'ui-component/extended/ResponseFailures';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const validationSchema = yup.object({
    employee: yup.object().shape({
        id: yup.string().required('employee')
    })
    // reasonSanctionAppealing: yup.string().required('reasonSanctionAppealing')
});

const MeasureApprouveDialog = ({
    api,
    params,
    open,
    setOpen,
    initialValues,
    yourState,
    setYourState,
    refleshTable,
    snackAlert,
    setState
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [dataResponse, setDataResponse] = useState({ open: false, success: [], failures: [] });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesSanctionAppealing = {
                id: '',
                disciplinaryMeasureId: values.id,
                reason: values.reasonSanctionAppealing
            };
            setLoading(true);
            api.addSanctionAppealing(valuesSanctionAppealing)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        setState(res.data);
                        formik.resetForm();
                        setDataResponse({ open: false, success: [], failures: [] });
                        refleshTable(params);
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setDataResponse({
                            open: false,
                            success: [],
                            failures: res.response?.data?.fieldsErrors?.details ? res.response?.data?.fieldsErrors?.details : []
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
        setDataResponse({ open: false, success: [], failures: [] });
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogResponse = () => {
        const openDialog = dataResponse.open ? <ResponseFailures dataResponse={dataResponse} setDataResponse={setDataResponse} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogResponse()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open.approuve} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Sanction Appealing</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="employee"
                                    id="employee"
                                    name="employee"
                                    value={`${formik.values.employee?.code || ''} ${formik.values.employee?.firstName || ''} ${
                                        formik.values.employee?.middleName || ''
                                    } ${formik.values.employee?.lastName || ''}`}
                                    handleChange={() => {}}
                                    error={formik.touched.employee && Boolean(formik.errors.employee)}
                                    helperText={formik.touched.employee && formik.errors.employee}
                                    placeholder="employee"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <CalendarTodayTwoTone sx={detailsIconSX} />
                                                    {yourState.employee?.firstName}
                                                    {yourState.employee?.lastName}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <PhoneAndroidTwoTone sx={detailsIconSX} />
                                                    {yourState.employee?.mobilePhoneNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <EmailTwoTone sx={detailsIconSX} /> {yourState.employee?.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            {yourState.sanctionAppealings.length > 0 && (
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h4">Information Sanction Appealing</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SanctionAppealingList dataSanctionApplealing={yourState.sanctionAppealings} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Information Disciplinary Measure</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    reason: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.reason}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    sanctionDate: &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.sanctionDate?.toString().slice(0, 10)}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            startDate : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {yourState.startDate?.toString().slice(0, 10)}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="subtitle1">
                                                                            endDate : &nbsp;
                                                                            <Typography component="span" variant="body2">
                                                                                {yourState.endDate?.toString().slice(0, 10)}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4">Other information</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    baseSalary : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.employee?.baseSalary}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    retainedAmount : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.retainedAmount}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    retainedPercentage : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.retainedPercentage}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    disciplinaryMeasureStatus : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.disciplinaryMeasureStatus === 'APPROVED' && (
                                                                            <Chip
                                                                                label="approved"
                                                                                variant="contained"
                                                                                size="small"
                                                                                color="error"
                                                                            />
                                                                        )}
                                                                        {yourState.disciplinaryMeasureStatus === 'CANCELLED' && (
                                                                            <Chip
                                                                                label="cancelled"
                                                                                variant="contained"
                                                                                size="small"
                                                                                color="success"
                                                                            />
                                                                        )}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    durationInMonths : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.durationInMonths}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel>retentionMonths</InputLabel>
                                                                {yourState.retentionMonths.length > 0 &&
                                                                    yourState.retentionMonths !== null &&
                                                                    yourState.retentionMonths.map((row, index) => {
                                                                        return (
                                                                            <Chip
                                                                                key={index}
                                                                                label={`${row.name}`}
                                                                                color="primary"
                                                                                variant="contained"
                                                                            />
                                                                        );
                                                                    })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1">
                                                                    sanctionType : &nbsp;
                                                                    <Typography component="span" variant="body2">
                                                                        {yourState.sanctionType?.code} ||{' '}
                                                                        {yourState.sanctionType?.designation}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={sxDivider} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="reasonSanctionAppealing"
                                    id="reasonSanctionAppealing"
                                    name="reasonSanctionAppealing"
                                    value={formik.values?.reasonSanctionAppealing || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.reasonSanctionAppealing && Boolean(formik.errors.reasonSanctionAppealing)}
                                    helperText={formik.touched.reasonSanctionAppealing && formik.errors.reasonSanctionAppealing}
                                    placeholder="reasonSanctionAppealing"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                        <ButtonComponent
                            variant="contained"
                            type="submit"
                            size="small"
                            text={<FormattedMessage id="save" />}
                            startIcon={<SaveRounded />}
                            handleClick={() => {}}
                        />
                    </DialogActions>
                </form>
                {dataResponse.failures.length > 0 && <FloatingCart dataResponse={dataResponse} setDataResponse={setDataResponse} />}
            </Dialog>
        </>
    );
};

MeasureApprouveDialog.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired
};

export default MeasureApprouveDialog;

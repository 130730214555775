import { forwardRef } from 'react';

// material-ui
import { CircularProgress, Dialog, Grid, IconButton, Slide, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// material-ui
import Stack from '@mui/material/Stack';

// material-ui
import InputField from 'ui-component/InputField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Close from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { Edit, RotateLeftRounded, Save } from '@mui/icons-material';
import { gridSpacing } from 'store/constant';
import SelectComponent from 'ui-component/SelectComponent';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import ButtonComponent from 'ui-component/ButtonComponent';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

// ================= IMPORT AXIOS ET INITIALISATION DE JSON DB =============

const CategorieModal = ({ open, handleClose, categorieToEdit, handleSubmit, loading, listePersonnels }) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            code: categorieToEdit.code,
            designation: categorieToEdit.designation,
            personalTypeId: categorieToEdit.id ? categorieToEdit?.personalTypeId?.value : categorieToEdit?.personalTypeId
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            code: yup
                .string()
                .max(6, <FormattedMessage id="code-car6-req" />)
                .required(<FormattedMessage id="code-req" />),
            designation: yup.string().required(<FormattedMessage id="designation-req" />),
            personalTypeId: yup.string().required(<FormattedMessage id="typePersonnel-req" />)
        }),
        onSubmit: async (values, { resetForm }) => {
            const categorieToSave = {
                ...values,
                personalTypeId: values?.personalTypeId
            };
            handleSubmit(categorieToSave, resetForm);
        }
    });
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-adresse1"
                maxWidth="sm"
                fullWidth
            >
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            {categorieToEdit.id ? <FormattedMessage id="edit-categorie" /> : <FormattedMessage id="add-new-category" />}
                        </DialogTitle>
                        <IconButton
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SelectComponent
                                            label={intl.formatMessage({ id: 'obl-typePersonnel' })}
                                            options={listePersonnels}
                                            handleChange={formik.handleChange}
                                            name="personalTypeId"
                                            error={formik.touched.personalTypeId && formik.errors.personalTypeId}
                                            helperText={formik.touched.personalTypeId && formik.errors.personalTypeId}
                                            value={formik.values.personalTypeId}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'obl-code' })}
                                            name="code"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9a-zA-Z-]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('code', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                            helperText={formik.touched.code && formik.errors.code}
                                            type="text"
                                            value={formik.values.code}
                                            disabled={loading}
                                            inputProps={{ maxLength: 6 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'obl-entitled' })}
                                            name="designation"
                                            handleChange={(e) => {
                                                if (
                                                    e.target.value.match(
                                                        /^([a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáãäåæëìíîïðñòóôõöüûúùéèêç'\s-]+)$/
                                                    ) ||
                                                    e.target.value === ''
                                                ) {
                                                    formik.setFieldValue('designation', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.designation && Boolean(formik.errors.designation)}
                                            helperText={formik.touched.designation && formik.errors.designation}
                                            value={formik.values.designation}
                                            disabled={loading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={intl.formatMessage({ id: 'reset' })}
                            disabled={loading || !formik.dirty}
                            handleClick={() => formik.resetForm()}
                        />
                        <ButtonComponent
                            variant="contained"
                            text={
                                categorieToEdit.id
                                    ? `${intl.formatMessage({ id: 'update' })}`
                                    : `${intl.formatMessage({
                                          id: 'save'
                                      })}`
                            }
                            disabled={loading || !formik.dirty}
                            size="small"
                            type="submit"
                            startIcon={categorieToEdit.id ? <Edit /> : <Save />}
                            endIcon={loading ? <CircularProgress size="16px" /> : null}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};
CategorieModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    categorieToEdit: PropTypes?.object.isRequired,
    listePersonnels: PropTypes?.array.isRequired,
    handleSubmit: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired
};
export default CategorieModal;

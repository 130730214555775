import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const url = '/erp-payroll-hr-service/api/v1';

async function getTypesPositions(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${url}/position-types`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveTypesPositions = async (positionTypeId) => {
    try {
        const response = await instance.post(`${url}/position-types`, positionTypeId);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypesPositions = async (positionTypeId) => {
    try {
        const response = await instance.put(`${url}/position-types/${positionTypeId.id}`, positionTypeId);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypesPositions = async (positionTypeId) => {
    try {
        const response = await instance.delete(`${url}/position-types/${positionTypeId}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getTypesPositions,
    saveTypesPositions,
    updateTypesPositions,
    deleteTypesPositions
};

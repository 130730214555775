const { CURRENT_EMPLOYEE, REINIT_EMPLOYEE } = require('./actions');

const initialState = {
    employees: [],
    current: null,
    loading: false,
    error: null
};

const employeeReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CURRENT_EMPLOYEE:
            return {
                ...state,
                current: action.payload
            };
        case REINIT_EMPLOYEE:
            return {
                ...state,
                current: null
            };
        default:
            return state;
    }
};

export default employeeReducer;

import PropTypes from 'prop-types';
import { ButtonBase, Grid, IconButton, Paper, styled, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
});

const FileUploadList = ({ viewFile, removeFile }) => {
    return (
        <Paper
            sx={{
                p: 1,
                margin: 'auto',
                maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff')
            }}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase sx={{ width: 128, height: 100 }}>
                        <Img alt="complex" src={viewFile} />
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Standard file
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Full resolution 1920x1080 • JPEG
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                ID: 1030114
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" title="Remove" onClick={() => removeFile()}>
                            <DeleteForeverIcon color="error" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
FileUploadList.propTypes = {
    viewFile: PropTypes.string,
    removeFile: PropTypes.func
};

export default FileUploadList;

import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { echelonAPI, gradePersonnelAPI } from 'api';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { useIntl } from 'react-intl';
import RungModal from './RungModal';
import RungTable from './RungTable';
import { SpinnerLoader } from 'views/ui-elements/Loaders';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [echelonListe, setEchelonListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [echelonToDelete, setEchelonToDelete] = useState(null);
    const [echelonToEdit, setEchelonToEdit] = useState({
        code: '',
        designation: '',
        duration: 0,
        personalGradeId: ''
    });
    const [listeGrades, setListeGrades] = useState([]);
    const [reload, setReload] = useState(false);
    const [paginationDetails, setPaginationDetails] = useState({
        number: 1,
        size: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setFilterValue('');
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const echelonPromise = echelonAPI.getListeEchelons(filterValue, {
            ...paginationDetails,
            number: paginationDetails.number - 1
        });
        const gradePromise = gradePersonnelAPI.getListeGrades('', 100, 0);
        axios
            .all([echelonPromise, gradePromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const echelonData = [];
                        responses[0]?.content?.forEach((row) => {
                            echelonData.push({
                                id: row.id,
                                code: row.code,
                                designation: row.designation,
                                duration: row.duration,
                                personalGradeId: row.personalGrade
                            });
                        });
                        const grades = responses[1]?.content?.map((grade) => ({
                            label: `${grade.code} | ${grade.designation} | ${grade.salary?.toLocaleString(intl.locale)}`,
                            value: grade.id
                        }));
                        setEchelonListe((echelonPersonnel) => ({
                            ...echelonPersonnel,
                            ...responses[0],
                            content: echelonData
                        }));
                        setListeGrades(grades);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, dispatch, filterValue, paginationDetails]);

    // open create or delete dialog
    const handleClickOpenCreateOrEditDialog = (echelon) => {
        if (echelon) {
            setEchelonToEdit({
                ...echelon,
                personalGradeId: {
                    label: `${echelon?.personalGradeId?.code} | ${echelon?.personalGradeId?.designation}`,
                    value: echelon?.personalGradeId?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (response) => {
        if (response) {
            setLoading(true);
            echelonAPI
                .getListeEchelons()
                .then((res) => {
                    setEchelonListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.description ? err.response.data.description : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setEchelonToEdit({
            code: '',
            designation: '',
            duration: 0,
            personalGradeId: ''
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (echelon) => {
        setEchelonToDelete(echelon);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (echelonToDelete) => {
        if (echelonToDelete) {
            setEchelonListe(echelonListe.filter((echelon) => echelon.id !== echelonToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getEchelons = (number, size) => {
        setLoading(true);
        let echelonPromise;
        if (!number && !size) {
            echelonPromise = echelonAPI.getListeEchelons(filterValue, {
                ...paginationDetails,
                number: paginationDetails.number - 1
            });

            setPaginationDetails({
                ...paginationDetails,
                number: 1
            });
        } else {
            echelonPromise = echelonAPI.getListeEchelons(filterValue, {
                ...paginationDetails,
                number: number || paginationDetails.number - 1,
                size: size || paginationDetails.size
            });
        }
        axios
            .all([echelonPromise])
            .then(
                axios.spread((...responses) => {
                    const echelonData = responses[0]?.content;
                    setEchelonListe({
                        ...echelonListe,
                        ...responses[0],
                        content: echelonData
                    });
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const getEchelonsFiltres = (number, size) => {
        setLoading(true);
        let echelonPromise;
        if (!number && !size) {
            echelonPromise = echelonAPI.getListeEchelonsFiltres(searchVal, {
                ...paginationDetails,
                number: paginationDetails.number - 1
            });

            setPaginationDetails({
                ...paginationDetails,
                number: 1
            });
        } else {
            echelonPromise = echelonAPI.getListeEchelonsFiltres(searchVal, {
                ...paginationDetails,
                number: paginationDetails.number - 1,
                size: size || paginationDetails.size
            });
        }
        axios
            .all([echelonPromise])
            .then(
                axios.spread((...responses) => {
                    const echelonData = responses[0]?.content;
                    setEchelonListe({
                        ...echelonListe,
                        ...responses[0],
                        content: echelonData
                    });
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDelete = () => {
        setDeleting(true);
        echelonAPI
            .deleteEchelon(echelonToDelete.id)
            .then(() => {
                setDeleting(true);
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getEchelons();
            })
            .catch((err) => {
                setDeleting(false);
                snackAlert(`${err.response?.data.description ? err.response.data.description : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
                setDeleting(false);
            });
    };

    const handleFilter = (e) => {
        if (e) {
            setFilterValue(e.target.value);
        } else {
            setFilterValue('');
        }
    };

    const handleClearSearch = () => {
        setFilterValue('');
        setSearchVal('');
        getEchelons();
    };
    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            number: 1,
            size: value
        });
        getEchelons(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            number: value
        });
        getEchelons(value - 1);
    };
    const handleSubmit = (values, resetForm) => {
        if (echelonToEdit.id) {
            setLoading(true);
            echelonAPI
                .updateEchelon({
                    id: echelonToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getEchelons();
                    setEchelonToEdit({
                        code: '',
                        designation: '',
                        duration: 0,
                        personalGradeId: ''
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            echelonAPI
                .saveEchelon({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    getEchelons();
                    resetForm();
                    setEchelonToEdit({
                        code: '',
                        designation: '',
                        duration: 0,
                        personalGradeId: ''
                    });
                    setOpenDialog(false);
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleFilterList = (event) => {
        const newString = event?.target?.value;
        setSearchVal(newString);

        if (newString) {
            const newRows = echelonListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setEchelonListe({ ...echelonListe, content: newRows });
        } else {
            getEchelons();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'echelons' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-echelon' })} />
            <SpinnerLoader open={deleting} />
            <RungTable
                echelonListe={{
                    content: echelonListe.content,
                    totalPages: echelonListe.totalPages,
                    totalElements: echelonListe.totalElements
                }}
                loading={loading}
                handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                handleFilter={handleFilter}
                filterValue={filterValue}
                getEchelons={getEchelons}
                handleClearSearch={handleClearSearch}
                paginate={paginate}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationDetails={paginationDetails}
                handleFilterList={handleFilterList}
                searchVal={searchVal}
                toggleReload={toggleReload}
                getEchelonsFiltres={getEchelonsFiltres}
            />
            <RungModal
                open={openDialog}
                handleClose={handleClickCloseCreateOrEditDialog}
                echelonToEdit={echelonToEdit}
                snackAlert={snackAlert}
                handleSubmit={handleSubmit}
                loading={loading}
                listeGrades={listeGrades}
            />

            <DeleteDialog
                text={intl.formatMessage({ id: 'echelon-personnel' })}
                handleClose={handleClickCloseDeleteAlertDialog}
                open={openDeleteAlertDialog}
                snackAlert={snackAlert}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default Index;

import React, { useState, useEffect } from 'react';
// material-ui
import { Grid, Stepper, StepButton, Step } from '@mui/material';

// project imports
import { useDispatch } from 'react-redux';
import HeaderComponent from 'ui-component/HeaderComponent';
import { gridSpacing } from 'store/constant';
import { coordonneeApi } from 'api';
import { SNACKBAR_OPEN } from 'store/actions';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import CompanyAdd from './CompanyAdd';
import LiasonComptaAdd from './LiasonComptaAdd';
import ImpressionAdd from './ImpressionAdd';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Refresh } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const initialValues = {
    id: '',
    employer: '',
    representativeName: '',
    companyName: '',
    fiscalIdentificationNumber: '',
    sector: '',
    description: '',
    startPeriod: new Date(),
    endPeriod: new Date(),
    employerInssNumber: '',
    operatingCountry: '',
    phoneNumber1: '',
    phoneNumber2: '',
    landline: '',
    email: '',
    legalForm: '',
    postOfficeBox: '',
    address1: '',
    address2: '',
    address3: '',
    street: '',
    fiscalYearExerciseId: ''
};

const initialValuesLiaison = {
    id: '',
    nomServeur: '',
    nomDataBase: '',
    password: '',
    nomUtilisateur: '',
    numeroDossierComptable: '',
    prefixComptaCongeMaladie: 0,
    prefixAllocationFamiliale: 0,
    prefixComptaEmployes: 0,
    prefixComptaHeureSuppl: 0,
    prefixComptaSalaireBase: 0,
    prefixComptaCongeMaternite: 0,
    prefixComptaIndemniteLogement: 0,
    idExercice: 0
};

const initialValuesImpr = {
    id: '',
    fichierDeBase: '',
    journalPaie: '',
    etatPaie: '',
    ordreVirement: '',
    listeEmplyes: '',
    recapIpr: '',
    recapRetenuesCotisations: '',
    recapPrimesIndemnitesDeductions: '',
    fraisMedicauxDivers: '',
    fichierTravailleur: ''
};

const steps = ['Select campaign settings', 'Create an liason', 'Impression'];

function getStepContent(
    step,
    yourState,
    yourStateLiaison,
    yourStateImpr,
    setSubmitting,
    snackAlert,
    handleNext,
    handleBack,
    open,
    setOpen,
    loadingError,
    setLoadingError
) {
    switch (step) {
        case 0:
            return (
                <CompanyAdd
                    api={coordonneeApi}
                    yourState={yourState}
                    setSubmitting={setSubmitting}
                    snackAlert={snackAlert}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    open={open}
                    setOpen={setOpen}
                    loadingError={loadingError}
                    setLoadingError={setLoadingError}
                />
            );
        case 1:
            return (
                <LiasonComptaAdd
                    api={coordonneeApi}
                    yourStateLiaison={yourStateLiaison}
                    setSubmitting={setSubmitting}
                    snackAlert={snackAlert}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            );
        case 2:
            return (
                <ImpressionAdd
                    api={coordonneeApi}
                    yourStateImpr={yourStateImpr}
                    setSubmitting={setSubmitting}
                    snackAlert={snackAlert}
                    handleBack={handleBack}
                />
            );
        default:
            throw new Error('unknownStep');
    }
}

const CompanyList = () => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [yourState, setYourState] = useState(initialValues);
    const [yourStateLiaison, setYourStateLiaison] = useState(initialValuesLiaison);
    const [yourStateImpr, setYourStateImpr] = useState(initialValuesImpr);
    const [open, setOpen] = useState(false);

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const refleshTable = async () => {
        setLoading(true);
        await coordonneeApi
            .getCoordonneeImprLiaison()
            .then((res) => {
                if (res.success) {
                    console.log(res.data.response);
                    const coordonnees = res.data.response.coordonneesResponse;
                    const liaison = res.data.response.liaisonComptaResponse;
                    const impression = res.data.response.impressionResponse;
                    const result = {
                        ...coordonnees,
                        periodeDebut: new Date(coordonnees?.periodeDebut),
                        periodeFin: new Date(coordonnees?.periodeFin)
                    };
                    setYourState(result);
                    setYourStateLiaison(liaison);
                    setYourStateImpr(impression);
                    setLoading(false);
                } else {
                    setYourState(initialValues);
                    setYourStateLiaison(initialValuesLiaison);
                    setYourStateImpr(initialValuesImpr);
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [activeStep, setActiveStep] = React.useState(0);
    const completed = {};

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === 0) {
                return prevActiveStep + 1;
            }
            return prevActiveStep - 1;
        });
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <HeaderComponent header="coordonnee-society" subtitle="view-coordonnee" />
            <SpinnerLoader open={submitting} />
            <Grid item xs={12} lg={12}>
                <Stepper nonLinear activeStep={activeStep} sx={{ pt: 3 }}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={index} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}
                    <Step>
                        <ButtonComponent
                            text={<FormattedMessage id="refresh" />}
                            size="small"
                            variant="contained"
                            startIcon={<Refresh />}
                            handleClick={() => {
                                refleshTable();
                            }}
                        />
                    </Step>
                </Stepper>
                <Grid item xs={12}>
                    {loading ? (
                        <EarningCard />
                    ) : (
                        <>
                            {getStepContent(
                                activeStep,
                                yourState,
                                yourStateLiaison,
                                yourStateImpr,
                                setSubmitting,
                                snackAlert,
                                handleNext,
                                handleBack,
                                open,
                                setOpen,
                                loadingError,
                                setLoadingError
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CompanyList;

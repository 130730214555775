import { forwardRef, useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
    Dialog,
    Grid,
    IconButton,
    Pagination,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    DialogContent,
    DialogTitle,
    Button,
    Tooltip
} from '@mui/material';

// material-ui
import ButtonComponent from 'ui-component/ButtonComponent';
import CloseIcon from '@mui/icons-material/Close';
// material-ui
// third-party
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { jobsPostingAPI } from 'api';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import { gridSpacing } from 'store/constant';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReplayIcon from '@mui/icons-material/Replay';
import OffreEmploiModal from './OffreEmploiModal';
import { IconEye } from '@tabler/icons';
import { Edit } from '@mui/icons-material';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// styles
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const ListeOffresEmploi = ({ open, handleClose, setRowToEdit, paginationDetails }) => {
    const [loading, setLoading] = useState(false);
    const [entriesFilters, setEntriesFilters] = useState({
        openingDate: new Date(),
        closingDate: new Date(),
        pageSize: 20,
        currentPage: 1
    });
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const [listeOffres, setListeOffres] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [element, setElement] = useState({});
    const intl = useIntl();
    const dispatch = useDispatch();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };

        const jobPromise = jobsPostingAPI.getListeLettre();
        axios
            .all([jobPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const listeOffres = responses[0]?.content;
                        const offresData = [];
                        listeOffres.forEach((offre) => {
                            offresData.push({
                                id: offre.id,
                                position: offre.position,
                                introduction: offre.introduction,
                                description: offre.description,
                                // companyLocation: offre.companyLocation,
                                openingDate: offre.openingDate,
                                closingDate: offre.closingDate,
                                // contacts: offre.contacts,
                                benefits: offre.benefits,
                                requiredSkills: offre.requiredSkills,
                                spokenLanguages: offre.spokenLanguages,
                                workType: offre.workType,
                                wantedWorkforce: offre.wantedWorkforce
                            });
                        });
                        setListeOffres((offre) => ({
                            ...offre,
                            ...responses[0],
                            content: offresData
                        }));
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(err.message, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, dispatch]);
    const toggleReload = () => {
        setReload(!reload);
    };
    const handleChangeClosingDate = (value) => {
        setEntriesFilters({
            ...entriesFilters,
            closingDate: value
        });
        setErrors({
            openingDate: ''
        });
    };
    const handleChangeOpeningDate = (value) => {
        setEntriesFilters({
            ...entriesFilters,
            openingDate: value,
            closingDate: ''
        });
        setErrors({
            closingDate: ''
        });
    };
    const validateFilters = () => {
        if (!entriesFilters.openingDate) {
            setErrors({
                openingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(entriesFilters.openingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                openingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        if (!entriesFilters.closingDate) {
            setErrors({
                closingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(entriesFilters.closingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                closingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        return true;
    };
    const getListeOffres = () => {
        if (validateFilters()) {
            setLoading(true);
            let isSubscribed = true;
            const jobPromise = jobsPostingAPI.getListeLettre('', {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            axios
                .all([jobPromise])
                .then(
                    axios.spread((...responses) => {
                        if (isSubscribed) {
                            const listesOffres = responses[0]?.content;
                            // const offresData = [];
                            // listeOffres.forEach((offre) => {
                            //     offresData.push({
                            //         id: offre.id,
                            //         // position: offre.position,
                            //         // introduction: offre.introduction,
                            //         // description: offre.description,
                            //         // openingDate: offre.openingDate,
                            //         // closingDate: offre.closingDate,
                            //         // wantedWorkforce: offre.wantedWorkforce,
                            //         // benefits: offre.benefits,
                            //         // workType: offre.workType,
                            //         // requiredSkills: offre.requiredSkills,
                            //         // spokenLanguages: offre.spokenLanguages,
                            //         companyDetails: offre.companyDetails
                            //     });
                            // });
                            // setListeOffres((offre) => ({
                            //     ...offre,
                            //     ...responses[0],
                            //     content: offresData
                            // }));
                            setListeOffres((offre) => ({
                                ...offre,
                                ...responses[0],
                                content: listesOffres
                            }));
                            setSuccessFetch(true);
                        }
                    })
                )
                .catch((err) => {
                    if (isSubscribed) {
                        snackAlert(err.message, 'error');
                    }
                    setSuccessFetch(false);
                })
                .finally(() => {
                    setLoading(false);
                    isSubscribed = false;
                });
        }
    };
    const handleChangeRowsPerPage = (e) => {
        const { value } = e.target;
        setEntriesFilters({
            ...entriesFilters,
            pageSize: value,
            currentPage: 0
        });
    };

    const paginate = (e, values) => {
        setEntriesFilters({
            ...entriesFilters,
            currentPage: values
        });
        getListeOffres(values);
    };
    const FormatDate = (date) => {
        return date
            .toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(/,/, '');
    };
    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setElement(row);
        }
        setOpenDialog(true);
    };
    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setElement(row);
        }
        setElement({});
        setOpenDialog(false);
    };
    console.log('LISTE', listeOffres);
    console.log('LISTE DET', listeOffres.content.companyDetails);
    console.log('LISTE DETENU', listeOffres.content?.companyDetails?.companyDetails);
    return (
        <div>
            <OffreEmploiModal
                open={openDialog}
                handleClose={handleClickCloseCreateOrEditDialog}
                demandeToEdit={element}
                loading={loading}
            />
            <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            onClick={() => {
                                setListeOffres({
                                    ecritures: [],
                                    totalElements: 0,
                                    totalPages: 0
                                });
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h4" component="div">
                            <FormattedMessage id="close" />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogTitle>{intl.formatMessage({ id: 'create-offre-emploi' })}</DialogTitle>

                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={4} md={3} lg={2} sm={3}>
                            <DatePickerComponent
                                label={<FormattedMessage id="from" />}
                                value={entriesFilters?.openingDate}
                                handleChange={handleChangeOpeningDate}
                                style={{ fontWeight: '400' }}
                                labelStyles={{ fontWeight: 'bold' }}
                                error={errors?.openingDate && Boolean(errors?.openingDate)}
                                helperText={errors?.openingDate && errors?.openingDate}
                            />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2} sm={3}>
                            <DatePickerComponent
                                label={<FormattedMessage id="to" />}
                                value={entriesFilters?.closingDate}
                                handleChange={handleChangeClosingDate}
                                style={{ fontWeight: '400' }}
                                labelStyles={{ fontWeight: 'bold' }}
                                error={errors?.closingDate && Boolean(errors?.closingDate)}
                                helperText={errors?.closingDate && errors?.closingDate}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={1}>
                            <Stack alignItems="flex-start" justifyContent="flex-end" height="100%">
                                <ButtonComponent
                                    variant="contained"
                                    style={{
                                        height: '70%',
                                        alignSelf: 'start'
                                    }}
                                    sx={{
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: theme.palette.primary.main
                                        }
                                    }}
                                    text={<FormattedMessage id="search" />}
                                    handleClick={() => getListeOffres()}
                                    disabled={loading}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    {successFetch ? (
                        <MainCard content={false} style={{ marginTop: 10 }}>
                            {loading ? (
                                <TotalGrowthBarChart />
                            ) : (
                                <TableContainer>
                                    <Table
                                        sx={{
                                            minWidth: 320,
                                            border: 1,
                                            borderColor: theme.palette.grey[200]
                                        }}
                                        aria-label="customized table"
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    fontWeight: 'bolder',
                                                    borderRight: '0.5px solid #ccc'
                                                }}
                                            >
                                                <StyledTableCell>
                                                    <FormattedMessage id="position" />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <FormattedMessage id="nombre" />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <FormattedMessage id="workType" />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <FormattedMessage id="openingDate" />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <FormattedMessage id="closingDate" />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <FormattedMessage id="actions" />
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listeOffres?.content?.length !== 0 ? (
                                                listeOffres?.content?.map((row, index) => (
                                                    <StyledTableRow
                                                        sx={{
                                                            ':hover td': {
                                                                backgroundColor: '#DDEDF9',
                                                                color: 'black',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        key={index}
                                                        onClick={() => {
                                                            // setListeOffres({
                                                            //     content: [],
                                                            //     totalElements: 0,
                                                            //     totalPages: 0
                                                            // });
                                                            setRowToEdit(row);
                                                        }}
                                                    >
                                                        <StyledTableCell>{row.position}</StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            {row?.wantedWorkforce ? row?.wantedWorkforce?.toLocaleString(intl.locale) : ''}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {row?.workType ? row?.workType?.toLocaleString(intl.locale) : ''}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            {row.openingDate ? FormatDate(new Date(row.openingDate)) : ''}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            {row.closingDate ? FormatDate(new Date(row.closingDate)) : ''}
                                                        </StyledTableCell>

                                                        <StyledTableCell align="center">
                                                            <Stack direction="row" justifyContent="end">
                                                                <Tooltip title={intl.formatMessage({ id: 'edit' })} arrow placement="right">
                                                                    <IconButton
                                                                        component="span"
                                                                        style={{
                                                                            padding: 4,
                                                                            margin: 0
                                                                        }}
                                                                        size="smalll"
                                                                        onClick={() => {
                                                                            setRowToEdit(row);
                                                                        }}
                                                                        sx={{
                                                                            ':hover': {
                                                                                color: theme.palette.primary.dark
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Edit fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={intl.formatMessage({ id: 'show-details' })}
                                                                    arrow
                                                                    placement="right"
                                                                >
                                                                    <IconButton
                                                                        component="span"
                                                                        style={{
                                                                            padding: 4,
                                                                            margin: 0
                                                                        }}
                                                                        size="smalll"
                                                                        onClick={() => {
                                                                            handleClickOpenCreateOrEditDialog(row);
                                                                        }}
                                                                        sx={{
                                                                            ':hover': {
                                                                                color: theme.palette.primary.dark
                                                                            }
                                                                        }}
                                                                    >
                                                                        <IconEye fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={8}>
                                                        <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                            <FormattedMessage id="noData" />
                                                        </Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: 10,
                                    backgroundColor: theme.palette.grey[200],
                                    borderWidth: '1px',
                                    borderColor: theme.palette.grey[300],
                                    borderStyle: 'solid',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <TypographyListDescription text={intl.formatMessage({ id: 'display' })} />{' '}
                                    <select onChange={handleChangeRowsPerPage}>
                                        <option value={5}>5</option>
                                        <option value={10}>20</option>
                                        <option value={25}>25</option>
                                    </select>
                                    <TypographyListDescription text={intl.formatMessage({ id: 'record' })} />{' '}
                                </div>
                                <Pagination
                                    count={listeOffres.totalPages}
                                    color="primary"
                                    size="small"
                                    onChange={paginate}
                                    shape="rounded"
                                    page={entriesFilters.currentPage}
                                />
                                <TypographyListDescription text={`${listeOffres.totalElements} recordings`} />
                            </div>
                        </MainCard>
                    ) : (
                        <MainCard content>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<ReplayIcon />}
                                    onClick={() => {
                                        toggleReload();
                                    }}
                                >
                                    <FormattedMessage id="reload" />
                                </Button>
                            </div>
                        </MainCard>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
ListeOffresEmploi.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    paginationDetails: PropTypes?.object.isRequired,
    setRowToEdit: PropTypes?.func.isRequired
};
export default ListeOffresEmploi;

// material-ui
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Stack,
    Tooltip,
    Pagination,
    Typography
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import MainCard from 'ui-component/cards/MainCard';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/styles';
import TypographyListDescription from 'ui-component/TypographyListDescription';

// project imports
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import PropTypes from 'prop-types';

// styles
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const TraitementSortieTable = ({
    loading,
    handleClickOpenCreateOrEditDialog,
    listeTraitements,
    handleClickOpenDeleteAlertDialog,
    getTraitementsConges,
    handleFilter,
    handleClearSearch,
    filterValue,
    paginate,
    handleChangeRowsPerPage,
    paginationDetails
}) => {
    const theme = useTheme();
    const intl = useIntl();
    if (loading) return <TotalGrowthBarChart />;
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 40,
                    marginTop: 10,
                    marginBottom: 15
                }}
            >
                <div>
                    <ListSearchComponent
                        style={{ height: '100%' }}
                        handleChange={handleFilter}
                        filterValue={filterValue}
                        filter={getTraitementsConges}
                        handleClear={handleClearSearch}
                    />
                    <ButtonComponent
                        text={intl.formatMessage({ id: 'search' })}
                        style={{ marginLeft: 20, height: '100%' }}
                        handleClick={() => getTraitementsConges()}
                        variant="outlined"
                        sx={{
                            '&:hover': {
                                color: 'white',
                                backgroundColor: theme.palette.primary.main
                            }
                        }}
                    />
                </div>
                <ButtonComponent
                    startIcon={<Add />}
                    text={intl.formatMessage({ id: 'new-traitement' })}
                    handleClick={() => handleClickOpenCreateOrEditDialog()}
                    variant="contained"
                />
            </div>
            <MainCard content={false}>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: 320,
                            border: 1,
                            borderColor: theme.palette.grey[200]
                        }}
                        aria-label="customized table"
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <FormattedMessage id="personnel" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <FormattedMessage id="typesConges" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <FormattedMessage id="dateDemande" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <FormattedMessage id="dateDebut" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <FormattedMessage id="dateFin" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <FormattedMessage id="decision" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <FormattedMessage id="decisionDate" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <FormattedMessage id="actions" />
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(listeTraitements?.content)?.length !== 0 ? (
                                listeTraitements?.content?.map((row, index) => (
                                    <StyledTableRow
                                        key={index}
                                        sx={{
                                            ':hover td': {
                                                backgroundColor: '#DDEDF9',
                                                color: 'black'
                                            }
                                        }}
                                    >
                                        <StyledTableCell>
                                            {row.requestLeaveId ? row.requestLeaveId?.employee?.firstName : ''}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.requestLeaveId ? row.requestLeaveId?.typeConge?.code : ''}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.requestLeaveId ? row.requestLeaveId?.requestDate : ''}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.requestLeaveId ? row.requestLeaveId?.startDate : ''}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.requestLeaveId ? row.requestLeaveId?.endDate : ''}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.decision && row.decision}</StyledTableCell>
                                        <StyledTableCell align="right">{row.decisionDate ? row.decisionDate : ''}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Stack direction="row" justifyContent="right" alignItems="center" spacing={2}>
                                                <Tooltip title="Delete" arrow placement="left">
                                                    <IconButton
                                                        component="span"
                                                        style={{
                                                            padding: 4,
                                                            margin: 0
                                                        }}
                                                        size="smalll"
                                                        onClick={() => handleClickOpenDeleteAlertDialog(row)}
                                                        sx={{
                                                            ':hover': {
                                                                color: theme.palette.error.main
                                                            }
                                                        }}
                                                    >
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit" arrow placement="right">
                                                    <IconButton
                                                        component="span"
                                                        style={{
                                                            padding: 4,
                                                            margin: 0
                                                        }}
                                                        size="smalll"
                                                        onClick={() => {
                                                            handleClickOpenCreateOrEditDialog(row);
                                                        }}
                                                        sx={{
                                                            ':hover': {
                                                                color: theme.palette.primary.dark
                                                            }
                                                        }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row" colSpan={4}>
                                        <Typography textAlign="center" variant="subtitle1" color="inherit">
                                            <FormattedMessage id="noData" />
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                        backgroundColor: theme.palette.grey[200],
                        borderWidth: '1px',
                        borderColor: theme.palette.grey[300],
                        borderStyle: 'solid',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <TypographyListDescription text={intl.formatMessage({ id: 'display' })} />{' '}
                        <select onChange={handleChangeRowsPerPage} value={paginationDetails.pageSize}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>{' '}
                        </select>
                        <TypographyListDescription text={intl.formatMessage({ id: 'record' })} />{' '}
                    </div>{' '}
                    <Pagination
                        count={listeTraitements.totalPages}
                        color="primary"
                        size="small"
                        onChange={paginate}
                        shape="rounded"
                        page={paginationDetails.pageNumber}
                    />
                    <TypographyListDescription
                        text={`${listeTraitements.totalElements} ${intl.formatMessage({
                            id: 'record'
                        })}`}
                    />
                </div>
            </MainCard>
        </>
    );
};
TraitementSortieTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    handleClickOpenCreateOrEditDialog: PropTypes.func.isRequired,
    listeTraitements: PropTypes.object.isRequired,
    paginationDetails: PropTypes.object.isRequired,
    handleClickOpenDeleteAlertDialog: PropTypes.func.isRequired,
    getTraitementsConges: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    paginate: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    filterValue: PropTypes.string.isRequired
};
export default TraitementSortieTable;

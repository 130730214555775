import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GET_ABSENCES, SNACKBAR_OPEN } from 'store/actions';
import { absencesApi } from 'api';

const useGeneralRequest = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const loadTable = useCallback(
        (values) => {
            setLoading(true);
            if (values.operationType === 'attente') {
                absencesApi
                    .getAbsences(values)
                    .then((res) => {
                        if (res.success) {
                            const result = [];
                            console.log(res.data);
                            res.data.content.forEach((row) => {
                                result.push({
                                    id: row.id,
                                    reason: row.reason,
                                    startDate: new Date(row.startDate),
                                    endDate: row.endDate !== null ? new Date(row.endDate) : '',
                                    durationInHours: row.durationInHours,
                                    employee: {
                                        ...row.employe,
                                        id: row.employe.id,
                                        code: row.employe.code,
                                        firstName: row.employe.firstName,
                                        lastName: row.employe.lastName
                                    },
                                    absenceStatus: row.absenceStatus,
                                    justified: row.justified,
                                    justifiedOn: new Date(row.justifiedOn)
                                });
                            });
                            dispatch({
                                payload: result,
                                type: GET_ABSENCES,
                                operationType: {
                                    ...values,
                                    length: res.data?.totalPages || 0,
                                    totalItems: res.data?.totalElements || 0
                                }
                            });
                            setLoading(false);
                        } else {
                            dispatch({ payload: [], type: GET_ABSENCES, operationType: values });
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                                variant: 'alert',
                                alertSeverity: 'warning',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        dispatch({ payload: [], type: GET_ABSENCES, operationType: values });
                        console.log(err.message);
                        setLoading(false);
                    });
            } else if (values.operationType === 'justification') {
                absencesApi
                    .getAbsencesJustifications(values)
                    .then((res) => {
                        if (res.success) {
                            console.log(res.data);
                            const result = [];
                            res.data.content.forEach((row) => {
                                result.push({
                                    id: row.absence.id,
                                    reason: row.absence.reason,
                                    startDate: new Date(row.absence.startDate),
                                    endDate: row.absence.endDate !== null ? new Date(row.absence.endDate) : '',
                                    durationInHours: row.absence.durationInHours,
                                    employee: {
                                        ...row.absence.employee,
                                        id: row.absence.employee.id,
                                        code: row.absence.employee.code,
                                        firstName: row.absence.employee.firstName,
                                        lastName: row.absence.employee.lastName
                                    },
                                    absenceStatus: row.absenceJustificationStatus,
                                    justified: row.absence.justified,
                                    justifiedOn: new Date(row.absence.justifiedOn),
                                    file: {
                                        id: row.id,
                                        fileAttachmentLink: row.fileAttachmentLink
                                    }
                                });
                            });
                            dispatch({
                                payload: result,
                                type: GET_ABSENCES,
                                operationType: {
                                    ...values,
                                    length: res.data?.totalPages || 0,
                                    totalItems: res.data?.totalElements || 0
                                }
                            });
                            setLoading(false);
                        } else {
                            dispatch({ payload: [], type: GET_ABSENCES, operationType: values });
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                                variant: 'alert',
                                alertSeverity: 'warning',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                close: true
                            });
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        dispatch({ payload: [], type: GET_ABSENCES, operationType: values });
                        console.log(err.message);
                        setLoading(false);
                    });
            } else if (values.operationType === 'traitement') {
                absencesApi
                    .getAbsences(values)
                    .then((res) => {
                        console.log(res.data);
                        const result = [];
                        res.data.content.forEach((row) => {
                            result.push({
                                id: row.id,
                                reason: row.reason,
                                startDate: new Date(row.startDate),
                                endDate: row.endDate !== null ? new Date(row.endDate) : '',
                                durationInHours: row.durationInHours,
                                employee: {
                                    id: row.employe.id,
                                    code: row.employe.code,
                                    firstName: row.employe.firstName,
                                    lastName: row.employe.lastName
                                },
                                absenceStatus: row.absenceStatus,
                                justified: row.justified
                            });
                        });
                        dispatch({
                            payload: result,
                            type: GET_ABSENCES,
                            operationType: {
                                ...values,
                                length: res.data?.totalPages || 0,
                                totalItems: res.data?.totalElements || 0
                            }
                        });
                        setLoading(false);
                    })
                    .catch((err) => {
                        dispatch({ payload: [], type: GET_ABSENCES, operationType: values });
                        console.log(err.message);
                        setLoading(false);
                    });
            }
        },
        [dispatch]
    );
    return { loadTable, loading };
};

export default useGeneralRequest;

import { useEffect, useState } from 'react';
import { Grid, MenuItem, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { absencesApi } from 'api';
import { TableAbsences, TableAbsencesJustification } from './tables';
import AddAbsences from './AddAbsences';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch, useSelector } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import head from './head.json';
import InputField from 'ui-component/InputField';
import useGeneralRequest from './manageRequest';
import ViewAbsences from './ViewAbsences';

function getTableContent(
    params,
    dataAbsences,
    head,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteFunction,
    handleClickOpenCreateOrEditDialog,
    handleClickOpenViewDialog
) {
    switch (params.operationType) {
        case 'attente':
            return (
                <TableAbsences
                    actions
                    params={params}
                    dataAbsences={dataAbsences.rows}
                    headCells={head.headCells}
                    loading={loading}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    editFunction={handleClickOpenCreateOrEditDialog}
                    viewFunction={handleClickOpenViewDialog}
                    deleteFunction={deleteFunction}
                />
            );
        case 'justification':
            return (
                <TableAbsencesJustification
                    actions
                    params={params}
                    dataAbsences={dataAbsences.rows}
                    headCells={head.headCells}
                    loading={loading}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    viewFunction={handleClickOpenViewDialog}
                />
            );
        case 'traitement':
            return (
                <TableAbsences
                    actions
                    params={params}
                    dataAbsences={dataAbsences.rows}
                    headCells={head.headCells}
                    loading={loading}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    editFunction={handleClickOpenCreateOrEditDialog}
                    viewFunction={handleClickOpenViewDialog}
                    deleteFunction={deleteFunction}
                />
            );
        default:
            throw new Error('unknownStep');
    }
}

const initialValues = {
    id: '',
    employee: null,
    reason: '',
    startDate: new Date(),
    endDate: new Date(),
    durationInHours: ''
};

const Absences = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        operationType: 'attente',
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataAbsences, setDataAbsences] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [filterValue, setFilterValue] = useState('attente');
    const { loadTable, loading } = useGeneralRequest();
    const [current, setCurrent] = useState(null);
    const [openView, setOpenView] = useState(false);

    const result = useSelector((state) => state.absence);

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const refleshTable = (params) => {
        loadTable(params);
    };

    useEffect(() => {
        setDataAbsences({ rows: result.absences, rowsInitial: result.absences });
        setParams(result.type);
    }, [result]);

    useEffect(() => {
        refleshTable(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };

    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataAbsences.rows.filter((row) => {
                let matches = true;

                const properties = ['reason', 'startDate', 'endDate'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataAbsences({ ...dataAbsences, rows: newRows });
        } else {
            setDataAbsences({ ...dataAbsences, rows: dataAbsences.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddAbsences
                api={absencesApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const HandleOpenDialogView = () => {
        const openDialogView = openView ? (
            <ViewAbsences
                api={absencesApi}
                open={openView}
                setOpen={setOpenView}
                current={current}
                params={params}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialogView;
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const handleClickOpenViewDialog = (row) => {
        setCurrent(row);
        setOpenView(true);
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete(true);
    };
    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete(false);
                }}
                open={openDelete}
                text="Are you sure you want to delete this absence ?"
                actionSave={{ action: <FormattedMessage id="delete" />, color: 'error' }}
                actionCancel={{ action: <FormattedMessage id="cancel" />, color: 'primary' }}
                toDelete={yourState}
                api={absencesApi.deleteAbsences}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    const filterTraitement = (value) => {
        refleshTable({ ...params, operationType: value });
    };
    return (
        <>
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            {HandleOpenDialogView()}
            <Grid container spacing={2}>
                <HeaderComponent header="absences-list" subtitle="view-your-absences" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={8}>
                                    <Stack direction="row" justifyContent="start" alignItems="center" spacing={gridSpacing}>
                                        <ListSearchComponent
                                            handleChange={handleSearchValue}
                                            handleClear={() => {
                                                setSearchVal('');
                                                setDataAbsences({ ...dataAbsences, rows: dataAbsences.rowsInitial });
                                            }}
                                            filterValue={searchVal}
                                            filter={handleSearchValue}
                                        />
                                        <ButtonComponent
                                            text="Rechercher"
                                            handleClick={handleClickSearch}
                                            variant="outlined"
                                            sx={{
                                                '&:hover': {
                                                    color: 'white',
                                                    backgroundColor: theme?.palette.primary.main
                                                }
                                            }}
                                        />
                                        <Grid item xs={4}>
                                            <InputField
                                                select
                                                size="small"
                                                fullWidth
                                                label=""
                                                value={filterValue}
                                                handleChange={(e) => {
                                                    setFilterValue(e.target.value);
                                                    filterTraitement(e.target.value);
                                                }}
                                            >
                                                <MenuItem value="attente">Attente</MenuItem>
                                                <MenuItem value="justification">Justification</MenuItem>
                                                <MenuItem value="traitement">Traitement</MenuItem>
                                            </InputField>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            {getTableContent(
                                params,
                                dataAbsences,
                                head,
                                loading,
                                handleChangePage,
                                handleChangeRowsPerPage,
                                deleteFunction,
                                handleClickOpenCreateOrEditDialog,
                                handleClickOpenViewDialog
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Absences;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

const getTypeRating = async () => {
    try {
        const response = await instance.get(`${uri}/rating-types`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const addTypeRating = async (values) => {
    try {
        const response = await instance.post(`${uri}/rating-types`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypeRating = async (values) => {
    try {
        const response = await instance.put(`${uri}/rating-types/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypeRating = async (id) => {
    try {
        const response = await instance.delete(`${uri}/rating-types/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getTypeRating, addTypeRating, updateTypeRating, deleteTypeRating };

export const initTasks = () => {
    const currentDate = new Date();
    const tasks = [
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: 'Conge Maternite',
            id: 'CongeMaternite',
            progress: 25,
            type: 'project',
            hideChildren: false,
            displayOrder: 1
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 23, 59),
            name: 'Employee 1',
            id: 'Employee 1',
            progress: 45,
            type: 'task',
            project: 'CongeMaternite',
            displayOrder: 2
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 23, 59),
            name: 'Employee 2',
            id: 'Employee 2',
            progress: 45,
            type: 'task',
            project: 'CongeMaternite',
            displayOrder: 3
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 23, 59),
            name: 'Employee 3',
            id: 'Employee 3',
            progress: 45,
            type: 'task',
            project: 'CongeMaternite',
            displayOrder: 4
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15, 23, 59),
            name: 'Employee 4',
            id: 'Employee 4',
            progress: 10,
            type: 'task',
            project: 'CongeMaternite',
            displayOrder: 5
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: 'Conge Annuel',
            id: 'CongeAnnuel',
            progress: 25,
            type: 'project',
            hideChildren: false,
            displayOrder: 6
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
            name: 'Employee 5',
            id: 'Employee 5',
            progress: 45,
            type: 'task',
            project: 'CongeAnnuel',
            displayOrder: 7
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
            name: 'Employee 6',
            id: 'Employee 6',
            progress: 25,
            dependencies: ['Employee 5'],
            type: 'task',
            project: 'CongeAnnuel',
            displayOrder: 8
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
            name: 'Employee 7',
            id: 'Employee 7',
            progress: 10,
            dependencies: ['Employee 6'],
            type: 'task',
            project: 'CongeAnnuel',
            displayOrder: 9
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
            name: 'Employee 8',
            id: 'Employee 8',
            progress: 2,
            dependencies: ['Employee 7'],
            type: 'task',
            project: 'CongeAnnuel',
            displayOrder: 10
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
            name: 'Employee 9',
            id: 'Employee 9',
            type: 'task',
            progress: 70,
            dependencies: ['Employee 8'],
            project: 'CongeAnnuel',
            displayOrder: 11
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: 'Employee 10',
            id: 'Employee 10',
            progress: currentDate.getMonth(),
            type: 'milestone',
            dependencies: ['Employee 9'],
            project: 'CongeAnnuel',
            displayOrder: 12
        },
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
            name: 'Party Time',
            id: 'Employee 11',
            progress: 0,
            isDisabled: true,
            type: 'task'
        }
    ];
    return tasks;
};

export const getStartEndDateForProject = (tasks, projectId) => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    console.log(projectTasks);
    // let start = projectTasks[0].start;
    // let end = projectTasks[0].end;

    // for (let index = 0; index < array.length; index++) {
    //     const element = array[index];
    // }

    // for (let i = 0; i < projectTasks.length; i++) {
    //     const task = projectTasks[i];
    //     if (start.getTime() > task.start.getTime()) {
    //         start = task.start;
    //     }
    //     if (end.getTime() < task.end.getTime()) {
    //         end = task.end;
    //     }
    // }
    // return [start, end];
};

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getSortesConges() {
    try {
        const response = await instance.get(`${baseURL}/sorte-conges`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}
async function getSortesConge(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${baseURL}/sorte-conges`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveSortesConges = async (values) => {
    try {
        const response = await instance.post(`${baseURL}/sorte-conges`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateSortesConges = async (values) => {
    try {
        const response = await instance.put(`${baseURL}/sorte-conges/${values.id}`, values);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteSortesConges = async (id) => {
    try {
        const response = await instance.delete(`${baseURL}/sorte-conges/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};
export default {
    getSortesConge,
    getSortesConges,
    saveSortesConges,
    updateSortesConges,
    deleteSortesConges
};

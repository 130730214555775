import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit, Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import SearchTypeCredit from 'views/payrall-service/type-credit/SearchTypeCredit';
import TypeCreditList from './TypeCreditList';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    rate: yup.string().required('rate')
});

const AddSettingTypeCredit = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [dataTypeCredit, setDataTypeCredit] = useState(yourState.dataTypeCredit);
    const [openType, setOpenType] = useState(false);
    const [currentTypeCredit, setCurrentTypeCredit] = useState(null);

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'numero',
            align: 'left'
        },
        {
            id: 'code',
            numeric: true,
            label: 'code',
            align: 'left'
        },
        {
            id: 'designation',
            numeric: true,
            label: 'designation',
            align: 'left'
        },
        {
            id: 'rate',
            numeric: true,
            label: 'rate',
            align: 'right'
        }
    ];

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataTypeCredit.filter((row, index) => index !== indexOf);
        setDataTypeCredit(arrayCopy);
    };

    const handleClickOpenCreateOrEditDialog = (row, indexOf) => {
        const arrayCopy = dataTypeCredit.filter((row, index) => index !== indexOf);
        setDataTypeCredit(arrayCopy);
        setYourState({
            ...yourState,
            creditType: row.creditType,
            rate: row.rate
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const selectedIndex = dataTypeCredit.findIndex((object) => {
                return (
                    object.creditType.code === values.creditType?.code && object.creditType.designation === values.creditType?.designation
                );
            });
            let newTypeCredit = [];
            if (selectedIndex >= 0) {
                snackAlert('elementExist', 'warning');
                return;
            }

            newTypeCredit = newTypeCredit.concat(dataTypeCredit, [
                {
                    creditType: values.creditType,
                    rate: values.rate
                }
            ]);
            setDataTypeCredit(newTypeCredit);
        }
    });

    useEffect(() => {
        if (currentTypeCredit !== null) {
            setYourState({ ...yourState, ...formik.values, creditType: currentTypeCredit });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTypeCredit]);

    const getSubmitSettingTypeCredit = async () => {
        const valuesSettingTypeCredit = [];
        dataTypeCredit.forEach((row) => {
            valuesSettingTypeCredit.push({
                creditTypeId: row.creditType?.id,
                rate: row.rate
            });
        });
        if (valuesSettingTypeCredit.length > 0) {
            setLoading(true);
            if (formik.values.id === '') {
                api.addSettingTypeCredit(valuesSettingTypeCredit)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                const valuesUpSettingTypeCredit = {
                    id: formik.values.id,
                    creditTypeId: formik.values.creditType?.id,
                    rate: formik.values.rate
                };
                api.updateSettingTypeCredit(valuesUpSettingTypeCredit)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            snackAlert('addTypeCredit', 'warning');
        }
    };

    const reinitForm = () => {
        setYourState(initialValues);
        setDataTypeCredit([]);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    const HandleOpenDialogTypeCredit = () => {
        const openDialog = openType ? (
            <SearchTypeCredit open={openType} setOpen={setOpenType} setCurrentTypeCredit={setCurrentTypeCredit} />
        ) : (
            ''
        );
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogTypeCredit()}
            <Dialog fullWidth={fullWidth} maxWidth="md" open={open} TransitionComponent={Transition}>
                <SpinnerLoader open={loading} />
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>Add Setting Type credit</DialogTitle>
                    <IconButton
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label="creditType"
                                    id="creditType"
                                    name="creditType"
                                    value={`${formik.values.creditType?.code || ''} || ${formik.values.creditType?.designation || ''} `}
                                    handleChange={() => {}}
                                    onDoubleClick={() => formik.values.id === '' && setOpenType(true)}
                                    error={formik.touched.creditType && Boolean(formik.errors.creditType)}
                                    helperText={formik.touched.creditType && formik.errors.creditType}
                                    placeholder="creditType"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="rate"
                                    id="rate"
                                    name="rate"
                                    value={formik.values?.rate || ''}
                                    handleChange={formik.handleChange}
                                    error={formik.touched.rate && Boolean(formik.errors.rate)}
                                    helperText={formik.touched.rate && formik.errors.rate}
                                    placeholder="rate"
                                />
                            </Grid>
                            {formik.values.id === '' && (
                                <>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <ButtonComponent
                                                variant="outlined"
                                                type="submit"
                                                size="small"
                                                color="secondary"
                                                startIcon={<Add />}
                                                text="Add"
                                                handleClick={() => {}}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TypeCreditList
                                            actions
                                            dataTypeCredit={dataTypeCredit}
                                            headCells={headCells}
                                            deleteFunction={deleteFunction}
                                            editFunction={handleClickOpenCreateOrEditDialog}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                </form>
                <DialogActions sx={{ mr: 2 }}>
                    {formik.values.id === '' && (
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={<FormattedMessage id="reset" />}
                            handleClick={reinitForm}
                        />
                    )}
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={getSubmitSettingTypeCredit}
                        data-testid="button-component"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

AddSettingTypeCredit.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddSettingTypeCredit;

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
import { useSelector } from 'react-redux';
import career from 'menu-items/career';
import payroll from 'menu-items/payroll';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const stateMenus = useSelector((state) => state.menu.menus);

    let menuItem = { items: [career] };
    if (stateMenus.value === 'PAYROLL') {
        menuItem = { items: [payroll] };
    }

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    // return navItems;
    return <div>{navItems}</div>;
};

export default MenuList;

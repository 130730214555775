import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeTraitementsConges(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${baseURL}/leave-treatments`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveTraitementConge = async (tc) => {
    try {
        const response = await instance.post(`${baseURL}/leave-treatments`, tc);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTraitementConge = async (tc) => {
    try {
        const response = await instance.put(`${baseURL}/leave-treatments/${tc.id}`, tc);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTraitementConge = async (id) => {
    try {
        const response = await instance.delete(`${baseURL}/leave-treatments/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getListeTraitementsConges,
    saveTraitementConge,
    updateTraitementConge,
    deleteTraitementConge
};

const { GET_MENU } = require('./actions');

const initialState = {
    menus: { value: 'CAREER', items: [] },
    error: null
};

const menuReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_MENU:
            return {
                ...state,
                menus: action.payload
            };
        default:
            return state;
    }
};

export default menuReducer;

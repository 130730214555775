import { memo } from 'react';

// material-ui
import {
    Grid,
    IconButton,
    Table,
    TableContainer,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    TextField,
    TableBody
    // Typography
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
// import DatePickerComponent from 'ui-component/DatePickerComponent';
import InputField from 'ui-component/InputField';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';
import { Delete } from '@mui/icons-material';
import MainCard from 'ui-component/cards/MainCard';
import { styled } from '@mui/material/styles';
import SelectComponent from 'ui-component/SelectComponent';
import FormattageNombre from 'ui-component/FormattageNombre';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const CreationCandidatureHeader = ({
    handleChange,
    journalEntry,
    errors,
    handleChangeCompetences,
    journalEntryCompetences,
    removeCompetences,
    journals,
    handleChangeJournal,
    handleChangeAnneExperience
}) => {
    const intl = useIntl();
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={3} lg={3}>
                        <SelectComponent
                            label={intl.formatMessage({ id: 'obl-offre-emploi' })}
                            options={journals}
                            handleChange={handleChangeJournal}
                            name="jobPostingId"
                            value={journalEntry.jobPostingId}
                            error={errors.jobPostingId}
                            helperText={errors.jobPostingId}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <InputField
                            label={intl.formatMessage({ id: 'firstName' })}
                            name="firstName"
                            handleChange={handleChange}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            value={journalEntry.firstName}
                            error={errors.firstName}
                            helperText={errors.firstName}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <InputField
                            label={intl.formatMessage({ id: 'middleName' })}
                            name="middleName"
                            handleChange={handleChange}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            value={journalEntry.middleName}
                            error={errors.middleName}
                            helperText={errors.middleName}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <InputField
                            label={intl.formatMessage({ id: 'lastName' })}
                            name="lastName"
                            handleChange={handleChange}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            value={journalEntry.lastName}
                            error={errors.lastName}
                            helperText={errors.lastName}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <InputField
                            label={intl.formatMessage({ id: 'email' })}
                            name="email"
                            handleChange={handleChange}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 0
                            }}
                            inputSize="2px"
                            value={journalEntry.email}
                            error={errors.email}
                            helperText={errors.email}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <FormattageNombre
                            label={intl.formatMessage({ id: 'obl-experienceYears' })}
                            name="experienceYears"
                            allowNegative={false}
                            value={journalEntry.experienceYears}
                            handleChange={(values) => {
                                const { value } = values;
                                handleChangeAnneExperience(value);
                            }}
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                marginBottom: 2
                            }}
                            error={errors.experienceYears}
                            helperText={errors.experienceYears}
                            labelStyles={{ color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard content={false}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <FormattedMessage id="skills" />
                                            </StyledTableCell>
                                            <StyledTableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {journalEntryCompetences?.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell sx={{ width: '98%' }}>
                                                        <TextField
                                                            placeholder={intl.formatMessage({
                                                                id: 'entry-description'
                                                            })}
                                                            name="requiredSkills"
                                                            size="small"
                                                            fullWidth
                                                            onChange={(e) => {
                                                                handleChangeCompetences(e.target.value, index);
                                                            }}
                                                            autoComplete="off"
                                                            value={row.requiredSkills}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                if (journalEntryCompetences.length > 1) {
                                                                    removeCompetences(index);
                                                                }
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    color: 'red'
                                                                }
                                                            }}
                                                        >
                                                            {index === journalEntryCompetences.length - 1 && <Delete fontSize="small" />}
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
CreationCandidatureHeader.propTypes = {
    handleChange: PropTypes.func.isRequired,
    journalEntry: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChangeCompetences: PropTypes.func.isRequired,
    journalEntryCompetences: PropTypes.object.isRequired,
    removeCompetences: PropTypes.func.isRequired,
    journals: PropTypes.array.isRequired,
    handleChangeJournal: PropTypes.func.isRequired,
    handleChangeAnneExperience: PropTypes.func.isRequired
};

export default memo(CreationCandidatureHeader);

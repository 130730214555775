// assets
import { IconBrandChrome, IconSettings, IconUsers, IconHelp, IconSitemap, IconBug, IconFlag, IconHandFinger } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconSettings,
    IconUsers,
    IconBug,
    IconFlag,
    IconHandFinger
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const payroll = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'additional-params',
                    title: 'Additional params',
                    type: 'item',
                    url: '/settings/additional-params',
                    breadcrumbs: false
                },
                {
                    id: 'bank',
                    title: 'Bank',
                    type: 'item',
                    url: '/settings/bank',
                    breadcrumbs: false
                },
                {
                    id: 'heure-sup',
                    title: 'Heure supplementaire',
                    type: 'item',
                    url: '/settings/heure-sup',
                    breadcrumbs: false
                },
                {
                    id: 'setting-type-fee',
                    title: 'setting-type-fee',
                    type: 'item',
                    url: '/settings/type-fee',
                    breadcrumbs: false
                },
                {
                    id: 'setting-type-credit',
                    title: 'setting-type-credit',
                    type: 'item',
                    url: '/settings/type-credit',
                    breadcrumbs: false
                },
                {
                    id: 'social-organization',
                    title: 'social-organization',
                    type: 'item',
                    url: '/settings/social-organization',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'type-credit',
            title: 'type-credit',
            type: 'item',
            icon: icons.IconHelp,
            url: '/type-credit',
            breadcrumbs: false
        },
        {
            id: 'type-fee',
            title: 'type-fee',
            type: 'item',
            icon: icons.IconHelp,
            url: '/type-fee',
            breadcrumbs: false
        }
    ]
};

export default payroll;

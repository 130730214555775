import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './cartReducer';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import galeryReducer from './galeryReducer';
import employeeReducer from './employeeReducer';
import absenceReducer from './absenceReducer';
import menuReducer from './menuReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'jude-'
        },
        cartReducer
    ),
    snackbar: snackbarReducer,
    galery: galeryReducer,
    employee: employeeReducer,
    absence: absenceReducer,
    menu: menuReducer
});

export default reducer;

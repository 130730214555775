import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiResponse';

const uri = '/erp-payroll-hr-service/api/v1';

async function getAbsences(params) {
    try {
        const response = await instance.get(
            `${uri}/absences?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

async function getAbsencesJustifications(params) {
    try {
        const response = await instance.get(
            `${uri}/absences/justifications?page=${params?.page}&size=${params?.rowsPerPage}&sortOption=${params?.sortOption}`
        );
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const addAbsences = async (values) => {
    const valuesAbsences = {
        reason: values.reason,
        startDate: values.startDate,
        endDate: values.endDate,
        durationInHours: values.durationInHours
    };
    try {
        const response = await instance.post(`${uri}/employees/${values.employee?.id}/absences`, valuesAbsences);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateAbsences = async (values) => {
    const valuesAbsences = {
        reason: values.reason,
        startDate: values.startDate,
        endDate: values.endDate,
        durationInHours: values.durationInHours
    };
    try {
        const response = await instance.put(`${uri}/employees/${values.employee?.id}/absences/${values.id}`, valuesAbsences);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteAbsences = async (id) => {
    try {
        const response = await instance.delete(`${uri}/absences/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const uploadFile = async (values, formData) => {
    try {
        const response = await instance.post(`${uri}/absences/${values.id}/justifications`, formData);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default { getAbsences, getAbsencesJustifications, addAbsences, updateAbsences, deleteAbsences, uploadFile };

import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import HeaderComponent from 'ui-component/HeaderComponent';
import TableAdditionalParams from './TableAdditionalParams';
import TableAdditionalParamsBool from './TableAdditionalParamsBool';
import AddAdditionalParams from './AddAdditionalParams';
import { additionalParamsApi } from 'api';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { Refresh, SaveRounded } from '@mui/icons-material';

const AdditionalParams = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [current, setCurrent] = useState(null);
    const [open, setOpen] = useState(false);
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });

    const [paramsBool, setParamsBool] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataAdditionalParams, setDataAdditionalParams] = useState({ AddParams: [], AddParamsBool: [] });
    const [selected, setSelected] = useState([]);

    const headCells = [
        {
            id: 'id',
            numeric: true,
            label: 'ID',
            align: 'center'
        },
        {
            id: 'description',
            numeric: true,
            label: 'description',
            align: 'left'
        },
        {
            id: 'value',
            numeric: true,
            label: 'value',
            align: 'right'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const setAllParams = (addParams, addParamsBool) => {
        setParams({
            ...params,
            length: Math.ceil(addParams.length / params.rowsPerPage) || 1,
            totalItems: addParams?.length || 0
        });
        setParamsBool({
            ...paramsBool,
            length: Math.ceil(addParamsBool.length / paramsBool.rowsPerPage) || 1,
            totalItems: addParamsBool.length || 0
        });
    };

    const refleshTable = async () => {
        setLoading(true);
        await additionalParamsApi
            .getAdditionalParams()
            .then((res) => {
                if (res.success) {
                    const propertyEntries = Object.entries(res?.data);
                    const result = propertyEntries.map((row) => {
                        const map = new Map([
                            ['description', row[0]],
                            ['value', row[1]]
                        ]);
                        return Object.fromEntries(map);
                    });
                    const arrayAddParams = result.filter((row) => row.value !== true && row.value !== false && row.description !== 'id');
                    const arrayAddParamsBool = result.filter(
                        (row) => row.value === true || (row.value === false && row.description !== 'id')
                    );
                    setDataAdditionalParams({ AddParams: arrayAddParams, AddParamsBool: arrayAddParamsBool });
                    setSelected(arrayAddParamsBool.filter((row) => row.value === true));
                    setAllParams(arrayAddParams, arrayAddParamsBool);
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        refleshTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const HandleOpenDialog = () => {
        const openDialog =
            open && current !== null ? (
                <AddAdditionalParams
                    open={open}
                    setOpen={setOpen}
                    dataAdditionalParams={dataAdditionalParams}
                    setDataAdditionalParams={setDataAdditionalParams}
                    current={current}
                    setCurrent={setCurrent}
                />
            ) : (
                ''
            );
        return openDialog;
    };

    const handleSubmit = () => {
        const objectResultBool = {};
        dataAdditionalParams.AddParamsBool.forEach((row) => {
            const result = selected.indexOf(row) !== -1;
            objectResultBool[row.description] = result;
        });
        const objectResult = {};
        dataAdditionalParams.AddParams.forEach((row) => {
            objectResult[row.description] = row.value;
        });
        const valuesAddParams = Object.assign(objectResultBool, objectResult);
        setSubmitting(true);
        additionalParamsApi
            .addAdditionalParams(valuesAddParams)
            .then((res) => {
                if (res.success) {
                    snackAlert('Operation reussie', 'success');
                    refleshTable();
                    setSubmitting(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setSubmitting(false);
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    return (
        <>
            {HandleOpenDialog()}
            <Grid container spacing={2}>
                <HeaderComponent header="additional-params" subtitle="view-additional-params" />
                <SpinnerLoader open={submitting} />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                <ButtonComponent
                                    text={<FormattedMessage id="refresh" />}
                                    size="small"
                                    variant="contained"
                                    startIcon={<Refresh />}
                                    handleClick={() => {
                                        refleshTable();
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <TableAdditionalParamsBool
                                params={paramsBool}
                                setParams={setParamsBool}
                                dataAdditionalParams={dataAdditionalParams.AddParamsBool}
                                selected={selected}
                                setSelected={setSelected}
                                headCells={headCells}
                                loading={loading}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TableAdditionalParams
                                params={params}
                                setParams={setParams}
                                dataAdditionalParams={dataAdditionalParams.AddParams}
                                headCells={headCells}
                                loading={loading}
                                setOpen={setOpen}
                                setCurrent={setCurrent}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        text={<FormattedMessage id="save" />}
                        startIcon={<SaveRounded />}
                        handleClick={handleSubmit}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AdditionalParams;

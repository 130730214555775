const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 0,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: 'AIzaSyDqgh9qkaaYoSqfz7MMCpCBaRv8J5UCl5s',
        authDomain: 'jude-material-ui-react.firebaseapp.com',
        projectId: 'jude-material-ui-react',
        storageBucket: 'jude-material-ui-react.appspot.com',
        messagingSenderId: '807626934819',
        appId: '1:807626934819:web:c935fcc4b97a79e2603f96',
        measurementId: 'G-C3E3W1B70X'
    },
    auth0: {
        client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
        domain: 'dev-w0-vxep3.us.auth0.com'
    }
};

export default config;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    Typography,
    Divider
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import ButtonComponent from 'ui-component/ButtonComponent';
import {
    Close,
    CalendarTodayTwoTone,
    PhoneAndroidTwoTone,
    EmailTwoTone,
    Start,
    CancelScheduleSend,
    ScheduleSend
} from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import TrainerList from './TrainerList';
import CustomStatusDialog from './CustomStatusDialog';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const ViewTraining = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [openStatus, setOpenStatus] = useState({ start: false, end: false, cancel: false });

    const handleClose = () => {
        setYourState(initialValues);
        setOpen(false);
    };

    const HandleOpenStatusDialog = () => {
        let action = false;
        let text = '';
        let status = '';
        if (openStatus.start && yourState.trainingStatus === 'HAPPENING') {
            action = openStatus.start;
            text = 'Are you sure to end training';
            status = 'end';
        } else {
            action = openStatus.start;
            text = 'Are you sure to start training';
            status = 'start';
        }
        if (openStatus.cancel) {
            action = openStatus.cancel;
            text = 'Are you sure to cancel training';
            status = 'cancel';
        }
        const valuesStatus = {
            training: yourState,
            statusParam: status
        };
        const openDialog = action ? (
            <CustomStatusDialog
                handleClose={() => setOpenStatus(false)}
                open={action}
                text={text}
                values={valuesStatus}
                setYourState={setYourState}
                api={api.changeStatusTraining}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    return (
        <>
            {HandleOpenStatusDialog()}
            <Dialog fullWidth={fullWidth} maxWidth="lg" open={open.view} TransitionComponent={Transition}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                    <DialogTitle>View Training</DialogTitle>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: theme?.palette.error.light,
                                color: theme?.palette.primary.light
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <CalendarTodayTwoTone sx={detailsIconSX} />
                                                {yourState.sanctionAppealing?.disciplinaryMeasure?.employee?.firstName}
                                                {yourState.sanctionAppealing?.disciplinaryMeasure?.employee?.lastName}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <PhoneAndroidTwoTone sx={detailsIconSX} />
                                                {yourState.sanctionAppealing?.disciplinaryMeasure?.employee?.mobilePhoneNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                <EmailTwoTone sx={detailsIconSX} />{' '}
                                                {yourState.sanctionAppealing?.disciplinaryMeasure?.employee?.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={sxDivider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">Information Training</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                subject: &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState.subject}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                venue: &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState.venue?.toString().slice(0, 10)}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                trainingStatus : &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState?.trainingStatus === 'PENDING' && (
                                                                        <Chip label="pending" color="warning" size="small" />
                                                                    )}
                                                                    {yourState?.trainingStatus === 'HAPPENING' && (
                                                                        <Chip label="happening" color="primary" size="small" />
                                                                    )}
                                                                    {yourState?.trainingStatus === 'CANCELLED' && (
                                                                        <Chip label="cancelled" color="error" size="small" />
                                                                    )}
                                                                    {yourState?.trainingStatus === 'ENDED' && (
                                                                        <Chip label="ended" color="success" size="small" />
                                                                    )}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    <Typography variant="subtitle1">
                                                                        startDateTime : &nbsp;
                                                                        <Typography component="span" variant="body2">
                                                                            {yourState.startDateTime?.toString().slice(0, 10)}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography variant="subtitle1">
                                                                        endDateTime : &nbsp;
                                                                        <Typography component="span" variant="body2">
                                                                            {yourState.endDateTime?.toString().slice(0, 10)}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">Information About trainers</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TrainerList dataTrainer={yourState.trainers} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">Other information</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                maxExpectedParticipants : &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState.maxExpectedParticipants}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                additionalNotes : &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState.additionalNotes}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                trainingType : &nbsp;
                                                                <Typography component="span" variant="body2">
                                                                    {yourState.trainingType?.code} || {yourState.trainingType?.designation}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={sxDivider} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        color="secondary"
                        startIcon={yourState.trainingStatus === 'PENDING' ? <Start /> : <ScheduleSend />}
                        text={yourState.trainingStatus === 'PENDING' ? 'Start' : 'End'}
                        handleClick={() => setOpenStatus({ ...openStatus, start: true })}
                    />
                    <ButtonComponent
                        variant="outlined"
                        type="submit"
                        size="small"
                        text="Cancel"
                        startIcon={<CancelScheduleSend />}
                        handleClick={() => setOpenStatus({ ...openStatus, cancel: true })}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

ViewTraining.propTypes = {
    api: PropTypes.object.isRequired,
    params: PropTypes.object,
    open: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired,
    snackAlert: PropTypes.func.isRequired
};

export default ViewTraining;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Stack,
    TableSortLabel
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { Delete, AddCircleOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/system';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import InputField from 'ui-component/InputField';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';
import SearchEmployee from 'views/employee/SearchEmployee';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, theme }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    Action
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const Actions = ({ row, index, deleteFunction, theme }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: theme.palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired,
    theme: PropTypes?.object
};

const EmployeeOutingList = ({
    actions,
    dataEmployeeOuting,
    setDataEmployeeOuting,
    loading,
    headCells,
    deleteFunction,
    initState,
    setInitState,
    staticLenght,
    ...tableProps
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;
    const [openEmpl, setOpenEmpl] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);

    const validateForm = (touchedValid) => {
        return touchedValid.employee;
    };

    function validateField(fieldName, value) {
        const fieldValidationErrors = initState.formErrors;
        const fieldValidationTouched = initState.touched;
        switch (fieldName) {
            case 'employee':
                fieldValidationTouched.employee = value !== null && (value.length >= 1 || value !== '');
                fieldValidationErrors.employee = fieldValidationTouched.employee ? '' : ' employee is required';
                break;
            default:
                break;
        }
        setInitState({
            ...initState,
            [fieldName]: value,
            formErrors: fieldValidationErrors,
            touched: fieldValidationTouched,
            formValid: validateForm(fieldValidationTouched)
        });
    }

    const handleUserInput = (e) => {
        const name = e?.target.name;
        const value = e?.target.value;
        validateField(name, value);
    };

    useEffect(() => {
        if (currentEmployee !== null) {
            validateField('employee', currentEmployee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee]);

    const handleSubmitEmployeeOuting = (event) => {
        event.preventDefault();
        const selectedIndex = dataEmployeeOuting.findIndex((object) => {
            return object.employee.id === initState.employee.id;
        });
        let newEmployeeOuting = [];
        if (selectedIndex >= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'elementExist',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
            return;
        }

        newEmployeeOuting = newEmployeeOuting.concat(dataEmployeeOuting, [
            {
                employee: initState.employee
            }
        ]);
        setDataEmployeeOuting(newEmployeeOuting);
        setInitState({
            employee: null,
            formErrors: { employee: null },
            touched: { employee: false },
            formValid: false
        });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const HandleOpenDialogEmployee = () => {
        const openDialog = openEmpl ? <SearchEmployee open={openEmpl} setOpen={setOpenEmpl} setCurrentEmployee={setCurrentEmployee} /> : '';
        return openDialog;
    };

    return (
        <>
            {HandleOpenDialogEmployee()}
            <MainCard content={false}>
                {loading ? (
                    <EarningCard />
                ) : (
                    <TableContainer>
                        <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                            <EnhancedTableHead
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />

                            {dataEmployeeOuting && (
                                <TableBody>
                                    <StyledTableRow hover tabIndex={-1} data-testid="rowItems">
                                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                            >
                                                ###
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <InputField
                                                size="small"
                                                fullWidth
                                                label=""
                                                id="employee"
                                                name="employee"
                                                value={initState.employee?.firstName || ''}
                                                handleChange={(event) => handleUserInput(event)}
                                                onDoubleClick={() => setOpenEmpl(true)}
                                                error={!initState.touched.employee && Boolean(initState.formErrors.employee)}
                                                helperText={initState.formErrors.employee && initState.formErrors.employee}
                                                placeholder="doucleClick employee"
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <InputField
                                                size="small"
                                                fullWidth
                                                value={initState.employee?.lastName || ''}
                                                handleChange={() => {}}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
                                                <Tooltip title="Ajouter" arrow placement="left">
                                                    <IconButton
                                                        component="span"
                                                        size="medium"
                                                        sx={{
                                                            ':hover': {
                                                                color: theme.palette.success.main
                                                            }
                                                        }}
                                                        disabled={!initState.formValid}
                                                        onClick={handleSubmitEmployeeOuting}
                                                    >
                                                        <AddCircleOutlined fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {stableSort(dataEmployeeOuting, getComparator(order, orderBy))?.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        return (
                                            <StyledTableRow hover tabIndex={-1} key={index} data-testid="rowItems">
                                                <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        #{index + 1}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <InputField
                                                        size="small"
                                                        fullWidth
                                                        value={`${row.employee?.firstName}`}
                                                        handleChange={() => {}}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <InputField
                                                        size="small"
                                                        fullWidth
                                                        value={row.employee?.lastName}
                                                        handleChange={() => {}}
                                                    />
                                                </StyledTableCell>
                                                <Actions row={row} index={index} deleteFunction={deleteFunction} theme={theme} />
                                            </StyledTableRow>
                                        );
                                    })}
                                    {dataEmployeeOuting.length <= 0 && (
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                                <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                    <FormattedMessage id="no-data" />
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                )}
            </MainCard>
        </>
    );
};

EmployeeOutingList.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object,
    dataEmployeeOuting: PropTypes?.array.isRequired,
    setDataEmployeeOuting: PropTypes?.func.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool,
    staticLenght: PropTypes?.number,
    deleteFunction: PropTypes?.func.isRequired,
    initState: PropTypes?.object.isRequired,
    setInitState: PropTypes?.func.isRequired
};

export default EmployeeOutingList;

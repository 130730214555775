import PropTypes from 'prop-types';
import { Worker, ProgressBar, TextDirection, Icon, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const LayoutPdfViewer = ({ viewPdf, maxHeigth }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) =>
            defaultTabs.concat({
                content: <div style={{ textAlign: 'center', width: '100%' }}>Notes are listed here</div>,
                icon: (
                    <Icon size={16}>
                        <path d="M23.5,17a1,1,0,0,1-1,1h-11l-4,4V18h-6a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
                        <path d="M5.5 12L18.5 12" />
                        <path d="M5.5 7L18.5 7" />
                    </Icon>
                ),
                title: 'Notes'
            })
    });

    // const renderPage = (props) => {
    //     const { canvasLayer, scale } = props;
    //     console.log(canvasLayer);
    //     return (
    //         <>
    //             {canvasLayer.children}
    //             <div>ccc</div>
    //         </>
    //     );
    // };

    // renderPage.propTypes = {
    // props: PropTypes.shape({
    //     canvasLayer: PropTypes.object
    // }),
    // canvasLayer: PropTypes.shape({
    //     children: PropTypes.node
    // })
    // };
    // <>
    //     {props.canvasLayer.children}
    //     <div
    //         style={{
    //             alignItems: 'center',
    //             display: 'flex',
    //             height: '100%',
    //             justifyContent: 'center',
    //             left: 0,
    //             position: 'absolute',
    //             top: 0,
    //             width: '100%'
    //         }}
    //     >
    //         <div
    //             style={{
    //                 color: 'rgba(0, 0, 0, 0.2)',
    //                 fontSize: `${8 * props.scale}rem`,
    //                 fontWeight: 'bold',
    //                 textTransform: 'uppercase',
    //                 transform: 'rotate(-45deg)',
    //                 userSelect: 'none'
    //             }}
    //         >
    //             HR
    //         </div>
    //     </div>
    //     {props.annotationLayer.children}
    //     {props.textLayer.children}
    // </>

    // renderPage.propTypes = {
    //     props: PropTypes.object,
    //     canvasLayer: PropTypes.object,
    //     children: PropTypes.object,
    //     scale: PropTypes.object
    // };

    return (
        <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        flex: 1,
                        height: maxHeigth
                    }}
                >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                            theme={{
                                theme: 'dark',
                                direction: TextDirection.LeftToRight
                            }}
                            fileUrl={viewPdf || 'Invalid PDF structure'}
                            renderLoader={(percentages) => (
                                <div style={{ width: '240px' }}>
                                    <ProgressBar progress={Math.round(percentages)} />
                                </div>
                            )}
                            plugins={[defaultLayoutPluginInstance]}
                            // renderPage={renderPage}
                        />
                    </Worker>
                </div>
            </div>
        </div>
    );
};

LayoutPdfViewer.propTypes = {
    viewPdf: PropTypes.string,
    maxHeigth: PropTypes.string
};

export default LayoutPdfViewer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    criteriaName: yup.string().required('criteriaName')
});

const AddCriteria = ({ api, params, open, setOpen, initialValues, yourState, setYourState, refleshTable, snackAlert }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState,
        validationSchema,
        onSubmit: (values) => {
            const valuesVisa = {
                id: values.id,
                criteriaName: values.criteriaName
            };
            setLoading(true);
            if (values.id === '') {
                api.addCriteria(valuesVisa)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            formik.resetForm();
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                api.updateCriteria(valuesVisa)
                    .then((res) => {
                        if (res.success) {
                            snackAlert('Operation reussie', 'success');
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });

    const reinitForm = () => {
        setYourState(initialValues);
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add Criteria</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label="criteriaName"
                                id="criteriaName"
                                name="criteriaName"
                                value={formik.values?.criteriaName || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.criteriaName && Boolean(formik.errors.criteriaName)}
                                helperText={formik.touched.criteriaName && formik.errors.criteriaName}
                                placeholder="criteriaName"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={yourState?.id ? <FormattedMessage id="edit" /> : <FormattedMessage id="save" />}
                        startIcon={yourState?.id ? <Edit /> : <SaveRounded />}
                        handleClick={() => {}}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddCriteria.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    initialValues: PropTypes?.object.isRequired,
    yourState: PropTypes?.object.isRequired,
    setYourState: PropTypes?.func.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired
};

export default AddCriteria;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { outingApi } from 'api';
import TableOuting from './TableOuting';
import AddOuting from './AddOuting';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const initialValues = {
    id: '',
    requestDate: new Date(),
    departureTime: '',
    returnTime: '',
    reason: '',
    employees: []
};

const OutingList = ({ ...props }) => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataOuting, setDataOuting] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const headCells = [
        {
            id: 'number',
            numeric: true,
            label: 'number',
            align: 'center'
        },
        {
            id: 'reason',
            numeric: true,
            label: 'reason',
            align: 'left'
        },
        {
            id: 'requestDate',
            numeric: true,
            label: 'requestDate',
            align: 'right'
        },
        {
            id: 'departureTime',
            numeric: true,
            label: 'departureTime',
            align: 'right'
        },
        {
            id: 'returnTime',
            numeric: true,
            label: 'returnTime',
            align: 'right'
        },
        {
            id: 'employees',
            numeric: true,
            label: 'employees',
            align: 'left'
        },
        {
            id: 'status',
            numeric: true,
            label: 'status',
            align: 'left'
        }
    ];

    const setTimeHours = (value) => {
        const date = new Date();
        let time = '';
        try {
            time = date.setHours(value.split(':')[0], value.split(':')[1], value.split(':')[2]);
        } catch (error) {
            console.log(error);
        }
        return time;
    };

    const refleshTable = async (params) => {
        setLoading(true);
        await outingApi
            .getOuting(params)
            .then((res) => {
                if (res.success) {
                    const result = [];
                    console.log(res.data);
                    res.data.content.forEach((row) => {
                        const startHour = row.departureTime;
                        const endHour = row.returnTime;
                        const setStartHour = setTimeHours(startHour);
                        const setEndHour = setTimeHours(endHour);
                        result.push({
                            id: row.id,
                            reason: row.reason,
                            requestDate: new Date(row.requestDate),
                            departureTime: setStartHour,
                            returnTime: setEndHour,
                            status: row.status,
                            employees: row.employees.map((row) => {
                                return {
                                    employee: {
                                        id: row.id,
                                        code: row.code,
                                        firstName: row.firstName,
                                        middleName: row.middleName,
                                        lastName: row.lastName
                                    }
                                };
                            })
                        });
                    });
                    setDataOuting({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: res.data?.totalPages || 0,
                        totalItems: res.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: `${res.response?.data.description ? res.response.data.description : res.message}`,
                        variant: 'alert',
                        alertSeverity: 'warning',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        close: true
                    });
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataOuting.rows.filter((row) => {
                let matches = true;

                const properties = ['reason', 'requestDate'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataOuting({ ...dataOuting, rows: newRows });
        } else {
            setDataOuting({ ...dataOuting, rows: dataOuting.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddOuting
                api={outingApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete(true);
    };
    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete(false);
                }}
                open={openDelete}
                text="Outing"
                toDelete={yourState}
                api={outingApi.deleteOuting}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };
    return (
        <>
            {HandleOpenDeleteDialog()}
            {HandleOpenDialog()}
            <Grid container spacing={gridSpacing}>
                <HeaderComponent header="outing-list" subtitle="view-your-outing" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataOuting({ ...dataOuting, rows: dataOuting.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableOuting
                                actions
                                params={params}
                                dataOuting={dataOuting.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                setCurrentOuting={props.setCurrentOuting}
                                open={props.open}
                                setOpen={props.setOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

OutingList.propTypes = {
    setCurrentOuting: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default OutingList;

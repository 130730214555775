import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const FileUpload = ({ setFile, setViewFile }) => {
    const dispatch = useDispatch();
    const fileUploadType = ['image/jpeg', 'image/png'];

    const uploadHandler = (event) => {
        const file = event.target.files[0];
        if (file && fileUploadType.includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                setFile(file);
                setViewFile(e.target.result);
            };
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'No file selected',
                variant: 'alert',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                close: true
            });
        }
    };
    return (
        <div
            style={{
                backgroundColor: '#FFF',
                borderStyle: 'dotted',
                borderWidth: 2,
                minHeight: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <div
                style={{
                    position: 'relative'
                }}
            >
                <input
                    type="file"
                    style={{
                        position: 'relative',
                        height: '30px',
                        width: '185px',
                        cursor: 'pointer',
                        zIndex: 2,
                        opacity: 0
                    }}
                    onChange={uploadHandler}
                />
                <button
                    type="button"
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#000',
                        backgroundColor: '#EEEEEE',
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        border: 'none',
                        borderRadius: '4px',
                        outline: 'none'
                    }}
                >
                    <i
                        style={{
                            width: '1.5em',
                            height: '1.5em',
                            padding: '0,4em',
                            backgroundColor: '#fff',
                            color: '#2196F3',
                            borderRadius: '50%',
                            marginLeft: 0.1
                        }}
                    >
                        <CloudUploadIcon />
                    </i>
                    Choose image file
                </button>
            </div>
        </div>
    );
};
FileUpload.propTypes = {
    setFile: PropTypes.func,
    setViewFile: PropTypes.func
};

export default FileUpload;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Add, Refresh } from '@mui/icons-material';
import ButtonComponent from 'ui-component/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import ListSearchComponent from 'ui-component/ListSearchComponent';
import HeaderComponent from 'ui-component/HeaderComponent';
import { employeeApi } from 'api';
import TableEmployeeList from './TableEmployeeList';
import AddEmployee from './AddEmployee';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import CustomDeleteDialog from 'ui-component/CustomDeleteDialog';

const initialValues = {
    id: '',
    code: '',
    firstName: '',
    middleName: '',
    lastName: '',
    address1: '',
    address2: '',
    address3: '',
    numIdentity: '',
    deliveryDate: new Date(),
    email: '',
    mobilePhoneNumber: '',
    landline: '',
    officePhoneNumber: '',
    idTypePersonnel: 0,
    matriculeNumber: '',
    section: '',
    entryDate: new Date(),
    leaveDate: new Date(),
    birthDate: new Date(),
    birthPlace: '',
    nationality: '',
    socialFundNumber: '',
    accountingSuffix: '',
    maritalStatus: '',
    gender: '',
    idPayFrequency: 0,
    baseSalary: 0,
    paymentMode: 0,
    complement: '',
    camNumber: '',
    status: 'ACTIVE',
    conjoint: true,
    personalGradeId: ''
};

const EmployeeList = ({ ...props }) => {
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [dataEmployee, setDataEmployee] = useState({ rows: [], rowsInitial: [] });
    const [yourState, setYourState] = useState(initialValues);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const headCells = [
        {
            id: 'code',
            numeric: true,
            label: 'code',
            align: 'left'
        },
        {
            id: 'firstName',
            numeric: false,
            label: 'firstName',
            align: 'left'
        },
        {
            id: 'email',
            numeric: true,
            label: 'email',
            align: 'left'
        },
        {
            id: 'entryDate',
            numeric: true,
            label: 'entryDate',
            align: 'right'
        },
        {
            id: 'baseSalary',
            numeric: true,
            label: 'baseSalary',
            align: 'right'
        },
        {
            id: 'status',
            numeric: false,
            label: 'Status',
            align: 'center'
        }
    ];

    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            close: true
        });
    };

    const refleshTable = async (params) => {
        setLoading(true);
        await employeeApi
            .getEmployee(params)
            .then((res) => {
                if (res.success) {
                    const result = [];
                    res.data.content.forEach((row) => {
                        result.push({
                            ...row,
                            id: row.id,
                            code: row.code,
                            firstName: row.firstName,
                            middleName: row.middleName,
                            lastName: row.lastName,
                            address1: row.address1,
                            address2: row.address2,
                            address3: row.address3,
                            numIdentity: row.numIdentity,
                            deliveryDate: new Date(row.deliveryDate),
                            email: row.email,
                            mobilePhoneNumber: row.mobilePhoneNumber,
                            landline: row.landline,
                            officePhoneNumber: row.officePhoneNumber,
                            idTypePersonnel: row.idTypePersonnel,
                            matriculeNumber: row.matriculeNumber,
                            section: row.section,
                            entryDate: new Date(row.entryDate),
                            leaveDate: new Date(row.leaveDate),
                            birthDate: new Date(row.birthDate),
                            birthPlace: row.birthPlace,
                            nationality: row.nationality,
                            socialFundNumber: row.socialFundNumber,
                            accountingSuffix: row.accountingSuffix,
                            maritalStatus: row.maritalStatus,
                            gender: row.gender,
                            idPayFrequency: row.idPayFrequency,
                            baseSalary: row.baseSalary,
                            paymentMode: row.paymentMode,
                            complement: row.complement,
                            camNumber: row.camNumber,
                            status: row.status,
                            conjoint: row.conjoint,
                            personalGradeId: row.personalGradeId,
                            profileImage: `data:image/jpeg;base64,${row.profileImage === null ? '' : row.profileImage}`
                        });
                    });
                    setDataEmployee({ rows: result, rowsInitial: result });
                    setParams({
                        ...params,
                        length: res.data?.totalPages || 0,
                        totalItems: res.data?.totalElements || 0
                    });
                    setLoading(false);
                } else {
                    snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        isMounted.current = true;
        refleshTable(params);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, newPage) => {
        refleshTable({ ...params, page: newPage - 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        refleshTable({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = dataEmployee.rows.filter((row) => {
                let matches = true;

                const properties = ['code', 'firstName', 'email'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDataEmployee({ ...dataEmployee, rows: newRows });
        } else {
            setDataEmployee({ ...dataEmployee, rows: dataEmployee.rowsInitial });
        }
    };

    const handleClickSearch = () => {
        refleshTable({
            ...params,
            search: searchVal
        });
    };

    const HandleOpenDialog = () => {
        const openDialog = open ? (
            <AddEmployee
                api={employeeApi}
                params={params}
                open={open}
                setOpen={setOpen}
                initialValues={initialValues}
                yourState={yourState}
                setYourState={setYourState}
                refleshTable={refleshTable}
                snackAlert={snackAlert}
            />
        ) : (
            ''
        );
        return openDialog;
    };

    const handleClickOpenCreateOrEditDialog = (row) => {
        setYourState(row);
        setOpen(true);
    };

    const deleteFunction = (row) => {
        setYourState(row);
        setOpenDelete(true);
    };

    const HandleOpenDeleteDialog = () => {
        const deleteDialog = openDelete ? (
            <CustomDeleteDialog
                handleClose={() => {
                    setYourState(initialValues);
                    setOpenDelete(false);
                }}
                open={openDelete}
                text="Employee"
                toDelete={yourState}
                api={employeeApi.deleteEmployee}
                snackAlert={snackAlert}
                params={params}
                refleshTable={refleshTable}
            />
        ) : (
            ''
        );
        return deleteDialog;
    };

    return (
        <>
            {HandleOpenDialog()}
            {HandleOpenDeleteDialog()}
            <Grid container spacing={gridSpacing}>
                <HeaderComponent header="employee-list" subtitle="view-your-employee" />
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ListSearchComponent
                                        handleChange={handleSearchValue}
                                        handleClear={() => {
                                            setSearchVal('');
                                            setDataEmployee({ ...dataEmployee, rows: dataEmployee.rowsInitial });
                                        }}
                                        filterValue={searchVal}
                                        filter={handleSearchValue}
                                    />
                                    <ButtonComponent
                                        text="Rechercher"
                                        handleClick={handleClickSearch}
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: theme?.palette.primary.main
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                                    <ButtonComponent
                                        text={<FormattedMessage id="refresh" />}
                                        size="small"
                                        variant="contained"
                                        startIcon={<Refresh />}
                                        handleClick={() => {
                                            refleshTable({ ...params, page: 0 });
                                        }}
                                    />
                                    <ButtonComponent
                                        text="Ajouter"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Add />}
                                        handleClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TableEmployeeList
                                actions
                                params={params}
                                dataEmployee={dataEmployee.rows}
                                headCells={headCells}
                                loading={loading}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                editFunction={handleClickOpenCreateOrEditDialog}
                                deleteFunction={deleteFunction}
                                setCurrentEmployee={props.setCurrentEmployee}
                                setOpen={props.setOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

EmployeeList.propTypes = {
    setCurrentEmployee: PropTypes.func,
    setOpen: PropTypes.func
};

export default EmployeeList;

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, Edit, Save } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
// import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import FormattageNombre from 'ui-component/FormattageNombre';
import moment from 'moment/moment';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const validationSchema = yup.object({
    code: yup
        .string()
        .max(3, <FormattedMessage id="code-car-req" />)
        .required(<FormattedMessage id="code-req" />),
    designation: yup.string().required(<FormattedMessage id="designation-req" />)
});

const BaremeOnprModal = ({ handleClose, baremoToEdit, handleSubmit, loading, open }) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: baremoToEdit?.code,
            designation: baremoToEdit?.designation,
            date_debut: baremoToEdit?.date_debut,
            date_fin: baremoToEdit?.date_fin,
            coefficient: baremoToEdit?.coefficient,
            categorie: baremoToEdit?.categorie,
            tranche_min: baremoToEdit?.tranche_min,
            tranche_max: baremoToEdit?.tranche_max,
            point_achete: baremoToEdit?.point_achete,
            valeur_achat: baremoToEdit?.valeur_achat
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const baremeOnprToSave = {
                ...values,
                date_debut: moment(values?.date_debut).format('YYYY-MM-DD'),
                date_fin: moment(values?.date_fin).format('YYYY-MM-DD')
            };
            handleSubmit(baremeOnprToSave, resetForm);
        }
    });
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-adresse1"
                maxWidth="sm"
                fullWidth
            >
                {/* <SpinnerLoader open={loading} /> */}
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} sx={{ mr: 2 }}>
                        <DialogTitle id="alert-dialog-slide-title1" sx={{ color: theme.palette.table.header }}>
                            {baremoToEdit.id ? <FormattedMessage id="edit-bareme-onpr" /> : <FormattedMessage id="add-new-bareme-onpr" />}
                        </DialogTitle>
                        <IconButton
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'obl-code' })}
                                            name="code"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9a-zA-Z-]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('code', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                            helperText={formik.touched.code && formik.errors.code}
                                            type="text"
                                            value={formik.values.code}
                                            disabled={loading}
                                            inputProps={{ maxLength: 6 }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'obl-entitled' })}
                                            name="designation"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([a-zA-Z\s-]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('designation', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.designation && Boolean(formik.errors.designation)}
                                            helperText={formik.touched.designation && formik.errors.designation}
                                            value={formik.values.designation}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <DatePickerComponent
                                            // size="small"
                                            // fullWidth
                                            label={intl.formatMessage({ id: 'dateDebut' })}
                                            id="date_debut"
                                            name="date_debut"
                                            value={formik.values.date_debut}
                                            handleChange={(value) => formik.setFieldValue('date_debut', value)}
                                            error={formik.touched.date_debut && Boolean(formik.errors.date_debut)}
                                            helperText={formik.touched.date_debut && formik.errors.date_debut}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <DatePickerComponent
                                            // size="small"
                                            // fullWidth
                                            label={intl.formatMessage({ id: 'dateFin' })}
                                            id="date_fin"
                                            name="date_fin"
                                            value={formik.values.date_fin}
                                            handleChange={(value) => formik.setFieldValue('date_fin', value)}
                                            error={formik.touched.date_fin && Boolean(formik.errors.date_fin)}
                                            helperText={formik.touched.date_fin && formik.errors.date_fin}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'coefficient' })}
                                            name="coefficient"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('coefficient', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.coefficient && Boolean(formik.errors.coefficient)}
                                            helperText={formik.touched.coefficient && formik.errors.coefficient}
                                            value={formik.values.coefficient}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="right"
                                                        style={{ color: 'black' }}
                                                        alignItems="right"
                                                        align="right"
                                                    >
                                                        $
                                                    </InputAdornment>
                                                )
                                            }}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'obl-categorie' })}
                                            id="categorie"
                                            name="categorie"
                                            value={formik.values?.categorie || ''}
                                            handleChange={formik.handleChange}
                                            error={formik.touched.categorie && Boolean(formik.errors.categorie)}
                                            helperText={formik.touched.categorie && formik.errors.categorie}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'tranche_min' })}
                                            name="tranche_min"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('tranche_min', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.tranche_min && Boolean(formik.errors.tranche_min)}
                                            helperText={formik.touched.tranche_min && formik.errors.tranche_min}
                                            value={formik.values.tranche_min}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="right"
                                                        style={{ color: 'black' }}
                                                        alignItems="right"
                                                        align="right"
                                                    >
                                                        TMin
                                                    </InputAdornment>
                                                )
                                            }}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'tranche_max' })}
                                            name="tranche_max"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('tranche_max', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.tranche_max && Boolean(formik.errors.tranche_max)}
                                            helperText={formik.touched.tranche_max && formik.errors.tranche_max}
                                            value={formik.values.tranche_max}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="right"
                                                        style={{ color: 'black' }}
                                                        alignItems="right"
                                                        align="right"
                                                    >
                                                        TMax
                                                    </InputAdornment>
                                                )
                                            }}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'point_achete' })}
                                            name="point_achete"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('point_achete', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.point_achete && Boolean(formik.errors.point_achete)}
                                            helperText={formik.touched.point_achete && formik.errors.point_achete}
                                            value={formik.values.point_achete}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormattageNombre
                                            label={intl.formatMessage({ id: 'valeur_achat' })}
                                            name="valeur_achat"
                                            allowNegative={false}
                                            value={formik.values.valeur_achat}
                                            handleChange={(values) => {
                                                const { value } = values;
                                                formik.setFieldValue('valeur_achat', value);
                                            }}
                                            style={{
                                                marginTop: 0,
                                                marginRight: 0,
                                                marginBottom: 2
                                            }}
                                            error={formik.touched.valeur_achat && Boolean(formik.errors.valeur_achat)}
                                            helperText={formik.touched.valeur_achat && formik.errors.valeur_achat}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid>
                                    {/* <Grid item md={6} xs={12}>
                                        <InputField
                                            label={intl.formatMessage({ id: 'taux' })}
                                            name="taux"
                                            handleChange={(e) => {
                                                if (e.target.value.match(/^([0-9,.]+)$/) || e.target.value === '') {
                                                    formik.setFieldValue('taux', e.target.value);
                                                }
                                            }}
                                            error={formik.touched.taux && Boolean(formik.errors.taux)}
                                            helperText={formik.touched.taux && formik.errors.taux}
                                            value={formik.values.taux}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="right"
                                                        style={{ color: 'black' }}
                                                        alignItems="right"
                                                        align="right"
                                                    >
                                                        %
                                                    </InputAdornment>
                                                )
                                            }}
                                            labelStyles={{ color: 'black' }}
                                        />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <ButtonComponent
                            variant="outlined"
                            size="small"
                            color="secondary"
                            startIcon={<RotateLeftRounded />}
                            text={intl.formatMessage({ id: 'reset' })}
                            disabled={loading || !formik.dirty}
                            handleClick={() => formik.resetForm()}
                        />
                        <ButtonComponent
                            variant="contained"
                            text={
                                baremoToEdit.id
                                    ? `${intl.formatMessage({ id: 'update' })}`
                                    : `${intl.formatMessage({
                                          id: 'save'
                                      })}`
                            }
                            disabled={loading || !formik.dirty}
                            size="small"
                            type="submit"
                            startIcon={baremoToEdit.id ? <Edit /> : <Save />}
                            endIcon={loading ? <CircularProgress size="16px" /> : null}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

BaremeOnprModal.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    baremoToEdit: PropTypes?.object.isRequired,
    // listeSortesConges: PropTypes?.array.isRequired,
    handleSubmit: PropTypes?.func.isRequired,
    loading: PropTypes?.bool.isRequired
};

export default BaremeOnprModal;

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const TypographyListDescription = ({ text, props }) => {
    return (
        <Typography variant="body1" gutterBottom {...props}>
            {text}
        </Typography>
    );
};

TypographyListDescription.propTypes = {
    text: PropTypes.string,
    props: PropTypes.object
};

export default TypographyListDescription;

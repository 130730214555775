import { forwardRef, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
    Dialog,
    Grid,
    IconButton,
    Pagination,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    DialogContent,
    DialogTitle
} from '@mui/material';

// material-ui
import ButtonComponent from 'ui-component/ButtonComponent';
import CloseIcon from '@mui/icons-material/Close';
// material-ui
// third-party
// import SelectComponent from 'ui-component/SelectComponent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TotalGrowthBarChart from 'ui-component/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import DeletInvoiceCustomerDialog from 'ui-component/DeleteDialog';
import { jobsPostingAPI, jobSubmissionsAPI } from 'api';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePickerComponent from 'ui-component/DatePickerComponent';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import { gridSpacing } from 'store/constant';
import moment from 'moment';
import PropTypes from 'prop-types';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// styles
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D5A80',
        color: 'white',
        fontWeight: 'bolder',
        borderRight: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const ListeCandidatures = ({ open, handleClose, setRowToEdit, paginationDetails }) => {
    const [rowToDelete, setRowToDelete] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [entriesFilters, setEntriesFilters] = useState({
        openingDate: new Date(),
        closingDate: new Date(),
        pageSize: 20,
        currentPage: 1
    });
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    // const [journalEntries, setJournalEntries] = useState({
    //     content: [],
    //     totalElements: 0,
    //     totalPages: 0
    // });
    const [journalEntries, setJournalEntries] = useState([]);

    const dispatch = useDispatch();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: state
        });
    };

    const handleChangeClosingDate = (value) => {
        setEntriesFilters({
            ...entriesFilters,
            closingDate: value
        });
        setErrors({
            openingDate: ''
        });
    };
    const handleChangeOpeningDate = (value) => {
        setEntriesFilters({
            ...entriesFilters,
            openingDate: value,
            closingDate: ''
        });
        setErrors({
            closingDate: ''
        });
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const validateFilters = () => {
        if (!entriesFilters.openingDate) {
            setErrors({
                openingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(entriesFilters.openingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                openingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        if (!entriesFilters.closingDate) {
            setErrors({
                closingDate: <FormattedMessage id="this-field-can-not-be-empty" />
            });
            return false;
        }
        if (!moment(entriesFilters.closingDate, 'YYYY-MM-DD').isValid()) {
            setErrors({
                ...errors,
                closingDate: <FormattedMessage id="format-date-invalid" />
            });
            return false;
        }
        return true;
    };
    const getJournalEntries = () => {
        if (validateFilters()) {
            setLoading(true);
            jobSubmissionsAPI
                .getListeCandidature()
                .then((res) => {
                    // setJournalEntries(res.content);
                    setJournalEntries(res);
                })
                .catch((err) => {
                    snackAlert(err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const intl = useIntl();
    const handleChangeRowsPerPage = (e) => {
        const { value } = e.target;
        setEntriesFilters({
            ...entriesFilters,
            pageSize: value,
            currentPage: 0
        });
    };

    const paginate = (e, values) => {
        setEntriesFilters({
            ...entriesFilters,
            currentPage: values
        });
        getJournalEntries(values);
    };
    console.log('OBJET', journalEntries);
    return (
        <div>
            <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setJournalEntries({
                                    content: [],
                                    totalElements: 0,
                                    totalPages: 0
                                });

                                handleClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h4" component="div">
                            <FormattedMessage id="close" />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    <FormattedMessage id="Offres d'emploi" />
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={4} md={3} lg={2} sm={3}>
                            <DatePickerComponent
                                label={<FormattedMessage id="from" />}
                                value={entriesFilters?.openingDate}
                                handleChange={handleChangeOpeningDate}
                                style={{ fontWeight: '400' }}
                                labelStyles={{ fontWeight: 'bold' }}
                                error={errors?.openingDate && Boolean(errors?.openingDate)}
                                helperText={errors?.openingDate && errors?.openingDate}
                            />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2} sm={3}>
                            <DatePickerComponent
                                label={<FormattedMessage id="to" />}
                                value={entriesFilters?.closingDate}
                                handleChange={handleChangeClosingDate}
                                style={{ fontWeight: '400' }}
                                labelStyles={{ fontWeight: 'bold' }}
                                error={errors?.closingDate && Boolean(errors?.closingDate)}
                                helperText={errors?.closingDate && errors?.closingDate}
                            />
                        </Grid>

                        {/* <Grid item xs={4} md={3} lg={2} sm={3}>
                            <SelectComponent
                                // options={[{ label: <FormattedMessage id="all" />, value: -1 }, ...draftEntries]}
                                options={[{ label: <FormattedMessage id="all" />, value: -1 }]}
                                label={<FormattedMessage id="draft-entries" />}
                                name="fichierSaisie"
                                handleChange={(e) => {
                                    setEntriesFilters({
                                        ...entriesFilters,
                                        draftEntry: e.target.value
                                    });
                                }}
                                value={entriesFilters.draftEntry}
                                labelStyles={{ fontWeight: 'bold' }}
                            />
                        </Grid> */}
                        <Grid item xs={6} md={6} lg={1}>
                            <Stack alignItems="flex-start" justifyContent="flex-end" height="100%">
                                <ButtonComponent
                                    variant="contained"
                                    style={{
                                        height: '70%',
                                        alignSelf: 'start'
                                    }}
                                    sx={{
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: theme.palette.primary.main
                                        }
                                    }}
                                    text={<FormattedMessage id="search" />}
                                    handleClick={() => getJournalEntries()}
                                    disabled={loading}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <MainCard content={false} style={{ marginTop: 10 }}>
                        {loading ? (
                            <TotalGrowthBarChart />
                        ) : (
                            <TableContainer>
                                <Table
                                    sx={{
                                        minWidth: 320,
                                        border: 1,
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    aria-label="customized table"
                                    size="small"
                                >
                                    <TableHead>
                                        <TableRow
                                            style={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                fontWeight: 'bolder',
                                                borderRight: '0.5px solid #ccc'
                                            }}
                                        >
                                            <StyledTableCell>
                                                <FormattedMessage id="position" />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <FormattedMessage id="openingDate" />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <FormattedMessage id="closingDate" />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <FormattedMessage id="nombre" />
                                            </StyledTableCell>

                                            <StyledTableCell>
                                                <FormattedMessage id="description" />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <FormattedMessage id="actions" />
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {journalEntries?.content?.length ? (
                                            journalEntries?.content?.map((row, index) => ( */}
                                        {journalEntries?.length ? (
                                            journalEntries?.map((row, index) => (
                                                <StyledTableRow
                                                    sx={{
                                                        ':hover td': {
                                                            backgroundColor: '#DDEDF9',
                                                            color: 'black',
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                    key={index}
                                                    onClick={() => {
                                                        // setJournalEntries({
                                                        //     content: [],
                                                        //     totalElements: 0,
                                                        //     totalPages: 0
                                                        // });
                                                        setJournalEntries([]);
                                                        setRowToEdit(row);
                                                    }}
                                                >
                                                    <StyledTableCell>{row.jobPosting ? row.jobPosting?.position : ''}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {row.jobPosting
                                                            ? new Date(row.jobPosting?.openingDate).toLocaleDateString(intl.locale)
                                                            : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {row.jobPosting
                                                            ? new Date(row.jobPosting?.closingDate).toLocaleDateString(intl.locale)
                                                            : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row?.journal.designation}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {row?.firstName ? `${row?.firstName} ${row?.laststName}` : ''}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell>{row?.description}</StyledTableCell>

                                                    <StyledTableCell align="right">
                                                        {row?.total.toLocaleString(intl.locale)}
                                                    </StyledTableCell> */}
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row" colSpan={8}>
                                                    <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                        <FormattedMessage id="noData" />
                                                    </Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {/* <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: 10,
                                backgroundColor: theme.palette.grey[200],
                                borderWidth: '1px',
                                borderColor: theme.palette.grey[300],
                                borderStyle: 'solid',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <TypographyListDescription text={intl.formatMessage({ id: 'display' })} />{' '}
                                <select onChange={handleChangeRowsPerPage}>
                                    <option value={5}>5</option>
                                    <option value={10}>20</option>
                                    <option value={25}>25</option>
                                </select>
                                <TypographyListDescription text={intl.formatMessage({ id: 'record' })} />{' '}
                            </div>
                            <Pagination
                                count={journalEntries.totalPages}
                                color="primary"
                                size="small"
                                onChange={paginate}
                                shape="rounded"
                                page={entriesFilters.currentPage}
                            />
                            <TypographyListDescription text={`${journalEntries.totalElements} recordings`} />
                        </div> */}
                    </MainCard>
                </DialogContent>
            </Dialog>
            <DeletInvoiceCustomerDialog handleClose={handleCloseDeleteDialog} open={openDeleteDialog} rowToDelete={rowToDelete} />
        </div>
    );
};
ListeCandidatures.propTypes = {
    open: PropTypes?.bool.isRequired,
    handleClose: PropTypes?.func.isRequired,
    paginationDetails: PropTypes?.object.isRequired,
    setRowToEdit: PropTypes?.func.isRequired
};
export default ListeCandidatures;

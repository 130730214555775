import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonComponent = ({ text, style, handleClick, variant, type, endIcon, startIcon, disabled, ...props }) => {
    return (
        <Button
            variant={variant}
            {...props}
            size="small"
            name="button-component"
            endIcon={endIcon}
            startIcon={startIcon}
            disabled={disabled}
            type={type}
            style={{
                cursor: 'pointer',
                paddingLeft: 30,
                paddingRight: 30,
                textTransform: 'none',
                ...style
            }}
            onClick={handleClick}
            //  sx={{
            //    "&:hover": {
            //      backgroundColor: "green",
            //    },
            //  }}
        >
            {text}
        </Button>
    );
};

ButtonComponent.propTypes = {
    text: PropTypes?.oneOfType([PropTypes.string, PropTypes.object]),
    // style: PropTypes.element,
    style: PropTypes.object,
    handleClick: PropTypes.func,
    variant: PropTypes.string,
    type: PropTypes.string,
    endIcon: PropTypes.element,
    startIcon: PropTypes.element,
    disabled: PropTypes.bool
};

export default ButtonComponent;

import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { dureeCongeAPI, typeCongeAPI, typePersonnelAPI } from 'api';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import DureeCongeTable from './DureeCongeTable';
import DureeCongeModal from './DureeCongeModal';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [dureeCongeListe, setDureeCongeListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [listeTypeConge, setListeTypeConge] = useState([]);
    const [listeTypesPersonnels, setListeTypesPersonnels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dureeToDelete, setDureeToDelete] = useState(null);
    const [dureeToEdit, setDureeToEdit] = useState({
        numberDays: null,
        yearAfter: null,
        durationAdd: null,
        consideredDays: false,
        typeCongeId: null,
        personalTypeId: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const dureePromise = dureeCongeAPI.getListeDureesConges(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        const typeCongePromise = typeCongeAPI.getTypesConges('', 100, 0);
        const typePersonnelPromise = typePersonnelAPI.getListeTypesPersonnels('', 100, 0);
        axios
            .all([dureePromise, typeCongePromise, typePersonnelPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const dureeData = responses[0]?.content;
                        const typeConge = responses[1]?.content?.map((tc) => ({
                            label: `${tc.code} | ${tc.designation}`,
                            value: tc.id
                        }));
                        const typePersonnel = responses[2]?.content?.map((tp) => ({
                            label: `${tp.code} | ${tp.designation}`,
                            value: tp.id
                        }));
                        const dureesCongesData = [];
                        dureeData.forEach((row) => {
                            dureesCongesData.push({
                                id: row.id,
                                numberDays: row.numberDays,
                                yearAfter: row.yearAfter,
                                durationAdd: row.durationAdd,
                                consideredDays: row.consideredDays,
                                typeCongeId: row.typeConge,
                                personalTypeId: row.personalType
                            });
                        });
                        setDureeCongeListe((dureeCongeListe) => ({
                            ...dureeCongeListe,
                            ...responses[0],
                            content: dureesCongesData
                        }));
                        setListeTypeConge(typeConge);
                        setListeTypesPersonnels(typePersonnel);
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setDureeToEdit({
                ...row,
                typeCongeId: {
                    label: `${row?.typeCongeId?.code} | ${row?.typeCongeId?.designation}`,
                    value: row?.typeCongeId?.id
                },
                personalTypeId: {
                    label: `${row?.personalTypeId?.code} | ${row?.personalTypeId?.designation}`,
                    value: row?.personalTypeId?.id
                }
            });
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            dureeCongeAPI
                .getListeDureesConges()
                .then((res) => {
                    setDureeCongeListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setDureeToEdit({
            numberDays: null,
            yearAfter: null,
            durationAdd: null,
            consideredDays: false,
            typeCongeId: null,
            personalTypeId: null
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (duree) => {
        setDureeToDelete(duree);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (dureeToDelete) => {
        if (dureeToDelete) {
            setDureeCongeListe(dureeCongeListe.filter((duree) => duree.id !== dureeToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getDureesConges = (pageNumber, pageSize) => {
        setLoading(true);
        let dureePromise;
        if (!pageNumber && !pageSize) {
            dureePromise = dureeCongeAPI.getListeDureesConges(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            dureePromise = dureeCongeAPI.getListeDureesConges(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([dureePromise])
            .then(
                axios.spread((...responses) => {
                    const dureeCongesData = [];
                    responses[0]?.forEach((row) => {
                        dureeCongesData.push({
                            id: row.id,
                            numberDays: row.numberDays,
                            yearAfter: row.yearAfter,
                            durationAdd: row.durationAdd,
                            consideredDays: row.consideredDays,
                            typeCongeId: row.typeConge,
                            personalTypeId: row.personalType
                        });
                    });
                    setDureeCongeListe((dureeCongeListe) => ({
                        ...dureeCongeListe,
                        ...responses[0],
                        content: dureeCongesData
                    }));
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        dureeCongeAPI
            .deleteDureeConge(dureeToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getDureesConges();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getDureesConges(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getDureesConges(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (dureeToEdit.id) {
            setLoading(true);
            dureeCongeAPI
                .updateDureeConge({
                    id: dureeToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getDureesConges();
                    setDureeToEdit({
                        numberDays: null,
                        yearAfter: null,
                        durationAdd: null,
                        consideredDays: false,
                        typeCongeId: null,
                        personalTypeId: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            dureeCongeAPI
                .saveDureeConge({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setDureeToEdit({
                        numberDays: null,
                        yearAfter: null,
                        durationAdd: null,
                        consideredDays: false,
                        typeCongeId: null,
                        personalTypeId: null
                    });
                    getDureesConges();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFilterList = (event) => {
        const newString = event?.target?.value;
        setFilterValue(newString);

        if (newString) {
            const newRows = dureeCongeListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setDureeCongeListe({ ...dureeCongeListe, content: newRows });
        } else {
            getDureesConges();
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'dureesConges' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-duree-conge' })} />
            {successFetch ? (
                <>
                    <DureeCongeTable
                        dureeCongeListe={{
                            content: dureeCongeListe.content,
                            totalPages: dureeCongeListe.totalPages,
                            totalElements: dureeCongeListe.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        filterValue={filterValue}
                        getDureesConges={getDureesConges}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        handleFilterList={handleFilterList}
                    />
                    <DureeCongeModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        dureeToEdit={dureeToEdit}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        listeTypeConge={listeTypeConge}
                        listeTypesPersonnels={listeTypesPersonnels}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'durees-conge' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    TableSortLabel,
    Stack,
    Slide
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/system';
import { IconMinus } from '@tabler/icons';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const headCells = [
    {
        id: 'number',
        numeric: true,
        label: 'number',
        align: 'left'
    },
    {
        id: 'field',
        numeric: true,
        label: 'field',
        align: 'left'
    },
    {
        id: 'message',
        numeric: true,
        label: 'message',
        align: 'left'
    },
    {
        id: 'rejectedValue',
        numeric: true,
        label: 'rejectedValue',
        align: 'left'
    }
];

const EnhancedTableHead = ({ order, orderBy, onRequestSort, theme }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell) => {
                    return (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired
};

const Row = (props) => {
    const { index, row, theme } = props;

    return (
        <StyledTableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
            <StyledTableCell component="th" scope="row">
                #{index + 1}
            </StyledTableCell>
            <StyledTableCell>
                <Typography variant="h4" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                    {row.field}
                </Typography>
            </StyledTableCell>
            <StyledTableCell>{row.message}</StyledTableCell>
            <StyledTableCell>{row.rejectedValue}</StyledTableCell>
        </StyledTableRow>
    );
};

Row.propTypes = {
    index: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    theme: PropTypes.object
};

const ResponseFailures = ({ dataResponse, setDataResponse, ...tableProps }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const lenghtForColsPan = 5;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClose = () => {
        setDataResponse({ ...dataResponse, open: false });
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={dataResponse.open} TransitionComponent={Transition}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Response failures</DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <IconMinus />
                </IconButton>
            </Stack>
            <DialogContent>
                <MainCard content={false}>
                    <TableContainer>
                        <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                            <EnhancedTableHead
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />

                            {dataResponse.failures && (
                                <TableBody>
                                    {stableSort(dataResponse.failures, getComparator(order, orderBy))?.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        return <Row key={index} index={index} row={row} theme={theme} />;
                                    })}
                                    {dataResponse.failures.length <= 0 && (
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                                <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                    <FormattedMessage id="no-data" />
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </MainCard>
            </DialogContent>
        </Dialog>
    );
};
ResponseFailures.propTypes = {
    dataResponse: PropTypes.object.isRequired,
    setDataResponse: PropTypes.func.isRequired
};

export default ResponseFailures;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slide,
    Stack,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded, Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';
import AssessmentList from './AssessmentList';
import InputLabel from 'ui-component/extended/Form/InputLabel';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    assessmentName: yup.string().required('assessmentName'),
    maximumMarks: yup.string().required('maximumMarks'),
    isApplicable: yup.string().required('isApplicable')
});

const AddAssessmentCriteriaDialog = ({ api, snackAlert, params, open, setOpen, yourState, setYourState, refleshTable }) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);
    const [dataAssessments, setDataAssessments] = useState([]);

    const headCells = [
        {
            id: 'numero',
            numeric: true,
            label: 'No',
            align: 'left'
        },
        {
            id: 'assessmentName',
            numeric: true,
            label: 'assessmentName',
            align: 'left'
        },
        {
            id: 'maximumMarks',
            numeric: true,
            label: 'maximumMarks',
            align: 'left'
        },
        {
            id: 'isApplicable',
            numeric: true,
            label: 'isApplicable',
            align: 'center'
        }
    ];

    const deleteFunction = (row, indexOf) => {
        const arrayCopy = dataAssessments.filter((row, index) => index !== indexOf);
        setDataAssessments(arrayCopy);
    };

    const handleClickOpenCreateOrEditDialog = (row, indexOf) => {
        const arrayCopy = dataAssessments.filter((row, index) => index !== indexOf);
        setDataAssessments(arrayCopy);
        setYourState({
            ...yourState,
            assessment: {
                assessmentName: row.assessmentName,
                maximumMarks: row.maximumMarks,
                isApplicable: row.isApplicable
            }
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { id: '', assessmentName: '', maximumMarks: '', isApplicable: '' },
        validationSchema,
        onSubmit: (values) => {
            const selectedIndex = dataAssessments.findIndex((object) => {
                return object.assessmentName === values.assessmentName && object.maximumMarks === values.maximumMarks;
            });
            let newAssessments = [];
            if (selectedIndex >= 0) {
                snackAlert('elementExist', 'warning');
                return;
            }

            newAssessments = newAssessments.concat(dataAssessments, [
                {
                    assessmentName: values.assessmentName,
                    maximumMarks: values.maximumMarks,
                    isApplicable: values.isApplicable
                }
            ]);
            setDataAssessments(newAssessments);
        }
    });

    const reinitForm = () => {
        formik.resetForm();
        setDataAssessments([]);
    };

    const getSubmitAssessments = async () => {
        if (yourState.id !== '' && dataAssessments.length > 0) {
            setLoading(true);
            api.addAssessmentCriteria(yourState, dataAssessments)
                .then((res) => {
                    if (res.success) {
                        snackAlert('Operation reussie', 'success');
                        reinitForm();
                        refleshTable(params);
                        setLoading(false);
                    } else {
                        snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    };

    const handleClose = () => {
        setOpen({ ...open, addAss: false });
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={open.addAss} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>
                    Add AssessmentCriteria{' '}
                    <Typography variant="h4" component="div">
                        {yourState.criteriaName}
                    </Typography>
                </DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6}>
                            <InputField
                                size="small"
                                fullWidth
                                label="assessmentName"
                                id="assessmentName"
                                name="assessmentName"
                                value={formik.values?.assessmentName || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.assessmentName && Boolean(formik.errors.assessmentName)}
                                helperText={formik.touched.assessmentName && formik.errors.assessmentName}
                                placeholder="assessmentName"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                size="small"
                                fullWidth
                                type="number"
                                label="maximumMarks"
                                id="maximumMarks"
                                name="maximumMarks"
                                value={formik.values?.maximumMarks || ''}
                                handleChange={formik.handleChange}
                                error={formik.touched.maximumMarks && Boolean(formik.errors.maximumMarks)}
                                helperText={formik.touched.maximumMarks && formik.errors.maximumMarks}
                                placeholder="maximumMarks"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>isApplicable</InputLabel>
                            <RadioGroup
                                row
                                aria-label="isApplicable"
                                name="isApplicable"
                                id="maximumMarks"
                                value={formik.values?.isApplicable || ''}
                                onChange={(e) => formik.setFieldValue('isApplicable', e.target.value)}
                            >
                                <FormControlLabel
                                    defaultChecked
                                    value="true"
                                    control={<Radio color="primary" sx={{ color: 'primary.main' }} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="warning" sx={{ color: 'warning.main' }} />}
                                    label="No"
                                />
                            </RadioGroup>
                            <FormControl>
                                {formik.touched.isApplicable && formik.errors.isApplicable && (
                                    <FormHelperText error>{formik.errors.isApplicable}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                                <ButtonComponent
                                    variant="outlined"
                                    type="submit"
                                    size="small"
                                    color="secondary"
                                    startIcon={<Add />}
                                    text="Add"
                                    handleClick={() => {}}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <AssessmentList
                                actions
                                dataAssessments={dataAssessments}
                                headCells={headCells}
                                deleteFunction={deleteFunction}
                                editFunction={handleClickOpenCreateOrEditDialog}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        size="small"
                        text={<FormattedMessage id="save" />}
                        startIcon={<SaveRounded />}
                        handleClick={getSubmitAssessments}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddAssessmentCriteriaDialog.propTypes = {
    api: PropTypes.object.isRequired,
    snackAlert: PropTypes.func.isRequired,
    params: PropTypes.object,
    open: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    yourState: PropTypes.object.isRequired,
    setYourState: PropTypes.func.isRequired,
    refleshTable: PropTypes.func.isRequired
};

export default AddAssessmentCriteriaDialog;

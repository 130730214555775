import React, { useState } from 'react';
import { Grid, Stepper, StepButton, Step } from '@mui/material';
import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/SubCard';
import { PrimeIndemnites, Cotisations, Deductions } from './elementsPaie';

const steps = ['Prime et indemnite', 'Cotations', 'Deductions'];

function getStepContent(step, dataPrimeInd, setDataPrimeInd, dataCotisation, setDataCotisation, dataDeduction, setDataDeduction) {
    switch (step) {
        case 0:
            return <PrimeIndemnites dataPrimeInd={dataPrimeInd} setDataPrimeInd={setDataPrimeInd} />;
        case 1:
            return <Cotisations dataCotisation={dataCotisation} setDataCotisation={setDataCotisation} />;
        case 2:
            return <Deductions dataDeduction={dataDeduction} setDataDeduction={setDataDeduction} />;
        default:
            throw new Error('unknownStep');
    }
}

const PaieElements = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [dataPrimeInd, setDataPrimeInd] = useState([]);
    const [dataCotisation, setDataCotisation] = useState([]);
    const [dataDeduction, setDataDeduction] = useState([]);

    return (
        <SubCard sx={{ mt: gridSpacing, mb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid item xs={6}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index}>
                                        <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                        <>
                            {getStepContent(
                                activeStep,
                                dataPrimeInd,
                                setDataPrimeInd,
                                dataCotisation,
                                setDataCotisation,
                                dataDeduction,
                                setDataDeduction
                            )}
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </SubCard>
    );
};

export default PaieElements;

import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { sorteCongeAPI } from 'api';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import SorteCongeTable from './SorteCongeTable';
import SorteCongeModal from './SorteCongeModal';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [sorteCongeListe, setSorteCongeListe] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    });
    const [loading, setLoading] = useState(true);
    const [sorteCongeToDelete, setSorteCongeToDelete] = useState(null);
    const [sorteCongeToEdit, setSorteCongeToEdit] = useState({
        code: '',
        designation: '',
        taux: null
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const sorteCongePromise = sorteCongeAPI.getSortesConge(filterValue, {
            ...paginationDetails,
            pageNumber: paginationDetails.pageNumber - 1
        });
        axios
            .all([sorteCongePromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const sorteCongesData = responses[0]?.content;
                        const sorteCongesCongesData = [];
                        sorteCongesData.forEach((row) => {
                            sorteCongesCongesData.push({
                                id: row.id,
                                code: row.code,
                                designation: row.designation,
                                taux: row.taux
                            });
                        });
                        setSorteCongeListe((sorteCongeListe) => ({
                            ...sorteCongeListe,
                            ...responses[0],
                            content: sorteCongesCongesData
                        }));
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue, paginationDetails]);

    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setSorteCongeToEdit(row);
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (row) => {
        if (row) {
            setLoading(true);
            sorteCongeAPI
                .getSortesConge()
                .then((res) => {
                    setSorteCongeListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setSorteCongeToEdit({
            code: '',
            designation: '',
            taux: 0.0
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (sorteConge) => {
        setSorteCongeToDelete(sorteConge);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (sorteCongeToDelete) => {
        if (sorteCongeToDelete) {
            setSorteCongeListe(sorteCongeListe.filter((sorteConge) => sorteConge.id !== sorteCongeToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getSortesConges = (pageNumber, pageSize) => {
        setLoading(true);
        let sorteCongePromise;
        if (!pageNumber && !pageSize) {
            sorteCongePromise = sorteCongeAPI.getSortesConge(filterValue, {
                ...paginationDetails,
                pageNumber: paginationDetails.pageNumber - 1
            });
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            sorteCongePromise = sorteCongeAPI.getSortesConge(filterValue, {
                ...paginationDetails,
                pageNumber: pageNumber || paginationDetails.pageNumber - 1,
                pageSize: pageSize || paginationDetails.pageSize
            });
        }
        axios
            .all([sorteCongePromise])
            .then(
                axios.spread((...responses) => {
                    const sorteCongesCongesData = [];
                    responses[0]?.content?.forEach((row) => {
                        sorteCongesCongesData.push({
                            id: row.id,
                            code: row.code,
                            designation: row.designation,
                            taux: row.taux
                        });
                    });
                    setSorteCongeListe((sorteCongeListe) => ({
                        ...sorteCongeListe,
                        ...responses[0],
                        content: sorteCongesCongesData
                    }));
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        sorteCongeAPI
            .deleteSortesConges(sorteCongeToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppr-succes' })}`, 'success');
                getSortesConges();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };

    const handleFilter = (e) => {
        if (e) {
            setFilterValue(e.target.value);
        } else {
            setFilterValue('');
        }
    };

    const handleClearSearch = () => {
        setFilterValue('');
    };
    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getSortesConges(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getSortesConges(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (sorteCongeToEdit.id) {
            setLoading(true);
            sorteCongeAPI
                .updateSortesConges({
                    id: sorteCongeToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getSortesConges();
                    setSorteCongeToEdit({
                        ccode: '',
                        designation: '',
                        taux: null
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            sorteCongeAPI
                .saveSortesConges({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setSorteCongeToEdit({
                        code: '',
                        designation: '',
                        taux: null
                    });
                    getSortesConges();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFilterList = (event) => {
        const newString = event?.target?.value;
        setFilterValue(newString);

        if (newString) {
            const newRows = sorteCongeListe?.content?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setSorteCongeListe({ ...sorteCongeListe, content: newRows });
        } else {
            getSortesConges();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'sorteConges' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-sorte-conge' })} />
            {successFetch ? (
                <>
                    <SorteCongeTable
                        sorteCongeListe={{
                            content: sorteCongeListe.content,
                            totalPages: sorteCongeListe.totalPages,
                            totalElements: sorteCongeListe.totalElements
                        }}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        handleFilter={handleFilter}
                        filterValue={filterValue}
                        getSortesConges={getSortesConges}
                        handleClearSearch={handleClearSearch}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        handleFilterList={handleFilterList}
                    />
                    <SorteCongeModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        sorteCongeToEdit={sorteCongeToEdit}
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'sorte-conges' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;

import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getTypesDecision() {
    try {
        const response = await instance.get(`${baseURL}/decisions`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

export default {
    getTypesDecision
};

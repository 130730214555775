import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getListeGradesInferieurs(filterValue, paginationDetails) {
    return instance
        .get(`${baseURL}/lower-grades`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const saveGradeInferieur = async (gradeInferieur) => {
    return instance.post(`${baseURL}/lower-grades`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const updateGradeInferieur = async (gradeInferieur) => {
    return instance.put(`${baseURL}/lower-grades/${gradeInferieur.id}`, gradeInferieur).then(HandleResponse).catch(HandleError);
};

const deleteGradeInferieur = async (id) => {
    return instance.delete(`${baseURL}/lower-grades/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListeGradesInferieurs,
    saveGradeInferieur,
    updateGradeInferieur,
    deleteGradeInferieur
};

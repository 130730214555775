import { instance } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-hr-service/api/v1';

async function getTypesConges(filterValue, paginationDetails) {
    try {
        const response = await instance.get(`${baseURL}/type-conges`, {
            params: {
                codeDesignation: filterValue || null,
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        });
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
}

const saveTypesConges = async (typeConge) => {
    try {
        const response = await instance.post(`${baseURL}/type-conges`, typeConge);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const updateTypesConges = async (typeConge) => {
    try {
        const response = await instance.put(`${baseURL}/type-conges/${typeConge.id}`, typeConge);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

const deleteTypesConges = async (id) => {
    try {
        const response = await instance.delete(`${baseURL}/type-conges/${id}`);
        return HandleResponse(response);
    } catch (error) {
        return HandleError(error);
    }
};

export default {
    getTypesConges,
    saveTypesConges,
    updateTypesConges,
    deleteTypesConges
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconSettings, IconUsers, IconHelp, IconSitemap, IconBug, IconFlag, IconHandFinger } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconSettings,
    IconUsers,
    IconBug,
    IconFlag,
    IconHandFinger
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== // niveauFormation

const career = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'hierarchy',
            title: 'Structure',
            type: 'collapse',
            icon: icons.IconSitemap,
            children: [
                {
                    id: 'direction',
                    title: 'Direction',
                    type: 'item',
                    url: '/hierarchy/direction',
                    breadcrumbs: false
                },
                {
                    id: 'direction-gen',
                    title: 'Direction generale',
                    type: 'item',
                    url: '/hierarchy/direction-gen',
                    breadcrumbs: false
                },
                {
                    id: 'service',
                    title: 'Service',
                    type: 'item',
                    url: '/hierarchy/service',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'additional-params',
                    title: 'Additional params',
                    type: 'item',
                    url: '/settings/additional-params',
                    breadcrumbs: false
                },
                {
                    id: 'bank',
                    title: 'Bank',
                    type: 'item',
                    url: '/settings/bank',
                    breadcrumbs: false
                },
                {
                    id: 'document',
                    title: 'Documents et signataires',
                    type: 'item',
                    url: '/settings/document',
                    breadcrumbs: false
                },
                {
                    id: 'heure-sup',
                    title: 'Heure supplementaire',
                    type: 'item',
                    url: '/settings/heure-sup',
                    breadcrumbs: false
                },
                {
                    id: 'hr-elements',
                    title: 'hr-elements',
                    type: 'collapse',
                    children: [
                        {
                            id: 'typesPersonnels',
                            title: <FormattedMessage id="typesPersonnels" />,
                            type: 'item',
                            url: '/employee-types-list',
                            icon: icons.IconCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'pays',
                            title: <FormattedMessage id="paysOperationnel" />,
                            type: 'item',
                            url: '/countries-list',
                            icon: icons.IconFlag,
                            breadcrumbs: false
                        },
                        {
                            id: 'gradeInferieur',
                            title: <FormattedMessage id="gradeInferieur" />,
                            type: 'item',
                            url: '/lower-rank-list',
                            icon: icons.IconCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'grade',
                            title: <FormattedMessage id="grade" />,
                            type: 'item',
                            url: '/ranks-list',
                            icon: icons.IconCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'categoriePersonnel',
                            title: <FormattedMessage id="categoriePersonnel" />,
                            type: 'item',
                            url: '/settings/categories-list',
                            icon: icons.IconCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'type-formation',
                            title: 'Type formation',
                            type: 'item',
                            url: '/settings/type-formation',
                            breadcrumbs: false
                        },
                        {
                            id: 'type-contract',
                            title: <FormattedMessage id="type-contract" />,
                            type: 'item',
                            url: '/settings/type-contract',
                            breadcrumbs: false
                        },
                        {
                            id: 'holidays',
                            title: 'Holidays',
                            type: 'item',
                            url: '/settings/holidays',
                            breadcrumbs: false
                        },
                        {
                            id: 'relation',
                            title: 'Relation',
                            type: 'item',
                            url: '/settings/relation',
                            breadcrumbs: false
                        },
                        {
                            id: 'bareme-onpr',
                            title: <FormattedMessage id="baremeOnpr" />,
                            type: 'item',
                            url: '/settings/bareme-onpr',
                            breadcrumbs: false,
                            icon: icons.IconCircle
                        },
                        {
                            id: 'bareme-ipr',
                            title: <FormattedMessage id="baremeIpr" />,
                            type: 'item',
                            url: '/settings/bareme-ipr',
                            breadcrumbs: false,
                            icon: icons.IconCircle
                        }
                    ]
                }
            ]
        },
        {
            id: 'absences',
            title: 'Absences',
            type: 'item',
            url: '/absences',
            icon: icons.IconFlag,
            breadcrumbs: false
        },
        {
            id: 'file-employee',
            title: 'Fiche employee',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'collapse',
                    icon: icons.IconSettings,
                    children: [
                        {
                            id: 'type-visa',
                            title: 'Type visa',
                            type: 'item',
                            url: '/settings/type-visa',
                            breadcrumbs: false
                        },
                        {
                            id: 'relation',
                            title: 'Relation',
                            type: 'item',
                            url: '/settings/relation',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'employee',
                    title: 'Employee',
                    type: 'item',
                    url: '/employee-list',
                    breadcrumbs: false
                },
                {
                    id: 'employee-visa',
                    title: 'Visa Employee',
                    type: 'item',
                    url: '/employee-visa',
                    breadcrumbs: false
                },
                {
                    id: 'employee-dependents',
                    title: 'Personne en charge',
                    type: 'item',
                    url: '/employee-dependents',
                    breadcrumbs: false
                },
                {
                    id: 'employee-contract',
                    title: 'contract',
                    type: 'item',
                    url: '/employee-contract',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'training-management',
            title: 'training-management',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'collapse',
                    icon: icons.IconSettings,
                    children: [
                        {
                            id: 'niveauFormation',
                            title: <FormattedMessage id="niveauFormation" />,
                            type: 'item',
                            url: '/training-level',
                            icon: icons.IconCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'type-training',
                            title: 'type-training',
                            type: 'item',
                            url: '/type-training',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'trainer',
                    title: 'trainer',
                    type: 'item',
                    url: '/trainer',
                    breadcrumbs: false
                },
                {
                    id: 'training',
                    title: 'training',
                    type: 'item',
                    url: '/training',
                    breadcrumbs: false
                },
                {
                    id: 'training-participant',
                    title: 'training-participant',
                    type: 'item',
                    url: '/training-participant',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'leave-management',
            title: <FormattedMessage id="leave-management" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'leave-params',
                    title: <FormattedMessage id="leave-params" />,
                    type: 'item',
                    url: '/leave-management/leave-params',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leaves-kind',
                    title: <FormattedMessage id="sorte-conge" />,
                    type: 'item',
                    url: '/leave-management/leaves-kind',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-types',
                    title: <FormattedMessage id="type-conge" />,
                    type: 'item',
                    url: '/leave-management/leave-types',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-duration',
                    title: <FormattedMessage id="dureeConges" />,
                    type: 'item',
                    url: '/leave-management/leave-duration',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-ask',
                    title: <FormattedMessage id="demandeConges" />,
                    type: 'item',
                    url: '/leave-management/leave-ask',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-traetments',
                    title: <FormattedMessage id="traitementConges" />,
                    type: 'item',
                    url: '/leave-management/leave-traetments',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-plan',
                    title: <FormattedMessage id="planConge" />,
                    type: 'item',
                    url: '/leave-management/leave-plan',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leave-planning',
                    title: 'Leave planning',
                    type: 'item',
                    url: '/leave/leave-planning',
                    icon: icons.IconBug,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'outing-management',
            title: 'outing-management',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'employee-outing',
                    title: 'employee-outing',
                    type: 'item',
                    url: '/employee-outing',
                    breadcrumbs: false
                },
                {
                    id: 'employee-outing-treatment',
                    title: 'employee-outing-treatment',
                    type: 'item',
                    url: '/employee-outing-treatment',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'sanction-management',
            title: 'sanction-management',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'settings',
                    title: 'settings',
                    type: 'collapse',
                    icon: icons.IconSettings,
                    children: [
                        {
                            id: 'type-sanction',
                            title: 'Type sanction',
                            type: 'item',
                            url: '/settings/type-sanction',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'disciplinary-measure',
                    title: 'disciplinary-measure',
                    type: 'item',
                    url: '/disciplinary-measure',
                    breadcrumbs: false
                },
                {
                    id: 'sanction-appealing',
                    title: 'sanction-appealing',
                    type: 'item',
                    url: '/sanction-appealing',
                    breadcrumbs: false
                },
                {
                    id: 'sanction-decision',
                    title: 'sanction-decision',
                    type: 'item',
                    url: '/sanction-decision',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'evaluation-management',
            title: 'evaluation-management',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'collapse',
                    icon: icons.IconSettings,
                    children: [
                        {
                            id: 'type-appreciation',
                            title: 'type-appreciation',
                            type: 'item',
                            url: '/settings/type-appreciation',
                            breadcrumbs: false
                        },
                        {
                            id: 'type-rating',
                            title: 'type-rating',
                            type: 'item',
                            url: '/settings/type-rating',
                            breadcrumbs: false
                        },
                        {
                            id: 'evaluation-system-settings',
                            title: <FormattedMessage id="evaluation-system-settings" />,
                            type: 'item',
                            url: '/settings/evaluation-system-settings',
                            breadcrumbs: false,
                            icon: icons.IconCircle
                        }
                    ]
                },
                {
                    id: 'criteria',
                    title: 'criteria',
                    type: 'item',
                    url: '/evaluation/criteria',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'evaluation',
                    title: 'evaluation',
                    type: 'item',
                    url: '/evaluation',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'evaluation-visualisation',
                    title: 'evaluation-visualisation',
                    type: 'item',
                    url: '/evaluation/visualisation',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                }
            ]
        },
        {
            id: 'coordonnees-entreprise',
            title: <FormattedMessage id="coordonnees-entreprise" />,
            type: 'item',
            url: '/coordonnees-entreprise',
            icon: icons.IconHelp,
            breadcrumbs: false
        },
        {
            id: 'historique',
            title: 'Historique des operations',
            type: 'item',
            url: '/historique',
            icon: icons.IconBug,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'attendance',
            type: 'item',
            url: '/attendance',
            icon: icons.IconHandFinger,
            breadcrumbs: false
        },
        {
            id: 'rating-advancement-management',
            title: <FormattedMessage id="rating-advancement-management" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'rung',
                    title: <FormattedMessage id="rung" />,
                    type: 'item',
                    url: '/rating-advancement-management/rungs',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'grade-advancements',
                    title: <FormattedMessage id="avancementGrade" />,
                    type: 'item',
                    url: '/rating-advancement-management/grade-advancements',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                }
            ]
        },
        {
            id: 'gestion-sortie',
            title: <FormattedMessage id="gestion-sortie" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'leaves-ask',
                    title: <FormattedMessage id="demandeSortie" />,
                    type: 'item',
                    url: '/gestion-sortie/leaves-ask',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'leaves-traetment',
                    title: <FormattedMessage id="traitementSortie" />,
                    type: 'item',
                    url: '/gestion-sortie/leaves-traetment',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                }
            ]
        },
        {
            id: 'gestion-position',
            title: <FormattedMessage id="gestion-position" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'kind-position',
                    title: <FormattedMessage id="sortePosition" />,
                    type: 'item',
                    url: '/position-management/kind-position',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'type-position',
                    title: <FormattedMessage id="typesPositions" />,
                    type: 'item',
                    url: '/position-management/type-position',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'param-position',
                    title: <FormattedMessage id="paramPositions" />,
                    type: 'item',
                    url: '/position-management/param-position',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                }
            ]
        },
        {
            id: 'recruitment-management',
            title: <FormattedMessage id="recruitment-management" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'job-posting',
                    title: <FormattedMessage id="offreEmploi" />,
                    type: 'item',
                    url: '/recruitment-management/job-posting',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                },
                {
                    id: 'job-submissions',
                    title: <FormattedMessage id="jobSubmissions" />,
                    type: 'item',
                    url: '/recruitment-management/job-submissions',
                    breadcrumbs: false,
                    icon: icons.IconCircle
                }
            ]
        }
    ]
};

export default career;

import { instanceGlobal } from 'utils/tools';
import { HandleResponse, HandleError } from './apiUtils';

const baseURL = '/erp-payroll-global-service/api/v1';

async function getPaysOperationnels() {
    return instanceGlobal.get(`/countries`).then(HandleResponse).catch(HandleError);
}
async function getPays(filterValue) {
    if (filterValue) {
        return instanceGlobal
            .get(`${baseURL}/countries`, {
                params: { name: filterValue }
            })
            .then(HandleResponse)
            .catch(HandleError);
    }
    return instanceGlobal.get(`/countries`).then(HandleResponse).catch(HandleError);
}
async function getListePaysOperationnels(filterValue, paginationDetails) {
    return instanceGlobal
        .get(`${baseURL}/countries`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}
async function getListePaysOperationnelsFiltres(searchVal, paginationDetails) {
    return instanceGlobal
        .get(`${baseURL}/countries/code-designation/${searchVal}`, {
            params: {
                pageNumber: paginationDetails ? paginationDetails.pageNumber : null,
                pageSize: paginationDetails ? paginationDetails.pageSize : null
            }
        })
        .then(HandleResponse)
        .catch(HandleError);
}

const savePaysOperationnel = async (paysOperationnel) => {
    return instanceGlobal.post(`${baseURL}/countries`, paysOperationnel).then(HandleResponse).catch(HandleError);
};

const updatePaysOperationnel = async (paysOperationnel) => {
    return instanceGlobal.put(`${baseURL}/countries/${paysOperationnel.id}`, paysOperationnel).then(HandleResponse).catch(HandleError);
};

const deletePaysOperationnel = async (id) => {
    return instanceGlobal.delete(`${baseURL}/countries/${id}`).then(HandleResponse).catch(HandleError);
};
export default {
    getListePaysOperationnels,
    getPays,
    getListePaysOperationnelsFiltres,
    getPaysOperationnels,
    savePaysOperationnel,
    updatePaysOperationnel,
    deletePaysOperationnel
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'ui-component/InputField';
import ButtonComponent from 'ui-component/ButtonComponent';
import { Close, RotateLeftRounded, SaveRounded } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoader } from 'views/ui-elements/Loaders';
import { useTheme } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    appreciationTypeId: yup.string().required('appreciationTypeId')
});

const AddAppreciationDialog = ({
    api,
    params,
    open,
    setOpen,
    yourState,
    refleshTable,
    snackAlert,
    dataTypeAppreciation,
    ordinalSuffixOf
}) => {
    const theme = useTheme();
    const fullWidth = true;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: yourState.appreciation,
        validationSchema,
        onSubmit: (values) => {
            const valuesAppreciation = {
                evaluationCode: values.evaluationCode,
                appreciationTypeId: values.appreciationTypeId,
                appreciatorId: values.appreciatorId
            };
            if (values.appreciatorId === '') {
                setLoading(true);
                api.addAppreciation(valuesAppreciation)
                    .then((res) => {
                        if (res.success) {
                            if (res.data?.submitted) {
                                snackAlert(`${res.data?.message}`, 'success');
                            } else {
                                snackAlert(`${res.data?.message}`, 'warning');
                            }
                            refleshTable(params);
                            setLoading(false);
                        } else {
                            snackAlert(`${res.response?.data.description ? res.response.data.description : res.message}`, 'warning');
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                snackAlert('Select evaluation', 'warning');
            }
        }
    });

    const reinitForm = () => {
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        reinitForm();
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="sm" open={open} TransitionComponent={Transition}>
            <SpinnerLoader open={loading} />
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" mr={2}>
                <DialogTitle>Add Appreciation for {`${ordinalSuffixOf(yourState.nextEvaluation)} Evaluation`}</DialogTitle>
                <IconButton
                    disabled={loading}
                    onClick={handleClose}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: theme?.palette.error.light,
                            color: theme?.palette.primary.light
                        }
                    }}
                >
                    <Close />
                </IconButton>
            </Stack>
            <form onSubmit={formik.handleSubmit} data-testid="form-creation">
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <InputField
                                select
                                size="small"
                                fullWidth
                                label="select appreciationTypeId"
                                name="appreciationTypeId"
                                value={formik.values.appreciationTypeId || ''}
                                handleChange={(e, params) => {
                                    formik.setFieldValue('appreciationTypeId', e.target.value);
                                    formik.setFieldValue('appreciationType', params.props.option);
                                }}
                                error={formik.touched.appreciationTypeId && Boolean(formik.errors.appreciationTypeId)}
                                helperText={formik.touched.appreciationTypeId && formik.errors.appreciationTypeId}
                            >
                                <MenuItem value="">
                                    <em>none</em>
                                </MenuItem>
                                {dataTypeAppreciation.map((option, index) => (
                                    <MenuItem key={index} value={option.id} option={option}>
                                        {option.code} || {option.designation}
                                    </MenuItem>
                                ))}
                            </InputField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <ButtonComponent
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<RotateLeftRounded />}
                        text={<FormattedMessage id="reset" />}
                        handleClick={reinitForm}
                    />
                    <ButtonComponent
                        variant="contained"
                        type="submit"
                        size="small"
                        text={<FormattedMessage id="save" />}
                        startIcon={<SaveRounded />}
                        handleClick={() => {}}
                        data-testid="button-component"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddAppreciationDialog.propTypes = {
    api: PropTypes?.object.isRequired,
    params: PropTypes?.object,
    open: PropTypes?.bool.isRequired,
    setOpen: PropTypes?.func.isRequired,
    yourState: PropTypes?.object.isRequired,
    refleshTable: PropTypes?.func.isRequired,
    snackAlert: PropTypes?.func.isRequired,
    dataTypeAppreciation: PropTypes.array.isRequired,
    ordinalSuffixOf: PropTypes?.func.isRequired
};

export default AddAppreciationDialog;

import { useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Typography,
    IconButton,
    Tooltip,
    Stack,
    TableSortLabel,
    Checkbox,
    Toolbar,
    Chip
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage } from 'react-intl';
import { Delete, Edit } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { Box, alpha } from '@mui/system';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import { gridSpacing } from 'store/constant';

// styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'white',
        backgroundColor: theme.palette.table?.header,
        fontSize: 14,
        fontWeight: 'bold',
        border: '0.5px solid #ccc'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '0.5px solid #fff'
    },

    [`&.${tableCellClasses.footer}`]: {
        fontSize: 16,
        fontWeight: '700',
        border: '0.5px solid #ccc'
    }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme?.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = (props) => {
    const { headCells, theme, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </StyledTableCell>
                {headCells?.map((headCell, index) => {
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: theme.palette.secondary[200]
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: '#C7D6DA !important'
                                    }
                                }}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
                <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    Action
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    theme: PropTypes?.object,
    headCells: PropTypes?.array.isRequired,
    onRequestSort: PropTypes?.func.isRequired,
    order: PropTypes?.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes?.string.isRequired,
    onSelectAllClick: PropTypes?.func.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, selected, setDataSelected, setOpenDelete } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h4" id="tableTitle" component="div">
                    Dependent
                </Typography>
            )}
            {selected.length > 0 && (
                <>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => setDataSelected({ key: 'check', data: selected })}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" onClick={() => setOpenDelete(true)}>
                        <IconButton>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired,
    setDataSelected: PropTypes.func.isRequired,
    setOpenDelete: PropTypes.func.isRequired
};

const Actions = ({ row, index, deleteFunction }) => (
    <StyledTableCell>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={gridSpacing}>
            <Tooltip title={<FormattedMessage id="delete" />} arrow placement="left">
                <IconButton
                    component="span"
                    size="small"
                    onClick={() => {
                        deleteFunction(row, index);
                    }}
                    sx={{
                        ':hover': {
                            color: useTheme().palette.error.main
                        }
                    }}
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    </StyledTableCell>
);

Actions.propTypes = {
    row: PropTypes?.object,
    index: PropTypes?.number.isRequired,
    deleteFunction: PropTypes?.func.isRequired
};

const EmployeeDependentsList = ({
    actions,
    dataDependents,
    loading,
    headCells,
    deleteFunction,
    editFunction,
    staticLenght,
    selected,
    setSelected,
    setDataSelected,
    setOpenDelete,
    ...tableProps
}) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const isActions = actions ? 1 : 0;
    const lenghtForColsPan = headCells ? headCells.length + isActions : staticLenght + isActions;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const setCurrent = (row, index) => {
        editFunction(row, index, 'add');
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dataDependents.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (row) => selected.indexOf(row) !== -1;

    return (
        <MainCard content={false}>
            {loading ? (
                <EarningCard />
            ) : (
                <>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        selected={selected}
                        setDataSelected={setDataSelected}
                        setOpenDelete={setOpenDelete}
                    />
                    <TableContainer>
                        <Table size="small" id="tableToExport" sx={{ minWidth: 320 }} aria-label="customized table" {...tableProps}>
                            <EnhancedTableHead
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                                numSelected={selected.length}
                                rowCount={dataDependents.length}
                            />

                            {dataDependents && (
                                <TableBody>
                                    {stableSort(dataDependents, getComparator(order, orderBy))?.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        if (typeof row === 'number') return null;
                                        return (
                                            <StyledTableRow
                                                sx={{ ': hover td': { backgroundColor: '#DDEDF9', color: 'black', cursor: 'pointer' } }}
                                                tabIndex={-1}
                                                key={index}
                                                onDoubleClick={() => setCurrent(row, index)}
                                                data-testid="rowItems"
                                            >
                                                <StyledTableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId
                                                        }}
                                                        onClick={(event) => handleClick(event, row)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell scope="row" sx={{ cursor: 'pointer' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        #{index + 1}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.firstName} {row.middleName} {row.lastName}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.birthDate.toString().slice(0, 15)}</StyledTableCell>
                                                <StyledTableCell>{row.birthPlace}</StyledTableCell>
                                                <StyledTableCell>{row.gender}</StyledTableCell>
                                                <StyledTableCell>{row.relation?.designation}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.id > 0 ? (
                                                        <Chip label="exist" color="success" size="small" />
                                                    ) : (
                                                        <Chip label="new" color="error" size="small" />
                                                    )}
                                                </StyledTableCell>
                                                <Actions row={row} index={index} deleteFunction={deleteFunction} />
                                            </StyledTableRow>
                                        );
                                    })}
                                    {dataDependents.length <= 0 && (
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" colSpan={lenghtForColsPan}>
                                                <Typography textAlign="center" variant="subtitle1" color="inherit">
                                                    <FormattedMessage id="no-data" />
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </>
            )}
        </MainCard>
    );
};

EmployeeDependentsList.propTypes = {
    actions: PropTypes?.bool,
    params: PropTypes?.object,
    dataDependents: PropTypes?.array.isRequired,
    headCells: PropTypes?.array.isRequired,
    loading: PropTypes?.bool,
    staticLenght: PropTypes?.number,
    deleteFunction: PropTypes?.func.isRequired,
    editFunction: PropTypes?.func.isRequired,
    selected: PropTypes?.array.isRequired,
    setSelected: PropTypes?.func.isRequired,
    setDataSelected: PropTypes?.func.isRequired,
    setOpenDelete: PropTypes?.func.isRequired
};

export default EmployeeDependentsList;

import { useEffect, useState } from 'react';
import TypographyListDescription from 'ui-component/TypographyListDescription';
import TypographyListHeader from 'ui-component/TypographyListHeader';
import { niveauFormationAPI } from 'api';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DeleteDialog from 'ui-component/DeleteDialog';
import { useTheme } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonComponent from 'ui-component/ButtonComponent';
import NiveauTable from './NiveauTable';
import NiveauModal from './NiveauModal';

const Index = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
    const [niveauListe, setNiveauListe] = useState([]);
    const [loading, setLoading] = useState(true);
    const [niveauToDelete, setNiveauToDelete] = useState(null);
    const [niveauToEdit, setNiveauToEdit] = useState({
        code: '',
        designation: ''
    });
    const [reload, setReload] = useState(false);
    const [successFetch, setSuccessFetch] = useState(true);
    const [paginationDetails, setPaginationDetails] = useState({
        pageNumber: 1,
        pageSize: 5
    });
    const [params, setParams] = useState({
        sort: 'asc',
        sortOption: '',
        search: '',
        page: 0,
        rowsPerPage: 5,
        length: 1,
        totalItems: 0
    });
    const [searchVal, setSearchVal] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();
    const snackAlert = (message, state) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: state
        });
    };

    const loadData = async (params) => {
        let isSubscribed = true;

        setLoading(true);
        setSuccessFetch(true);
        const niveauPromise = niveauFormationAPI.getNiveauxFormation();
        axios
            .all([niveauPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const niveauData = [];
                        responses[0]?.forEach((row) => {
                            niveauData.push({
                                id: row.id,
                                code: row.code,
                                designation: row.designation
                            });
                        });
                        setNiveauListe(niveauData);
                        setParams({
                            ...params,
                            length: responses[0]?.totalPages || 0,
                            totalItems: responses[0]?.totalElements || 0
                        });
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    };

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        setSuccessFetch(true);
        const snackAlert = (message, state) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: state
            });
        };
        const niveauPromise = niveauFormationAPI.getNiveauxFormation();
        axios
            .all([niveauPromise])
            .then(
                axios.spread((...responses) => {
                    if (isSubscribed) {
                        const niveauData = [];
                        responses[0]?.forEach((row) => {
                            niveauData.push({
                                id: row.id,
                                code: row.code,
                                designation: row.designation
                            });
                        });
                        setNiveauListe(niveauData);
                        setParams((priv) => ({
                            ...priv,
                            length: responses[0]?.totalPages || 0,
                            totalItems: responses[0]?.totalElements || 0
                        }));
                        setSuccessFetch(true);
                    }
                })
            )
            .catch((err) => {
                if (isSubscribed) {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                }
                setSuccessFetch(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, [reload, dispatch, filterValue]);

    const handleChangePage = (event, newPage) => {
        setParams({ ...params, page: newPage - 1 });
    };
    const handleChangesRowsPerPage = (event) => {
        loadData({ ...params, page: 0, rowsPerPage: parseInt(event?.target.value, 10) });
    };
    const handleSearchValue = (event) => {
        const newString = event?.target.value;
        setSearchVal(newString || '');
        if (newString) {
            const newRows = niveauListe?.filter((row) => {
                let matches = true;

                const properties = ['code', 'designation'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setNiveauListe(newRows);
        } else {
            setNiveauListe(niveauListe);
        }
    };

    const handleClickSearch = () => {
        loadData({
            ...params,
            search: searchVal
        });
    };

    // open create or delete dialog
    const handleClickOpenCreateOrEditDialog = (row) => {
        if (row) {
            setNiveauToEdit(row);
        }
        setOpenDialog(true);
    };

    const handleClickCloseCreateOrEditDialog = (response) => {
        if (response) {
            setLoading(true);
            niveauFormationAPI
                .getNiveauxFormation()
                .then((res) => {
                    setNiveauListe(res);
                })
                .catch((err) => {
                    snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        setNiveauToEdit({
            code: '',
            designation: ''
        });
        setOpenDialog(false);
    };

    // open delete alert dialog
    const handleClickOpenDeleteAlertDialog = (niveau) => {
        setNiveauToDelete(niveau);
        setOpenDeleteAlertDialog(true);
    };
    const handleClickCloseDeleteAlertDialog = (niveauToDelete) => {
        if (niveauToDelete) {
            setNiveauListe(niveauListe.filter((niveau) => niveau.id !== niveauToDelete.id));
        }
        setOpenDeleteAlertDialog(false);
    };

    const toggleReload = () => {
        setReload(!reload);
    };

    const getNiveauFormation = (pageNumber, pageSize) => {
        setLoading(true);
        let niveauPromise;
        if (!pageNumber && !pageSize) {
            niveauPromise = niveauFormationAPI.getNiveauxFormation();
            setPaginationDetails({
                ...paginationDetails,
                pageNumber: 1
            });
        } else {
            niveauPromise = niveauFormationAPI.getNiveauxFormation();
        }
        axios
            .all([niveauPromise])
            .then(
                axios.spread((...responses) => {
                    const niveauData = responses[0];
                    setNiveauListe(niveauData);
                })
            )
            .catch((err) => {
                snackAlert(`${err.response?.data?.message ? err.response?.data?.message : err.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDelete = () => {
        niveauFormationAPI
            .deleteNiveauFormation(niveauToDelete.id)
            .then(() => {
                snackAlert(`${intl.formatMessage({ id: 'suppression-niveau-formation-succes' })}`, 'success');
                getNiveauFormation();
            })
            .catch((err) => {
                snackAlert(`${err.response?.data.message ? err.response.data.message : err.message}`, 'error');
            })
            .finally(() => {
                setOpenDeleteAlertDialog(false);
            });
    };
    const handleFilter = (e) => {
        if (e) {
            setFilterValue(e.target.value);
        } else {
            setFilterValue('');
        }
    };

    const handleClearSearch = () => {
        setFilterValue('');
        loadData();
        getNiveauFormation();
    };
    const handleChangeRowsPerPage = (e) => {
        const value = parseInt(e.target.value, 10);
        setPaginationDetails({
            ...paginationDetails,
            pageSize: value,
            pageNumber: 1
        });
        getNiveauFormation(0, value);
    };
    const paginate = (e, value) => {
        setPaginationDetails({
            ...paginationDetails,
            pageNumber: value
        });
        getNiveauFormation(value);
    };
    const handleSubmit = (values, resetForm) => {
        if (niveauToEdit.id) {
            setLoading(true);
            niveauFormationAPI
                .updateNiveauFormation({
                    id: niveauToEdit.id,
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'modif-succes' })}`, 'success');
                    getNiveauFormation();
                    setNiveauToEdit({
                        code: '',
                        designation: ''
                    });
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            niveauFormationAPI
                .saveNiveauFormation({
                    ...values
                })
                .then(() => {
                    snackAlert(`${intl.formatMessage({ id: 'ajout-succes' })}`, 'success');
                    setNiveauToEdit({
                        code: '',
                        designation: ''
                    });
                    getNiveauFormation();
                    setOpenDialog(false);
                    resetForm();
                })
                .catch((err) => {
                    snackAlert(err.response?.data ? err.response?.data?.description : err.message, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyListHeader text={intl.formatMessage({ id: 'niveauFormation' })} />
            <TypographyListDescription text={intl.formatMessage({ id: 'cvm-niveau-formation' })} />
            {successFetch ? (
                <>
                    <NiveauTable
                        niveauListe={niveauListe}
                        loading={loading}
                        handleClickOpenDeleteAlertDialog={handleClickOpenDeleteAlertDialog}
                        handleClickOpenCreateOrEditDialog={handleClickOpenCreateOrEditDialog}
                        handleFilter={handleFilter}
                        filterValue={filterValue}
                        getNiveauFormation={getNiveauFormation}
                        handleClearSearch={handleClearSearch}
                        paginate={paginate}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationDetails={paginationDetails}
                        handleChangesRowsPerPage={handleChangesRowsPerPage}
                        handleChangePage={handleChangePage}
                        searchVal={searchVal}
                        handleSearchValue={handleSearchValue}
                        handleClickSearch={handleClickSearch}
                        params={params}
                    />
                    <NiveauModal
                        open={openDialog}
                        handleClose={handleClickCloseCreateOrEditDialog}
                        niveauToEdit={niveauToEdit}
                        snackAlert={snackAlert}
                        handleSubmit={handleSubmit}
                        theme={theme}
                        loading={loading}
                        ButtonComponent={ButtonComponent}
                    />

                    <DeleteDialog
                        text={intl.formatMessage({ id: 'niveau-formation' })}
                        handleClose={handleClickCloseDeleteAlertDialog}
                        open={openDeleteAlertDialog}
                        snackAlert={snackAlert}
                        handleDelete={handleDelete}
                    />
                </>
            ) : (
                <MainCard content>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ReplayIcon />}
                            onClick={() => {
                                toggleReload();
                            }}
                        >
                            <FormattedMessage id="reload" />
                        </Button>
                    </div>
                </MainCard>
            )}
        </div>
    );
};

export default Index;
